<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">角色管理</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	<el-row :gutter="15">
		<el-col :span="24">
			<div class="panel">
				<div class="panel-body pad16" ref="userlist">
					
					<div class="panel-body" style="border-bottom: 1px solid #EBEEF5;">
						<div class="fcj_lr table-filter">
							<div class="fcj_ll">
					
							</div>
							<div style="margin-left:10px;">
								<router-link to="/roleedit"><el-button type="primary" size="small" icon="el-icon-plus">添加角色</el-button></router-link>
							</div>
						</div>
					</div>
					<template>
						<el-table :data="tableData" header-cell-class-name="table-header" stripe>
							<el-table-column prop="groupsid" label="编号" width="50">
							</el-table-column>
							<el-table-column prop="gname" label="角色名称" width="160">
							</el-table-column>
							<el-table-column prop="mname" label="权限">
								<template slot-scope="scope">
									<div>{{scope.row.mname.join('，')}}</div>
									</template>
							</el-table-column>
							<el-table-column label="" width="100">
								<template slot-scope="scope">
									<el-button size="mini" slot="reference" icon="el-icon-edit" @click.stop="edit(scope.$index, scope.row)" circle style="margin-right: 10px;">
									</el-button>
									<el-popover placement="top" width="60" v-model="scope.row.visible">
										<p>删除后与此角色相关的人员权限将全部关闭！确定删除吗？</p>
										<div style="text-align: right; margin: 0">
											<el-button size="mini" type="text"
												@click.stop="scope.row.visible = false">取消</el-button>
											<el-button type="primary" size="mini"
												@click.stop="handleDelete(scope.$index, scope.row)">确定
											</el-button>
										</div>
										<el-button size="mini" slot="reference" icon="el-icon-delete" circle>
										</el-button>
									</el-popover>
								
								</template>
							</el-table-column>
							
						</el-table>
					</template>
				</div>
				<div class="panel-foot">
					<div class="pad16"></div>
				</div>
			</div>
		</el-col>
	</el-row>	
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				formname:'角色管理',
				might:{},
				ruleForm:{
					gname:'',
					mights:[],
					id:''
				},
				rules:{
					gname: [{
						required: true,
						message: '请输角色名称',
						trigger: 'blur'
					}],
					mights: [{
						type: 'array',
						required: true,
						message: '请至少选择一个权限',
						trigger: 'blur'
					}]
				}
			}
		},
		mounted: function() {
			
			this.ini();
			
			this.host = this.$host
		},
		methods: {
			edit(index, row){
				this.$router.push('/roleedit?id='+row.groupsid)
			},
			showadd(){
				var f={
					gname:'',
					mights:[],
					id:''
				}
				this.ruleForm=f;
			},
			handleDelete(index, row) {
				this.$ajax({
					url: 'role/',
					data: {
						'do': 'del',
						'id': row.groupsid
					},
				}).then(res=> {
						this.ini();
					
				})
			},
			submitForm(formName) {
				
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.ruleForm.do='add';console.log(this.ruleForm);
							var data=this.ruleForm;
							data.mights=data.mights.join(',');
							this.$ajax({
								url: 'role/',
								data: this.ruleForm,
							}).then(res=> {
									if (res.error > 0) {
										this.$message({
											message: res.msg,
											type: 'warning'
										});
									} else {
										this.$message({
											message: '保存成功',
											type: 'success'
										});
										this.showadd();
										this.ini();
									}
								
							})
						} else {
							return false;
						}
					});
				
				},
			ini() {
				this.$ajax({
					url: 'role/',
					data: {
						'do': 'ini'
					},
				}).then(res=> {
						for (var i = 0; i < res.groups.length; i++) {
							var a = res.groups[i].mights?res.groups[i].mights.split(','):[];
							res.groups[i].mights=a;
							res.groups[i].mname = [];
							for (var j = 0; j < a.length; j++) {

								res.groups[i].mname.push(res.might[a[j]].name);
							}
						}
						this.tableData = res.groups;
						this.might = res.might
					
				})
			}
		},
	}
</script>

<style>
</style>
