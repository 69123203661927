<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">空间查询</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel">
					<div class="panel-body pad16">
						<div class=" table-filter">
							<div class="fcj_ll" >
								<el-select v-model="filter.build" slot="prepend" size="small" clearable placeholder="楼栋名称"
									@change="goinfo" class="searchitem" style="width:120px">
									<el-option v-for="item in building" :key="item.value" :label="item.bname"
										:value="item.id">
									</el-option>
								</el-select>
								<el-select v-model="filter.floor" slot="prepend" size="small" clearable placeholder="楼层名称"
									@change="chfilter" class="searchitem" style="width:120px">
									<el-option v-for="item in floor.res" :key="item.ordid" :label="item.fname"
										:value="item.ordid">
									</el-option>
								</el-select>
								<el-select v-model="filter.tj" slot="prepend" size="small" clearable placeholder="是否统计"
									@change="chfilter" class="searchitem" style="width:120px">
									<el-option  label="全部" value="">全部</el-option>
									<el-option  label="统计" value="1">统计</el-option>
									<el-option  label="不统计" value="2">不统计</el-option>
								</el-select>
								<el-select v-model="filter.type" slot="prepend" size="small" clearable placeholder="物业类型"
									@change="chfilter" class="searchitem" style="width:120px">
									<el-option v-for="(item,index) in officetype" :key="index" :label="item.class_name"
										:value="index">
									</el-option>
								</el-select>
								<el-select v-model="filter.ptype"  size="small" clearable placeholder="物业属性"
									@change="chfilter" class="searchitem" style="width:120px">
									
									<el-option v-for="(item,index) in buildingtype" :key="index" :label="item.class_name"
										:value="index">
									</el-option>
									
								</el-select>
								<el-select v-model="filter.state" slot="prepend" size="small" clearable placeholder="房间状态"
									@change="chfilter" class="searchitem" style="width:100px">
									<el-option label="正常" value="1"></el-option>
									<el-option label="失效" value="0"></el-option>
								</el-select>
								<el-select v-model="filter.rentstate" slot="prepend" size="small" clearable placeholder="租赁状态"
									@change="chfilter" class="searchitem" style="width:100px">
									<el-option v-for="item in rentstate" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
								</el-select>
							</div>
							<div class="fcj_ll" style="margin-top:10px;">
								<div class="fcj_ll">
									<div style="padding:0 10px;">空间面积</div>
									<div><el-input v-model="filter.minarea" size="small" placeholder="" clearable style="width:80px" @keyup.enter.native="getdata"></el-input></div>
									<div style="padding:0 4px;">~</div>
									<div><el-input v-model="filter.maxarea" size="small" placeholder="" clearable style="width:80px" @keyup.enter.native="getdata"></el-input></div>
								</div>
								
								<div style="padding:0 4px;"><el-input size="small" v-model="filter.endtime" clearable placeholder="剩余租期(天)" style="width:130px"></el-input></div>
								<div v-if="showqu">
									<el-select v-model="filter.officearea" size="small" clearable placeholder="区"
									@change="getdata" style="margin-right:10px;width:100px;" >
									<el-option value="">全部</el-option>
									<el-option primary v-for="(item,index) in officearea" :label="item" :value="item" :key="index">
										{{item}}
									</el-option>
										
									</el-select>
								</div>
								<div><el-button size="small" type="primary" @click="getdata()" icon="el-icon-search">查询</el-button></div>
								<div style="margin-left:4px;"><el-button size="small" type="info" @click="reset()">重置</el-button></div>
							</div>
						</div>
							<div>
								<el-table v-loading="isLoading"  :data="filter.rentstate==''?res:res.slice((pgno-1)*pgsize, res.length>pgsize?pgno*pgsize:res.length)" header-cell-class-name="table-header" stripe>
									<el-table-column width="40">
									</el-table-column>
									<el-table-column prop="bname" label="楼栋楼层" width="150px">
										<template slot-scope="scope">
											<router-link :to="'/areaedit?bid='+scope.row.building+'&fid='+scope.row.floor">{{scope.row.bname+scope.row.fname}}</router-link>
										</template>
									</el-table-column>
									<el-table-column prop="roomnumber" label="门牌名称" width="180">
										<template slot-scope="scope">
											<router-link :to="'/officeinfo?bid='+scope.row.building+'&fid='+scope.row.floor+'&id='+scope.row.id">{{scope.row.roomnumber}}</router-link>
										</template>
									</el-table-column>

									<el-table-column label="物业类型" width="80">
										<template slot-scope="scope">
											{{officetype[scope.row.type]?officetype[scope.row.type].class_name:''}}
										</template>
									</el-table-column>
									<el-table-column label="租赁状态" width="80">
										<template slot-scope="scope">
											{{rentstate[scope.row.state-1].label}}
										</template>
									</el-table-column>
									<el-table-column label="房间状态" width="80">
										<template slot-scope="scope">
											{{scope.row.state==0?'失效':'正常'}}
										</template>
									</el-table-column>
									<el-table-column label="装修状态" width="80">
										<template slot-scope="scope">
											{{scope.row.renovation_state==1?'装修中':'无'}}
										</template>
									</el-table-column>
									<el-table-column prop="builduparea" label="面积（㎡）" width="100">
									</el-table-column>
									<el-table-column label="当前租期" width="180">
										<template slot-scope="scope">
											{{scope.row.starttime+'~'+scope.row.endtime}}
										</template>
									</el-table-column>
									<el-table-column prop="company" label="当前租户">
										<template slot-scope="scope">
											<router-link :to="'/editcustomer?proview=1&id='+scope.row.customerid">{{scope.row.company}}</router-link>
										</template>
									</el-table-column>
									<el-table-column label="合同编号">
										<template slot-scope="scope">
											<router-link :to="'/contractinfo?id='+scope.row.contractid">{{scope.row.number}}</router-link>
										</template>
									</el-table-column>

									<template>
										<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
									</template>
								</el-table>
								<div style="padding:15px 0;text-align: right;">
									<div class="fcj_lr">
										<div style="font-size:12px;color:#999;"><el-tag size="small" type="info">合计面积： {{allarea}} 平米</el-tag></div>
										<div>
											<el-pagination background layout="total,prev, pager, next" :current-page.sync="pgno" :page-size="pgsize"
												@current-change="getdata" :total="pgcount">
											</el-pagination>

										</div>
									</div>
									
								</div>
							</div>
						</div>
					</div>
					
			</el-col>
		</el-row>


	</div>
</template>

<script>
	export default {
		data() {
			return {
				isLoading:false,
				filter:{
					type:'',
					ptype:'',
					build:'',
					floor:'',
					officetype:'',
					state:'1',
					rentstate:'',
					minarea:'',
					maxarea:'',
					endtime:'',
					tj:'',
					repair:'',
					officearea:''
					
				},
				showqu:false,
				building:[],
				officetype: [],
				statemap:{
					'2':{
						value: '2',
						label: '空置中'
					},
					'1':{
						value: '1',
						label: '出租中'
					},
					'0':{
						value: '0',
						label: '失效'
					}
				},
				
				rentstate:[
					{
						value: '1',
						label: '出租中'
					},{
						value: '2',
						label: '空置中'
					},{
						value: '3',
						label: '预定'
					}
				],
				host: '',
				res: [],
				floor: {},
				offices: {},
				pgno: 1,
				pgcount: 0,
				pgsize:13,
				allarea:'',
				buildingtype:[],
				officearea:[]
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=[to];
			})
		},
		mounted: function() {
			if (this.$route.query.bid != undefined) {
				this.filter.build = this.$route.query.bid;
			}

			if(this.$route.query.repair==1){
				this.filter.repair=1
			}
			this.host = this.$host
			if(this.host=='https://admin.kefan.com/zhfty/'||this.host=='http://localhost/zhfty/'){
				this.showqu=true;
			}
			this.getdata();

		},
		methods: {
			reset(){
				this.filter={
					type:'',
					build:'',
					floor:'',
					officetype: '',
					state:'',
					minarea:'',
					maxarea:'',
					endtime:'',
					repair:''

				}
				this.getdata();
			},
			
			goinfo(v) {
				this.pgno=1;
				this.filter.floor='';
				this.$ajax({
					url: 'floor/',
					data: {
						'buildingid': this.filter.build
					},
				}).then(res => {
					this.floor = res;
					this.getdata();

				})
			},
			chfilter(){
				this.pgno=1;
				this.getdata();
			},
			getdata() {
				
				var data=this.filter;
				if(data.rentstate==''||this.pgno==1){
					this.isLoading=true;
					data.do='getdata';
					data.pgno=this.pgno;
					data.pgsize=this.pgsize;
					this.$ajax({
						url: 'building/',
						data: data
					}).then(res => {
						this.isLoading=false;
						this.res = res.res;
						this.officetype=res.roomtype;
						this.buildingtype=res.buildingtype;
						this.allarea=res.allarea;
						this.building=res.building
						this.pgcount = parseInt(res.count);
						this.officearea=res.officearea;
					})
				}
			}
		}
	};
</script>

<style>


</style>
