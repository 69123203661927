<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadcrumb" :key="index">{{item.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row>
			<el-col>
				<div class="panel">
					<el-row>
						<el-col :span="16">
							<div class="info res pad16" style="line-height:180%;font-size:12px;" v-if="banks.length>0">
								
								
								<el-descriptions title="" :column="1" size="medium">
									<el-descriptions-item label="合同编号" label-class-name="des-label" content-class-name="des-content">
										{{info.number}}
									</el-descriptions-item>
									<el-descriptions-item label="客户名称" label-class-name="des-label" content-class-name="des-content">
										{{info.company}}
									</el-descriptions-item>
									<el-descriptions-item label="租赁期限" label-class-name="des-label" content-class-name="des-content">
										{{info.regist_time+' ~ '+info.term}}
									</el-descriptions-item>
									<el-descriptions-item label="费用类型" label-class-name="des-label" content-class-name="des-content">
										{{paytype[info.typeid]?paytype[info.typeid].class_name:''}}
									</el-descriptions-item>
									<el-descriptions-item label="账单周期" label-class-name="des-label" content-class-name="des-content">
										{{info.ra1}}
									</el-descriptions-item>
								</el-descriptions>
								
								
								<div class="dotline"></div>
								
								<el-descriptions :column="1" size="medium">
									<el-descriptions-item label="账单类型" label-class-name="des-label" content-class-name="des-content">
										{{info.io==1?'负项账单':'进项账单'}}
									</el-descriptions-item>
									<el-descriptions-item label="账单总金额" label-class-name="des-label" content-class-name="des-content">
										¥ {{Math.abs(info.amountmoney)}}
									</el-descriptions-item>
									<el-descriptions-item :label="info.io==1?'实付金额':'实收金额'" label-class-name="des-label" content-class-name="des-content">
										¥ {{Math.abs(info.paymoney)}}
									</el-descriptions-item>
									<el-descriptions-item label="抵扣" label-class-name="des-label" content-class-name="des-content">
										¥ {{info.offset/100}}
									</el-descriptions-item>
									<el-descriptions-item :label="info.io==1?'应付金额':'应收金额'" label-class-name="des-label" content-class-name="des-content">
										¥ {{(Math.abs(info.amountmoney)-Math.abs(info.paymoney)-(info.offset>0?info.offset/100:0)).toFixed(2)}}
									</el-descriptions-item>
									
									<el-descriptions-item label="账单头寸" label-class-name="des-label" content-class-name="des-content">
										¥ {{info.payed==2?(info.amountmoney-info.paymoney-(info.offset>0?info.offset/100:0)).toFixed(2):0}}
									</el-descriptions-item>
								</el-descriptions>
								
								
								
								
								<div class="dotline"></div>
								
								<el-descriptions :column="1" size="medium">
									<el-descriptions-item :label="info.io==1?'付款账号':'收款账号'" label-class-name="des-label" content-class-name="des-content">
										{{info.io==1?banks[info.linkbill[0].paycount].des:banks[info.paycount].des}}
									</el-descriptions-item>
									<el-descriptions-item label="最迟付款日期" label-class-name="des-label" content-class-name="des-content">
										{{info.endtime=='0000-00-00'?'':info.endtime}}
									</el-descriptions-item>
									<el-descriptions-item label="备注说明" label-class-name="des-label" content-class-name="des-content">
										{{info.note}}
									</el-descriptions-item>
									<el-descriptions-item label="账单状态" label-class-name="des-label" content-class-name="des-content">
										{{billstate[info.state]}}
									</el-descriptions-item>
									<el-descriptions-item label="读取状态" label-class-name="des-label" content-class-name="des-content">
										{{readstatemap[info.readstate].name}}
									</el-descriptions-item>
									<el-descriptions-item label="付款状态" label-class-name="des-label" content-class-name="des-content">
										{{paystate[info.payed].name}}
									</el-descriptions-item>
									
									<el-descriptions-item label="账单创建人" label-class-name="des-label" content-class-name="des-content">
										{{info.mname||info.musern||(info.uid>0?info.uid:'')}}
									</el-descriptions-item>
									<el-descriptions-item label="创建时间" label-class-name="des-label" content-class-name="des-content">
										{{info.create_time}}
									</el-descriptions-item>
									<el-descriptions-item label="结算发起人" label-class-name="des-label" content-class-name="des-content">
										{{info.mmname||info.mmusern||(info.dealuid==0?'':info.dealuid)}}
									</el-descriptions-item>
									<el-descriptions-item label="结算发起时间" label-class-name="des-label" content-class-name="des-content">
										{{info.settlement_time}}
									</el-descriptions-item>
									<el-descriptions-item label="开票状态" label-class-name="des-label" content-class-name="des-content">
										{{info.invoicestate==1?'已登记':'未登记'}}
									</el-descriptions-item>
									<el-descriptions-item label="发票编码" label-class-name="des-label" content-class-name="des-content">
										{{info.invoice}}
									</el-descriptions-item>
									<el-descriptions-item label="发票登记人" label-class-name="des-label" content-class-name="des-content">
										{{info.invoicename||info.invoiceusern}}
									</el-descriptions-item>
									<el-descriptions-item label="发票登记时间" label-class-name="des-label" content-class-name="des-content">
										{{info.invoice_in_time}}
									</el-descriptions-item>
									<el-descriptions-item label="是否坏账" label-class-name="des-label" content-class-name="des-content">
										{{info.isbad=='1'?'是':'否'}}  <el-button style="margin-left:20px;" @click="badDebts">{{info.isbad=='1'?'取消':''}}坏账</el-button>
									</el-descriptions-item>
								</el-descriptions>

								
								<div class="block">
									<div v-if="info.typeid==91">
										<div class="fcjtitle">
											费用明细
										</div>
										<div>
											<div class="fcj_ll" style="margin-bottom:10px;margin-left:10px;font-size:13px;">
												<div>导入批次号：{{billmeter[0].filenumber}}</div>
												<div style="padding-left:20px;">文件名：{{billmeter[0].filename}}</div>
											</div>
											<el-table :data="billmeter" header-cell-class-name="table-header" stripe>
												<el-table-column  prop="type" label="项目" >
												</el-table-column>
												<el-table-column  prop="month" label="日期" >
												</el-table-column>
												<el-table-column  prop="predigit" label="上月抄见数" >
												</el-table-column>
												<el-table-column  prop="thidigit" label="本月抄见数" >
												</el-table-column>
												<el-table-column  prop="watt" label="耗用" >
												</el-table-column>
												<el-table-column  prop="price" label="单价" >
												</el-table-column>
												<el-table-column  prop="magnification" label="倍率" >
												</el-table-column>
												<el-table-column  prop="amount" label="金额" >
												</el-table-column>
											</el-table>
											
										</div>
																	
									</div>
									<div class="fcjtitle">
										对公开票信息
									</div>
									<el-descriptions :column="1" size="medium">
										<el-descriptions-item label="单位名称" label-class-name="des-label" content-class-name="des-content">
											{{info.taxcompany}}
										</el-descriptions-item>
										<el-descriptions-item label="税号" label-class-name="des-label" content-class-name="des-content">
											{{info.taxnumber}}
										</el-descriptions-item>
										<el-descriptions-item label="地址及电话" label-class-name="des-label" content-class-name="des-content">
											{{info.taxaddress}}
										</el-descriptions-item>
										<el-descriptions-item label="开户行及账号" label-class-name="des-label" content-class-name="des-content">
											{{info.taxbankinfo}}
										</el-descriptions-item>
									</el-descriptions>
									<div>
																
										<div class="fcjtitle">合同负责人（<i class="el-icon-circle-check"></i>已读，<i class="el-icon-minus"></i>未读）</div>
										<div style="padding:20rpx 0;">
											<el-button v-for="(item,index) in info.readuser" :key="index" :icon="item.look?'el-icon-circle-check':'el-icon-minus'">
												{{item.cname}}
											</el-button>
										</div>
																
									</div>
								</div>
								
								
							

							</div>
						</el-col>
						<el-col :span="8">
							<el-row>
								<el-col>
									<div class="mapright" style="padding: 10px;">
										<div class="fcjtitle">账单编辑记录</div>
										<div>
											
												<el-table :data="log" style="width:100%">
													<el-table-column prop="content" label="操作内容">
													</el-table-column>
													<el-table-column prop="create_time" label="" width="200" align="right">
														<template slot-scope="scope">
															
																<div class="fcj_ll" style="justify-content:right">
																	
																	<div>
																		{{(scope.row.name||scope.row.usern)}}
																	</div>
																	<div v-if="scope.row.logo">
																		<img :src="scope.row.logo" class="header-logo" style="margin-left:10px;">
																	</div>
																</div>
																<div style="margin-top:10px;">{{scope.row.phone}}</div>
																<div>{{scope.row.create_time}}</div>
																
															
														</template>
													</el-table-column>
												</el-table>
												
											
										</div>
										
										<div class="fcjtitle">账单付款记录</div>
										<div>
											<el-table :data="billloglist" style="width:100%">
												<el-table-column prop="money" label="付款金额">
													<template slot-scope="scope">
														￥{{scope.row.money/100}} <el-tag size="mini" v-if="scope.row.tp>0">抵</el-tag>
													</template>
												</el-table-column>
												<el-table-column prop="dt" label="付款日期">
												</el-table-column>
												<el-table-column prop="dt" label="登记人">
													<template slot-scope="scope">
														{{scope.row.name||scope.row.usern||scope.row.uuid}}
													</template>
												</el-table-column>
												<el-table-column prop="create_time" label="登记时间">
												</el-table-column>
											</el-table>
										</div>
										<div >
											<div class="fcjtitle">关联账单</div>
											<div>
												<el-table :data="info.linkbill" style="width:100%">
													<el-table-column prop="money" label="类型">
														<template slot-scope="scope">
															{{paytype[scope.row.typeid].class_name}}
														</template>
													</el-table-column>
													<el-table-column prop="amountmoney" label="账单总金额">
														<template slot-scope="scope">
															¥ {{Math.abs(scope.row.amountmoney/100)}}
														</template>
													</el-table-column>
													<el-table-column prop="dt" label="状态" width="70">
														<template slot-scope="scope">
															{{billstate[scope.row.state]}}
														</template>
													</el-table-column>
													<el-table-column prop="io" label="出入" width="50">
														<template slot-scope="scope">
															{{scope.row.io==1?'出':'入'}}
														</template>
													</el-table-column>
													<el-table-column prop="create_time" label="" width="50">
														<template slot-scope="scope">
															<u @click="gobillinfo(scope.row.id)" style="cursor:pointer;">详情</u>
														</template>
													</el-table-column>
												</el-table>
											</div>
										</div>
										<!-- <div >
											<div class="fcjtitle">抵扣账单</div>
											<div>
												<el-table :data="info.di" style="width:100%">
													<el-table-column prop="money" label="抵扣账单类型">
														<template slot-scope="scope">
															{{paytype[scope.row.typeid].class_name}}
														</template>
													</el-table-column>
													<el-table-column prop="amountmoney" label="抵扣金额">
														<template slot-scope="scope">
															¥ {{scope.row.dimoney/100}}
														</template>
													</el-table-column>
													
													<el-table-column prop="create_time" label="" width="50">
														<template slot-scope="scope">
															<u @click="gobillinfo(scope.row.id)" style="cursor:pointer;">详情</u>
														</template>
													</el-table-column>
												</el-table>
											</div>
										</div> -->
									</div>
								</el-col>
							</el-row>
							
							
						</el-col>
					</el-row>

				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				breadcrumb:[],
				id: '',
				host:'',
				info:{},
				log:[],
				paytype:{},
				banks:[],
				billloglist:[],
				billmeter:[],
				billstate:{},
				paystate:[],
				readstatemap:[]
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=vm.$breadcrumb(vm.$root.myrouter,to)
			})
		},
		watch:{
			'$route'(to,from){
				this.$router.go(0)
			}
		},
		mounted: function() {
			this.breadcrumb=this.$root.myrouter
			this.id = this.$route.query.id;
			this.host = this.$host
			this.getdata();
		},
		methods: {
			badDebts(){
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'baddebts',
						id: this.id
					}
				}).then(res => {
					this.getdata();
				})
			},
			gobillinfo(id) {
				this.$router.resolve({
					name:'bill',
					query:{id:id},
					
				})
				this.$router.push('/billinfo?id=' + id)
			},
			getdata() {
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'info',
						id: this.id
					}
				}).then(res => {
					this.info = res
					this.paytype=res.paytype
					
					this.billstate=res.billstate
					this.paystate=res.paystate
					this.readstatemap=res.readstatemap
					
					this.banks=res.banks
					if(res.billmeter){
						if(res.billmeter.c>0){
							this.billmeter=res.billmeter.res;
						}else{
							this.billmeter=[];
						}
					}
					if(res.log){
						if(res.log.c>0){
							this.log=res.log.res;
						}else{
							this.log=[];
						}
					}
				})
				
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'billlog',
						id: this.id
					}
				}).then(res => {
					if (res.c > 0) {
						this.billloglist = res.res
					} else {
						this.billloglist = []
					}
					
				})
				
			}
		},
	}
</script>
