<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<div class="pagetitle">{{title}}</div>
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<div @click="back" style="display: inline;cursor: pointer;">文章列表</div>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="panel">
			<div class="panel-body pad16">
				<el-descriptions :column="1" size="medium" labelClassName="des-label-1" contentClassName="des-content">
					<el-descriptions-item label="标题">
						<el-input v-model="atitle" style="width:400px"></el-input>
					</el-descriptions-item>
					<el-descriptions-item label="分类">
						<el-select v-model="chtype" placeholder="请选择" style="width:400px;">
							<el-option v-for="item in alltype" :key="item.fid" :label="item.class_name"
								:value="item.fid">
							</el-option>
						</el-select>
					</el-descriptions-item>
					<el-descriptions-item label="置项">
						<el-radio-group v-model="istop" style="margin-top:10px;">
							<el-radio name="istop"  label="0" > 否 </el-radio>
							<el-radio name="istop"  label="1" > 是 </el-radio>
						</el-radio-group>
					</el-descriptions-item>
					<el-descriptions-item label="加粗">
						<el-radio-group v-model="b" style="margin-top:10px;">
							<el-radio name="b"  label="0"> 否 </el-radio>
							<el-radio name="b"  label="1"> 是 </el-radio>
						</el-radio-group>
					</el-descriptions-item>
					<el-descriptions-item label="标题颜色">
						<el-color-picker v-model="color"></el-color-picker>
					</el-descriptions-item>
					<el-descriptions-item label="内容">
						<VueUeditorWrap v-model="content" :config="editorConfig" editor-id="editor-demo-01"
							@ready="ueditorReady" />
					</el-descriptions-item>
					<el-descriptions-item label="附件">
						<el-upload :action="host+'upfile/'" style="margin-top: 5px;"
							ref="upload" name="upfile" :data="uploaddata" :on-success="uploadsuccess"
							:file-list="fileList" :auto-upload="false" :on-change="uploadchange">
							<el-button size="small" type="default">点击上传</el-button>
						</el-upload>
					</el-descriptions-item>
					<el-descriptions-item label="" label-class-name="des-label-2">
						<div style="height: 30px;"></div>
						<el-button type="primary" @click="submit" style="width: 150px;">保存</el-button>
					</el-descriptions-item>
				</el-descriptions>
				<div>

				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import VueUeditorWrap from 'vue-ueditor-wrap';
	export default {
		components: {
			VueUeditorWrap //ueditor富文本编辑器
		},
		data() {
			return {
				uploaddata: {
					pusern: '',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				host: '',
				fileList: [],
				editorConfig: {
					UEDITOR_HOME_URL: "/UEditor/",
					initialFrameWidth: 400 //初始化编辑器宽度,默认1000
						,
					initialFrameHeight: 600 //初始化编辑器高度,默认320
						,
					zIndex: 99,
					wordCount: false,
					elementPathEnabled: false
				},
				title: '',

				chtype: '',
				istop:'0',
				color:'#000000',
				b:'0',
				atitle: '',
				content: '',
				id: '',
				info: '',
				alltype: [],
				files: []
			}
		},

		mounted: function() {
			this.uploaddata.pusern = localStorage.getItem('pusern');
			this.host = this.$host
			this.id = this.$route.query.id;
			if (this.id) {
				this.title = "编辑文章"
				this.getdata();
			} else {
				this.title = "添加文章"
			}
			this.ini();


		},
		methods: {
			uploadsuccess(response, file, fileList) {
				this.files = [];
				this.fileList = fileList;
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].response == undefined) {
						break;
					} else {
						if (typeof(this.fileList[i].response) == 'object') {
							this.files.push(this.fileList[i].response)
						} else {
							this.files.push(this.fileList[i].response)
						}
					}
				}
				if (i == this.fileList.length) {
					this.sub();
				}

				//this.buluform.pics.push(response);
			},
			uploadchange(file, fileList) {
				this.fileList = fileList;
			},

			ueditorReady(editorInstance) {
				var pusern = localStorage.getItem('pusern');
				let UE = editorInstance;
				UE.ready(function() {
					UE.execCommand('serverparam', {
						'pusern': pusern
					})
				})
			},
			ini() {
				this.$ajax({
					url: 'note/',
					data: {
						'do': 'ini'
					},
				}).then(res => {
					this.alltype = res.data
				})
			},
			getdata(v) {
				if (typeof(v) != 'object' || v.pgno === undefined) {
					this.pgno = 1;
				}
				this.$ajax({
					url: 'note/',
					data: {
						'do': 'info',
						'id': this.id,
					},
				}).then(res => {
					if (res.error == 0) {
						this.content = res.data.content
						this.chtype = res.data.type
						this.atitle = res.data.title
						this.color = res.data.color
						this.b = res.data.b
						this.istop = res.data.istop
						this.files = res.data.attachment
						var fi=[];
						for (var i = 0; i < this.files.length; i++) {
							this.files[i].fileid=this.files[i].id;
							fi.push({'name':this.files[i].fname,'url':this.files[i].fpath,'response':this.files[i]});
						}
						this.fileList = fi;
					}
					this.info = res.data
				})
			},
			submit() {
				if(this.chtype==''){
					this.$alert('请选择分类')
					return;
				}
				if (this.fileList.length == 0) {
					this.sub();
				} else {
					this.files = [];
					for (var i = 0; i < this.fileList.length; i++) {
						if (this.fileList[i].response == undefined) {
							break;
						} else {
							if (typeof(this.fileList[i].response) == 'object') {
								this.files.push(this.fileList[i].response)
							} else {
								this.files.push(this.fileList[i].response)
							}
						}
					}

					if (i == this.fileList.length) {
						this.sub();
					} else {
						this.$refs.upload.submit();
					}
				}
			},
			sub() {
				this.$ajax({
					url: 'note/',
					data: {
						'do': 'update',
						'content': this.content,
						'title': this.atitle,
						'id': this.id,
						'istop': this.istop,
						'color': this.color,
						'b': this.b,
						'type': this.chtype,
						'files': this.files
					},
				}).then(res => {
					if (res.error == 0) {
						this.$message({
							message: '发布成功',
							type: 'success'
						})
						this.back()
					}
				})
			},
			back() {
				this.$router.go(-1);
			}
		}
	}
</script>
