<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">公寓管理</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel">
					
					<div class="panel-body pad16">
						<div class="fcj_lr" style="border-bottom:1px solid #e4e7ed;padding-bottom: 20px;">
							<div class="fcj_ll">
								<div class="fc"><el-button :type="active=='room'?'primary':'info'" plain @click="chac('room')" size="small">公寓</el-button></div>
								
								<div class="fc"><el-button :type="active=='tongji'?'primary':'info'"  plain @click="chac('tongji')" size="small">数据统计</el-button></div>
								<div class="fc"><el-button :type="active=='paylog'?'primary':'info'"  plain @click="chac('paylog')" size="small">充值记录</el-button></div>

								<div style="width:50px;"></div>

								<div class="fc"><el-button :type="active=='goods'?'primary':'info'" plain @click="chac('goods')" size="small" icon="el-icon-refrigerator">房内物品</el-button></div>
								<!-- <div class="fc"><el-button :type="active=='paylog'?'primary':'info'" plain @click="chac('paylog')" size="small">账单</el-button></div> -->
								<div class="fc"><el-button :type="active=='lun'?'primary':'info'" plain @click="chac('lun')" size="small" icon="el-icon-picture">轮播图</el-button></div>
								<div class="fc"><el-button :type="active=='business'?'primary':'info'" plain @click="chac('business')" size="small" icon="el-icon-goods">商业服务</el-button></div>

							</div>
							<div>
							<div><el-button  plain @click="updaterentinfo()" :loading="istb" size="small" icon="el-icon-refresh">同步租约信息</el-button></div>
							</div>
						</div>

						<div v-show="active=='room'">
							<div class=" table-filter" style="margin-top:20px;">
								<div class="fcj_ll" >
									<el-select v-model="filter.build" slot="prepend" size="small" clearable placeholder="楼栋名称"
										@change="goinfo" class="searchitem" style="width:120px">
										<el-option v-for="item in building" :key="item.value" :label="item.bname"
											:value="item.id">
										</el-option>
									</el-select>
									<el-select v-model="filter.floor" slot="prepend" size="small" clearable placeholder="楼层名称"
										@change="getdata" class="searchitem" style="width:120px">
										<el-option v-for="item in floor.res" :key="item.ordid" :label="item.fname"
											:value="item.ordid">
										</el-option>
									</el-select>
									<el-select v-model="filter.rentstate" slot="prepend" size="small" clearable placeholder="租赁状态"
										@change="getdata" class="searchitem" style="width:100px">
										<el-option v-for="item in rentstate" :key="item.value" :label="item.label" :value="item.value">
										</el-option>
									</el-select>
									<div class="fcj_ll">
										<div style="padding:0 10px;">空间面积</div>
										<div><el-input v-model="filter.minarea" size="small" placeholder="" clearable style="width:80px" @keyup.enter.native="getdata"></el-input></div>
										<div style="padding:0 4px;">~</div>
										<div><el-input v-model="filter.maxarea" size="small" placeholder="" clearable style="width:80px" @keyup.enter.native="getdata"></el-input></div>
									</div>
									
									<div style="padding:0 4px;"><el-input size="small" v-model="filter.endtime" clearable placeholder="剩余租期(天)" style="width:130px"></el-input></div>
									<div><el-button size="small" type="primary" @click="getdata()" icon="el-icon-search">查询</el-button></div>
									<div style="margin-left:4px;"><el-button size="small" type="info" @click="reset()">重置</el-button></div>
								</div>
							</div>
							<div>
								<el-table :data="res" header-cell-class-name="table-header" stripe>
									<el-table-column prop="bname" label="楼栋楼层" width="150px">
										<template slot-scope="scope">
											<router-link :to="'/areaedit?bid='+scope.row.building+'&fid='+scope.row.floor">{{scope.row.bname+scope.row.fname}}</router-link>
										</template>
									</el-table-column>
									<el-table-column prop="roomnumber" label="门牌名称" width="80">
										<template slot-scope="scope">
											<router-link :to="'/officeinfo?bid='+scope.row.building+'&fid='+scope.row.floor+'&id='+scope.row.id">{{scope.row.roomnumber}}</router-link>
										</template>
									</el-table-column>

									<el-table-column label="租赁状态" width="80">
										<template slot-scope="scope">
											{{statemap[scope.row.state].label}}
										</template>
									</el-table-column>
									
									<el-table-column prop="builduparea" label="面积（㎡）" width="100">
									</el-table-column>
									<el-table-column label="合同租期" width="180">
										<template slot-scope="scope">
											{{scope.row.starttime+'~'+scope.row.endtime}}
										</template>
									</el-table-column>
									<el-table-column prop="company" label="当前租户">
										<template slot-scope="scope">
											<router-link :to="'/editcustomer?proview=1&id='+scope.row.customerid">{{scope.row.company}}</router-link>
										</template>
									</el-table-column>
									<el-table-column label="合同编号">
										<template slot-scope="scope">
											<router-link :to="'/contractinfo?id='+scope.row.contractid">{{scope.row.number}}</router-link>
										</template>
									</el-table-column>

									<template>
										<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
									</template>
								</el-table>
								<div style="padding:15px 0;text-align: right;">
									<div class="fcj_lr">
										<div style="font-size:12px;color:#999;"><el-tag size="small" type="info">合计面积： {{allarea}} 平米</el-tag></div>
										<div>
											<el-pagination background layout="total,prev, pager, next" :current-page.sync="pgno" :page-size="pgsize"
												@current-change="getdata" :total="pgcount">
											</el-pagination>

										</div>
									</div>
									
								</div>
							</div>
						</div>

						


						<div v-show="active=='tongji'">
							<div style="margin-top:20px;text-align: left;" class="demo-tag">
								<el-tag>公寓房间总数： <span style="font-size: 18px;marin-left:10px;">{{tongji.all}}</span></el-tag>
								<el-tag>已出租房间数量： <span style="font-size: 18px;marin-left:10px;">{{tongji.rented}}</span></el-tag>
								<el-tag>空置房间数量： <span style="font-size: 18px;marin-left:10px;">{{tongji.all?tongji.all-tongji.rented:''}}</span></el-tag>
								<el-tag>公寓出租率： <span style="font-size: 18px;marin-left:10px;">{{tongji.all?parseInt(tongji.rented/tongji.all*100):''}}%</span></el-tag>
							</div>
							<div>
								<div class=" table-filter" style="margin-top:20px;">
									<div class="fcj_ll">
										
										<div>
											<el-date-picker v-model="searchkey.day" size="small" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:270px;" @change="gettongji()"></el-date-picker>
										</div>
										<el-select v-model="searchkey.state" slot="prepend" size="small" clearable placeholder="是否退租"
											@change="gettongji()" class="searchitem" style="width:120px">
											<el-option  label="是" value="1"></el-option>
											<el-option  label="否" value="0"></el-option>
										</el-select>
										<div><el-input size="small" v-model="searchkey.company" placeholder="公司名称" /></div>
										<div><el-input size="small" v-model="searchkey.roomnumber" placeholder="房间号" /></div>
										<div><el-button type="primary" size="mini" @click="gettongji()">确定</el-button></div>
										<div><el-button type="success" icon="el-icon-download" size="mini" @click="gettongji(1)">下载</el-button></div>

									</div>
									
								</div>
							</div>
							<div v-if="tongji.room">
								<el-table :data="tongji.room.res" header-cell-class-name="table-header" stripe>
									<el-table-column prop="roomnum" label="房间" width="150px">
										<template slot-scope="scope">
											{{scope.row.bname+scope.row.roomnumber}}
										</template>
									</el-table-column>
									
									<el-table-column prop="company" label="合同" width="300">
									</el-table-column>
									
									<el-table-column prop="pre" label="付费类型" >
										<template slot-scope="scope">
											{{scope.row.pre==1?'预付费':'后付费'}}
										</template>
									</el-table-column>
									<el-table-column prop="ele_min" label="前电表数" v-show="searchkey.day!=''">
									</el-table-column>
									<el-table-column prop="ele_max" label="后电表数"  v-show="searchkey.day!=''">
									</el-table-column>
									<el-table-column prop="meter_ele" label="总用电量" >
										<template slot-scope="scope">
											{{scope.row.meter_ele}}
										</template>
									</el-table-column>
									<el-table-column prop="wat_min" label="前水表数"  v-show="searchkey.day!=''">
									</el-table-column>
									<el-table-column prop="wat_max" label="后水表数"  v-show="searchkey.day!=''">
									</el-table-column>
									<el-table-column prop="meter_wat" label="总用水量" >
										<template slot-scope="scope">
											{{scope.row.meter_wat}}
										</template>
									</el-table-column>
									<el-table-column prop="money" label="当前余额" align="right">
										
									</el-table-column>
									<el-table-column prop="inmoney" label="累计充值" align="right">
									</el-table-column>
									<el-table-column prop="outmoney" label="实际消费" align="right">
									</el-table-column>
									<el-table-column prop="note" label="备注" >
									</el-table-column>
									<template>
										<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
									</template>
								</el-table>
							</div>
						</div>

						<div v-show="active=='paylog'">
							
							<div class=" table-filter" style="margin-top:20px;">
								<div class="fcj_ll">
									<div>
										<el-date-picker v-model="paylogsearch.day" size="small" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:270px;" @change="getpaylog"></el-date-picker>
									</div>
									<div>
										<el-input size="small" v-model="paylogsearch.roomnumber" placeholder="房间号" />
									</div>
									<div>
										<el-input size="small" v-model="paylogsearch.company" placeholder="公司名称" />
									</div>
									<div>
										<el-input size="small" v-model="paylogsearch.wxorder" placeholder="微信订单号" />
									</div>
									<div>
										<el-button size="small" @click="getpaylog" icon="el-icon-search" type="primary">查询</el-button>
									</div>
									<div>
										<el-button size="small" @click="getpaylog('d')" icon="el-icon-down" type="success">导出</el-button>
									</div>
								</div>
							</div>
							<div v-if="paylog!=''">
								<el-table :data="paylog" header-cell-class-name="table-header" stripe>
									<el-table-column prop="roomnumber" label="房间" width="150px">
										<template slot-scope="scope">
											{{scope.row.bname+scope.row.roomnumber}}
										</template>
									</el-table-column>
									
									
									<el-table-column prop="company" label="承租公司" >
										<template slot-scope="scope">
											<span v-if="scope.row.company">{{scope.row.company}}</span><el-tag type="info" size="mini" v-else>没有租约</el-tag>
										</template>
									</el-table-column>
									<el-table-column prop="name" label="支付人" >
									</el-table-column>
									<el-table-column prop="money" label="支付金额" width="100">
									</el-table-column>
									<el-table-column prop="create_time" label="支付时间" width="160">
									</el-table-column>
									<el-table-column prop="out_trade_no" label="微信订单号" >
									</el-table-column>

									<template>
										<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
									</template>
								</el-table>
								<div style="padding:15px 0;text-align: right;">
									<div class="fcj_lr">
										<div>
											合计：{{paylog_all}}
										</div>
										<div>
											<el-pagination background layout="total,prev, pager, next" :current-page.sync="paylog_pgno" :page-size="paylog_pgsize"
												@current-change="getpaylog" :total="paylog_pgcount">
											</el-pagination>

										</div>
										
									</div>
									
								</div>
							</div>
						</div>

						<div v-show="active=='goods'">
							<div style="margin-top:20px;text-align: right;"><el-button type="primary" @click="showaddasset=true"  icon="el-icon-plus">添加物品</el-button></div>
							<el-table :data="goods" stripe style="width: 100%;" :cell-style="goodrow">
								
								<el-table-column prop="name" label="物品名称" width="200px">
									<template slot-scope="scope">
										<div @click="editgoods(scope.row)" class="link">{{scope.row.name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="remark" label="备注" >
								</el-table-column>
								<el-table-column prop="picsf" label="图片" >
									<template slot-scope="scope">
										<div class="fcj_ll"><div style="border-radius:5px;box-shadow: 0 0 10px #ddd;padding:5px;margin:10px;" v-for="(item,index) in scope.row.picsf" :key="'p'+item.id"><el-image 
										style="width: 50px; height: 50px;display: block;"
										:src="item" 
										:preview-src-list="scope.row.picsf" v-if="index<3">
										</el-image></div><div v-if="scope.row.picsf.length>3"> ...</div></div>
									</template>
								</el-table-column>
							</el-table>

							<div style="padding:15px;text-align: right;">
								<el-pagination background layout="total,prev, pager, next" :current-page.sync="goods_pgno" :page-size="goods_pgsize"
									@current-change="getgoods" :total="goods_pgcount">
								</el-pagination>
							</div>
						</div>
						<div v-show="active=='lun'">
							<div style="margin-top:20px;text-align: right;"><el-button type="primary" @click="showaddad('apa_lun')" icon="el-icon-plus">添加图片</el-button></div>
							<div v-if="ads.apa_lun.res" style="margin:30px 0;">
								<div class="fcj_ll" style="flex-wrap:wrap;">
									<div v-for="item in ads.apa_lun.res" :key="'lun'+item.id">
										<div class="item"><div :style="'background-image:url('+host+item.content.img+');'"  alt=""></div></div>
										<div style="text-align:center;"><el-button size="mini" icon="el-icon-delete" @click="dellun(item)">删除</el-button></div>
									</div>
								</div>
								
								
							</div>
						</div>
						<div v-show="active=='business'">
							<div style="margin-top:20px;text-align: right;"><el-button type="primary" @click="showaddad('apa_shop')" icon="el-icon-plus">添加</el-button></div>
							<div v-if="ads.apa_shop.res" style="margin:30px 0;">
								<div class="fcj_ll" style="flex-wrap:wrap;">
									<div v-for="item in ads.apa_shop.res" :key="'lun'+item.id" class="item_shop">
										
										<div :style="'background-image:url('+host+item.content.img+');'"  class="item_bg"></div>
										<div class="item_title">{{item.content.title}}</div>
										<div style="text-align:center;"><el-button size="mini" icon="el-icon-delete" @click="dellun(item)">删除</el-button></div>
									
										
									</div>
								</div>
							</div>
						</div>
					</div>

					

				</div>
					
			</el-col>
		</el-row>

		<el-dialog :visible.sync="showaddasset" width="700px" title="物品管理" :close-on-click-modal="false">
			<el-form :model="assetform" :rules="assetrule" ref="assetform" label-width="100px" class="demo-ruleForm">
				<el-form-item label="资产名称" prop="name">
					<el-input v-model="assetform.name" style="width:550px;"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input type="textarea" v-model="assetform.remark" style="width:550px;"></el-input>
				</el-form-item>
				<el-form-item label="图片" prop="pics">
					<el-upload :action="host+'upimg/'" list-type="picture-card"
					ref="upload" name="upfile" :data="uploaddata" :on-success="uploadsuccess"
					:file-list="fileList" :auto-upload="false"
					:on-change="uploadchange">
						<i slot="default" class="el-icon-plus"></i>
						<div slot="file" slot-scope="{file}">
							<img class="el-upload-list__item-thumbnail" :src="file.url"
								alt="">
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview"
									@click="handlePictureCardPreview(file)">
									<i class="el-icon-zoom-in"></i>
								</span>
								<span v-if="!disabled"
									class="el-upload-list__item-delete"
									@click="handleDownload(file)">
									<i class="el-icon-download"></i>
								</span>
								<span v-if="!disabled"
									class="el-upload-list__item-delete"
									@click="handleRemove(file)">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>

					</el-upload>
					<el-dialog :visible.sync="dialogVisible" append-to-body>
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</el-form-item>
				

				<el-form-item>
					<el-button type="primary" @click="addasset()">保存</el-button>
					<el-button @click="showaddasset = false">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog :visible.sync="isshowedit" :title="ads_title" width="700px" :close-on-click-modal="false">

			<el-form :model="adform" :rules="adformrule" label-width="100px" ref="adform" v-if="isshowedit">

				<el-form-item label="图片" prop="img">
					<div class="fcj_ll">
						<div><el-upload :action="host+'upimg/'" :data="uploaddata" list-type="text"
						:ref="'uploadparkpics'" name="upfile" :on-success="uploadparkpicsuccess"
						:auto-upload="true" :limit="1">
						<el-button type="primary" size="medium" icon="el-icon-picture-outline">上传图片</el-button>
					</el-upload></div>
					<div style="margin-left:10px;">图片宽高（750*480px)</div>
					</div>
					
				</el-form-item>
				<el-form-item label="标题" prop="title" v-if="adform.type=='apa_shop'">
					<el-input v-model="adform.title" ></el-input>
				</el-form-item>
				<el-form-item label="跳转类型" prop="linktype">
					<el-radio-group v-model="adform.linktype" @change="qingkong">
						<el-radio name="linktype" :label="1" :value="1">功能模块</el-radio>
						<el-radio name="linktype" :label="2" :value="2">小程序页面</el-radio>
						<el-radio name="linktype" :label="3" :value="3">公众号链接</el-radio>
						<el-radio name="linktype" :label="0" :value="0">不跳转</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="链接" prop="link">
					<el-input v-model="adform.link" v-if="adform.linktype==2||adform.linktype==3"></el-input>
					<el-select v-model="adform.link" placeholder="请选择" v-if="adform.linktype==1">
						<el-option v-for="item in sysmodel.res" :key="item.url" :label="item.modelname"
							:value="item.url">
						</el-option>
					</el-select>
					<div v-if="adform.linktype==2" style="font-size:12px;">外部小程序格式：appid:[appid],[page_path] 例：appid:wxoiuwegeej32,pages/index/index?pare=123</div>
				</el-form-item>

				

				<el-form-item>
					<el-button type="primary" @click="submitForm('adform')">保存</el-button>
					<el-button @click="resetForm('adform')">重置</el-button>
				</el-form-item>
			</el-form>
			</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchkey:{
					day:'',
					company:'',
					roomnumber:'',
					state:''
				},
				adform: {
					'id': '',
					'title': '',
					'img': '',
					'content': '',
					'modelname': '',
					'cssid': '',
					'link': '',
					'picwh': '',
					'type': 'apa_lun',
					'linktype': 0,
					'orde': '',
					'view': ''
				},
				adformrule: {
					img:[{
						required: true,
						message: '请上传图片',
						trigger: 'change'
					}]
				},
				isshowedit:false,
				goods_pgno:1,
				goods_pgsize:12,
				goods_pgcount:0,
				goods:[],
				assetform:{
					id:'',
					name:'',
					remark:'',
					pics:[],
				},
				assetrule:{

				},
				showaddasset:false,
				active:'room',


				host:'',
				uploaddata: {
					pusern: localStorage.getItem('pusern'),
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				fileList: [],
				disabled: false,
				dialogVisible:false,
				dialogImageUrl:'',

				filter:{
					type:'75',
					build:'',
					floor:'',
					state:'',
					rentstate:'',
					minarea:'',
					maxarea:'',
					endtime:'',
					tj:'',
					repair:''
				},
				building:[],
				statemap:{
					'2':{
						value: '2',
						label: '空置中'
					},
					'1':{
						value: '1',
						label: '出租中'
					},
					'0':{
						value: '0',
						label: '失效'
					}
				},
				
				rentstate:[
					{
						value: '1',
						label: '出租中'
					},{
						value: '2',
						label: '空置中'
					}
				],

				res: [],
				floor: {},
				offices: {},
				pgno: 1,
				pgcount: 0,
				pgsize:13,
				allarea:'',
				ads:{'apa_lun':{},'apa_shop':{}},
				tongji:'',
				istb:false,
				ads_title:'公寓轮播图设置',


				paylog:'',
				paylogsearch:{
					day:[],
					roomnumber:'',
					company:'',
					wxorder:''
				},
				paylog_pgcount:0,
				paylog_pgno:1,
				paylog_pgsize:20,
				paylog_all:0
				
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=[to];
			})
		},
		mounted: function() {
			if (this.$route.query.bid != undefined) {
				this.filter.build = this.$route.query.bid;
			}
			var myDate = new Date();
			var y=myDate.getFullYear()
			var m=myDate.getMonth()
			var d=myDate.getDate()
			this.searchkey.day=[y+'-'+(m+1)+'-'+(d-1),y+'-'+(m+1)+'-'+(d-1)];

			if(this.$route.query.repair==1){
				this.filter.repair=1
			}
			this.host = this.$host
			this.getdata();
			this.ini();

		},
		methods: {
			showaddad(v){
				this.adform.type=v;
				this.isshowedit=true
			},
			qingkong(){
				this.adform.link='';
			},
			goodrow(){
				return {'padding':'5px'};
			},
			chac(v){
				this.active=v;
				if(v=='goods'){
					this.getgoods();
				}else if(v=='tongji'){
					this.gettongji();
				}else if(v=='paylog'){
					this.getpaylog();
				}else if(v=='lun'){
					this.adform.type='apa_lun';
					this.ads_title='公寓轮播图设置'
					this.getads()
				}else if(v=='business'){
					this.adform.type='apa_shop';
					this.ads_title='商业服务'
					this.getads();
				}
			},
			gettongji(v){
				if(v){
					this.$ajax({
						responseType:'blob',
						url: 'apartment/',
						data: {
							'do': 'tongji',
							'search':this.searchkey,
							'down':v
						}
					}).then(res => {
						let url=window.URL.createObjectURL(new Blob([res]));
						let link=document.createElement('a')
						link.download='公寓水电费.xls'
						link.href=url
						link.click()
						return
					});
					
				}else{
					this.$ajax({
						url: 'apartment/',
						data: {
							'do': 'tongji',
							'search':this.searchkey,
							'down':v
						}
					}).then(res => {
						if(res.error>0){
							this.$alert(res.msg);
						}
						this.tongji=res.data;
					});
				}
				
			},
			getpaylog(v){
				this.$ajax({
					responseType:v==='d'?'blob':'',
					url: 'apartment/',
					data: {
						'do': 'paylog',
						'io':0,
						'pgno':this.paylog_pgno,
						'pgsize':this.paylog_pgsize,
						'search':this.paylogsearch,
						'down':v==='d'?1:''
					}
				}).then(res => {
					if(v==='d'){
						this.down=0;
						let url=window.URL.createObjectURL(new Blob([res]));
						let link=document.createElement('a')
						link.download='公寓充值记录.xls'
						link.href=url
						link.click()
						return
					}
					if(res.error>0){
						this.$alert(res.msg);
					}
					this.paylog=res.data.res;
					this.paylog_pgcount=parseInt(res.data.count);
					this.paylog_all=res.data.all;
				});
			},
			updaterentinfo(){
				this.istb=true;
				this.$ajax({
					url: 'apartment/',
					data: {
						'do': 'updaterentinfo',
					},
				}).then(res => {
					this.istb=false;
					this.$message('数据已同步');
				})
			},
			dellun(v){
				this.$ajax({
					url: 'mod_ads/',
					data: {
						'do': 'del',
						'id': v.id
					},
				}).then(res => {
					this.getads();
				})
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			submitForm(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.adform.do = 'add';
						this.adform.styleid = 1;
						var data = this.adform;
						this.$ajax({
							url: 'mod_ads/',
							data: this.adform,
						}).then(res => {
							if (res.error > 0) {
								this.$message({
									message: res.msg,
									type: 'warning'
								});
							} else {
								this.$message({
									message: '保存成功',
									type: 'success'
								});

								this.resetForm
								this.getads();
								this.isshowedit = false
							}

						})
					} else {
						return false;
					}
				});

				},
			uploadparkpicsuccess(response, e) {
				this.adform.img = response.fpath;
				this.adform.picwh=response.imgw+','+response.imgh;
				
			},
			ini(){
				this.$ajax({
					url: 'mod_ads/',
					data: {},
				}).then(res => {
					this.sysmodel = res.sysmodel;
				})
			},
			getads(v){
				this.$ajax({
					url: 'mod_ads/',
					data: {
						'do': 'ads',
						'type': [this.adform.type],
						'styleid':1
					},
				}).then(res => {
					this.ads[this.adform.type] = res[this.adform.type];
				})
			},


			handleRemove(file) {
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].uid == file.uid) {
						this.fileList.splice(i, 1);
						break;
					}
				}
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleDownload(file) {
				console.log(file);
				console.log(this.fileList);
			},
			uploadsuccess(response, file, fileList) {
				this.assetform.pics = [];
				this.fileList = fileList;
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].response == undefined) {
						break;
					} else {
						if(typeof(this.fileList[i].response)=='object'){
							this.assetform.pics.push(this.fileList[i].response.fpath)
						}else{
							this.assetform.pics.push(this.fileList[i].response)
						}
					}
				}
				console.log(this.fileList);
				if (i == this.fileList.length) {
					this.sub();
				}

				//this.buluform.pics.push(response);
			},
			uploadchange(file, fileList) {
				this.fileList = fileList;
			},

			sub(){
				this.$ajax({
					url: 'apartment/',
					data: {
						'do': 'update_asset',
						'form':this.assetform
					}
				}).then(res => {
					this.showaddasset=false;
					this.assetform={
						id:'',
						name:'',
						remark:'',
						pics:[],
					}
					this.fileList=[];
					this.goods_pgno=1;
					this.getgoods();
				})
			},
			editgoods(v){
				this.assetform=v
				this.fileList=[];
				if (this.assetform.pics.length > 0) {
					for (var i = 0; i < this.assetform.pics.length; i++) {
						this.fileList.push({
							name: '',
							url:  this.host + this.assetform.pics[i],
							response: this.assetform.pics[i]
						})
					}
				}
				this.showaddasset=true
			},
			addasset(){
				this.$refs['assetform'].validate((valid) => {
					if (valid) {
						if (this.fileList.length == 0) {
							this.sub();
						} else {
							this.assetform.pics = [];
							for (var i = 0; i < this.fileList.length; i++) {
								if (this.fileList[i].response == undefined) {
									break;
								} else {
									if(typeof(this.fileList[i].response)=='object'){
										this.assetform.pics.push(this.fileList[i].response.fpath)
									}else{
										this.assetform.pics.push(this.fileList[i].response)
									}
								}
							}
							
							if (i == this.fileList.length) {
								this.sub();
							} else {
								this.$refs.upload.submit();
							}
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			getgoods(){
				this.$ajax({
					url: 'apartment/',
					data: {
						'do': 'goods',
						'pgsize':this.goods_pgsize,
						'pgno':this.goods_pgno
					}
				}).then(res => {
					this.goods=res.data.res;
					for (var j = this.goods.length - 1; j >= 0; j--) {
						if(!this.goods[j].picsf){
							this.goods[j].picsf=[];
						}
						for (var i = 0; i < this.goods[j].pics.length; i++) {

							this.goods[j].picsf[i]=this.host+this.goods[j].pics[i];
						}
					}
					this.goods_pgcount=parseInt(res.data.count);
				})
			},
			reset(){
				this.filter={
					type:'75',
					build:'',
					floor:'',
					state:'',
					minarea:'',
					maxarea:'',
					endtime:'',
					repair:''
				}
				this.getdata();
			},
			
			goinfo(v) {
				this.filter.floor='';
				this.$ajax({
					url: 'floor/',
					data: {
						'onlyapa':1,
						'buildingid': this.filter.build
					},
				}).then(res => {
					this.floor = res;
					this.getdata();

				})
			},
			getdata() {
				var data=this.filter;
				data.do='getdata';
				data.onlyapa=1;
				data.pgno=this.pgno;
				data.pgsize=this.pgsize;
				this.$ajax({
					url: 'building/',
					data: data
				}).then(res => {
					this.res = res.res;
					this.allarea=res.allarea;
					this.building=res.building
					this.pgcount = parseInt(res.count);
				})
			}
		}
	};
</script>

<style scoped>
	.demo-tag .el-tag+.el-tag{
		margin-left:10px;
	}
	.item{
		margin:20px;
		box-shadow: 0 0 10px #ddd;
		padding:10px;
		width:120px;
		height: 120px;
		border-radius: 5px;
	}

	.item_shop{
		margin:10px;
		box-shadow: 0 0 10px #ddd;
		padding:10px;
		width:120px;
		height: 210px;
		border-radius: 5px;
		font-size:13px;
	}

	.item_title{
		margin:5px 0 20px 0;
		height: 36px;
		line-height:18px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.item_bg{
		width:120px;
		background-size:cover;
		background-position: center;
		height:120px;
	}
	.item>div{
		width:100%;
		background-size:cover;
		background-position: center;
		height:100%;
	}
	.fc{
		margin-right: 10px;
	}

</style>
