<template>
	<div>
		<div class="panel">
			<div class="panel-body pad16">
				<div class="panel-body" >
					<div class="fcj_lr" style="border-bottom:1px solid #e4e7ed;padding-bottom: 20px;margin-bottom:20px;">
						<div class="fcj_ll">
							<div style="margin-right:5px;"><el-button :type="active == 'monthtj' ? 'primary' : 'info'"
									plain icon="el-icon-s-data" @click="chac('monthtj')" size="small">收缴统计</el-button></div>
							<div style="margin-right:5px;"><el-button :type="active == 'monthlist' ? 'primary' : 'info'"
									plain icon="el-icon-s-grid" @click="chac('monthlist')" size="small">统计明细</el-button></div>
							<div style="width:50px;"></div>
						</div>
					</div>

					<div v-show="active == 'monthtj'">
						<div style=" font-size:16px;padding:20px 0;width:900px;font-weight: bold;" class="fcj_lc">
							<div style="flex:1;" class="fcj_ll">
								<el-date-picker type="month" placeholder="选择日期" value-format="yyyy-MM" style="width:120px;" v-model="month" 
									@change="getdata" >
								</el-date-picker>
								
								<el-select v-model="billtype" slot="prepend" clearable
								placeholder="费用类型" multiple collapse-tags @change="getdata" style="margin-left:10px;margin-right:10px;width:170px;">
									<el-option v-for="item in paytype" :key="item.fid" :label="item.class_name" 
									:value="item.fid">{{item.class_name}}</el-option>
								</el-select>
								<div>	&nbsp;各租赁点收缴率统计表</div>
								<!-- <div>	&nbsp;<el-button type="primary" size="mini" icon="el-icon-receiving" @click="save">保存本月统计</el-button></div> -->
							</div>
							<div>
								<el-upload :action="host+'upfile/'" :data="uploaddata" :show-file-list="false"
									list-type="text" ref="uploadfloorpic1" name="upfile"
									:on-success="uploadfloorpicsuccess" :auto-upload="true" :limit="1">

									<el-button type="primary" plain size="mini" icon="el-icon-upload">
										上传账单更新开票日期
									</el-button>

								</el-upload>
							</div>	
							<div><el-button type="success" size="mini" plain icon="el-icon-download" style="margin-left:10px;" @click="getdata('down')">导出</el-button></div>
						</div>
						<div class="fcj_tb">
							<div class="fcj_ll fcj_theader">
								<div class="fcj_td" style="flex:1"><div>项    目</div></div>
								<div style="flex:3;">
									<div class="fcj_td"><div>本期应收款</div></div>
									<div class="fcj_ll" >
										<div class="fcj_td" style="flex:1"><div>上期欠款金额</div></div>
										<div class="fcj_td" style="flex:1"><div>本月应收款</div></div>
										<div class="fcj_td" style="flex:1"><div>合计</div></div>
									</div>
								</div>
								<div class="fcj_td" style="flex:1"><div>本月实收款</div></div>
								<div class="fcj_td" style="flex:1"><div>收缴率</div></div>
								<div class="fcj_td" style="flex:1"><div>期末欠款金额</div></div>
							</div>
							<div class="fcj_ll" v-for="(item,index) in tj" :key="index">
								<div class="fcj_td" style="flex:1"><div>{{item.class_name?item.class_name:'其他'}}</div></div>
								<div class="fcj_td" style="flex:1"><div>{{ item.preqian.div(100) }}</div></div>
								<div class="fcj_td" style="flex:1"><div>{{ item.thismoney.div(100) }}</div></div>
								<div class="fcj_td" style="flex:1"><div>{{ item.thisphase.div(100) }}</div></div>
								<div class="fcj_td" style="flex:1"><div>{{ item.thisgetmoney.div(100) }}</div></div>
								<div class="fcj_td" style="flex:1"><div>{{ item.paypercent }}%</div></div>
								<div class="fcj_td" style="flex:1"><div>{{ item.qian.div(100) }}</div></div>
							</div>
							
							
							
						</div>
						<div v-if="showyuan">
							<div style="height: 20px;"></div>
							<div class="fcj_tb">
								<div class="fcj_ll fcj_theader">
									<div class="fcj_td" style="flex:1"><div>项    目</div></div>
									<div style="flex:3;">
										<div class="fcj_td"><div>本期应收款</div></div>
										<div class="fcj_ll" >
											<div class="fcj_td" style="flex:1"><div>上期欠款金额</div></div>
											<div class="fcj_td" style="flex:1"><div>本月应收款</div></div>
											<div class="fcj_td" style="flex:1"><div>合计</div></div>
										</div>
									</div>
									<div class="fcj_td" style="flex:1"><div>本月实收款</div></div>
									<div class="fcj_td" style="flex:1"><div>收缴率</div></div>
									<div class="fcj_td" style="flex:1"><div>期末欠款金额</div></div>
								</div>
								<div class="fcj_ll" v-for="(iitem,iindex) in area" :key="iindex">
									<div class="fcj_td" style="flex:1"><div>{{iitem.class_name?iitem.class_name:'其他'}}</div></div>
									<div class="fcj_td" style="flex:1"><div>{{ iitem.preqian.div(100) }}</div></div>
									<div class="fcj_td" style="flex:1"><div>{{ iitem.thismoney.div(100) }}</div></div>
									<div class="fcj_td" style="flex:1"><div>{{ iitem.thisphase.div(100) }}</div></div>
									<div class="fcj_td" style="flex:1"><div>{{ iitem.thisgetmoney.div(100) }}</div></div>
									<div class="fcj_td" style="flex:1"><div>{{ iitem.paypercent }}%</div></div>
									<div class="fcj_td" style="flex:1"><div>{{ iitem.qian.div(100) }}</div></div>
								</div>
								
								
								
							</div>
						</div>
					</div>

					<div v-show="active == 'monthlist'">
						
						<div><el-button type="success" size="mini" plain icon="el-icon-download" @click="getlist('down')">导出</el-button></div>
						<div>
							<el-table :data="monthlist" header-cell-class-name="table-header" stripe>
								<el-table-column prop="stagename" label="经营点名称">
								</el-table-column>
								<el-table-column prop="office" label="室号">
								</el-table-column>
								<el-table-column prop="company" label="客户名称">
								</el-table-column>
								<el-table-column prop="ra1" label="欠租期限">
								</el-table-column>
								<el-table-column prop="notpay" label="期末欠款" width="100">
									<template slot-scope="scope">
										{{scope.row.qian}}
									
									</template>
								</el-table-column>
								<el-table-column prop="paytype" label="账单类型" width="120">
								</el-table-column>

							</el-table>
							
						</div>

					</div>

				</div>
			</div>
		</div>

		


	</div>
</template>
<style>
	.fcj_tb{
		display: inline-block;
		width:900px;
		font-size:14px;
	}
	.fcj_tb .fcj_ll{
		border-left: 1px solid #ddd;
		border-top:1px solid #ddd;
		align-items: stretch;
		width:100%;
	}
	.fcj_tb .fcj_td{
		border-right: 1px solid #ddd;
		border-bottom:1px solid #ddd;

	}
	.fcj_tb .fcj_td>div{
		padding:10px;
	}
	.fcj_theader{
		font-weight: bold;
	}
</style>

<script>

	import property from '../components/property.vue'
	export default {
		data() {
			return {
				uploaddata: {
					pusern: localStorage.getItem('pusern'),
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				host:'',
				active:'monthtj',
				monthlist:[],
				tj:{},
				area:[],
				down:0,
				month:'',
				paytype:[],
				billtype:'',
				showyuan:false
			}
		},
		mounted: function() {

			this.host = this.$host
			if(this.host=='https://admin.kefan.com/zhfty/'){
				this.showyuan=true;
			}
			this.getdata();
		},
		methods: {
			uploadfloorpicsuccess(response) {
				this.floorpic = response.fpath
				console.log(response);
				this.upbill(response.fileid)
				this.$refs.uploadfloorpic1.clearFiles();
			},
			upbill(fileid){
				this.$ajax({
					url: 'bill/',
					data: {
						'do':'update_invoice_in_time',
						'fileid':fileid
					},
				}).then(res => {
					this.$alert(res.msg+res.data, '提示', {
							confirmButtonText: '确定'
						});
				})
			},
			chac(v){
				this.active=v;
				if(v=='monthtj'){
					this.getdata();
				}else if(v=='monthlist'){
					this.getlist();
				}
			},
			
			getlist(v){
				if (v=='down') {
					this.down=1;
				}else{
					this.down=0;
				}
				this.$ajax({
					responseType:this.down?'blob':'',
					url: 'monthtj/',
					data: {
						'do':'list',
						'down':this.down,
						'month':this.month,
						'billtype':this.billtype
					},
				}).then(res => {
					if(this.down){
						this.down=0;
						let url=window.URL.createObjectURL(new Blob([res]));
						let link=document.createElement('a')
						link.download='月底欠款明细.xls'
						link.href=url
						link.click()
						return
					}
					this.monthlist=res.data;
				})
			},
			save(){
				this.getdata('save')
			},
			getdata(v){
				if (v=='down') {
					this.down=1;
				}else{
					this.down=0;
				}
				var datas={
						'down':this.down,
						'month':this.month,
						'billtype':this.billtype
					};console.log(datas)
				if(v=='save'){
					datas['save']=1;
				}
				this.$ajax({
					responseType:this.down?'blob':'',
					url: 'monthtj/',
					data: datas,
				}).then(res => {
					if(this.down){
						this.down=0;
						let url=window.URL.createObjectURL(new Blob([res]));
						let link=document.createElement('a')
						link.download='月底统计.xls'
						link.href=url
						link.click()
						return
					}
					this.tj = res.data.tj;
					this.paytype=res.data.paytype
					this.area=res.data.area
					console.log(this.area)
				})
			}
		}
	}

</script>
