<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">客户管理</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel ">
					<div class="panel-body pad16">
						<div class="fcj_lr table-filter">
							<div class="fcj_ll">
								<div>
									<el-input size="small" v-model="searchkey.cname" placeholder="客户名称" clearable @keyup.enter.native="getdata"></el-input>
								</div>
								<div>
									<el-select size="small" v-model="searchkey.source" placeholder="客户来源" clearable>
										<el-option v-for="item in maps.source" :key="item.value"
											:label="item.class_name" :value="item.fid">
										</el-option>
									</el-select>
								</div>
								<div>
									<el-select size="small" v-model="searchkey.tradetype" placeholder="行业属性" clearable>
										<el-option v-for="item in maps.tradetype" :key="item.value"
											:label="item.class_name" :value="item.fid">
										</el-option>
									</el-select>
								</div>
								<div>
									<el-button size="small" @click="getdata" type="primary" icon="el-icon-search">查找</el-button>
								</div>
								<!-- 
							<el-cascader v-model="searchkey.trade" :options="maps.trades"></el-cascader> -->


							</div>
							<div>
								<router-link to="/editcustomer">
									<el-button size="small" type="primary" icon="el-icon-plus">添加客户</el-button>
								</router-link>
							</div>
						</div>
					
					
						<el-table :data="tableData" header-cell-class-name="table-header" stripe>
							<el-table-column prop="id" label="#" width=120 align="center">
							</el-table-column>
							<el-table-column prop="cname" label="客户名称" width="300px">
								<template slot-scope="scope">
									<div><router-link :to="'/editcustomer?proview=1&id='+scope.row.id">{{scope.row.cname}}</router-link></div>
									<el-rate v-model="scope.row.avgstar" disabled show-score text-color="#ff9900"
										score-template="">
									</el-rate>
								</template>
							</el-table-column>
							<el-table-column prop="tradetype" label="行业属性">
								<template slot-scope="scope">
									<div v-if="scope.row.tradetype!=''">
										<span v-for="(item,index) in scope.row.tradetype"
											:key="index">{{maps.tradetype[item]?maps.tradetype[item].class_name:''}}</span>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="contacts" label="联系人/电话" width="160px">
								<template slot-scope="scope">
									<div>{{scope.row.contacts+','+scope.row.phone}}</div>
								</template>
							</el-table-column>
							<el-table-column prop="registplacestate" label="注册情况" width="110px">
								<template slot-scope="scope">
									<div v-if="scope.row.registplacestate>0">
										{{maps.registplacestate[scope.row.registplacestate]?maps.registplacestate[scope.row.registplacestate].class_name:''}}
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="nature" label="企业性质" width="100px">
								<template slot-scope="scope">
									
									<div v-if="scope.row.nature>0">{{maps.nature[scope.row.nature]?maps.nature[scope.row.nature].class_name:''}}</div>
								</template>
							</el-table-column>
							<el-table-column prop="qualifications" label="企业资质" width="150px">
								<template slot-scope="scope">
									<div v-if="scope.row.qualifications!=''">
										<span v-for="(item,index) in scope.row.qualifications"
											:key="index">{{(index>0?'，':'')+(maps.qualifications[item]?maps.qualifications[item].class_name:'')}}</span>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="customertype" label="客户类型" width="110px">
								<template slot-scope="scope">
									<div v-if="scope.row.customertype">
										{{maps.customertype[scope.row.customertype]?maps.customertype[scope.row.customertype].class_name:''}}
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="source" label="客户来源" width="100px">
								<template slot-scope="scope">
									<div v-if="scope.row.source">{{maps.source[scope.row.source]?maps.source[scope.row.source].class_name:''}}</div>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="180">
								<template slot-scope="scope">
									
									<router-link :to="'/editcustomer?id='+scope.row.id">
										<el-button size="mini" style="margin-right: 5px;" icon="el-icon-edit">编辑</el-button>
									</router-link>
									<el-popover placement="top" width="60" v-model="scope.row.visible">
										<p>确定删除吗？</p>
										<div style="text-align: right; margin: 0">
											<el-button size="mini" type="text" @click.stop="scope.row.visible = false">
												取消
											</el-button>
											<el-button type="primary" size="mini" @click.stop="del(scope.row)">确定
											</el-button>
										</div>
										<el-button size="mini" slot="reference" icon="el-icon-delete">删除
										</el-button>
									</el-popover>
								</template>
							</el-table-column>
							<template>
								<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
							</template>
						</el-table>

						<div style="padding:15px;text-align: right;">
							<el-pagination background layout="total,prev, pager, next" :current-page.sync="pgno"
								:page-size="pgsize" @current-change="page" :total="pgcount">
							</el-pagination>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				host:this.$host,
				searchkey: {
					cname: '',
					trade: '',
					source: '',
					tradetype:'',
				},
				maps: {},
				tableData: [],
				pgno: 1,
				pgcount: 0,
				pgsize:13
			}
		},

		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=[to];
			})
		},

		activated:function(){
			this.ini();
		},
		watch:{
			
			'$route'(to,from){
				
				if(to.path==from.path&&to.fullPath!=from.fullPath){
					this.ini();
				}
			}
		},
		mounted: function() {

			this.host = this.$host
			if(this.$route.query.tradetypeid&&this.$route.query.tradetypeid>0){
				this.searchkey.tradetype=this.$route.query.tradetypeid
			}
			if(this.$route.query.sourceid&&this.$route.query.sourceid>0){
				this.searchkey.source=this.$route.query.sourceid
			}
			//this.ini();
		},
		methods: {

			ini() {
				this.$ajax({
					url: 'customer/',
					data: {
						'do': 'ini',
						'type': 'key'
					},
				}).then(res => {

					this.maps = res.nav;
					this.getdata({
					pgno: this.pgno
				});
				})

			},
			del(row) {
				this.$ajax({
					url: 'customer/',
					data: {
						'do': 'del',
						'id': row.id
					},
				}).then(res => {
					if(res.error>0){
						this.$alert('已绑定合同，不能删除')
					}
					this.getdata();
				})
			},
			page(v) {
				this.pgno = v;
				this.getdata({
					pgno: v
				});
			},
			getdata(v) {
				if (typeof(v) != 'object' || v.pgno === undefined) {
					console.log('9999')
					this.pgno = 1;
				}
				
				this.$ajax({
					url: 'customer/',
					data: {
						'do': 'list',
						'pgno': this.pgno,
						'pgsize':this.pgsize,
						'searchkey': this.searchkey
					},
				}).then(res => {
					for(var i =0;i<res.c;i++){
						res.res[i].avgstar=parseFloat(res.res[i].avgstar)
					}
					this.tableData = res.res;
					this.pgcount=parseInt(res.count)
				})
			}
		},
	}
</script>

<style>
</style>
