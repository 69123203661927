<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right" v-if="breadcrumb.length>0">
				<div class="pagetitle">{{breadcrumb[breadcrumb.length-1].name}}</div>
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadcrumb" :key="index" v-show="index!=breadcrumb.length-1">{{item.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row>
			<el-col>
				<div class="panel">
					<div class="fcj_ll" style="align-items: stretch;">
						<div style="flex:1;padding:20px 40px 40px 40px;">
							<div class="fcjtitle">房间基本信息</div>
							<div class="info res" style="line-height:180%;font-size:12px;">
								<el-descriptions title="" :column="2" size="medium">
									<el-descriptions-item label="门牌名称" label-class-name="des-label"
										content-class-name="des-content">
										<span style="color: rgb(250, 100, 0); font-size: 20px;">{{office.roomnumber}}</span>
									</el-descriptions-item>
									<el-descriptions-item label="" label-class-name="des-label"
										content-class-name="des-content">
										
									</el-descriptions-item>
									<el-descriptions-item label="物业类型" label-class-name="des-label"
										content-class-name="des-content">
										{{office.class_name}}
									</el-descriptions-item>
									<el-descriptions-item label="建筑面积" label-class-name="des-label"
										content-class-name="des-content">
										{{office.builduparea}}
									</el-descriptions-item>
									<el-descriptions-item label="租金单价" label-class-name="des-label"
										content-class-name="des-content">
										{{office.price}}
									</el-descriptions-item>
									<el-descriptions-item label="月租金" label-class-name="des-label"
										content-class-name="des-content">
										{{office.monthprice}}
									</el-descriptions-item>
									<el-descriptions-item label="物业单价" label-class-name="des-label"
										content-class-name="des-content">
										{{office.property}}
									</el-descriptions-item>
									<el-descriptions-item label="月物业费" label-class-name="des-label"
										content-class-name="des-content">
										{{office.monthproperty}}
									</el-descriptions-item>
								</el-descriptions >
								<el-descriptions title="" :column="1" size="medium">
									
									<el-descriptions-item label="出租统计" label-class-name="des-label"
										content-class-name="des-content">
										{{office.statistics==1?'统计':'不统计'}}
									</el-descriptions-item>
									<el-descriptions-item label="展示状态" label-class-name="des-label"
										content-class-name="des-content">
										{{office.isshow==1?'对外可见':'对外不可见'}}
									</el-descriptions-item>
									<el-descriptions-item label="租赁状态" label-class-name="des-label"
										content-class-name="des-content">
										{{office.sstate==1?'出租中':(office.sstate==3?'已预定':'空置中')}}
									</el-descriptions-item>
									
									
									
									<el-descriptions-item label="租赁客户" label-class-name="des-label"
										content-class-name="des-content">
										{{office.company}}
									</el-descriptions-item>
									<el-descriptions-item label="到期日期" label-class-name="des-label"
										content-class-name="des-content">
										{{office.endtime}}
									</el-descriptions-item>
									<el-descriptions-item label="装修状态" label-class-name="des-label"
										content-class-name="des-content">
										{{office.renovation_state==1?'装修中':'正常'}}
									</el-descriptions-item>
									<el-descriptions-item label="报修状态" label-class-name="des-label"
										content-class-name="des-content">
										{{office.repair_state==1?'报修中':'正常'}}
									</el-descriptions-item>
									
									
									<el-descriptions-item label="意向客户" label-class-name="des-label"
										content-class-name="des-content">
										{{office.intention?'有':'无'}}
									</el-descriptions-item>
								</el-descriptions>
							
								<div>
									<div class="des-label" style="text-align: left;">房间内景图</div>
									<div style="margin:0 -8px;">
										<div class="demo-image__preview" v-for="(item,index) in office.pics" :key="index">
											<el-image style="width: 200px; height: 150px;border-radius: 5px;" :src="item"
												:preview-src-list="office.pics">
											</el-image>
										</div>
									</div>
								</div>
							
							</div>
						</div>
						<div style="width:300px;background-color: #f7f9ff;border-radius: 0 8px 8px 0;padding:30px;">
							<div  v-if="office.type!=75||!apartmentmodel">
								<div class="fcj_lr">
									<div class="fcjtitle">装修记录</div>
									<div>
										<el-button v-if="office.state>0" @click="showaddrenovationlog=true" type="primary" size="mini"
											icon="el-icon-plus">新增</el-button>
									</div>
								</div>
								<div  v-for="(item) in renovationlog" :key="item.id" style="border-bottom: 1px solid #E4E8F0;">
								<el-descriptions title="" :column="1" size="medium" labelClassName="des-labeloffceinfo" contentClassName="des-content">

									<el-descriptions-item label="开始日期">
										{{item.starttime}}
									</el-descriptions-item>
									<el-descriptions-item label="状态">
										<el-switch v-if="office.state>0" v-model="item.state" active-color="#13ce66" inactive-color="#ff4949"
											active-value="0" inactive-value="1" @change="editrenovation(item)">
										</el-switch>
										{{item.state==1?'进行中':'已完成'}}
									</el-descriptions-item>
									<el-descriptions-item label="说明">
										{{item.note}}
									</el-descriptions-item>
									<el-descriptions-item label="经办人">
										{{item.name||item.usern}}
									</el-descriptions-item>
									<el-descriptions-item label="操作">
										<el-button size="small" @click="delrenovation(item)" v-if="office.state>0">删除</el-button>
									</el-descriptions-item>
								</el-descriptions>
								</div>
							</div>
							<div v-if="office.type==75&&apartmentmodel">
								<div class="fcj_lr">
									<div class="fcjtitle">室内物品</div>
									<div>
										<el-button v-if="office.state>0" @click="addgoods()" type="primary" size="mini"
											icon="el-icon-plus">新增</el-button>
									</div>
								</div>
								<div class="fcj_ll" style="flex-wrap:wrap;">
								<div  v-for="(item,index) in thisgoods" :key="'goods'+index" style="margin:10px;text-align:center;position: relative;">
									<div class="pao">{{item.number}}</div>
									<el-image style="width: 80px; height: 80px;border-radius: 5px;text-align:center;" :src="item.picsf[0]"
										:preview-src-list="item.picsf">
									</el-image>
									<div>
										<div style="margin:10px 0px;font-size: 12px;overflow: hidden;width:80px;height: 16px;">{{item.name}}</div>

										<div ><el-button size="mini" @click="delgoods(index)" v-if="office.state>0" icon="el-icon-delete">删除</el-button></div>
									</div>
								
								</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</el-col>
		</el-row>

		<el-row>
			<el-col>
				<div class="panel">
					<div>
						
						<div class="detai pad16" style="line-height:180%;font-size:24rpx;">
							<div class="fcjtitle">租赁记录</div>
							<el-table :data="rentlog">
								<el-table-column prop="contractnum" label="合同号">
								</el-table-column>
								<el-table-column prop="customer" label="客户名称">
								</el-table-column>
								<el-table-column label="合同周期">
									<template slot-scope="scope">
										{{scope.row.starttime+' 至 '+scope.row.endtime}}
									</template>
								</el-table-column>
								<el-table-column prop="personal" label="经办人">
								</el-table-column>
								
							</el-table>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row v-if="office.type==75&&apartmentmodel">
			<el-col>
				<div class="panel">
					<div>
						<div class="fcj_lr pad16">
							<div class="fcjtitle">租户信息</div>
							<div><el-button size="mini" icon="el-icon-plus" @click="showadduser()" type="primary">添加租户</el-button></div>
						</div>
						<div class="detai pad16" >
							<div>
								<el-table :data="apalist">
									<el-table-column prop="tname" label="姓名">
									</el-table-column>
									<el-table-column prop="phone" label="电话">
									</el-table-column>
									<el-table-column prop="intime" label="入住时间">
									</el-table-column>
									<el-table-column prop="outtime" label="退房时间">
									</el-table-column>
									<el-table-column  label="操作" width="260">
										<template slot-scope="scope">
											<div class="fcj_ll">
												<div><el-button size="mini" icon="el-icon-edit" @click="edituser(scope.row)">编辑</el-button></div>
												<div><el-button size="mini" icon="el-icon-delete" @click="deluser(scope.row)">删除</el-button></div>
												<div><el-button size="mini" icon="el-icon-takeaway-box" v-if="scope.row.state==1" @click="outrent(scope.row)">退租</el-button><span v-else style="margin-left:10px;">已退租</span> </div>
											</div>
										</template>
									</el-table-column>

								</el-table>
							</div>
						</div>
					</div>
				</div>				
			</el-col>
		</el-row>
		<el-row v-if="office.type!=75||!apartmentmodel">
			<el-col>
				<div class="panel " >
					<div class="fcjtitle">
						<div class="fcj_lr pad16">
							<div>意向客户</div>
							<div>
								<el-popconfirm v-if="office.state>0" 
									confirm-button-text='确认'
									cancel-button-text='取消'
									icon="el-icon-info"
									icon-color="red"
									@confirm="editintentionlog()"
									title="确定将所有记录改成已失效吗？"
									style="margin-right:10px"
								>
								<el-button type="info" size="small"
									icon="el-icon-refresh-right" slot="reference">批量失效</el-button>
									</el-popconfirm>
								<el-button v-if="office.state>0" @click="showaddintentionlog=true" type="primary" size="small"
									icon="el-icon-plus">新增</el-button>
							</div>
						</div>
					</div>
					<div class=" pad16">
						<el-table :data="intentionlog">
							<el-table-column prop="customer" label="客户名称">
							</el-table-column>
							<el-table-column prop="note" label="意向说明">
							</el-table-column>
							<el-table-column prop="dt" label="看房日期">
							</el-table-column>
							<el-table-column prop="state" label="状态">
								<template slot-scope="scope">
									{{scope.row.state==1?'正常':'失效'}}
								</template>
							</el-table-column>
							<el-table-column prop="name" label="经办人">
								<template slot-scope="scope">
									{{scope.row.name||scope.row.usern}}
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-col>
		</el-row>
		
		<el-row>
			<el-col>
				<div class="panel ">
					
					<div class=" pad16">
						<div class="fcjtitle">门牌名称使用记录</div>
						<el-table :data="namelog">
							<el-table-column prop="oldname" label="门牌名称">
							</el-table-column>
							<el-table-column prop="dt" label="修改日期">
							</el-table-column>
							<el-table-column prop="personal" label="修改人">
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-col>
		</el-row>



		<el-dialog :visible.sync="showaddrenovationlog" width="700px">
			<div></div>
			<div>
				<div>
					<el-form :model="renovationForm" :rules="renovationFormrules" ref="renovationForm"
						label-width="100px" class="demo-ruleForm">
						<el-form-item label="开始日期" prop="starttime">
							<el-date-picker v-model="renovationForm.starttime" type="date" style="width:140px"
								placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="装修状态" prop="state">
							<el-radio-group v-model="renovationForm.state">
								<el-radio name="renovationFormstate" label="1" value="1">进行中</el-radio>
								<el-radio name="renovationFormstate" label="0" value="0">已完成</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="装修说明" prop="note">
							<el-input type="textarea" v-model="renovationForm.note" style="width:550px;"></el-input>
						</el-form-item>
						

						<el-form-item>
							<el-button type="primary" @click="submitForm('renovation')">保存</el-button>
							<el-button @click="showaddrenovationlog = false">取 消</el-button>
						</el-form-item>
					</el-form>
				</div>

			</div>
		</el-dialog>

		<el-dialog :visible.sync="showaddintentionlog" width="700px">
			<div></div>
			<div>
				<div>
					<el-form :model="intentionForm" :rules="intentionFormrules" ref="intentionForm"
						label-width="100px" class="demo-ruleForm">
						<el-form-item label="客户名称" prop="customer">
							<el-input v-model="intentionForm.customer" style="width:550px;"></el-input>
						</el-form-item>
						<el-form-item label="意向说明" prop="note">
							<el-input type="textarea" v-model="intentionForm.note" style="width:550px;"></el-input>
						</el-form-item>
						<el-form-item label="看房日期" prop="dt">
							<el-date-picker v-model="intentionForm.dt" type="date" style="width:140px"
								placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						

						<el-form-item>
							<el-button type="primary" @click="submitForm('intention')">保存</el-button>
							<el-button @click="showaddintentionlog = false">取 消</el-button>
						</el-form-item>
					</el-form>
				</div>

			</div>
		</el-dialog>

		<el-dialog :visible.sync="showaddgoods" width="700px">
			<div></div>
			<div v-if="goods.length>0">
				<el-table :data="goods" stripe style="width: 100%;" @selection-change="handleSelectionChange">
					<el-table-column
					type="selection"
					width="55">
					</el-table-column>
					<el-table-column prop="name" label="物品名称" width="200px">
						<template slot-scope="scope">
							<div>{{scope.row.name}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="number" label="数量" width="180px">
						<template slot-scope="scope">
							
							<el-input-number size="small" v-model="scope.row.number"  :min="0" :max="20" ></el-input-number>
							
						</template>
					</el-table-column>
					<el-table-column prop="remark" label="备注" >
					</el-table-column>
					<el-table-column prop="picsf" label="图片" width="150px">
						<template slot-scope="scope">
							<div class="fcj_ll"><div><el-image 
							style="width: 60px; height: 60px"
							:src="scope.row.picsf[0]" 
							:preview-src-list="scope.row.picsf">
							</el-image></div><div> ({{scope.row.picsf.length}})</div></div>
						</template>
					</el-table-column>
				</el-table>

				<div style="padding:15px;text-align: right;">
					<el-pagination background layout="total,prev, pager, next" :current-page.sync="goods_pgno" :page-size="goods_pgsize"
						@current-change="goods_page" :total="goods_pgcount">
					</el-pagination>
				</div>

				<div class="fcj_lc">
					<div><el-button @click="linkgoods" type="primary" style="margin-right: 10px;">确认添加</el-button></div>
					<div><el-button @click="cancelgoods">取消</el-button></div>
				</div>

			</div>
		</el-dialog>

		<el-dialog :visible.sync="showapalist" width="700px" title="添加租户信息">
			<el-form :model="userform" :rules="userrule" ref="userform" label-width="100px" class="demo-ruleForm">
				<el-form-item label="姓名" prop="tname">
					<el-input v-model="userform.tname" style="width:550px;"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="userform.phone" style="width:550px;"></el-input>
				</el-form-item>
				
				<el-form-item label="入住时间" prop="intime">
					<el-date-picker  type="date" placeholder="选择日期" value-format="yyyy-MM-dd"  v-model="userform.intime" ></el-date-picker>
				</el-form-item>
				<el-form-item label="退租时间" prop="outtime">
					<el-date-picker  type="date" placeholder="选择日期" value-format="yyyy-MM-dd"  v-model="userform.outtime" ></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="adduser()">保存</el-button>
					<el-button @click="showapalist = false">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				apartmentmodel:false,
				sysname:'',
				userform:{
					tname:'',
					phone:'',
					intime:'',
					outtime:'',
					id:'',
					roomid:'',
					contractid:'',
				},
				userrule:{
					tname: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					phone:[{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}]
				},
				goods_pgno:1,
				goods_pgsize:12,
				goods_pgcount:0,
				goods:[],
				showaddgoods:false,
				abc:[],

				breadcrumb:[],
				showaddintentionlog: false,
				showaddrenovationlog: false,
				isroomform: false,
				officeid: '',
				buildid: '',
				floorid: '',
				res: {},
				log: {},
				p: '',
				states: [],
				state: '',
				rentlog: [],
				renovationlog: [],
				intentionlog: [],
				namelog: [],
				staff: [],
				office: '',
				phone: '',

				renovationForm: {
					officeid: '',
					starttime: '',
					state: '',
					note: '',
					personal: ''
				},
				renovationFormrules: {
					starttime: [{
						required: true,
						message: '请输入装修日期',
						trigger: 'blur'
					}],
				},
				intentionForm: {
					officeid: '',
					customer:'',
					dt: '',
					note: ''
				},
				intentionFormrules:{},
				showapalist:false,
				apalist:[],
				thisgoods:[],
				multipleSelection:[]

			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=vm.$breadcrumb(vm.$root.myrouter,to)
			})
		},
		mounted: function() {
			this.breadcrumb=this.$root.myrouter
			this.officeid = this.$route.query.id;
			this.buildid = this.$route.query.bid;
			this.floorid = this.$route.query.fid;
			this.host = this.$host
			this.getoffice();
		},
		methods: {
			outrent(v){
				this.$ajax({
					url: 'apartment/',
					data: {
						'do':'outrent',
						'id':v.id
					}
				}).then(res => {
					this.getuser();
				});
			},
			showadduser(){
				this.showapalist=true
				this.userform={
					tname:'',
					phone:'',
					intime:'',
					outtime:'',
					id:'',
					roomid:'',
					contractid:'',
				}
			},
			edituser(v){
				this.userform.id=v.id;
				this.userform.tname=v.tname;
				this.userform.phone=v.phone;
				this.userform.intime=v.intime;
				this.userform.outtime=v.outtime;
				this.showapalist=true;
			},
			deluser(v){
				this.$ajax({
					url: 'apartment/',
					data: {
						'do':'deluser',
						'id':v.id
					}
				}).then(res => {
					this.getuser();
				});
			},
			adduser(){
				this.$refs['userform'].validate((valid) => {
					if (valid) {
						var data=this.userform;
						data.contractid=this.office.contractid;
						data.roomid=this.office.id;
						data.do='adduser'
						this.$ajax({
							url: 'apartment/',
							data: data
						}).then(res => {
							if(res.error==0){
								this.getuser();
								this.showapalist=false;
								this.$message({
									message: res.msg,
									type: 'success'
								});
							}else{
								this.$alert(res.msg);
							}
						});
					}
				})
			},
			numberChange(v,e){
				console.log(v);
				console.log(e)
				//this.goods[v].number=v;
			},
			cancelgoods(){
				this.showaddgoods=false;
				this.multipleSelection=[]
			},
			linkgoods(){

				for(var i=0;i<this.multipleSelection.length;i++){
					this.thisgoods.push({
						'name':this.multipleSelection[i].name,
						'pics':this.multipleSelection[i].pics,
						'picsf':this.multipleSelection[i].picsf,
						'number':this.multipleSelection[i].number
					})
				}
				
				this.updategoods();
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			goods_page(v){
				this.goods_pgno=v;
				this.getgoods()
			},
			getgoods(){
				this.$ajax({
					url: 'apartment/',
					data: {
						'do': 'goods',
						'pgsize':this.goods_pgsize,
						'pgno':this.goods_pgno
					}
				}).then(res => {

					
					for (var j = res.data.res.length - 1; j >= 0; j--) {
						if(!res.data.res[j].picsf){
							res.data.res[j].picsf=[];
						}
						res.data.res[j].number=1;
						for (var i = 0; i < res.data.res[j].pics.length; i++) {
							res.data.res[j].picsf[i]=this.host+res.data.res[j].pics[i];
						}
					}
					this.goods=res.data.res;
					this.goods_pgcount=parseInt(res.data.count);
				})
			},
			delgoods(v){
				this.thisgoods.splice(v,1);
				this.updategoods();
			},
			addgoods(){
				this.showaddgoods=true
				this.goods_page(1)
			},
			updategoods(){
				this.$ajax({
					url: 'apartment/',
					data: {
						'do': 'updategoods',
						'roomid': this.officeid,
						'attr': this.thisgoods
					}
				}).then(res => {
					this.showaddgoods=false
				})
			},
			
			resetForm(formName) {
				this.$refs[formName+'Form'].resetFields();
			},
			submitForm(formName) {
				this.$refs[formName+'Form'].validate((valid) => {
					if (valid) {
						this[formName+'Form'].officeid = this.officeid;
						this[formName+'Form'].do = 'add'+formName+'log';
						this.$ajax({
							url: 'room/',
							data: this[formName+'Form']
						}).then(res => {
							if (res.error == 0) {
								//this.[formName+'log'] = res.data.res;
								this.showaddintentionlog=false
								this.showaddrenovationlog=false
							}else{
								this.$alert(res.msg, '提示', {
									confirmButtonText: '确定'
								});
							}

						})
					}
				})
			},
			editintentionlog(){
				this.$ajax({
					url: 'room/',
					data: {
						do:'editintentionlog',
						officeid:this.officeid
					}
				}).then(res => {
					for(var i=0;i<this.intentionlog.length;i++){
						this.intentionlog[i].state=0;
					}
				})
			},
			editrenovation(v) {
				this.$ajax({
					url: 'room/',
					data: {
						'do': 'editrenovationlog',
						'state': v.state,
						'id': v.id
					}
				}).then(res => {
					
				})
			},
			delrenovation(v) {
				this.$ajax({
					url: 'room/',
					data: {
						'do': 'delrenovationlog',
						'id': v.id
					}
				}).then(res => {
					if (res.error == 0) {
						this.renovationlog = res.data.res;
					}
					this.$alert(res.msg, '提示', {
						confirmButtonText: '确定'
					});
				})
			},
			getuser(){
				this.$ajax({
					url:'apartment/',
					data:{
						do:'apalist',
						officeid:this.office.id,
						contractid:this.office.contractid
					}
				}).then(res=>{
					this.apalist=res.data.res;
				})
			},
			getoffice() {
				this.$ajax({
					url: 'floor/',
					data: {
						'roomid': this.officeid
					}
				}).then(res => {

					res.acsys.models=res.acsys.models.split(',');
					if(res.acsys.models.indexOf('26')>=0){
						this.apartmentmodel=true;
					}else{
						this.apartmentmodel=false;
					}
					this.sysname=res.sysname;
					this.office = res.res[0];

					if (res.rentlog['c'] > 0) {
						this.rentlog = res.rentlog['res'];
					}
					if (res.renovationlog['c'] > 0) {
						this.renovationlog = res.renovationlog['res'];
					}
					if (res.intentionlog['c'] > 0) {
						this.intentionlog = res.intentionlog['res'];
					}
					if (res.namelog['c'] > 0) {
						this.namelog = res.namelog['res'];
					}
					if (res.staff['c'] > 0) {
						this.staff = res.staff['res'];
					}
					if(res.goods){
						
						for (var i = 0; i < res.goods.attr.length; i++) {
							res.goods.attr[i].picsf=[];
							if(Array.isArray(res.goods.attr[i].pics)){
								for(var j=0;j<res.goods.attr[i].pics.length;j++){
									res.goods.attr[i].picsf[j]=this.host+res.goods.attr[i].pics[j];
								}
							}else{
								res.goods.attr[i].picsf[0]=this.host+res.goods.attr[i].pics;
							}
							
						}
						this.thisgoods=res.goods.attr;
					}else{
						this.thisgoods=[];
					}
					if(this.office.type==75&&res.acsys.models.indexOf('26')>=0){
						this.getuser();
					}
				})
			},
		},
	}
</script>

<style scoped>
	.pao{
		position: absolute;
		right: 5px;
		top: 5px;
		border-radius: 14px;
		height: 16px;
		line-height: 16px;
		min-width: 16px;
		text-align: center;
		font-size: 12px;
		color: #fff;
		z-index: 1;
		box-shadow: 0 0 4px 2px #fff;
		background-color: rgb(96 133 204);
	}
	.logo {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.tt {
		margin: 16px;
	}
	.pad16{
		padding-top:0px;
	}
/* 
	.fcj_ll {
		font-size: 12px;
	}

	.fcj_ll>div:first-child {
		width: 40px;
		text-align: right;
		color: #666;
	} */
</style>
