<template>

	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">复盘管理</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="24">
				<div class="fcj_lr table-filter" style="margin-bottom: 20px;">
					<div>
					</div>
					<div class="textright">
						<el-button type="primary" icon="el-icon-plus" @click="showadd" v-if="onepark!=1">添加区域</el-button>
					</div>
				</div>
				<div class="replaytable">
					<div v-for="(item,index) in tableData" :key="item.id" :style="item.level==2?'background:rgba(255,255,255,0.3)':''" :class="['fcj_table',item.level==0?'relaylinet':'',item.border==1?'replaylineb':'']">
						
						
						<div class="fcj_ll" style="flex:1;">
							<div><span class="span" :style="{width:item.level*20+5+'px'}"></span></div>
							<div class="fcj_ll" style="margin:0 40px;">
								<img class="icon-small" :src="host+'images/icon/down.png'" alt="" @click.stop="sorts(index, item,'down')">
								<img class="icon-small" :src="host+'images/icon/up.png'" alt="" @click.stop="sorts(index, item,'up')">
							</div>
							<div><img v-if="item.level==2"
									:src="item.tid&&users[item.tid]&&users[item.tid].logo!=''?users[item.tid].logo:(host+'images/fb_icon12.png')"
									style="margin-right:10px;width:36px;height: 36px;border-radius: 50%;">
							</div>
							<div :class="[item.level==0?'red blod':'']">
							{{item.class_name+(item.menu==1?'(管理层)':'')}}</div>
						</div>
						
						<div class="fcj_ll">
							
							<div class="fcj_ll" v-if="item.level==0" @click.stop="addchild(index, item)" style="cursor:pointer;"><img :src="host+'images/icon/replay2.png'" class="replay_icon" style="color: #4D7CFE;" alt=""><div>{{onepark==1?'添加部门':'添加园区'}}</div></div>
							<div class="fcj_ll" v-if="item.level==1" @click.stop="addchild(index, item)" style="cursor:pointer;"><img :src="host+'images/icon/replay3.png'" class="replay_icon" style="color: #96A1B3;" alt=""><div>添加人员</div></div>
							
						</div>
						<div class="fcj_ll">
							<img :src="host+'images/icon/icon_edit_light.png'" @click.stop="edit(index, item)" style="margin-right:20px;" class="icon" alt="">
							
							
							<el-popover placement="top" v-model="item.visible">
								<p>删除后与此属性相关的数据将找不到此属性！确定删除吗？</p>
								<div style="text-align: right; margin: 0">
									<el-button size="mini" type="text" @click.stop="item.visible = false">
										取消</el-button>
									<el-button type="primary" size="mini"
										@click.stop="handleDelete(index, item)">确定
									</el-button>
								</div>
								<img :src="host+'images/icon/icon_delete_light.png'" slot="reference" class="icon" alt="">
								
							</el-popover>
						</div>
						
					</div>

					


				</div>
			</el-col>
		</el-row>
		<el-dialog :visible.sync="isshowadd" :title="formname" @close="closefrom" width="800px">

			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="复盘人员" prop="tid" v-if="level==1&&ruleForm.tid==''">
					<el-select v-model="acadminusers" filterable placeholder="请选择"
						style="width:200px;margin-bottom:20px;">
						<el-option v-for="(item,index) in adminusers" :key="index" :label="item.sysname" :value="index">
						</el-option>
					</el-select>
					<el-transfer v-model="sevalue" :data="adminusers[acadminusers].users"></el-transfer>
				</el-form-item>

				<el-form-item :label="acdepartment==''?'区域名称':(level==0?'园区名称':'人员姓名')" prop="class_name" v-else>
					<el-input v-model="ruleForm.class_name"></el-input>
				</el-form-item>


				<el-form-item label="管理层" prop="menu" v-if="level==1">
					<el-radio-group v-model="ruleForm.menu">
						<el-radio label="0">普通人员</el-radio>
						<el-radio label="1">管理层</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="复盘任务" prop="over" v-if="level==1">
					<el-radio-group v-model="ruleForm.over">
						<el-radio label="0">不复盘</el-radio>
						<el-radio label="1">要复盘</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
				</el-form-item>
			</el-form>

		</el-dialog>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				host: '',
				sevalue: [],
				acadminusers: 0,
				adminusers: [],
				users: {},

				formname: '',
				isshowadd: false,
				activeName: 'department',
				tableData: [],
				might: {},

				ruleForm: {
					class_name: '',
					tid: '',
					note: '',
					sku: '',
					plid: '0',
					logo: '',
					ord: '',
					fid: '',
					menu: '0',
					over: '1'
				},
				rules: {
					gname: [{
						required: true,
						message: '请输角色名称',
						trigger: 'blur'
					}],
					mights: [{
						type: 'array',
						required: true,
						message: '请至少选择一个权限',
						trigger: 'blur'
					}]
				},
				level: 0,
				department: [],
				acdepartment: '',
				onepark:1
			}
		},
		mounted: function() {

			this.getdepartment();
			this.host = this.$host
		},
		methods: {
			closefrom() {
				this.sevalue = [];
			},
			tabclick(tab, event) {
				this.getdepartment();
			},
			edit(index, row) {
				this.isshowadd = true;
				this.level = row.level - 1;
				if (row.level == 0) {
					this.formname = '区域管理'
					this.acdepartment = '';
				} else {
					this.formname = row.level == 1 ? '园区管理' : '人员管理'
					this.acdepartment = row
				}
				var f = {
					class_name: row.class_name,
					note: row.note,
					sku: row.sku,
					plid: row.plid,
					logo: row.logo,
					ord: row.ord,
					fid: row.fid,
					tid: row.tid,

					menu: row.menu,
					over: row.over
				}
				this.ruleForm = f;
			},
			addchild(i, row) {
				this.isshowadd = true;
				this.acdepartment = row;
				this.formname = row.level == 0 ? '添加园区' : '添加人员'
				this.level = row.level;
				var f = {
					class_name: '',
					note: '',
					sku: '',
					plid: row.fid,
					logo: '',
					ord: parseInt(row.ord) + 1,
					fid: '',
					tid: '',
					menu: '0',
					over: '1'
				}
				this.ruleForm = f;
			},

			showadd() {
				this.isshowadd = true;
				this.acdepartment = '';
				this.formname = '添加区域'
				this.level = 0;
				var f = {
					class_name: '',
					note: '',
					sku: '',
					plid: '0',
					logo: '',
					ord: '',
					fid: '',
					menu: '0',
					over: '1'
				}
				this.ruleForm = f;
			},
			handleDelete(index, row) {
				this.$ajax({
					url: 'navset/',
					data: {
						'op': 'del',
						'fid': row.fid
					},
				}).then(res => {
					if (res.error > 0) {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						this.getdepartment();
					}


				})
			},
			sorts(index, row, v) {
				this.$ajax({
					url: 'navset/',
					data: {
						'op': 'sort',
						'sort': v,
						'fid': row.fid
					},
				}).then(res => {
					this.getdepartment();

				})

			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			submitForm(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.ruleForm.op = 'add';
						var data = this.ruleForm;
						if (this.sevalue.length > 0) {
							data.tids = this.sevalue;
						}
						this.$ajax({
							url: 'navset/',
							data: this.ruleForm,
						}).then(res => {
							if (res.error > 0) {
								this.$message({
									message: res.msg,
									type: 'warning'
								});
							} else {
								this.$message({
									message: '保存成功',
									type: 'success'
								});
								this.getdepartment();
							}

						})
					} else {
						return false;
					}
				});

			},
			ini() {
				this.$ajax({
					url: 'sysset/',
					data: {
						'op': 'adminusers'
					},
				}).then(res => {
					var sys = []
					var sysid = '',
						j = -1;
					var us = {};
					for (var i = 0; i < res.data.c; i++) {
						us[res.data.res[i].uuid] = res.data.res[i]
						if (sysid != res.data.res[i].sysid) {
							j++;
							sysid = res.data.res[i].sysid;
							sys[j] = {
								sysid: res.data.res[i].sysid,
								sysname: res.data.res[i].sysname,
								users: [{
									key: res.data.res[i].uuid,
									label: res.data.res[i].name
								}],
							}
						} else {
							sys[j].users.push({
								key: res.data.res[i].uuid,
								label: res.data.res[i].name
							})
						}
					}
					this.users = us;
					this.adminusers = sys;
					console.log(sys);
				})
				this.$ajax({
					url: 'daily/',
					data: {
						'do': 'parkset'
					},
				}).then(res => {
					this.onepark=res.data;
					console.log(this.onepark);
				})
			},
			getdepartment() {
				if (this.activeName == 'department') {
					this.$ajax({
						url: 'navset/',
						data: {
							'navname': this.activeName
						},
					}).then(res => {
						for(var i=0;i<res.res.length;i++){
							res.res[i].border=0;
							if(res.res[i].level==0){
								if(res.res[i-1]){
									res.res[i-1].border=1;
								}
							}
						}
						this.tableData = res.res;
						this.ini();
					})
				} else {
					this.ini();
				}
			}
		},
	}
</script>

<style>
	.daily-row {
		height: 50px;
	}
</style>
