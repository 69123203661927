<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">基础配置</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="panel">
			<div class="panel-header">
				<div class="charttitle">园区基础配置</div>
			</div>
			<div class="panel-body pad16" style="padding-top:0px;">

				<el-tabs v-model="activeName" type="card" @tab-click="tabclick" style="margin-top:20px;">
					<el-tab-pane label="基本信息" name="first">

						<el-form :model="pare" :rules="rules" ref="pare" label-width="150px" class="demo-pare"
							style="margin:20px 0;">
							<el-form-item label="园区地址" prop="address">
								<el-input v-model="pare.address" style="width:500px;"></el-input>
							</el-form-item>
							<el-form-item label="招商电话" prop="telphone">
								<el-input v-model="pare.telphone" style="width:300px;"></el-input>
							</el-form-item>
							<el-form-item label="物业电话" prop="propertyphone">
								<el-input v-model="pare.propertyphone" style="width:300px;"></el-input>
							</el-form-item>
							<el-form-item label="出租方" prop="propertyphone">
								<el-input v-model="pare.mycompany" style="width:300px;"></el-input>
							</el-form-item>
							<el-form-item label="关注公众号链接" prop="wxurl">
								<el-input v-model="pare.wxurl"></el-input>
							</el-form-item>
							<el-form-item label="水电费账单告示" prop="elenote">
								<el-input type="textarea" v-model="pare.elenote"></el-input>
							</el-form-item>
							<el-form-item label="租金物业费账单告示" prop="propertynote">
								<el-input type="textarea" v-model="pare.propertynote"></el-input>
							</el-form-item>
							<el-form-item label="小程序转发标题" prop="share_title">
								<el-input v-model="pare.share_title" style="width:500px;"></el-input>
							</el-form-item>
							<el-form-item label="小程序转发图片" prop="share_pic">
								<div class="fcj_ll">
									<div><img :src="host+pare.share_pic" v-if="pare.share_pic!=''"
											style="margin-right:20px;height: 100px;" /></div>
									<div>
										<el-upload :action="host+'upimg/'" :data="uploaddata" list-type="text"
											ref="uploadparkpic" name="upfile" :on-success="uploadparkpicsuccess"
											:auto-upload="true" :limit="1">
											<el-button type="primary" size="medium" icon="el-icon-picture-outline">
												上传图片</el-button>
										</el-upload>
									</div>
								</div>

							</el-form-item>
							<el-form-item label="">
								<el-button type="primary" @click="submitForm('pare')">保存</el-button>
							</el-form-item>
						</el-form>
					</el-tab-pane>
					<el-tab-pane label="收款账号" name="second">
						<el-form :model="bank" ref="bank" label-width="150px" style="margin:20px 0;">
							<el-form-item label="账户名称" prop="comname">
								<el-input v-model="bank.comname"></el-input>
							</el-form-item>
							<el-form-item label="开户银行" prop="bankname">
								<el-input v-model="bank.bankname"></el-input>
							</el-form-item>
							<el-form-item label="银行账号" prop="banknumber">
								<el-input v-model="bank.banknumber"></el-input>
							</el-form-item>

							<el-form-item label="">
								<el-button type="primary" @click="submitForm('bank')">保存</el-button>
							</el-form-item>
						</el-form>

						<div>
							<el-table :data="banks" header-cell-class-name="table-header" stripe>
								<el-table-column prop="comname" label="账户名称">
								</el-table-column>

								<el-table-column prop="bankname" label="开户银行">
								</el-table-column>

								<el-table-column prop="banknumber" label="银行账号">
								</el-table-column>
								<el-table-column prop="" label="操作">
									<template slot-scope="scope">
									<el-button size="small" @click="editbank(scope.$index,scope.row)">编辑</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</el-tab-pane>

				</el-tabs>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				acbank:'',
				host: '',
				activeName: 'first',
				uploaddata: {
					pusern: '',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				acsys: {},
				bank: {
					comname: '',
					bankname: '',
					banknumber: ''
				},
				pare: {
					address: '',
					telphone: '',
					propertyphone: '',
					wxurl: '',
					elenote: '',
					propertynote: '',
					share_title: '',
					share_pic: ''

				},
				rules: {
					sysname: [{
						required: true,
						message: '请输入园区名称',
						trigger: 'blur'
					}],
					database: [{
						required: true,
						message: '请输入数据库',
						trigger: 'blur'
					}]
				},
				banks: []
			}
		},
		mounted: function() {
			this.uploaddata.pusern = localStorage.getItem('pusern');
			this.ini();
			this.host = this.$host
		},
		methods: {
			editbank(v,n){
					this.bank=JSON.parse(JSON.stringify(n));
					this.acbank=v;
			},
			tabclick() {

			},
			uploadparkpicsuccess(response) {
				this.pare.share_pic = response.fpath

				this.$refs.uploadparkpic.clearFiles();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			submitForm(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						var data = {
							'do': 'updatecontent',
							'type': formName
						}
						if(formName=='bank'){
							var s=this.banks;
							if(this.acbank!==''){
								s[this.acbank]=this.bank
							}else{
								s.push(this.bank)
							}
							data.content = JSON.stringify(s)
							
						}else{
							data.content = JSON.stringify(this[formName])
						}

						this.$ajax({
							url: 'map/',
							data: data,
						}).then(res => {
							if (res.error > 0) {
								this.$message({
									message: res.msg,
									type: 'warning'
								});
							} else {
								this.$message({
									message: '保存成功',
									type: 'success'
								});
								this.resetForm(formName)
								this.acbank='';
								this.ini();
							}

						})
					} else {
						return false;
					}
				});
			},
			ini() {
				var maps = ['pare', 'bank'];
				this.$ajax({
					url: 'map/',
					data: {
						'do': 'list',
						'type': maps
					},
				}).then(res => {
					if (res.data.pare.content) {
						this.pare = JSON.parse(res.data.pare.content)
					}
					if (res.data.bank.content) {
						this.banks = JSON.parse(res.data.bank.content)

					}
					this.acsys = res.acsys;
				});

			}
		}
	}
</script>

<style>
</style>
