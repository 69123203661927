<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">园区管理</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel">

					<div class="panel-body" ref="userlist">

						
						<div class="pad16 textright"><el-button type="primary" size="small"  icon="el-icon-plus" @click="showadd">添加园区</el-button></div>
						
						
						<el-table :data="tableData" style="width: 100%">
							<el-table-column prop="sort" label="#" width="50">
							</el-table-column>
							<el-table-column prop="sort" label="排序" width="110">
								<template slot-scope="scope">
									<el-button size="mini" slot="reference" icon="el-icon-sort-down"
										@click.stop="sorts(scope.$index, scope.row,'down')" circle
										style="margin-right: 10px;">
									</el-button>
									<el-button size="mini" slot="reference" icon="el-icon-sort-up"
										@click.stop="sorts(scope.$index, scope.row,'up')" circle
										style="margin-right: 10px;">
									</el-button>
						
								</template>
							</el-table-column>
							<el-table-column prop="logo" label="" width="100">
								<template slot-scope="scope">
									<div :style="'background-image:url('+host+scope.row.logo+');background-repeat:no-repeat;height:50px;background-size:contain;background-position:center;'"></div>
								</template>
							</el-table-column>
							<el-table-column prop="sysname" label="园区名称" >
								<template slot-scope="scope">
									<span class="span"
										:style="{width:scope.row.level*20+5+'px'}"></span>{{scope.row.sysname}}
								</template>
							</el-table-column>
							<el-table-column prop="menu" label="菜单激活">
								<template slot-scope="scope">
									<el-switch v-model="scope.row.menu" active-color="#13ce66" inactive-color="#ff4949"
										active-value="1" inactive-value="0" @change="editmenu(scope.row)">
									</el-switch>
									{{scope.row.menu==1?'激活':'不激活'}}
								</template>
							</el-table-column>
							<el-table-column prop="" label="园区状态">
								<template slot-scope="scope">
									<el-switch v-model="scope.row.state" active-color="#13ce66" inactive-color="#ff4949"
										active-value="1" inactive-value="0" @change="editstate(scope.row)">
									</el-switch>
									{{scope.row.state==1?'正常':'停用'}}
								</template>
							</el-table-column>
							<el-table-column prop="database" label="数据库">
							</el-table-column>
							<el-table-column prop="database" label="小程序链接" width="300">
								<template slot-scope="scope">
									<span style="margin-right:10px;" :ref="'a'+scope.row.id">../index/index?sysid={{scope.row.id}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="database" label="二维码" width="220">
								<template slot-scope="scope">
									<el-button @click="shower(scope.row,0)" size="mini">园区二维码</el-button>
									<el-button @click="shower(scope.row,1)" size="mini">绑定二维码</el-button>
								</template>
							</el-table-column>

							<el-table-column label="" width="100">
								<template slot-scope="scope">

									<el-button size="mini" slot="reference" icon="el-icon-edit"
										@click.stop="edit(scope.$index, scope.row)" circle
										style="margin-right: 10px;">
									</el-button>
									<el-popover placement="top" width="60" v-model="scope.row.visible">
										<p>删除后与此属性相关的数据将找不到此属性！确定删除吗？</p>
										<div style="text-align: right; margin: 0">
											<el-button size="mini" type="text"
												@click.stop="scope.row.visible = false">取消</el-button>
											<el-button type="primary" size="mini"
												@click.stop="handleDelete(scope.$index, scope.row)">确定
											</el-button>
										</div>
										<el-button size="mini" slot="reference" icon="el-icon-delete" circle>
										</el-button>
									</el-popover>
						
								</template>
							</el-table-column>
						</el-table>
						


					</div>
					<div class="panel-foot">
						<div class="pad16"></div>
					</div>
				</div>
			</el-col>

		</el-row>
		<el-dialog :visible.sync="isshowadd" :title="formname" width="800px">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
				<el-form-item label="园区名称" prop="sysname">
					<el-input v-model="ruleForm.sysname"></el-input>
				</el-form-item>
				
				<el-form-item label="logo" prop="logo">
					<div class="fcj_ll">
						<div>
							<el-image v-if="ruleForm.logo"
							style="width: 80px; height: 80px;margin-right: 10px;"
							:src="host+ruleForm.logo"
							></el-image>
						</div>
						<div>
							<el-upload :action="host+'upimg/'" :data="uploaddata" list-type="text" ref="uploadparkpic"
								name="upfile" :on-success="uploadparkpicsuccess" :auto-upload="true" :limit="1">
								<el-button type="primary" size="medium" icon="el-icon-picture-outline">上传logo</el-button>
							</el-upload>
						</div> 
					</div>
					
					
				</el-form-item>
				<el-form-item label="功能选择" prop="models">
					<div >
						<div class="block">
						<div class="fcj_ll" style="margin:10px 0;">
							<div style="width:100px;">模块功能</div>
							<div style="flex:1;">
								<el-checkbox-group v-model="ruleForm.models">
									<template>
										<el-checkbox name="mode" :disabled="chmodels.indexOf(item.id)>=0" :label="item.id" :value="item.id"   v-for="(item) in sysmodel[1]" :key="item.id">{{item.modelname}}</el-checkbox>
									</template>
								</el-checkbox-group>
							</div>
						</div>
						
						<div class="fcj_ll" style="margin:10px 0;">
							<div style="width:100px;">管理功能</div>
							<div style="flex:1;">
								<el-checkbox-group v-model="ruleForm.models">
									<template>
										<el-checkbox name="mode" :disabled="chmodels.indexOf(item.id)>=0"  :label="item.id" :value="item.id"   v-for="(item) in sysmodel[2]" :key="item.id">{{item.modelname}}</el-checkbox>
									</template>
								</el-checkbox-group>
							</div>
						</div>
						
						<div class="fcj_ll" style="margin:10px 0;">
							<div style="width:100px;">个人中心</div>
							<div style="flex:1;">
								<el-checkbox-group v-model="ruleForm.models">
									<template>
										<el-checkbox name="mode" :disabled="chmodels.indexOf(item.id)>=0"  :label="item.id" :value="item.id"   v-for="(item) in sysmodel[3]" :key="item.id">{{item.modelname}}</el-checkbox>
									</template>
								</el-checkbox-group>
							</div>
						</div>
						</div>
					</div>
				</el-form-item>
				
				<!-- 						
					<el-form-item label="类属性" prop="logo">
						<el-input v-model="class_attr" style="width:100px" />
						<el-button class="btn btn-sm" type="button" @click="addmodels">添加</el-button>
					</el-form-item>
					<el-form-item label="" prop="logo">
						<div v-for="(item,index) in models" :key="index" class="fcj_ll" style="margin-bottom:10px">
							<div style="width:200px;">{{item}}</div>
							<div><el-button @click="delmodels(index)" size="mini">删除</el-button></div>
						</div>
					</el-form-item> 
				-->
							
				<el-form-item label="数据库" prop="database">
					<el-input  v-model="ruleForm.database"></el-input>
				</el-form-item>
				<!-- 
				<div class="block" style="padding:20px 20px 20px 0;">
					<el-form-item label="园区地址" prop="address">
						<el-input  v-model="ruleForm.pare.address"></el-input>
					</el-form-item>
					<el-form-item label="招商电话" prop="telphone">
						<el-input  v-model="ruleForm.pare.telphone"></el-input>
					</el-form-item>
					<el-form-item label="物业电话" prop="propertyphone">
						<el-input  v-model="ruleForm.pare.propertyphone"></el-input>
					</el-form-item>
					<el-form-item label="关注公众号链接" prop="wxurl">
						<el-input  v-model="ruleForm.pare.wxurl"></el-input>
					</el-form-item>
					<el-form-item label="水电费账单告示" prop="elenote">
						<el-input type="textarea"  v-model="ruleForm.pare.elenote"></el-input>
					</el-form-item>
					<el-form-item label="租金物业费账单告示" prop="propertynote">
						<el-input type="textarea"  v-model="ruleForm.pare.propertynote"></el-input>
					</el-form-item>
				</div> -->

				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
					<el-button @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog :visible.sync="erdiv" :title="acyuan.sysname+'二维码'" width="500px">
			<img :src="host+'images/quer/a'+acyuan.id+(bindtag==1?'bind':'')+'.jpg'" alt="" style="width:200px;margin:0 auto;">
		</el-dialog>
	</div>
</template>

<script>
	import uilt from '../assets/a.js'
	export default {
		data() {
			return {
				erdiv:false,
				chmodels:[],
				acyuan:{},
				uploaddata: {
					pusern: '',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				activeName: 'sys',
				isshowadd: false,
				tableData: [],
				host:'',
				formname: '园区管理',
				might: {},
				ruleForm: {
					sysname: '',
					database: '',
					models: [],
					plid: '0',
					logo: '',
					state:'',
					menu:'',
					sort: '',
					id: '',

				},
				rules: {
					sysname: [{
						required: true,
						message: '请输入园区名称',
						trigger: 'blur'
					}],
					database: [{
						required: true,
						message: '请输入数据库',
						trigger: 'blur'
					}]
				},
				class_attr:'',
				models:[],
				sysmodel:{},
				digsysmodel:{},
				bindtag:0,
			}
		},
		mounted: function() {
			this.uploaddata.pusern=localStorage.getItem('pusern');
			this.ini();

			this.host = this.$host
		},
		methods: {

			copy(v){

			},
			shower(v,p){
				this.$ajax({
					url: 'sysset/',
					data: {
						'op': 'qrcode',
						'bindtag': p,
						'id': v.id
					}
				}).then(res => {
					this.erdiv=true
					this.acyuan=v;
					this.bindtag=p;
				})
				
			},
			editmenu(v) {
				this.$ajax({
					url: 'sysset/',
					data: {
						'op': 'editmenu',
						'menu': v.menu,
						'id': v.id
					}
				}).then(res => {
					
				})
			},
			editstate(v) {
				this.$ajax({
					url: 'sysset/',
					data: {
						'op': 'editstate',
						'state': v.state,
						'id': v.id
					}
				}).then(res => {
					
				})
			},
			uploadparkpicsuccess(response) {
				this.ruleForm.logo=response.fpath
				this.$refs.uploadparkpic.clearFiles();
			},
			handleClick(tab, event) {
				this.ini();
			},
			edit(index, row) {
				this.isshowadd = true;
				var f = {
					sysname: row.sysname,
					database: row.database,
					models: row.models,
					plid: row.plid,
					logo: row.logo,
					sort: row.sort,
					id: row.id
				};
				this.ruleForm = f;
			},
			addchild(i, row) {
				this.isshowadd = true;
				var f = {
					sysname: '',
					database: '',
					models: [],
					plid: row.id,
					logo: '',
					sort: parseInt(row.sort) + 1,
					id: ''
				}
				this.ruleForm = f;
			},
			showadd() {
				this.isshowadd = true;
				var f = {
					sysname: '',
					database: '',
					models: this.chmodels,
					plid: '0',
					logo: '',
					sort: '',
					id: '',
					pare:{
						address:'',
						telphone:'',
						propertyphone:'',
						wxurl:'',
						elenote:'',
						propertynote:'',
					}
				}
				this.ruleForm = f;
			},
			handleDelete(index, row) {
				this.$ajax({
					url: 'sysset/',
					data: {
						'op': 'del',
						'id': row.id
					},
				}).then(res => {
					if (res.error > 0) {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
					
						this.ini();
						uilt.$emit('demo');
					}


				})
			},
			sorts(index, row, v) {
				this.$ajax({
					url: 'sysset/',
					data: {
						'op': 'sort',
						'sort': v,
						'id': row.id
					},
				}).then(res => {
					this.ini();

				})

			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				},
			submitForm(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.ruleForm.op = 'add';
						this.ruleForm.models=this.ruleForm.models.join(',')
						var data = this.ruleForm;
						this.$ajax({
							url: 'sysset/',
							data: this.ruleForm,
						}).then(res => {
							if (res.error > 0) {
								this.$message({
									message: res.msg,
									type: 'warning'
								});
							} else {
								this.$message({
									message: '保存成功',
									type: 'success'
								});
								this.isshowadd = false;
								this.resetForm('ruleForm')
								this.ini();
							}

						})
					} else {
						return false;
					}
				});

			},
			ini() {
				this.$ajax({
					url: 'sysset/',
					data: {
						'navname': this.activeName
					},
				}).then(res => {
					this.tableData = res.sys.res;
					//this.sysmodel=res.sysmodel;
					var s=[];
					for(var i=0;i<res.sysmodel.c;i++){
						if(res.sysmodel.res[i].nochange==1){
							this.chmodels.push(res.sysmodel.res[i].id)
						}
						if(!s[res.sysmodel.res[i].typeid]){
							s[res.sysmodel.res[i].typeid]=[];
						}
						s[res.sysmodel.res[i].typeid].push(res.sysmodel.res[i]);
					}

					this.sysmodel=s;
					this.digsysmodel=res.digsysmodel;
				})
			},


			
		},
	}
</script>

<style>
	
</style>
