<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>
					<div class="pagetitle">文章列表</div>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="panel">
			<div class="panel-body pad16">
				<div class="fcj_lr table-contral">
					<div class="fcj_ll">
						<div>
							<el-select v-model="type" placeholder="请选择" @change="getdata" clearable="">
								<el-option v-for="item in alltype" :key="item.fid" :label="item.class_name"
									:value="item.fid">
								</el-option>
							</el-select>
						</div>
						<div>
							<el-input :value="'article.html?dos=list&type='+type" style="margin-left: 10px;width:400px;"
								v-if="type!=''">
								<template slot="prepend">分类地址：</template>
							</el-input>

						</div>
					</div>
					<div>
						<router-link :to="'/articleedit'">
							<el-button type="primary" size="small" icon="el-icon-plus">添加文章
							</el-button>
						</router-link>
					</div>
				</div>
				<el-table :data="tabledata" header-cell-class-name="table-header" :row-class-name="tableRowClassName"
					cell-class-name="fcj" stripe v-loading="loading">
					<el-table-column prop="content" label="置顶" width="50">
						<template slot-scope="scope">
							<i class="el-icon-upload2" v-if="scope.row.istop==0" @click="totop(1,scope.row)" style="cursor: pointer;"></i>
							<i class="el-icon-download" v-else @click="totop(0,scope.row)" style="cursor: pointer;"></i>
						</template>
					</el-table-column>
					<el-table-column prop="content" label="分类" width="100">
						<template slot-scope="scope">
							<div>{{typemap[scope.row.type]?typemap[scope.row.type].class_name:''}}</div>
						</template>
					</el-table-column>

					<el-table-column prop="content" label="内容">
						<template slot-scope="scope">
							<div v-if="scope.row.title"
								:style="'font-size: 14px;color:'+scope.row.color+';font-weight:'+(scope.row.b==1?'700':'400')+';'">
								{{scope.row.title}}</div>
							<div>{{scope.row.content}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="ntime" width="250" label="地址">
						<template slot-scope="scope">
							article.html?dos=info&id={{scope.row.id}}
						</template>
					</el-table-column>
					<el-table-column prop="ntime" width="150" label="发布时间">
					</el-table-column>
					<el-table-column prop="ntime" width="150" label="操作">
						<template slot-scope="scope">
							<el-button size="small" icon="el-icon-edit" @click.stop="edit(scope.$index, scope.row)"
								style="margin-right:15px;">编辑</el-button>
							<el-popover placement="top" width="60" v-model="scope.row.visible">
								<p>确定删除吗？</p>
								<div style="text-align: right; margin: 0">
									<el-button size="mini" type="text" @click.stop="scope.row.visible = false">取消
									</el-button>
									<el-button type="primary" size="mini"
										@click.stop="handleDelete(scope.$index, scope.row)">确定
									</el-button>
								</div>
								<el-button size="mini" slot="reference" icon="el-icon-delete" circle>
								</el-button>
							</el-popover>
						</template>
					</el-table-column>
					<template>
						<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg"
								alt="">
							<div>暂无数据</div>
						</div>
					</template>
				</el-table>
				<div style="padding:15px;text-align: right;">
					<el-pagination background layout="total,prev, pager, next" :current-page.sync="pgno"
						:page-size="pgsize" @current-change="page" :total="pgcount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				host: this.$host,
				tabledata: [],
				pgsize: 15,
				pgcount: 0,
				pgno: 1,
				type: '',
				alltype: '',
				typemap: {},
				loading: false
			}
		},

		mounted: function() {
			this.getdata();
			this.ini();
		},
		methods: {
			totop(v,row){
				
					this.$ajax({
						url: 'note/',
						data: {
							'do': 'totop',
							'v':v,
							'id':row.id
						},
					}).then(res => {
						this.page(this.pgno);
					})
				
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (row.istop == 1) {
					return 'top-row';
				}
				return '';
			},
			ini() {
				this.$ajax({
					url: 'note/',
					data: {
						'do': 'ini'
					},
				}).then(res => {
					this.alltype = res.data
					var typemap = {};
					for (var i = 0; i < res.data.length; i++) {
						typemap[res.data[i].fid] = res.data[i];
					}
					this.typemap = typemap;
				})
			},
			edit(index, row) {
				this.$router.push('/articleedit?id=' + row.id);
			},
			handleDelete(index, row) {
				this.$ajax({
					url: 'note/',
					data: {
						'do': 'del',
						'id': row.id
					},
				}).then(res => {
					if (res.error > 0) {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						this.page(this.pgno)
					}
				})
			},
			page(v) {
				this.pgno = v;
				this.getdata({
					pgno: v
				});
			},
			getdata(v) {
				if (typeof(v) != 'object' || v.pgno === undefined) {
					this.pgno = 1;
				}
				this.loading = true;
				this.$ajax({
					url: 'note/',
					data: {
						'do': '',
						'type': this.type,
						'pgno': this.pgno,
						'pgsize': this.pgsize,
					},
				}).then(res => {
					this.loading = false;
					this.tabledata = res.res
					this.pgcount = parseInt(res.count)
				})
			}
		}
	}
</script>

<style>
	.el-table .top-row {
		background: #eee;
	}
</style>
