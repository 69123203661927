<template>
	<div>
	<div class="breadcrumb">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<div class="pagetitle">编辑角色</div>
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/role' }">角色管理</el-breadcrumb-item>
		</el-breadcrumb>
	</div>
	<el-row :gutter="15">
		<el-col :span="24">
			<div class="panel">
				<div class="panel-header">
					<div class="charttitle">{{formname}}</div>
				</div>
				<div class="panel-body pad16">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
						class="demo-ruleForm">
						<el-form-item label="角色名称" prop="gname">
							<el-input v-model="ruleForm.gname" style="width:200px;"></el-input>
						</el-form-item>
						
					
						<el-form-item label="权限" prop="mights">
							<el-checkbox-group v-model="ruleForm.mights">
								<el-checkbox name="mights" v-for="(item,index) in might" :key="index"
									:label="index">
									{{item.name}}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
					
						
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
							<el-button @click="resetForm('ruleForm')">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-col>
	</el-row>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				id:'',
				tableData: [],
				formname:'角色管理',
				might:{},
				ruleForm:{
					gname:'',
					mights:[],
					id:''
				},
				rules:{
					gname: [{
						required: true,
						message: '请输角色名称',
						trigger: 'blur'
					}],
					mights: [{
						type: 'array',
						required: true,
						message: '请至少选择一个权限',
						trigger: 'blur'
					}]
				}
			}
		},
		mounted: function() {
			this.id=this.$route.query.id;
			this.ini();
			
			this.host = this.$host
		},
		methods: {

			submitForm(formName) {
				
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.ruleForm.do='add';console.log(this.ruleForm);
							var data=this.ruleForm;
							data.mights=data.mights.join(',');
							this.$ajax({
								url: 'role/',
								data: this.ruleForm,
							}).then(res=> {
									if (res.error > 0) {
										this.$message({
											message: res.msg,
											type: 'warning'
										});
									} else {
										this.$message({
											message: '保存成功',
											type: 'success'
										});
										this.showadd();
										this.ini();
									}
								
							})
						} else {
							return false;
						}
					});
				
				},
			ini() {

				if(this.id==undefined){
					this.$ajax({
						url: 'role/',
						data: {
							'do': 'ini'
						},
					}).then(res=> {

							this.might = res.might
						
					})
				}else{
					this.$ajax({
						url: 'role/',
						data: {
							'do': 'info',
							'id':this.id
						},
					}).then(res=> {
						if(res.data.c==0){
							console.log('kkk')
						}else{
							var d=res.data.res.res[0];
							var a = d.mights?d.mights.split(','):[];
							d.mights=a;
							d.mname = [];
							for (var j = 0; j < a.length; j++) {
						
								d.mname.push(res.data.might[a[j]].name);
							}
							this.ruleForm=d;
							this.might = res.data.might
						}
							
						
					})
				}
			}
		},
	}
</script>

<style>
</style>
