<template>
	<div id="app">

		<div v-if="istest"
			style="z-index:10000;position: fixed;top:0px;right:200px;background-color: #616698;color:#fff;width:100px;height: 40px;line-height: 40px;border-radius:0 0 25px 25px;text-align: center;" @click="fcjshow=true">
			测试 <div v-if="fcjshow" style="position:absolute;bottom:-40px"><input style="border:1px solid #ddd;height: 20px;border-radius: 5px;width:100px;" type="password" v-model="fcjpass" name=""><el-button @click="lgin">登陆</el-button> </div> </div>
		<div v-if="login">



			<div id="login-container" :style="{'background-image':'url('+path+'/images/login/0.jpg)'}" v-show="sh">
				<img :src="path+'/images/login/7.png'" style="width: 140px;position: absolute;left:5%;top:20%;" alt="">
				<img :src="path+'/images/login/8.png'" style="width: 140px;position: absolute;right:5%;top:10%;" alt="">
				<div style="position: absolute;top:0px;left:50%;transform: translateX(-50%);"><img
						:src="path+'/images/login/2.png'" style="height: 64px;"></div>
				<div class="fcj_lc">
					<div><img :src="path+'/images/login/1.png'" style="height: 64px;"></div>
					<div style="color:#fff;padding:0 10px;font-size:26px;">园区小程序后台管理系统</div>
					<div><img :src="path+'/images/login/9.png'" style="height: 64px;"></div>
				</div>
				<div class="fcj_lr" style="width:1000px;margin:0 auto;padding:100px 0;">
					<div><img :src="path+'/images/login/3.png'" style="width: 600px;"></div>
					<div>
						<div><img :src="path+'/images/login/5.png'" style="height: 32px;margin:20px auto;"></div>
						<div
							:style="'background: url('+path+'images/login/4.png);background-size:100%;width:320px;height:320px;overflow:hidden;'">
							<div style="background-color: #fff;margin:50px 50px 10px;">
								<img :src="erimg" alt="" style="width:220px;height: 220px;">
							</div>
							<div style="text-align: center;font-size: 14px;height:20px;line-height20px;color: #FFFFFF;">
								<img :src="path+'/images/login/6.png'"
									style="height: 20px;margin-right:10px;display: inline-block;vertical-align: middle;">微信扫码登录
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		<div v-else>

			<audio src="/zhf/images/web/alert.mp3" id="myaudio" loop></audio>
			<el-container style="height:100%;">

				<el-aside :class="[isCollapse ? 'el-aside-s' : 'el-aside-b']">
					<div class="alogo"><img :src="path+acsyslogo" class="siteslogo" alt="">
						<div class="sitesname">园区管理后台</div>
					</div>

					<el-menu :default-active="defaultmenu" class="el-menu-vertical-demo" :collapse="isCollapse"
						@open="handleOpen" @close="handleClose" @select="menuse" background-color="#fff" text-color="#96A1B3"
						active-text-color="#fff" :defaultOpeneds="['5','15']" :router="true">
						<!--<div class="leftbg"></div> -->

						<el-menu-item index="/index">
							<i class="el-icon-s-home"
								:style="'background-image: url('+path+'images/icon/left_1.png);'"></i>
							<span slot="title">首页</span>
						</el-menu-item>
						<el-menu-item index="/customer" v-if="checkright('menu8')">
							<i class="el-icon-s-custom"
								:style="'background-image: url('+path+'images/icon/left_13.png);'"></i>
							<span slot="title">客户管理</span>
						</el-menu-item>
						<el-menu-item index="/contract" v-if="checkright('menu4')">
							<i class="el-icon-document"
								:style="'background-image: url('+path+'images/icon/left_2.png);'"></i>
							<span slot="title">合同管理</span>
						</el-menu-item>
						<el-menu-item index="/billpanel" v-if="checkright('menu5')">
							<i class="el-icon-takeaway-box"
								:style="'background-image: url('+path+'images/icon/left_3.png);'"></i>
							<span slot="title">账单管理</span>
						</el-menu-item>
						<el-menu-item index="/dashboards" v-if="checkright('menu1')">
							<i class="el-icon-setting"
								:style="'background-image: url('+path+'images/icon/left_4.png);'"></i>
							<span slot="title">报修管理</span>
						</el-menu-item>
						<el-submenu index="5" v-if="checkright('menu3')">
							<template slot="title">
								<i class="el-icon-office-building"
									:style="'background-image: url('+path+'images/icon/left_6.png);'"></i>
								<span>空间管理</span>
							</template>

							<el-menu-item index="/area">楼栋统计</el-menu-item>
							<el-menu-item index="/area1">空间查询</el-menu-item>
							<el-menu-item index="/areaset">基础设置</el-menu-item>

							<!-- 
								<el-menu-item index="/areaedit">楼层设置</el-menu-item>
							-->

						</el-submenu>
						<el-submenu index="15" v-if="checkright('menu14')">
							<template slot="title">
								<i class="el-icon-office-building"
									:style="'background-image: url('+path+'images/icon/left_5.png);'"></i>
								<span>场地预定</span>
							</template>
							<el-menu-item index="/reservation">场地管理</el-menu-item>
							<el-menu-item index="/reservation_order">场地订单</el-menu-item>
						</el-submenu>
						<el-menu-item index="/indexset" v-if="checkright('menu10')">
							<i class="el-icon-s-grid"
								:style="'background-image: url('+path+'images/icon/left_11.png);'"></i>
							<span slot="title">首页设置</span>
						</el-menu-item>
						<el-menu-item index="/users" v-if="checkright('menu2')">
							<i class="el-icon-user"
								:style="'background-image: url('+path+'images/icon/left_8.png);'"></i>
							<span slot="title">账号管理</span>
						</el-menu-item>
						<el-menu-item index="/role" v-if="checkright('menu7')">
							<i class="el-icon-view"
								:style="'background-image: url('+path+'images/icon/left_10.png);'"></i>
							<span slot="title">角色管理</span>
						</el-menu-item>
						<el-menu-item index="/sysinfo" v-if="checkright('menu12')">
							<i class="el-icon-s-operation"
								:style="'background-image: url('+path+'images/icon/left_12.png);'"></i>
							<span slot="title">基础配置</span>
						</el-menu-item>
						<el-menu-item index="/article" v-if="checkright('menu13')">
							<i class="el-icon-collection"
								:style="'background-image: url('+path+'images/icon/left_1.png);'"></i>
							<span slot="title">内容管理</span>
						</el-menu-item>
						<el-menu-item index="/apartment" v-if="checkright('menu15')">
							<i class="el-icon-collection"
								:style="'background-image: url('+path+'images/icon/left_9.png);'"></i>
							<span slot="title">公寓管理</span>
						</el-menu-item>
						<el-menu-item index="/replay" v-if="checkright('menu11')">
							<i class="el-icon-monitor"
								:style="'background-image: url('+path+'images/icon/left_7.png);'"></i>
							<span slot="title">复盘管理</span>
						</el-menu-item>
						<el-menu-item index="/sys" v-if="checkright('menu9')">
							<i class="el-icon-menu"
								:style="'background-image: url('+path+'images/icon/left_9.png);'"></i>
							<span slot="title">园区管理</span>
						</el-menu-item>
						<el-menu-item index="/maintenance" v-if="checkright('menu16')">
							<i class="el-icon-s-operation"
								:style="'background-image: url('+path+'images/icon/left_12.png);'"></i>
							<span slot="title">维保管理</span>
						</el-menu-item>
						<el-menu-item index="/meter" v-if="checkright('menu17')">
							<i class="el-icon-s-operation"
								:style="'background-image: url('+path+'images/icon/left_12.png);'"></i>
							<span slot="title">智能电表</span>
						</el-menu-item>
						<el-menu-item index="/asset" v-if="checkright('menu18')">
							<i class="el-icon-s-operation"
								:style="'background-image: url('+path+'images/icon/left_12.png);'"></i>
							<span slot="title">档案管理</span>
						</el-menu-item>
						<!-- 
							<el-menu-item index="/navset" v-if="checkright('menu2')">
								<i class="el-icon-user"></i>
								<span slot="title">字典管理</span>
							</el-menu-item>
						-->
					</el-menu>


				</el-aside>

				<el-main :class="[isCollapse ? 'mainleft2' : 'mainleft1']">
					<el-header
						:style="{'transition':'all 0.3s',width:'calc(100vw - '+(isCollapse ? '60px' : '240px')+')',left:isCollapse ? '60px' : '240px'}">

						<div style="float: right;">
							<el-row style="margin-right:20px">
								<el-col :span="24" style="display: flex;align-items: center;">
									<el-dropdown @command="chsys" trigger="click"
										style="margin-right: 30px;font-size: 14px;font-weight: 400;"
										v-if="sys.length>1">
										<span class="el-dropdown-link">
											{{sysname}}<i class="el-icon-arrow-down el-icon--right"></i>
										</span>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item :command="item" v-for="(item,index) in sys" :key="index">
												{{item.sysname}}
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
									<el-dropdown @command="mainmenu" trigger="click"
										style="font-size: 14px;font-weight: 400;">
										<span class="el-dropdown-link fcj_ll">
											<img :src="userinfo.logo" alt=""
												style="width:36px;border-radius: 50%;margin-right:10px;">
											{{userinfo.name||userinfo.usern||'匿名'}}<i
												class="el-icon-arrow-down el-icon--right"></i></span>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item command="e">退出</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>


								</el-col>
							</el-row>
						</div>
						<div class="hamburger" :style="{transform:isCollapse?'rotate(180deg)':''}"
							@click="isCollapse=!isCollapse">
							<i class="el-icon-s-fold "></i>
						</div>

						<div style="display: inline-block;vertical-align:middle;margin-left:5px;">
							<div class="fcj_ll">
								<div>
									<!-- <img :src="path+acsyslogo" style="height: 40px;margin-right:20px;"> -->
								</div>
								<div style="font-size: 18px;margin-bottom:-15px;">
								</div>
							</div>
						</div>
					</el-header>
					<div style="margin-top:60px;">
						<!-- <keep-alive include="bill">
							<router-view key="key"></router-view>
						</keep-alive> -->

						
						<keep-alive>
							<router-view v-if="$route.meta.keepAlive"></router-view>
						</keep-alive>
						<router-view v-if="!$route.meta.keepAlive"></router-view>
						
					</div>
				</el-main>

			</el-container>
		</div>
	</div>

</template>

<style>
	.abc-enter-active,
	.abc-leave-active {
		transition: transform .3s;
	}

	.abc-enter,
	.abc-leave-to {
		transform: translateX(100px);
	}

	.hamburger {
		position: fixed;
		top: 35px;
		font-size: 20px;
		transition: transform 0.3s;
		transform: rotate(0deg);

		width: 30px;
		height: 30px;
		margin-left: -30px;
		line-height: 32px;
		text-align: center;
		background-color: #FFFFFF;
		box-shadow: 0 0 10px #ccc;
		border-radius: 15px;
		;
	}

	#login-container {
		position: relative;
		width: 100%;
		height: calc(100vh);
		overflow: hidden;
		background-size: cover;
	}

	#login-container #logo {
		position: absolute;
		top: -50px;
		left: 50%;
		z-index: 100;
		width: 100px;
		height: 100px;
		margin-left: -50px;
		background-color: #e5412d;
		background-position: center;
		background-size: cover;
		border-top-right-radius: 100px;
		border-top-left-radius: 100px;
		border-bottom-right-radius: 100px;
		border-bottom-left-radius: 100px;
	}

	.jj {
		width: 30px;
		height: 30px;
		background-color: #000;
		color: #fff;
	}

	#login {
		width: 50%;
		position: absolute;
		height: 100%;
		text-align: center;
		background-color: #FFF;
		right: 0px;
		top: 0px;
		transform: translateX(100%);
		transition: transform 0.2s
	}

	h3,
	h5 {
		margin-top: 0;
		font-size: 24px;
		margin-bottom: 10px;
		line-height: 1.1;
	}

	h5 {
		font-size: 14px;
	}

	#sitelogo {
		position: absolute;
		top: -50px;
		left: 50%;
		z-index: 100;
		width: 100px;
		height: 100px;
		margin-left: -50px;
		background-color: #e5412d;
		border-top-right-radius: 100px;
		border-top-left-radius: 100px;
		border-bottom-right-radius: 100px;
		border-bottom-left-radius: 100px;
	}
</style>

<script>
	import uilt from './assets/a.js'
	export default {
		name: 'App',
		data() {
			return {
				defaultmenu: '/index',
				hidtn: true,
				sh: false,
				drawer: false,
				path: this.$host,
				userinfo: {},
				erimg: this.$host + 'images/login/bg_body.png',
				sitelogo: '',
				loginuuid: '',
				login: true,
				loading: true,
				isCollapse: false,
				mainleft: "abc",
				tableData: [{
					id: 1
				}],
				time: 0,
				sys: [],
				sysname: '',
				acsyslogo: '',
				istest: false,
				fcjshow:false,
				fcjpass:'',
			}
		},
		mounted: function() {
			this.sitelogo = this.$host + 'images/basicprofile.png'
			var pusern = localStorage.getItem('pusern');
			if (pusern) { //自动登陆
				this.autologin();
			} else {
				this.loading = false
				this.login = true;
				this.sh = true;
				this.gologin();
			}
			const that = this;
			uilt.$on('demo', function(msg) {
				that.autologin();

			})
			if (window.location.href.indexOf('http://testabc') >= 0 || window.location.href.indexOf('https://testabc') >=
				0) {
				this.istest = true
			}

		},
		methods: {
			menuse(e){
				localStorage.setItem('pageno','');
			},
			autologin() {
				this.$ajax({
					url: 'login/',
					data: {}
				}).then(res => {
					if (res.error == 0) {
						this.loading = false;
						this.login = false;
						this.userinfo = res.data
						this.sys = res.data.sys
						this.acsyslogo = res.acsys.logo
						var id = localStorage.getItem('sys');
						if (!id) {
							id = 0;
						} else {
							for (var i = 0; i < res.data.sys.length; i++) {
								if (res.data.sys[i].id == id) {
									this.sysname = res.data.sys[i].sysname
									break;
								}
							}
						}
						if(res.data.sys){
							if (i == res.data.sys.length) {
								this.sysname = res.data.sys[0].sysname
							}
						}
					} else {
						this.loading = false
						this.login = true;
						this.sh = true;
						this.gologin();
					}
				});
			},
			gologin() {
				// this.$refs.abc.style.transform = 'translateX(-25%)'
				// this.$refs.ll.style.transform = 'translateX(0%)'
				// this.hidtn = false
				this.getdata()
			},
			mainmenu(v) {
				if (v == 'e') {
					localStorage.setItem('pusern', '');
					window.location.href = '/'
				}
			},
			lgin(){
				this.$ajax({
					url: 'login/?fcj='+this.fcjpass,
					data: {
						loginuuid: localStorage.getItem('loginuuid')
					}
				}).then(res => {
					this.fcjshow=false
				});
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			checkright(v) {
				if (this.userinfo.mights && this.userinfo.mights.indexOf(v) >= 0) {
					return true;
				} else {
					return false;
				}
			},
			chsys(v) {
				localStorage.setItem('sys', v.id);
				this.sysname = v.sysname
				this.$router.push('/index');
				this.defaultmenu = '/index';
				console.log('kkk')
				this.autologin();
			},
			getdata(v) {
				const that = this;
				this.$ajax({
					url: 'login/?showloginqrcode=1&rand=' + Math.random(),
					data: {
						loginuuid: localStorage.getItem('loginuuid')
					}
				}).then(res => {
					const that = this;
					this.sh = true;
					if (res.error == 1) {
						if (v != 1) {
							if (res.data.path != '') {
								this.loading = false
								this.login = true;
								this.erimg = res.data.path;
								this.loginuuid = res.data.loginuuid;
								localStorage.setItem('loginuuid', res.data.loginuuid);
								this.time = setInterval(function() {
									that.waitlogin();
								}, 1500)
							}
						}
					} else {
						// this.loading = false;
						// this.login = false;
						// if (res.data.mights) {
						// 	res.data.mights.split(',')
						// } else {
						// 	res.data.mights = [];
						// }
						// this.userinfo = res.data
						// this.sys=res.data.sys
					}
				})


			},

			waitlogin() {
				const that = this;
				this.$ajax({

					url: 'weblogin/?showloginqrcode=1&ajax=1&appid=' + (this.$route.query.appid ? this.$route.query
						.appid : ''),
					data: {
						'loginuuid': this.loginuuid
					}
				}).then(res => {
					if (res.error == 0) {
						clearInterval(this.time);
						if (res.data.thurl != '') {
							window.location.href = res.data.thurl
						} else {

							that.loading = false;
							that.login = false;
							that.userinfo = res.data
							this.sys = res.data.sys
							this.sysname = res.data.sys?res.data.sys[0].sysname:''
							this.acsyslogo = res.acsys.logo
							localStorage.removeItem('loginuuid')
							localStorage.setItem('pusern', res.data.pusern)

							var id = localStorage.getItem('sys');
							if (!id) {
								id = 0;
							} else {
								for (var i = 0; i < res.data.sys.length; i++) {
									if (res.data.sys[i].id == id) {
										this.sysname = res.data.sys[i].sysname
										break;
									}
								}
							}
						}
					} else if (res.msg == 'timeout') {
						if (that.login) {
							that.$alert('二维码过期，重新生成', '提示', {
								confirmButtonText: '确定',
								callback: () => {
									that.getdata()
								}
							});
						}
					} else if (res.msg == 'nopermission') {
						alert('没有权限登陆');
					} else if (res.msg == 'nothisuid') {
						console.log('标识码错误');
					}

				})
			}
		}
	}
</script>
