<template>
	<div>
		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel">
					<div class="panel-header">

					</div>
					<div class="panel-body" ref="userlist">
						<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
							<el-tab-pane label="所属行业" name="trade">
								</el-tab-pane>
							<el-tab-pane label="行业属性" name="tradetype">
								</el-tab-pane>
							<el-tab-pane label="注册地情况" name="registplacestate">
								</el-tab-pane>
							<el-tab-pane label="企业性质" name="nature">
								</el-tab-pane>
							<el-tab-pane label="上市情况" name="stockmarket">
								</el-tab-pane>
							<el-tab-pane label="企业资质" name="qualifications">
								</el-tab-pane>
							<el-tab-pane label="企业证书" name="certificate">
								</el-tab-pane>
							<el-tab-pane label="客户类型" name="customertype">
								</el-tab-pane>
							<el-tab-pane label="客户来源" name="source">
								</el-tab-pane>
							<el-tab-pane label="企业需求" name="demand">
								</el-tab-pane>
							<el-tab-pane label="物业类型" name="roomtype">
								</el-tab-pane>
							<el-tab-pane label="建筑用途" name="buildingpurpose">
								</el-tab-pane>
							<el-tab-pane label="建筑类型" name="buildingtype">
								</el-tab-pane>
							<el-tab-pane label="费用类型" name="paytype">
								</el-tab-pane>
							<el-tab-pane label="文章类型" name="article">
								</el-tab-pane>
							<el-tab-pane label="期数" name="stage">
								</el-tab-pane>
							<el-tab-pane label="租赁类型" name="rent_type">
								</el-tab-pane>
							<el-tab-pane label="公司注册情况" name="createtype">
								</el-tab-pane>
							<el-tab-pane label="电箱" name="eleblock">
								</el-tab-pane>
								
						</el-tabs>
						
						<div class="pad16 textright"><el-button type="primary" size="small"  icon="el-icon-plus" @click="showadd">添加主类</el-button></div>
						
						
						<el-table :data="tableData" style="width: 100%">
							<el-table-column prop="ord" label="#" width="50">
							</el-table-column>
							<el-table-column prop="ord" label="排序" width="150">
								<template slot-scope="scope">
									<el-button size="mini" slot="reference" icon="el-icon-sort-down"
										@click.stop="sorts(scope.$index, scope.row,'down')" circle
										style="margin-right: 10px;">
									</el-button>
									<el-button size="mini" slot="reference" icon="el-icon-sort-up"
										@click.stop="sorts(scope.$index, scope.row,'up')" circle
										style="margin-right: 10px;">
									</el-button>
						
								</template>
							</el-table-column>
							<el-table-column prop="class_name" label="分类" >
								<template slot-scope="scope">
									<span class="span"
										:style="{width:scope.row.level*20+5+'px'}"></span>{{scope.row.class_name+' '+scope.row.fid}}
								</template>
							</el-table-column>
							<el-table-column prop="note" label="备注">
							</el-table-column>
							<el-table-column label="" width="240">
								<template slot-scope="scope">
									<el-button size="mini" slot="reference" icon="el-icon-plus"
										@click.stop="addchild(scope.$index, scope.row)" style="margin-right: 10px;">
										添加子分类
									</el-button>
									<el-button size="mini" slot="reference" icon="el-icon-edit"
										@click.stop="edit(scope.$index, scope.row)" circle
										style="margin-right: 10px;">
									</el-button>
									<el-popover placement="top" width="60" v-model="scope.row.visible">
										<p>删除后与此属性相关的数据将找不到此属性！确定删除吗？</p>
										<div style="text-align: right; margin: 0">
											<el-button size="mini" type="text"
												@click.stop="scope.row.visible = false">取消</el-button>
											<el-button type="primary" size="mini"
												@click.stop="handleDelete(scope.$index, scope.row)">确定
											</el-button>
										</div>
										<el-button size="mini" slot="reference" icon="el-icon-delete" circle>
										</el-button>
									</el-popover>
						
								</template>
							</el-table-column>
						</el-table>
						


					</div>
					<div class="panel-foot">
						<div class="pad16"></div>
					</div>
				</div>
			</el-col>

		</el-row>
		<el-dialog :visible.sync="isshowadd">
			<div class="panel">
				<div class="panel-header">
					<div class="charttitle">{{formname}}</div>
				</div>
				<div class="panel-body pad16">


					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="分类名" prop="class_name">
							<el-input v-model="ruleForm.class_name"></el-input>
						</el-form-item>


						<el-form-item label="备注" prop="note">
							<el-input type="textarea" v-model="ruleForm.note"></el-input>
						</el-form-item>


						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
							<el-button @click="resetForm('ruleForm')">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeName: 'tradetype',
				isshowadd: false,
				tableData: [],
				formname: '角色管理',
				might: {},
				ruleForm: {
					class_name: '',
					note: '',
					sku: '',
					plid: '0',
					logo: '',
					ord: '',
					fid: ''
				},
				rules: {
					gname: [{
						required: true,
						message: '请输角色名称',
						trigger: 'blur'
					}],
					mights: [{
						type: 'array',
						required: true,
						message: '请至少选择一个权限',
						trigger: 'blur'
					}]
				}
			}
		},
		mounted: function() {

			this.ini();

			this.host = this.$host
		},
		methods: {
			handleClick(tab, event) {
				
				this.ini();
			},
			edit(index, row) {
				this.isshowadd = true;
				var f = {
					class_name: row.class_name,
					note: row.note,
					sku: row.sku,
					plid: row.plid,
					logo: row.logo,
					ord: row.ord,
					fid: row.fid
				}
				this.ruleForm = f;
			},
			addchild(i, row) {
				this.isshowadd = true;
				var f = {
					class_name: '',
					note: '',
					sku: '',
					plid: row.fid,
					logo: '',
					ord: parseInt(row.ord) + 1,
					fid: ''
				}
				this.ruleForm = f;
			},
			showadd() {
				this.isshowadd = true;
				var f = {
					class_name: '',
					note: '',
					sku: '',
					plid: '0',
					logo: '',
					ord: '',
					fid: ''
				}
				this.ruleForm = f;
			},
			handleDelete(index, row) {
				this.$ajax({
					url: 'navset/',
					data: {
						'op': 'del',
						'fid': row.fid
					},
				}).then(res => {
					if (res.error > 0) {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						this.ini();
					}


				})
			},
			sorts(index, row, v) {
				this.$ajax({
					url: 'navset/',
					data: {
						'op': 'sort',
						'sort': v,
						'fid': row.fid
					},
				}).then(res => {
					this.ini();

				})

			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				},
			submitForm(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.ruleForm.op = 'add';
						var data = this.ruleForm;
						this.$ajax({
							url: 'navset/',
							data: this.ruleForm,
						}).then(res => {
							if (res.error > 0) {
								this.$message({
									message: res.msg,
									type: 'warning'
								});
							} else {
								this.$message({
									message: '保存成功',
									type: 'success'
								});
								this.showadd();
								this.ini();
							}

						})
					} else {
						return false;
					}
				});

			},
			ini() {console.log(this.activeName)
				this.$ajax({
					url: 'navset/',
					data: {
						'navname': this.activeName
					},
				}).then(res => {
					this.tableData = res.res;
					// for (var i = 0; i < res.groups.length; i++) {
					// 	var a = res.groups[i].mights?res.groups[i].mights.split(','):[];
					// 	res.groups[i].mights=a;
					// 	res.groups[i].mname = [];
					// 	for (var j = 0; j < a.length; j++) {

					// 		res.groups[i].mname.push(res.might[a[j]].name);
					// 	}
					// }
					// this.tableData = res.groups;
					// this.might = res.might

				})
			}
		},
	}
</script>

<style>
	
</style>
