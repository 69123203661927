<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">档案管理</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel">
					
					<div class="panel-body pad16">
						<div class="fcj_lr" style="border-bottom:1px solid #e4e7ed;padding-bottom: 20px;">
							<div class="fcj_ll">
								

								<div class="fc"><el-button :type="active=='asset'?'primary':'info'" plain @click="chac('asset')" size="small" icon="el-icon-document">档案管理</el-button></div>
								<div class="fc"><el-button :type="active=='lend'?'primary':'info'" plain @click="chac('lend')" size="small" icon="el-icon-picture">借阅管理</el-button></div>
								<div class="fc"><el-button :type="active=='nav_tab'?'primary':'info'" plain @click="chac('nav_tab')" size="small" icon="el-icon-asset">档案分类管理</el-button></div>
								<!-- <div class="fc"><el-button :type="active=='qrcode'?'primary':'info'" plain @click="chac('qrcode')" size="small" icon="el-icon-qrcode">归还二维码</el-button></div> -->


							</div>
						</div>

						
						<div v-show="active=='asset'">
							<div class="table-filter fcj_lr" style="margin-top:20px;">
								
								<div class="fcj_ll">
									<div>
										<el-input clearable size="small" v-model="assetsearch.no" placeholder="档案编号" />
									</div>
									<div>
										<el-input clearable size="small" v-model="assetsearch.assetName" placeholder="档案名称" />
									</div>
									<div>
										<el-input clearable size="small" v-model="assetsearch.location" placeholder="存放位置" />
									</div>
									<div>
										<el-cascader
										placeholder="档案类别"
										size="small"
										v-model="assetsearch.type"
										:options="navtable"
										:props="{ checkStrictly: true ,value:'fid',label:'class_name'}"
										collapse-tags
										clearable></el-cascader>
										
									</div>
									<div>
										<el-button size="small" @click="getasset" icon="el-icon-search" type="primary">查询</el-button>
									</div>
									<div>
										<el-button size="small" @click="showupdatelocation"  type="info">批量修改存放位置</el-button>
									</div>
								</div>
								<div style="display: flex;">
									<el-upload :action="host+'upfile/'" :data="uploaddata" :show-file-list="false"
									list-type="text" ref="uploadfloorpic1" name="upfile"
									:on-success="uploadfloorpicsuccess" :auto-upload="true" :limit="1">

										<el-button type="primary" size="small" plain  icon="el-icon-upload">
											批量导入档案
										</el-button>

									</el-upload>
									<el-button style="margin-left:10px;" type="success" size="small" @click="showaddasset"  icon="el-icon-plus">添加档案</el-button>
								</div>
							</div>

							
							<div v-if="asset!=''">
								<el-table :data="asset" stripe style="width: 100%;" ref="multipleTable" :show-overflow-tooltip="true"  @selection-change="handleSelectionChange">
									<el-table-column
									fixed
									type="selection"
									width="55">
									</el-table-column>
									<el-table-column prop="no" label="档案编号" width="100">
									</el-table-column>
									<el-table-column prop="assetName" label="档案名称" width="200px">
										<template slot-scope="scope">
											<div @click="editasset(scope.row)" class="link">{{scope.row.assetName}}</div>
										</template>
									</el-table-column>
									<el-table-column prop="location" label="存放位置" width="200">
									</el-table-column>
									<el-table-column prop="typename" label="类别" width="200">
										<template slot-scope="scope">
											{{ scope.row.typename.join(',') }}
										</template>
									</el-table-column>
									<el-table-column prop="note" label="备注" >
									</el-table-column>
									<el-table-column prop="files" label="电子文档" >
										<template slot-scope="scope">
											<div>{{ scope.row.files.length>0?'有':'无' }}</div>
										</template>
									</el-table-column>
									
									
									<el-table-column prop="securityLevel" label="保密等级"  width="100">
										<template slot-scope="scope">
											<el-tag size="small" :type="securityLevelColor[scope.row.securityLevel]">{{ securityLevel[scope.row.securityLevel] }}</el-tag>
										</template>
									</el-table-column>
									<el-table-column prop="createDate" label="建档日期"  width="150">
									</el-table-column>
									
								</el-table>
							</div>
							<div style="padding:15px;text-align: right;">
								<el-pagination background layout="total,prev, pager, next" :current-page.sync="asset_pgno" :page-size="asset_pgsize"
									@current-change="getasset" :total="asset_pgcount">
								</el-pagination>
							</div>
						</div>


						<div v-show="active=='lend'">
							<div class="table-filter fcj_lr" style="margin-top:20px;">
								
								<div class="fcj_ll">
									<div>
										<el-date-picker v-model="lendsearch.day" size="small" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:270px;" @change="getlend"></el-date-picker>
									</div>
									<div>
										<el-input size="small" v-model="lendsearch.operatorname" placeholder="借阅人" />
									</div>
									<div>
										<el-input size="small" v-model="lendsearch.no" placeholder="档案编号" />
									</div>
									<div>
										<el-input size="small" v-model="lendsearch.assetName" placeholder="档案名称" />
									</div>
									<div>
										<el-button size="small" @click="getlend" icon="el-icon-search" type="primary">查询</el-button>
									</div>
								</div>

								<!-- <div>
									<el-button size="small" @click="showaddlend" icon="el-icon-edit" type="success">添加借阅</el-button>
								</div> -->
							</div>
							
							<el-table :data="lend" header-cell-class-name="table-header"  stripe>

								<el-table-column width="50" type="expand">
									
									<template slot-scope="props">
										<div style="margin-left:100px;">
											<el-table :data="props.row.detail" stripe>
												<el-table-column prop="no" label="档案编号" width="150px">
												</el-table-column>
												<el-table-column prop="assetName" label="档案名称">
												</el-table-column>
												<el-table-column prop="state" label="是否已归还"  width="150px">
													<template slot-scope="scope">
														<el-tag size="small"  :type="lendstatebtn[scope.row.state]">{{ scope.row.lendStateName }}</el-tag>
													</template>
												</el-table-column>
												<el-table-column prop="returnDate" label="归还日期" >
												</el-table-column>
											</el-table>
										</div>
									</template>
								
								</el-table-column>
								<el-table-column prop="lendDate" label="借阅日期" width="150">

								</el-table-column>
								<el-table-column prop="returnDate" label="归还日期" width="150">

								</el-table-column>
								<el-table-column prop="name" label="借阅人员" width="150px">
								</el-table-column>
								<el-table-column prop="assetName" label="档案名称" width="150px">
								</el-table-column>
								<el-table-column prop="department" label="借阅部门" >
								</el-table-column>
								<el-table-column prop="operatorName" label="经办人" >
								</el-table-column>
								<el-table-column label="审批状态" width="90" prop="approval_state">
									<template slot-scope="scope">
										<el-tag size="small" style="margin-right: 10px;" :type="approvalbtn[scope.row.approval_state]"
											disable-transitions>
											{{scope.row.approval_state==-1?'已撤销':scope.row.approval_state_name}}
										</el-tag>
								
									</template>
								</el-table-column>
								<el-table-column prop="reason" label="借阅事由" >
								</el-table-column>
								
								<el-table-column prop="company" label="操作" width="220">
									<template slot-scope="scope">
										<el-button size="small" type="warning" plain @click="ddcheck(scope.row)"  v-if="scope.row.approval_state==1">提交审批</el-button>
										<el-button size="small" type="warning" plain @click="revoke(scope.row.id)" icon="el-icon-refresh-left" v-if="scope.row.approval_state==2">撤销审批</el-button>
										<el-button size="small" @click="modifylend(scope.row)" plain icon="el-icon-edit" type="primary" v-if="scope.row.state==0&&(scope.row.approval_state==1||scope.row.approval_state==-1)">修改</el-button>
									</template>
								</el-table-column>

								<template>
									<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
								</template>
							</el-table>
							<div style="padding:15px 0;text-align: right;">
								<div class="fcj_lr">
									<div>
										
									</div>
									<div>
										<el-pagination background layout="total,prev, pager, next" :current-page.sync="lend_pgno" :page-size="lend_pgsize"
											@current-change="getlend" :total="lend_pgcount">
										</el-pagination>

									</div>
									
								</div>
								
							</div>
							
						</div>

						<div v-show="active=='nav_tab'">
							<div class="pad16 textright"><el-button type="primary" size="small"  icon="el-icon-plus" @click="shownavadd">添加主类</el-button></div>
						
						
							<el-table :data="navtable" style="width: 100%">
								<el-table-column prop="ord" label="#" width="50">
								</el-table-column>
								<el-table-column prop="ord" label="排序" width="150">
									<template slot-scope="scope">
										<el-button size="mini" slot="reference" icon="el-icon-sort-down"
											@click.stop="sorts(scope.$index, scope.row,'down')" circle
											style="margin-right: 10px;">
										</el-button>
										<el-button size="mini" slot="reference" icon="el-icon-sort-up"
											@click.stop="sorts(scope.$index, scope.row,'up')" circle
											style="margin-right: 10px;">
										</el-button>
							
									</template>
								</el-table-column>
								
								<el-table-column prop="class_name" label="分类" >
									<template slot-scope="scope">
										<span class="span"
											:style="{width:scope.row.level*20+5+'px'}"></span>{{scope.row.class_name}}（{{scope.row.fid}}）
									</template>
								</el-table-column>
								<el-table-column prop="c_title" label="档案数量">
								</el-table-column>
								<el-table-column prop="note" label="备注">
								</el-table-column>
								<el-table-column label="设置" width="240">
									<template slot-scope="scope">
										<el-button size="mini" slot="reference" icon="el-icon-plus"
											@click.stop="addchild(scope.$index, scope.row)" style="margin-right: 10px;">
											添加子分类
										</el-button>
										<el-button size="mini" slot="reference" icon="el-icon-edit"
											@click.stop="editnav(scope.$index, scope.row)" circle
											style="margin-right: 10px;">
										</el-button>
										<el-popover placement="top" width="60" v-model="scope.row.visible">
											<p>删除后与此属性相关的数据将找不到此属性！确定删除吗？</p>
											<div style="text-align: right; margin: 0">
												<el-button size="mini" type="text"
													@click.stop="scope.row.visible = false">取消</el-button>
												<el-button type="primary" size="mini"
													@click.stop="delnav(scope.$index, scope.row)">确定
												</el-button>
											</div>
											<el-button size="mini" slot="reference" icon="el-icon-delete" circle>
											</el-button>
										</el-popover>
							
									</template>
								</el-table-column>
							</el-table>
						</div>


						<div v-show="active=='qrcode'">
							
						</div>
					</div>

					

				</div>
					
			</el-col>
		</el-row>
		
		<!-- 档案表单 -->
		<el-dialog :visible.sync="isshowaddasset" width="700px" title="文档管理" :close-on-click-modal="false" @close="clearaddasset">
			<el-form :model="assetform" :rules="assetrule" ref="assetform" label-width="100px" class="demo-ruleForm">
				<!-- <el-form-item label="档案编号" prop="no">
					<div class="fcj_ll">
						<div><el-input v-model="assetform.no" style="width:250px;" placeholder="请填写档案编号"></el-input></div>
						<div style="padding-left:20px;color:#999;font-size:12px;">数字、字母和_-()的组合，长度小于50个字符</div>
					</div>
				</el-form-item> -->
				<el-form-item label="档案名称" prop="assetName">
					<el-input v-model="assetform.assetName" style="width:550px;"></el-input>
				</el-form-item>
				<el-form-item label="档案类别" prop="type">
					<el-cascader v-model="assetform.type" :options="navtable"  :props="{value:'fid',label:'class_name'}"></el-cascader>
				</el-form-item>
				<el-form-item label="存放位置" prop="location">
					<el-input v-model="assetform.location" style="width:550px;"></el-input>
				</el-form-item>
				<el-form-item label="部门" prop="department">
					<el-cascader v-model="assetform.department" :options="department"  :props="{value:'id',label:'name'}"></el-cascader>
				</el-form-item>
				<el-form-item label="保密等级" prop="securityLevel">
					<el-radio-group v-model="assetform.securityLevel">
					<el-radio label="1">公开</el-radio>
					<el-radio label="2">保密</el-radio>
					<el-radio label="3">绝密</el-radio>
				</el-radio-group>
				</el-form-item>
				<el-form-item label="建档日期" prop="createDate">
					<el-date-picker
					v-model="assetform.createDate"
					type="date"
					placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="是否原件" prop="original">
					<el-radio-group v-model="assetform.original">
					<el-radio label="1">是</el-radio>
					<el-radio label="0">否</el-radio>
					</el-radio-group>
				</el-form-item>
				
				
				<el-form-item label="备注" prop="note">
					<el-input type="textarea" v-model="assetform.note" style="width:550px;" rows="5"></el-input>
				</el-form-item>


				<el-form-item label="电子档" prop="files">
					<el-upload
					ref="upload"
					:action="host+'upfile/'"
					name="upfile"
					multiple
					:auto-upload="false"
					:data="uploaddata"
					:on-preview="onPreview"
					:on-success="uploadsuccess"
					:on-change="uploadchange"
					:on-remove="handleRemove"
					:file-list="fileList">
					<el-button size="small" type="primary">点击上传</el-button>
					<div slot="tip" class="el-upload__tip">文件大小不超过8000kb</div>
					</el-upload>
				</el-form-item>
				

				<el-form-item>
					<el-button type="primary" @click="addasset()">保存</el-button>
					<el-button @click="isshowaddasset = false">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!-- 分类表单 -->
		<el-dialog :visible.sync="isshownavadd">
			<div class="panel">
				<div class="panel-header">
					<div class="charttitle">{{formname}}</div>
				</div>
				<div class="panel-body pad16">


					<el-form :model="navruleForm" :rules="navrules" ref="navruleForm" label-width="100px" >
						<el-form-item label="分类名" prop="class_name">
							<el-input v-model="navruleForm.class_name"></el-input>
						</el-form-item>


						<el-form-item label="备注" prop="note">
							<el-input type="textarea" v-model="navruleForm.note"></el-input>
						</el-form-item>


						<el-form-item>
							<el-button type="primary" @click="submitnavForm('navruleForm')">保存</el-button>
							<el-button @click="resetForm('navruleForm')">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-dialog>
		<!-- 借阅表单 -->
		<el-dialog :visible.sync="isshoweditlend" :title='(aclend.id?"修改":"添加")+"借阅申请"' :close-on-click-modal="false">
			<div class="panel">
				<div class="panel-body ">
					<el-form :model="aclend"  ref="aclendruleForm" label-width="120px">
						<el-form-item label="借阅人手机" prop="uid">
							<div class="fcj_ll">
								<div v-if="!aclend.id"><el-input v-model="aclend.phone" ref="abcss" style="width:250px;" @input="getlendname"></el-input></div>
								<div style="padding-left:10px;">{{ aclend.name }}</div>
							</div>
						</el-form-item>
						<el-form-item label="借阅时间" prop="lendDate">
							<el-date-picker
							v-model="aclend.lendDate"
							type="datetime"
							format="yyyy-MM-dd HH:mm:ss"
							placeholder="选择日期时间">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="归还时间" prop="returnDate">
							<el-date-picker
							v-model="aclend.returnDate"
							type="datetime"
							format="yyyy-MM-dd HH:mm:ss"
							placeholder="选择日期时间">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="是否需要下载" prop="needdown">
							<el-switch
							v-model="aclend.needdown"
							active-color="#13ce66"
							inactive-color="#cccccc">
							</el-switch>
						</el-form-item>
						<el-form-item label="借阅事由" prop="reason">
							<el-input type="textarea" v-model="aclend.reason" :rows="4" style="width:500px;"></el-input>
						</el-form-item>
						<el-form-item label="备注" prop="note">
							<el-input type="textarea" v-model="aclend.note" :rows="4" style="width:500px;"></el-input>
						</el-form-item>
						<el-form-item label="借阅资料" prop="detail">
							<div v-for="(item,index) in aclend.detail" :key="item.id" class="fcj_ll">
								<div>{{ item.assetName }}</div>
								<div style="padding-left:10px;"><el-button size="small" @click="dellendasset(index)" icon="el-icon-delete">删除</el-button>	</div>
							</div>
							<div style="height: 10px;"></div>
						</el-form-item>


						<el-form-item>
							<div style="margin-top:20px;">
							<el-button type="primary" @click="modifylend('aclend')">保存</el-button>
							<el-button type="warning" @click="modifylend('aclend',1)">保存并提交申请</el-button>
							<el-button @click="resetForm('aclendruleForm')">重置</el-button>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-dialog>

		<!-- 批量修改存放位置 -->
		<el-dialog :visible.sync="isShowUpdateLocation" title='修改存放位置' width="500px" :close-on-click-modal="false">
			<div class="fcj_ll" >
				<el-input clearable  v-model="newlocation" placeholder="输入存放位置" />
				<el-button @click="updateLocation">修改</el-button>
			</div>
		</el-dialog>

		<!-- 购物车 -->
		<div class="fixpannel" v-show="selectedAsset.length>0&&active=='asset'">
			<div style="padding:8px;border-bottom: 1px solid #ddd;margin-bottom:10px;">已选档案</div>
			<div class="fcj_lr" v-for="(item,index) in selectedAsset" :key="item.id" style="margin:5px;">
				<div>{{ item.assetName }}</div>
				<div><div style="padding:5px;color:#999;font-size:12px;cursor:pointer;" @click="delselsetasset(index)">X</div></div>	
			</div>
			<div class="fcj_ll" style="margin-top:10px;">
				<div><el-button @click="showaddlend">借阅</el-button></div>
				<div><el-button @click="clearselend">清除</el-button></div>
			</div>
			
		</div>
		
	</div>
</template>

<script>

	export default {
		data() {
			return {
				newlocation:'',
				isshownavadd: false,
				navtable: [],
				navruleForm: {
					class_name: '',
					note: '',
					sku: '',
					plid: '0',
					logo: '',
					ord: '',
					fid: ''
				},
				navrules: {
					gname: [{
						required: true,
						message: '请输角色名称',
						trigger: 'blur'
					}],
					mights: [{
						type: 'array',
						required: true,
						message: '请至少选择一个权限',
						trigger: 'blur'
					}]
				},


				department:[],
				assettype:[],
				oldpath:'',
				fileList:[],
				aclend:{'name':'','lendDate':'',needdown:false,returnDate:''},
				isshoweditlend:false,
				qrcodeurl:'',

				activeName: 'asset',
				isshowadd: false,
				tableData: [],
				formname: '角色管理',
				might: {},
				ruleForm: {
					class_name: '',
					note: '',
					sku: '',
					plid: '0',
					logo: '',
					ord: '',
					fid: ''
				},
				rules: {
					class_name: [{
						required: true,
						message: '请输入分类名称',
						trigger: 'blur'
					}]
				},
				comkey:'',
				restaurants:[],


				isshowedit:false,
				approvalbtn: ['info', 'warning', 'danger', 'success'],
				lendstatebtn: {'-2':'info','-1':'info','0':'', '1':'success', '2':'info'},
				multipleSelection:[],
				selectedAsset:[],
				asset_pgno:1,
				asset_pgsize:12,
				asset_pgcount:0,
				asset:[],
				assetform:{
					"id":0,
					"no":"",	// 档案编号
					"type":[],	//  档案类别
					"assetName":"",	// 档案名称
					"location":"",	// 存放位置
					"department":0,	//  部门
					"securityLevel":'1',	// 保密等级
					"note":"",	//  备注
					"createDate":"",	//  建档日期
					"original":'1',	//  是否原件1是，0否
					"files":[]	//  电子档编
				},
				assetrule:{
					// no: [
					// 	{ required: true, message: '请输入档案编号', trigger: 'blur' },
					// 	{ min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
					// ],
					assetName: [
						{ required: true, message: '请输入档案名称', trigger: 'blur' }
					],
					type:[
						{ required: true, message: '请选择档案分类', trigger: 'blur' }
					],
					createDate:[
						{ required: true, message: '请选择建档日期', trigger: 'blur' }
					],
					securityLevel:[
						{ required: true, message: '请选保密等级', trigger: 'blur' }
					],
					original:[
						{ required: true, message: '是否原件', trigger: 'blur' }
					]
					
				},
				assetsearch:{
					type:[],
					no:'',
					assetName:'',
					location:''
				},

				isshowaddasset:false,
				active:'asset',
				securityLevel:{},
				securityLevelColor:{"1":"info","2":"warning","3":"danger"},

				host:'',
				uploaddata: {
					pusern: localStorage.getItem('pusern'),
					sys:localStorage.getItem('sys')
				},
				disabled: false,
				dialogVisible:false,
				dialogImageUrl:'',


				lend:[],
				lendsearch:{
					day:[],
					operatorname:'',
					no:'',
					assetName:''
				},
				lend_pgcount:0,
				lend_pgno:1,
				lend_pgsize:20,

				

				showapalist:false,
				isShowUpdateLocation:false
			}
		},
		beforeRouteLeave (to, from, next) {
			this.$setpath(this.oldpath)
			next()
		},

		mounted: function() {
			this.oldpath=this.$host
			this.$setpath('https://asset.kefan.com/asset/')
			this.host = 'https://asset.kefan.com/asset/'
			// this.$setpath('http://localhost/asset/')
			// this.host = 'http://localhost/asset/'
			this.ini()
			
		},
		methods: {
			showupdatelocation(){
				if(this.selectedAsset.length==0){
					this.$alert('请选择要替换的档案');
					return;
				}
				this.isShowUpdateLocation=true
			},
			updateLocation(){
				this.$confirm('确认修改当前当选中的文档的存放位置吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
				}).then(() => {
					
					var ids=[];
					for(var i=0;i<this.selectedAsset.length;i++){
						ids.push(this.selectedAsset[i].id)
					}
					this.$ajax({
						url: 'asset/?do=updatelocation',
						data: {
							'ids':ids,
							'search':this.assetsearch,
							'newlocation':this.newlocation
						},
					}).then(res => {
						this.$alert(res.msg+res.data, '提示', {
							confirmButtonText: '确定'
						});
						this.isShowUpdateLocation=false
						this.newlocation=''
						this.getasset();
					})
				});
			},


			uploadfloorpicsuccess(response) {
				this.floorpic = response.fpath
				console.log(response);
				this.upasset(response.fileid)
				this.$refs.uploadfloorpic1.clearFiles();
			},
			onPreview(res){
				window.open(res.url)
			},
			upasset(fileid){
				this.$ajax({
					url: 'asset/?do=importasset',
					data: {
						'id':fileid
					},
				}).then(res => {
					this.$alert(res.msg+res.data, '提示', {
							confirmButtonText: '确定'
						});
				})
			},
			ini(){
				var assetpusern=localStorage.getItem('assetpusern');
				if(!assetpusern){
					var pusern=localStorage.getItem('pusern');
					this.$ajax({
						url: 'login/',
						data: {
							'pusern': pusern,
							'park':'zhw'
						},
					}).then(res => {
						if(res.error==0){
							localStorage.setItem('assetpusern',res.data.pusern);
							this.chac('asset')
							this.getdduid()
						}else{
							this.$alert(res.msg);
						}
						
					})
				}else{
					this.chac('asset')
					this.getdduid();
				}
				
			},
			getqrcode(){
				this.$ajax({
					url: 'asset/?do=qrcode',
					data: {
						
					}
				}).then(res => {
					this.qrcodeurl=res.msg
				});
			},
			getdduid(){
				
				this.$ajax({
						url: 'ddapi/',
						data: {
							'do': 'ddalluser'
						},
					}).then(res => {
					})
			},
			editnav(index, row) {
				this.isshownavadd = true;
				var f = {
					class_name: row.class_name,
					note: row.note,
					sku: row.sku,
					plid: row.plid,
					logo: row.logo,
					ord: row.ord,
					fid: row.fid
				}
				this.navruleForm = f;
			},
			addchild(i, row) {
				this.isshownavadd = true;
				var f = {
					class_name: '',
					note: '',
					sku: '',
					plid: row.fid,
					logo: '',
					ord: parseInt(row.ord) + 1,
					fid: ''
				}
				this.navruleForm = f;
			},
			shownavadd() {
				this.isshownavadd = true;
				var f = {
					class_name: '',
					note: '',
					sku: '',
					plid: '0',
					logo: '',
					ord: '',
					fid: ''
				}
				this.navruleForm = f;
			},
			delnav(index, row) {
				this.$ajax({
					url: 'navset/',
					data: {
						'op': 'del',
						'fid': row.fid,
						'navname':'asset'
					},
				}).then(res => {
					if (res.error > 0) {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						this.ininav();
					}


				})
			},
			sorts(index, row, v) {
				this.$ajax({
					url: 'navset/',
					data: {
						'op': 'sort',
						'sort': v,
						'fid': row.fid,
						'navname':'asset'
					},
				}).then(res => {
					this.ininav();

				})

			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			submitnavForm(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.navruleForm.op = 'add';
						this.navruleForm.navname = 'asset';
						var data = this.navruleForm;
						this.$ajax({
							url: 'navset/',
							data: this.navruleForm,
						}).then(res => {
							if (res.error > 0) {
								this.$message({
									message: res.msg,
									type: 'warning'
								});
							} else {
								this.$message({
									message: '保存成功',
									type: 'success'
								});
								this.shownavadd();
								this.ininav();
							}

						})
					} else {
						return false;
					}
				});

			},
			ininav() {
				this.$ajax({
					url: 'navset/',
					data: {
						'navname': 'asset'
					},
				}).then(res => {
					this.navtable = res.res;
				})
			},
			chac(v){
				this.active=v;
				if(v=='asset'){
					this.getasset();
					this.$ajax({
						url: 'navset/',
						data: {
							'navname': 'asset',
							'op':'key'
						},
					}).then(res => {
						this.navtable = res.data;
					})
					this.$ajax({
						url: 'department/',
						data: {
							'op':'list'
						},
					}).then(res => {
						this.department = res.data;
					})
				}else if(v=='nav_tab'){
					this.ininav();
				}else if(v=='lun'){
					console.log('kkk')
				}else if(v=='qrcode'){
					this.getqrcode();
				}else if(v=='contract'){
					this.getcontract();
				}else if(v=='lend'){
					this.getlend();
				}
			},

			handleSelectionChange(val){
				for(var i=this.multipleSelection.length-1;i>=0;i--){
				
					for(var j=0;j<val.length;j++){
						if(val[j].id==this.multipleSelection[i].id){
							break;
						}
					}
					if(j==val.length){//删除
						console.log('abc')
						for(var m=this.selectedAsset.length-1;m>=0;m--){
							if(this.selectedAsset[m].id==this.multipleSelection[i].id){
								this.selectedAsset.splice(m,1);
								console.log('mmm')
								break;
							}
						}
						this.multipleSelection.splice(i,1);
					}
				
				}
				this.multipleSelection=val
				for(j=0;j<val.length;j++){
					for(i=this.selectedAsset.length-1;i>=0;i--){
						if(this.selectedAsset[i].id==val[j].id){
							break;
						}
					}
					if(i<0){
						this.selectedAsset.push(val[j]);
					}
				}
			},
			delselsetasset(v){
				console.log(this.selectedAsset[v])
				for(var i=0;i<this.asset.length;i++){
					if(this.asset[i].id==this.selectedAsset[v].id){
						break;
					}
				}
				if(i<this.asset.length){
					this.$refs.multipleTable.toggleRowSelection(this.asset[i],false)
				}
				
				this.selectedAsset.splice(v,1);
			},
			showaddasset(){
				this.isshowaddasset=true;
				
			},
			clearaddasset(){
				this.assetform={};
				if(this.$refs.upload){
					this.$refs.upload.clearFiles();
				}
			},
			getasset(){
				this.$ajax({
					url: 'asset/?do=list',
					data: {
						'search':this.assetsearch,
						'pgsize':this.asset_pgsize,
						'pgno':this.asset_pgno
					}
				}).then(res => {
					this.asset=res.data.res;
					this.asset_pgcount=parseInt(res.data.count);
					this.securityLevel=res.data.securityLevel;
					this.multipleSelection=[];
					setTimeout(() => {
						this.selectedAsset.forEach(row => {
							this.asset.forEach(tt=>{
								if(tt.id==row.id){
									this.multipleSelection.push(tt);
									this.$refs.multipleTable.toggleRowSelection(tt);
								}
							})
						})
					}, 300);
					
				})
			},
			editasset(v){
				this.assetform=v
				
				this.fileList=[];
				if (this.assetform.files.length > 0) {
					for (var i = 0; i < this.assetform.files.length; i++) {
						this.fileList.push({
							name: this.assetform.files[i].fname,
							url:  this.host + this.assetform.files[i].fpath,
							response: this.assetform.files[i]
						})
					}
				}
				this.isshowaddasset=true
			},
			addasset(){
				this.$refs['assetform'].validate((valid) => {
					if (valid) {
						
						if (this.fileList.length == 0) {
							this.sub();
						} else {
							this.assetform.files = [];
							for (var i = 0; i < this.fileList.length; i++) {
								if (this.fileList[i].response == undefined) {
									break;
								} else {
									if(typeof(this.fileList[i].response)=='object'){
										this.assetform.files.push(this.fileList[i].response.id)
									}else{
										this.assetform.files.push(this.fileList[i].response)
									}
								}
							}
							
							if (i == this.fileList.length) {
								this.sub();
							} else {
								this.$refs.upload.submit();
							}
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			sub(){
				this.$ajax({
					url: 'asset/?do=edit',
					data: this.assetform
				}).then(res => {
					if(res.error==0){
						this.isshowaddasset=false;
						this.assetform={
							"id":0,
							"no":"",	// 档案编号
							"type":1,	//  档案类别
							"assetName":"",	// 档案名称
							"location":"",	// 存放位置
							"department":0,	//  部门
							"securityLevel":'',	// 保密等级
							"note":"",	//  备注
							"createDate":"",	//  建档日期
							"original":'1',	//  是否原件1是，0否
							"fileid":""	//  电子档编
						}
						this.restaurants=[];
						this.comkey='';
						this.fileList=[];
						this.asset_pgno=1;
						this.getasset();
					}else{
						this.$alert(res.msg);
					}
				})
			},
			uploadsuccess(response, file, fileList) {
				this.assetform.files = [];
				this.fileList = fileList;
				console.log(this.fileList);
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].response == undefined) {
						break;
					} else {
						if(typeof(this.fileList[i].response)=='object'){
							this.assetform.files.push(this.fileList[i].response.id)
						}else{
							this.assetform.files.push(this.fileList[i].response)
						}
					}
				}
				if (i == this.fileList.length) {
					this.sub();
				}

				//this.buluform.pics.push(response);
			},
			uploadchange(file, fileList) {
				this.fileList = fileList;
			},
			handleRemove(file) {
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].uid == file.uid) {
						this.fileList.splice(i, 1);
						break;
					}
				}
			},

			
			//撤销审批
			revoke(v){
				this.$confirm("确认要撤销吗？","提示").then(()=>{
					this.$ajax({
						url: 'ddapi/',
						data: {
							'do': 'revoke',
							'id': v,
							'tp':1
						},
					}).then(res => {
						if(res.error==0){
							this.getlend()
						}
						this.$alert(res.msg);
					});
				}).catch(()=>{})
			},
			getlendname(){
				if(this.aclend.phone.length==11){
					this.$ajax({
						url: 'lend/?do=getuser',
						data: {
							'phone':this.aclend.phone
						},
					}).then(res => {
						if (res.error > 0) {
							this.aclend.name='';
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							});
						} else {
							this.aclend.name=res.data.name
						}
					})
				}
				
				
			},
			showaddlend(){
				this.aclend={'name':''}
				this.isshoweditlend=true;
				const that=this;
				if(this.selectedAsset.length>0){
					this.aclend.detail=this.selectedAsset;
				}
				document.onkeydown=function(event){
					var e=event||window.event;
					var key=e.keyCode
					console.log(e)
					if((e.ctrlKey||e.metaKey)&&key==86){
						that.$refs.abcss.focus();
					}
				}
			},
			ddcheck(v){
				this.aclend=JSON.parse(JSON.stringify(v));
				this.modifylend('aclend',1);
			},
			modifylend(v,t){
				if(v=='aclend'){
					var data=this.aclend;
					if(t){
						data.process=1;
					}
					console.log(data)
					this.$ajax({
						url: 'lend/?do=edit',
						data: data
					}).then(res => {
						if (res.error > 0) {
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							});
						} else {
							this.aclend.detail=[];
							this.selectedAsset=[];
							this.isshoweditlend=false;
							this.getlend();
						}


					})
				}else{
					this.isshoweditlend=true;
					this.aclend=JSON.parse(JSON.stringify(v))
					this.aclend.needdown=this.aclend.needdown==1?true:false
				}
			},
			getlend(){
				this.$ajax({
					url: 'lend/?do=list',
					data: {
						'pgno':this.lend_pgno,
						'pgsize':this.lend_pgsize,
						'search':this.lendsearch
					}
				}).then(res => {
					if(res.error>0){
						this.$alert(res.msg);
					}
					this.lend=res.data.res;
					this.lend_pgcount=parseInt(res.data.count);
				});
			},
			dellendasset(index){
				console.log(this.aclend.detail[index]);
				this.aclend.detail.splice(index,1);
				console.log(this.aclend.detail);
			},
			clearselend(){
				this.aclend.detail=[];
				this.selectedAsset=[];
				
			}



		}
	};
</script>

<style scoped>
	.demo-tag .el-tag+.el-tag{
		margin-left:10px;
	}
	
	.fc{
		margin-right: 10px;
	}

	.fixpannel{
		min-width: 200px;
		position: fixed;
		z-index: 100;
		background-color: #f5f5f5;
		box-shadow: 0 0 10px #999;
		padding:10px;
		right:10px;
		top:200px;
		border-radius: 10px;
		font-size: 12px;
	}
</style>
