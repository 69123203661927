<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">报修管理</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="panel">
		<el-row :gutter="15" style="margin-bottom:0px;">
			<el-col :span="16">
				<div class="panel-body pad16">
					<div class="fcj_lr">
						<div>
							<div class="fcj_ll" style="font-weight:700;">
								<div class="fcj_ll" style="margin-right:20px;">
									<div>
										<img :src="path+'images/icon/repair1.png'" style="width:24px;display: block;">
									</div>
									<div style="margin:0 5px;">待处理</div>
									<div>{{cc!=''&&cc[1]?cc[1].a:0}}</div>
									
								</div>
								<div class="fcj_ll">
									<div>
										<img :src="path+'images/icon/repair2.png'" style="width:24px;display: block;">
									</div>
									<div style="margin:0 5px;">处理中</div>
									<div>{{cc!=''&&cc[2]?cc[2].a:0}}</div>
									
								</div>
								
							</div>
						</div>
						<div>
							<el-button type="success" @click="showqrcode()" icon="el-icon-picture-outline-round">报修二维码</el-button>
							<el-button type="primary" @click="isbulushow=true" icon="el-icon-edit">报修补录</el-button>
							<el-button type="primary" icon="el-icon-document" @click="golist">报修记录</el-button>
							<el-button type="primary" @click="isshowchar=true" icon="el-icon-s-data">统计图表</el-button>
						</div>
					</div>
				
				</div>
				
				<div>
					<div class="panel-body pad16">
						<div style="height: calc(100vh - 300px);overflow-y: auto; background-color: #fff;">
							<div class="mainpic" :style="{'background-image':'url('+path+mainpic+')'}" ref="maps">
								
								
								<div v-for="(item,index) in clr" :key="'a'+index" class="po"
									:style="{left:item[0]*bi+mainpicleft+'px',top:item[1]*bi+mainpictop+'px','background-image': 'url('+path+'images/web/icon6.png)'}">
								</div>
								
								<div v-for="(item,index) in ddr" :key="index" class="po"
									:style="{left:item[0]*bi+mainpicleft+'px',top:item[1]*bi+mainpictop+'px','background-image': 'url('+path+'images/web/icon7.png)'}">
								</div>
							</div>
						</div>
					</div>
				</div>
					
			</el-col>
			<el-col :span="8">
				<div style="background-color: #f7f9ff;border-radius: 0 8px 8px 0;">
					<div style="padding:40px 32px 0px 32px;">报修处理</div>
					<div class=" pad16 rightlist" >
						
						<div v-if="dd!=''&&dd.c>0">
							<div v-for="(item,index) in dd.res" :key="item.id" class="item">
								
									<div class="repirlist">
										<div><span v-for="(iitem,sindex) in item.retp" :key="item.id+iitem.id">
										{{((sindex>0)?'，':'')+iitem.tpname}}
										</span></div>
										<el-descriptions title="" :column="1" size="small" >
											<el-descriptions-item label="报修单号" label-class-name="des-labels" content-class-name="des-content">
												{{item.number}}
											</el-descriptions-item>
											<el-descriptions-item label="报修时间" label-class-name="des-labels" content-class-name="des-content">
												{{item.time}}
											</el-descriptions-item>
											<el-descriptions-item label="报修分类" label-class-name="des-labels" content-class-name="des-content">
												<span v-for="(iitem,sindex) in item.retp" :key="item.id+iitem.id">
												{{((sindex>0)?'，':'')+iitem.tpname}}
												</span>
											</el-descriptions-item>
											
											<el-descriptions-item label="维修区域" label-class-name="des-labels" content-class-name="des-content">
												{{item.build+item.office+item.other}}
											</el-descriptions-item>
											<el-descriptions-item label="处理状态" label-class-name="des-labels" content-class-name="des-content">
												<el-tag type="danger" size="mini">{{state[item.state]}}</el-tag>
											</el-descriptions-item>
											
											<el-descriptions-item label="问题描述" label-class-name="des-labels" content-class-name="des-content">
												{{item.note}}
												<div style="margin:10px 0;" v-if="item.file&&item.file.length>0">
													<div class="audios" :style="'width:'+(40+iitem.time*3)+'px'" v-for="(iitem,aindex) in item.file" :key="item.id+iitem.path.fileid" @click.stop="playa(index,aindex)"> {{iitem.time}} " </div>
												</div>
											</el-descriptions-item>
											
											
											<el-descriptions-item label="维修师傅" v-if="item.state==2" label-class-name="des-labels" content-class-name="des-content">
												<div><span v-for="iitem in item.retp"
														:key="'pic'+iitem.id">{{iitem.tname+'&nbsp;'}}</span></div>
											</el-descriptions-item>
											<el-descriptions-item label="维修备注" v-if="item.state==2" label-class-name="des-labels" content-class-name="des-content">
												{{item.remark}}
											</el-descriptions-item>
											
										</el-descriptions>
										
										<div style="position: absolute;right:10px; top:10px;">
											<el-button @click="stopalert(item,index)" type="primary" size="mini">去处理</el-button>
										</div>
									</div>
									

							</div>
						</div>
						
						<div v-if="cl!=''&&cl.c>0">
							<div v-for="(item,index) in cl.res" :key="'cl'+item.id" class="item hav">
								<div class="repirlist">
									<el-descriptions title="" :column="1" size="small">
										<el-descriptions-item label="报修单号" label-class-name="des-labels" content-class-name="des-content">
											{{item.number}}
										</el-descriptions-item>
										<el-descriptions-item label="报修时间" label-class-name="des-labels" content-class-name="des-content">
											{{item.time}}
										</el-descriptions-item>
										<el-descriptions-item label="报修分类" label-class-name="des-labels" content-class-name="des-content">
											<span v-for="(iitem,sindex) in item.retp" :key="item.id+iitem.id">
											{{((sindex>0)?'，':'')+iitem.tpname}}
											</span>
										</el-descriptions-item>
										<el-descriptions-item label="维修区域" label-class-name="des-labels" content-class-name="des-content">
											{{item.build+item.office+item.other}}
										</el-descriptions-item>
										<el-descriptions-item label="处理状态" label-class-name="des-labels" content-class-name="des-content">
											<el-tag type="success">{{state[item.state]}}</el-tag>
										</el-descriptions-item>
										<el-descriptions-item label="问题描述" label-class-name="des-labels" content-class-name="des-content">
											{{item.note}}
											<div style="margin:10px 0;" v-if="item.file&&item.file.length>0">
												<div class="audios" :style="'width:'+(40+iitem.time*3)+'px'" v-for="(iitem,aindex) in item.file" :key="item.id+iitem.path.fileid" @click.stop="playa(index,aindex)">{{iitem.time}} " </div>
											</div>
										</el-descriptions-item>
										
										
										
										<el-descriptions-item label="维修师傅" v-if="item.state==2" label-class-name="des-labels" content-class-name="des-content">
											<div><span v-for="iitem in item.retp"
													:key="item.id+iitem.id">{{iitem.tname+'&nbsp;'}}</span></div>
										</el-descriptions-item>
										<el-descriptions-item label="维修备注" v-if="item.state==2" label-class-name="des-labels" content-class-name="des-content">
											{{item.remark}}
										</el-descriptions-item>
										
									</el-descriptions>
									
									<div style="position: absolute;right:10px; top:10px;">
										<el-button @click="stopalert(item,index)" type="primary" size="mini">处理</el-button>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</el-col>
		</el-row>
		</div>
		<el-dialog :visible.sync="isshow" width="600px">
			
			<div style="line-height: 150%;" class="rlist">
				<div><span>报修单号：</span>{{acrepair.number}}</div>
				<div><span>报修时间：</span>{{acrepair.time}}</div>

				<div><span>电话：</span>{{acrepair.phone}}</div>
				<div>
					<span>维修区域：</span>{{acrepair.build+acrepair.office+acrepair.other}}
				</div>
				<div>
					<span>图片：</span><el-image v-for="(item) in acrepair.pics" :key="'d'+item.id"
						style="width: 100px; height: 100px;margin:0 5px 5px 0"
						:src="item" 
						:preview-src-list="acrepair.pics">
					</el-image>
				</div>
				<div style="border-bottom:2rpx solid #ddd;margin:20rpx;"></div>

			<el-form ref="repairform" :model="repairform" label-width="100px">
				<el-form-item label="报修来源">
					<el-radio-group v-model="repairform.source">
						<el-radio label="0">外部报修</el-radio>
						<el-radio label="1">内部报修</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="是否收费">
					<el-radio-group v-model="repairform.paytype">
						<el-radio label="0">否</el-radio>
						<el-radio label="1">是</el-radio>
					</el-radio-group>
				</el-form-item>
				
			
				
				<el-form-item label="收费金额" v-if="repairform.paytype==1">
					<el-input-number  :controls="false" :step="1" :min="0" v-model="repairform.fee" style="width:100px"></el-input-number>
				</el-form-item>
				<el-form-item label="报修类型">
					<el-checkbox-group  v-model="repairform.retp" >
						
						<el-checkbox :label="index" name="type" v-for="(item,index) in retp" :key="index" @click='chretp(index)'>
							{{item.tpname}}
						</el-checkbox>
					
					</el-checkbox-group>
					
				
				</el-form-item>
				<div v-for="(item,index) in repairform.retp" :key="'c'+index">
					<el-form-item :label="retp[item].tpname" >
						<div class="fcj_ll">
							
								<el-select v-model="retp[item].stid" placeholder="请选择">
									<el-option v-for="(iitem,iindex) in retp[item].rename" :key="'e'+iindex" :label="iitem.tname"
										:value="iitem.stid">
									</el-option>
								</el-select>
							
						</div>
						
					</el-form-item>
				</div>

				<el-form-item label="报修状态">
				

					<el-select v-model="repairform.state" placeholder="请选择">
						<el-option v-for="(item,index) in state" :key="index" :label="item" :value="index">
						</el-option>
					</el-select>


				</el-form-item>
					<el-form-item label="备注">
				
						<el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="repairform.remark">
						</el-input>

					</el-form-item>


				<div style="display:flex;justify-content: center;">
					<div style="padding:20rpx;text-align:center;">
						<el-button type="primary" @click="subset()" icon="el-icon-document">保存</el-button>
					
						<el-button @click="calset()" icon="">取消</el-button>
					</div>
				</div>
			</el-form>
			</div>

		</el-dialog>

		<div v-show="isshowchar"  class="mark"></div>
		<div v-show="isshowchar" class="chardiv">
			<div style="padding-top: 20px;">
			<el-row :gutter="15">
				<el-col :span="12">
					<div class="panel">
						<div class="panel-header">

							<div class="charttitle" style="margin-top:0px;">报修总数趋势图</div>
							<div  style="text-align: right;padding:5px 0;">
								<el-date-picker v-model="date1" type="daterange" align="right" unlink-panels
									range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
									:picker-options="pickerOptions" @change="getdata" :default-value="this.date1"
									style="width:260px;">
								</el-date-picker>
							</div>
						</div>
						<div class="panel-body pad16" style="background-color: #fff;">
							<div >
								<div id="myChart" :style="{width: '460px', height: '300px'}"></div>
							</div>
						</div>

					</div>
				</el-col>

				<el-col :span="12">
					<div class="panel">
						<div class="panel-header">
							<div class="charttitle" style="margin-top:0px;">报修项目数量统计</div>
							<div  style="text-align: right;padding:5px 0;">
								<el-date-picker v-model="date2" type="daterange" align="right" unlink-panels
									range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
									:picker-options="pickerOptions" @change="getdata" style="width:260px;">
								</el-date-picker>
							</div>
						</div>
						<div class="panel-body pad16" style="background-color: #fff;">
							<div >
								<div id="myChart1" :style="{width: '460px', height: '300px'}"></div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
			</div>
			<div style="text-align: center;margin:20px;"> <el-button @click="isshowchar=false" type="primary" style="width:100px;">关闭</el-button></div>
		</div>

		<el-dialog :visible.sync="isbulushow" width="700px">
			<div class="buluform">
				<el-form ref="buluform" :model="buluform" label-width="140px">
					<el-form-item label="请选择故障类别">
						<el-checkbox-group v-model="buluform.tp">
							<el-checkbox :label="index" name="type" v-for="(item,index) in repairtype" :key="index" v-show="item!=''">
								{{item}}
							</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<div class="address">
						<el-form-item label="公司" >
							<el-input v-model="buluform.office"></el-input>
						</el-form-item>
						<el-form-item label="地址" >
							<div class="fcj_ll">
								<div><property v-model="chproperty" :fsize="['small']" @change="ch"  /></div>
								<div style="padding-left:10px;">{{chproperty.propertyname}}</div>
							</div>
						</el-form-item>
						<el-form-item label="其他" >
							<el-input v-model="buluform.other"></el-input>
						</el-form-item>
						
					</div>
					<el-form-item label="故障描述">
						<el-input type="textarea" v-model="buluform.note"></el-input>
					</el-form-item>
					<el-form-item label="报修来源">
						<el-radio-group v-model="buluform.source">
							<el-radio label="0">外部报修</el-radio>
							<el-radio label="1">内部报修</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="维修响应期望">
						<el-radio-group v-model="buluform.back">
							<el-radio label="0">不急</el-radio>
							<el-radio label="1">有点急</el-radio>
							<el-radio label="2">非常急</el-radio>
						</el-radio-group>
					</el-form-item>
		
					<el-form-item label="联系电话">
						<el-input v-model="buluform.phone" style="width:300px"></el-input>
					</el-form-item>
					<el-form-item label="联系人">
						<el-input v-model="buluform.name" style="width:300px"></el-input>
					</el-form-item>
		
					<el-form-item label="图片">
						<el-upload 
						:action="host+'upimg/'" 
						list-type="picture-card" 
						ref="upload"
						name="upfile" 
						:on-success="uploadsuccess" 
						:on-change="uploadchange"
						:file-list="fileList" 
						:auto-upload="false"
						>
							<i slot="default" class="el-icon-plus"></i>
							<div slot="file" slot-scope="{file}">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="handleDownload(file)">
										<i class="el-icon-download"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="handleRemove(file)">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
		
						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</el-form-item>
		
		
					<el-form-item>
						<el-button type="primary" @click="createrepair()" icon="el-icon-document">立即创建</el-button>
						<el-button @click="isbulushow=false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		
		</el-dialog>
		
		<el-dialog :visible.sync="isershow" >
			<div style="padding:50px;">
				<img :src="host+qrcode" style="margin:0 auto;" alt="">
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import property from '../components/property.vue'
	var timeline = 0;
	export default {
		components: {
			property
		},
		data() {
			return {
				isershow:false,
				host:'',
				qrcode:'',
				chproperty:{},
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				fileList: [],
				buluform: {
					area: 1,
					tp: [],
					building: '',
					office: '',
					other: '',
					note: '',
					source:'',
					back: '',
					phone: '',
					name: '',
					pics: '',
					buildingid:'',
					file: []
				},
				isbulushow: false,
				repairtype: {},
				
				
				isshowchar: false,
				state: [],
				retp: [],
				acrepair: {},
				repairform: {
					paytype: 0,
					source: '',
					fee: '',
					tmptp: [],
					retp:[],
					state: ''
				},
				isshow: false,
				path: this.$host,
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);

						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);

						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);

						}
					}, {
						text: '最近六个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 182);
							picker.$emit('pick', [start, end]);

						}
					}]
				},
				date1: '',
				date2: '',
				picker: {
					date1: [],
					date2: []
				},
				form: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				},
				abc: false,
				cc: {
					c: 0
				},
				dd: {
					c: 0
				},
				ddr: [],
				cl: '',
				clr: [],
				source: '',
				color: ['alt0', 'alt1', 'alt2'],
				char1: [],
				char2: [],
				myChart: '',
				myChart1: '',
				timeline: 0,
				mainpic:'',
				mainpicw:'',
				mainpich:'',
				bi:1,
				mainpicleft:0,
				mainpictop:0
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=[to];
			})
		},
		mounted() {
			this.host=this.$host
			this.mainpicw=this.$refs.maps.offsetWidth
			this.mainpich=this.$refs.maps.offsetHeight
			if (timeline == 0) {
				timeline = setInterval(() => {
					this.getdata(1);
				}, 130000);
			}
			this.ini();
			
			this.open();
			var a = new Date();
			var b = new Date(a.getTime() - 86400 * 182 * 1000);
			this.date2 = this.date1 = [b.getFullYear() + '-' + (b.getMonth() + 1) + '-' + b.getDate(), a.getFullYear() +
				'-' + (a.getMonth() + 1) + '-' + a.getDate()
			];
			this.drawLine();
			
		},
		methods: {
			showqrcode(){
				this.$ajax({
					url: 'repair/',
					data: {
						do:'qrcode'
					}
				}).then(res => {
					this.qrcode=res.data
					this.isershow=true
				});
			},
			ch(){
				var buildid='';
				for(var i in this.chproperty.buildch){
					buildid= this.chproperty.buildch[i]
				}
				for(i in this.chproperty.floorch){
					buildid= this.chproperty.floorch[i][0]
				}
				for(i in this.chproperty.offices){
					buildid= this.chproperty.offices[i].buildingid
				}
				
				this.buluform.buildingid=buildid
				this.buluform.officeid=this.chproperty.roomch
				this.buluform.building=this.chproperty.propertyname;
			},
			handleRemove(file) {
				for(var i=0;i<this.fileList.length;i++){
					if(this.fileList[i].uid==file.uid){
						this.fileList.splice(i,1);
						break;
					}
				}
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleDownload(file) {
			},
			uploadsuccess(response, file, fileList) {
				this.buluform.pics=[];
				this.fileList=fileList;
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].response == undefined) {
						break;
					}else{
						this.buluform.pics.push(this.fileList[i].response.fpath)
					}
				}
				if (i == this.fileList.length) {
					this.buluform.pics=this.buluform.pics.join(',')
					this.sub();
				}
				
				//this.buluform.pics.push(response);
			},
			uploadchange(file, fileList){
				this.fileList=fileList;
			},
			createrepair() {
				if (this.fileList.length == 0) {
					this.sub();
				} else {
					for (var i = 0; i < this.fileList.length; i++) {
						if (this.fileList[i].response == undefined) {
							break;
						}
					}
					if (i == this.fileList.length) {
						this.sub();
					}else{
						this.$refs.upload.submit();
					}
					
				}
				
				
			},
			sub() {
				this.buluform.do = 'add'
				this.$ajax({
					url: 'repair/',
					data: this.buluform,
				}).then(res => {
					if (res.error > 0) {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						this.isbulushow = false;
						this.getdata();
					}
				})
			
			},
			handleDelete(index, row) {
				this.$ajax({
					url: 'repair/',
					data: {
						'do': 'del',
						'id': row.id
					},
				}).then(res => {
					this.getdata();
			
				})
			},
			ini(){
				this.$ajax({
					url: 'map/',
					data: {
						'do': 'list',
						'type': ['mainmap']
					},
				}).then(res => {
					this.mainpic = res.data.mainmap.content;
					// this.mainpich=res.data.mainmap.imgh;
					// this.mainpicw=res.data.mainmap.imgw;
					
					//高度充满，宽度缩放
					if(this.mainpicw/this.mainpich>res.data.mainmap.imgw/res.data.mainmap.imgh){
						
						var w=this.mainpich/res.data.mainmap.imgh*res.data.mainmap.imgw
						this.mainpictop=0;
						this.mainpicleft=(this.mainpicw-w)/2
						this.bi=w/750;
					}else{
						var h=this.mainpicw/res.data.mainmap.imgw*res.data.mainmap.imgh
						this.mainpicleft=0
						this.mainpictop=(this.mainpich-h)/2
						this.bi=this.mainpicw/750
					}
					this.getdata();
				})	
			},
			golist(){
				this.$router.push('repair');
			},
			playa(a, b) {
				document.getElementById('myaudio').loop = false;
				document.getElementById('myaudio').src = this.$host + this.dd.res[a].file[b].path.fpath
				document.getElementById('myaudio').play();
			},
			alert() {
				document.getElementById('myaudio').loop = true;
				document.getElementById('myaudio').src = this.$host + 'images/web/alert.mp3'
				document.getElementById('myaudio').play();
			},
			onSubmit() {
			},
			open() {
				if (sessionStorage.getItem('play') != 1) {
					this.$alert('请及时查看用户报修信息', '提示', {
						confirmButtonText: '确定',
						callback: () => {
							this.alert();
							sessionStorage.setItem('play', 1);
							setTimeout(() => {
								document.getElementById('myaudio').pause();
							}, 100);
						}
					}).then(() => {
						this.alert();
					});
				}
			},
			calset() {
				this.isshow = false;
			},
			subset() {
				var md = [];
				
				for (var i in this.retp) {
					if (this.retp[i].stid) {
						md.push([i, this.retp[i].stid]);
					}
				}
				this.$ajax({
					url: 'repair/',
					data: {
						'do': 'update',
						'id': this.acrepair.id,
						'state': this.repairform.state,
						'source': this.repairform.source,
						'paytype': this.repairform.paytype,
						'fee': this.repairform.paytype == 1 ? this.repairform.fee : 0,
						'md': md,
						'remark': this.repairform.remark
					},
				}).then(res => {
					if (res.error == 0) {
						if (this.repairform.state != 1) {
							this.dd.res.splice(this.acrepairid, 1);
						}
						this.isshow = false;
						this.getdata();
					}else{
						this.$alert(res.msg, {
							confirmButtonText: '确定'
						});
					}
				});
			},
			
			stopalert(item, id) {
				var localalert = localStorage.getItem('localalert');
				if (localalert != null) {
					localalert = JSON.parse(localalert);
					for (var i = 0; i < localalert.length; i++) {
						if (item.id == localalert[i]) {
							break;
						}
					}
					if (i == localalert.length) {
						localalert.push(item.id);
					}
				} else {
					localalert = [item.id]
				}
				document.getElementById('myaudio').pause();
				localStorage.setItem('localalert', JSON.stringify(localalert));

				this.$router.push('repairedit?id='+item.id)
			},
			getdata() {
				this.$ajax({
					url: 'repair/',
					data: {
						'do': 'tongji',
						'st': this.date1,
						'st1': this.date2
					},
				}).then(res => {
					var i=0;
					this.state = res.state
					if(res.c>0){
						this.cc = res.res;
					}else{
						this.cc=''
					}
					
					this.repairtype = res.repairtype
					if (res.source.c > 0) {
						this.source = res.source['res'];
					}
					if ((res.dd.c > 0)) {

						var localalert = localStorage.getItem('localalert');

						if (localalert != null) {
							localalert = JSON.parse(localalert);
							for (i = 0; i < localalert.length; i++) {
								if (res.dd.res[0].id == localalert[i]) {
									break;
								}
							}
							if (i == localalert.length) {
								//没有就报警
								this.alert();
							}
						} else {
							this.alert();

						}
					}
					this.dd = res.dd;
					this.ddr = [];

					for (i = 0; i < this.dd.c; i++) {
						
						if (this.dd.res[i].position ) {
							this.dd.res[i].position = this.dd.res[i].position.split(',');
							this.ddr.push(this.dd.res[i].position)
						}

					}
					this.cl = res.cl;
					this.clr = []
					for (i = 0; i < this.cl.c; i++) {
						if (this.cl.res[i].position != null) {
							
							this.cl.res[i].position = this.cl.res[i].position.split(',');
							this.clr.push(this.cl.res[i].position)
						}

					}


					var tmp1 = [],
						tmp2 = [];
					for (i in res.month) {
						tmp1.push(i);
						tmp2.push(res.month[i])

					}
					this.char1 = [tmp1, tmp2];
					this.char2 = res.chartp;
					this.drawLine();



				})

			},
			drawLine() {
				// 基于准备好的dom，初始化echarts实例
				if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
					//this.myChart.dispose();
				} else {
					this.myChart = this.$echarts.init(document.getElementById('myChart'))
				}
				// 绘制图表
				this.myChart.setOption({
					xAxis: {
						type: 'category',
						data: this.char1[0]
					},
					legend: {
						data: ['报修数量']
					},
					tooltip: {
						trigger: 'axis'
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						name: '报修数量',
						data: this.char1[1],
						type: 'line'
					}]
				});

				if (this.myChart1 != null && this.myChart1 != "" && this.myChart1 != undefined) {
					//this.myChart1.dispose();
				} else {
					this.myChart1 = this.$echarts.init(document.getElementById('myChart1'))
				}

				// 绘制图表
				this.myChart1.setOption({
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					legend: {
						data: ['报修数量']
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'value',
						boundaryGap: [0, 0.01]
					},
					yAxis: {
						type: 'category',
						data: this.char2[0]
					},
					series: [{
						name: '报修数量',
						type: 'bar',
						data: this.char2[1]
					}]
				});
			}
		}
	}
</script>

<style scoped>
	.chardiv{
		position: fixed;
		z-index: 500;
		left:50%;
		top:50%;
		transform: translate(-50%,-50%);
		width:1000px;
		height: 500px;
		padding:20px;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0 0 10px #ccc;
	}
	.mainpic{
		position: relative;
		width: 100%;
		height: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
	.repirlist{
		position: relative;
	}
	.des-labels{
		min-width:80px;
	}
	.rlist>div {
		margin: 10px 0;
		display: flex;
	}

	.rlist>div>span {
		display: inline-block;
		width: 100px;
		text-align: right;
	}

	.po {
		position: absolute;
		width: 32px;
		height: 40px;
		border-radius: 50%;
		background-size: 100% 100%;
	}

	.item {
		color: #00071C;
		border-bottom: 1px solid #E4E8F0;
		margin: 10px;
		padding:6px;
		
	}
	.hav{
		background-color: #f8f8f8;
	}
	
	.alt0 {
		background-color: #FF3300;
		color: #fff;
	}

	.alt1 {
		background-color: #FF9900;
		color: #fff;
	}

	.alt2 {
		background-color: #FFCC00;
		color: #fff;
	}

	.time {
		float: right;
		color: #888;
	}

	.alt0 .time,
	.alt1 .time,
	.alt2 .time {
		color: #fff;
	}




	.rightlist {
		height: calc(100vh - 240px);
		overflow-y: auto;
	}

	.chartbody {
		position: relative;
		height: calc((100vh - 400px)/2);
	}



	.audiobar {
		margin-right: 5px;
		height: 25px;
		line-height: 25px;
		background-color: #ddd;
		padding: 0 10px;
		border-radius: 12px;
		display: inline-block;
		cursor: pointer;
	}
</style>
