<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">预定管理</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel">

					<div class="panel-body pad16" >
						<div class="table-filter textright"><el-button type="primary" size="small"  icon="el-icon-plus" @click="showadd">添加场地</el-button></div>

						<el-table :data="tableData"  header-cell-class-name="table-header" cell-class-name="fcj" stripe>
							<el-table-column prop="" width="20" >
							</el-table-column>
							<el-table-column prop="rname" label="场地名称" >
							</el-table-column>
							<el-table-column prop="area" label="场地面积（㎡）" >
							</el-table-column>
							<el-table-column prop="personal" label="最多容纳人数" >
							</el-table-column>
							<el-table-column prop="ordernumber" label="订单总数量" >
								<template slot-scope="scope">
									<router-link :to="'/reservation_order?tpid='+scope.row.id">{{scope.row.ordernumber}}</router-link>
								</template>
							</el-table-column>
							<el-table-column prop="neworder" label="新订单" >
								<template slot-scope="scope">
									<router-link :to="'/reservation_order?state=0&tpid='+scope.row.id">{{scope.row.neworder}}</router-link>
								</template>
							</el-table-column>
							<el-table-column prop="rentstate" label="租赁状态">
								<template slot-scope="scope">
									<el-switch v-model="scope.row.rentstate" active-color="#13ce66" inactive-color="#ff4949"
										active-value="1" inactive-value="0" @change="editrentstate(scope.row)">
									</el-switch>
									{{scope.row.rentstate==1?'可租':'停租'}}
								</template>
							</el-table-column>
							<el-table-column prop="state" label="展示状态">
								<template slot-scope="scope">
									<el-switch v-model="scope.row.state" active-color="#13ce66" inactive-color="#ff4949"
										active-value="1" inactive-value="0" @change="editstate(scope.row)">
									</el-switch>
									{{scope.row.state==1?'展示':'隐藏'}}
								</template>
							</el-table-column>
							<el-table-column prop="rentstate" label="场地使用计划">
								<template slot-scope="scope">
									<i class="el-icon-date" @click="setdatae(scope.row)" style="cursor: pointer;"></i>
								</template>
							</el-table-column>
							<el-table-column prop="rentstate" label="地址" width="390">
								<template slot-scope="scope">
									{{'../venue_reservation/venue_reservation?member=1&tp='+scope.row.id}}
								</template>
							</el-table-column>
							<el-table-column label="" width="100">
								<template slot-scope="scope">

									<el-button size="mini" slot="reference" icon="el-icon-edit"
										@click.stop="edit(scope.$index, scope.row)" circle
										style="margin-right: 10px;">
									</el-button>
									<el-popover placement="top" width="60" v-model="scope.row.visible">
										<p>确定删除吗？</p>
										<div style="text-align: right; margin: 0">
											<el-button size="mini" type="text"
												@click.stop="scope.row.visible = false">取消</el-button>
											<el-button type="primary" size="mini"
												@click.stop="handleDelete(scope.$index, scope.row)">确定
											</el-button>
										</div>
										<el-button size="mini" slot="reference" icon="el-icon-delete" circle>
										</el-button>
									</el-popover>
						
								</template>
							</el-table-column>
							<template>
								<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
							</template>
						</el-table>
						


					</div>
					<div class="panel-foot">
						<div class="pad16"></div>
					</div>
				</div>
			</el-col>

		</el-row>
		<el-dialog :visible.sync="isshowadd"  @close="closefrom" width="680px" top="10vh">
			<div>
			<el-calendar v-model="sedata" height="400px">
				<template
					slot="dateCell"
					slot-scope="{date, data}">
					<p @click="gets(data.day)">
					{{ parseInt(data.day.split('-')[2]) }} {{ data.isSelected ? '✔️' : ''}}
					</p>
				</template>
			</el-calendar>
			</div>
			
			<div style="text-align: center;margin-bottom:20px;" v-if="acrow!==''&&acrow.mintime==2">
				<el-radio-group v-model="timemodel" @change="timemodelc">
					<el-radio name="tp" label="1">
						全天
					</el-radio>
					<el-radio name="tp" label="2">
						上午
					</el-radio>
					<el-radio name="tp" label="3">
						下午
					</el-radio>
				</el-radio-group>
			</div>
			
			<div style="padding:0 20px 20px 20px;" class="tag-group">
				<el-tag v-for="(item,index) in marks" :key="item.label" :type="st[item.st]" :class="[(item.selected==1?'selected':'')]" @click="cht(index,item)">{{item.label}}</el-tag>
			</div>
			<div style="padding:20px;text-align: center;">
				<el-button type="warning" @click="start(2)">停用</el-button>
				<el-button type="primary" @click="start(0)">启用</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>

	export default {
		data() {
			return {
				st:['primary','success','info'],
				sedt: [],
				marks:[{label:1,st:0},{label:2,st:0}],
				sedata:'',
				real:[],
				timemodel:'',
				
				isshowadd:false,
				uploaddata: {
					pusern: '',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				tableData: [],
				host:'',
				acrow:''
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=[to];
			})
		},
		mounted: function() {
			this.breadcrumb=this.$root.myrouter
			this.uploaddata.pusern=localStorage.getItem('pusern');
			this.ini();
			this.host = this.$host
			var d=new Date;
			this.sedata=d.getFullYear()+'-'+('0'+(d.getMonth()+1)).slice(-2)+'-'+('0'+d.getDate()).slice(-2)
		},
		methods: {
			timemodelc(v){
				this.sedt=[];
				for(var i=0;i<this.marks.length;i++){
					if(v==1||v==2&&i<this.marks.length/2||v==3&&i>=this.marks.length/2){
						this.marks[i].selected=1;
						this.sedt.push(this.marks[i].value);
					}else{
						this.marks[i].selected=0;
					}
				}
				
			},
			start(state){
				//2停用0启用
				// if(state==2){
				// 	for(var i=this.sedt;i<=this.sedt[1];i++){
				// 		if(this.marks[i].st>0){
				// 			//已经停用或已启用的不能再停用
				// 			this.$message({
				// 				message: '不能选择已占用的时间段',
				// 				type: 'warning'
				// 			})
				// 			return;
				// 		}
						
				// 	}
				// 	this.sedt[0]=this.marks[this.sedt[0]].v
				// 	this.sedt[1]=this.marks[this.sedt[1]].v
				// }
				if(this.sedt.length==0){
					this.$message({
						message: '请选择时间段',
						type: 'warning'
					})
					return;
				}
				this.$ajax({
					url: 'reservation/',
					data: {
						'do': 'yuliu',
						'realday':this.sedata,
						'time':this.sedt,
						'state':state,
						'tp':this.acrow.id
					},
				}).then(res => {
					this.$message({
						message: res.msg,
						type: 'success'
					})
				})
			},
			gets(v){
				
				console.log(v)
				this.timemodel='';
				//某一天已预定的时间段数据
				this.$ajax({
					url: 'reservation/',
					data: {
						'do': 'getselected',
						'date':v,
						'tp':this.acrow.id
					},
				}).then(res => {
					this.sedata=v;
					this.sedt=[];
					if(res.data.c>0){
						this.real = res.data.res;
					}else{
						this.real =[];
					}
					
					//已选时间段回显
					
						var j=0;
						for(var i=0;i<this.marks.length;i++){
							
							if(res.data.c>0&&this.real[j]&&v+' '+this.marks[i].value==this.real[j].dt){
								this.marks[i].st=this.real[j].state;
								j++;
							}else{
								this.marks[i].st=0;
							}
							this.marks[i].selected=0;
						}
					
				})
			},
			cht(key,v){
				//选取非确认时间段
				if(this.acrow!==''&&this.acrow.mintime==2){
					this.$message({
						message:'最少选择半天',
						type:'warning'
					})
				}else{
					if(v.st!=1){
						var i=this.sedt.indexOf(v.value);
						if(i>=0){
							this.sedt.splice(i,1);
							v.selected=0;
						}else{
							this.sedt.push(v.value);
							v.selected=1;
						}
						
					}
				}
				
			},
			closefrom(){
				this.timemodel=''
			},
			setdatae(row){
				//初始货显示时间列表
				this.isshowadd=true;
				var m=[];
				var tmp='';
				for(var i=8;i<20;i++){
					tmp=i<10?'0'+i:i;
					//tmp=i+':00';
					m.push({label:tmp+':00'+'~'+tmp+':30',st:0,value:tmp+':00',selected:0})
					m.push({label:tmp+':30'+'~'+('0'+(parseInt(tmp)+1)).slice(-2)+':00',st:0,value:tmp+':30',selected:0})
				}
				this.marks=m;
				this.acrow=row;
				this.gets(this.sedata);
			},
			edit(i,v){
				this.$router.push({path:'/reservation/reservation_eidt?id='+v.id});
			},
			editrentstate(v) {
				this.$ajax({
					url: 'reservation/',
					data: {
						'do': 'editrentstate',
						'rentstate': v.rentstate,
						'id': v.id
					}
				}).then(res => {
					
				})
			},
			editstate(v) {
				this.$ajax({
					url: 'reservation/',
					data: {
						'do': 'editstate',
						'state': v.state,
						'id': v.id
					}
				}).then(res => {
					
				})
			},

			showadd() {
				this.$router.push({path:'/reservation/reservation_eidt'});
				
			},
			handleDelete(index, row) {
				this.$ajax({
					url: 'reservation/',
					data: {
						'do': 'delreservation',
						'id': row.id
					},
				}).then(res => {
					if (res.error > 0) {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						this.ini();
					}


				})
			},

			ini() {
				this.$ajax({
					url: 'reservation/',
					data: {
						'do': 'listreservation'
					},
				}).then(res => {
					this.tableData = res.data.res;
	
				})
			},


			
		},
	}
</script>
<style>
	
</style>