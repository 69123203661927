<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">场地订单</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel">

					<div class="panel-body pad16">
						<div class="table-filter">
							<div class="fcj_lr">
								<div class="fcj_ll" style="font-size:12px;">
									<el-select v-model="chstate" slot="prepend" size="small" clearable
										placeholder="订单状态" @change="getdata" style="margin-right:10px;width:140px;">
										<el-option v-for="(item,index) in staten" :key="item" :label="item" :value="index">{{item}}</el-option>
									</el-select>
									
									<el-input placeholder="请输入内容" size="small" clearable v-model="keywords"
										class="input-with-select">
										<template slot="prepend">客户名称</template>
										<el-button slot="append" icon="el-icon-search" @click="getdata"></el-button>
									</el-input>
									
								</div>
								
								<div class="fcj_ll">
									<div style="margin-right: 20px;font-size:20px;"><i class="el-icon-date" @click="showorder"></i></div>
									<el-button type="primary" size="small"  icon="el-icon-plus" @click="showadd(1)">订单补录</el-button>
									<el-button type="success" size="small"  icon="el-icon-takeaway-box" @click="getdata(1)">导出</el-button>
									
								</div>
							</div>
						</div>

						<el-table :data="tableData" header-cell-class-name="table-header" cell-class-name="fcj" stripe>
							<el-table-column prop="" width="20" >
							</el-table-column>
							<el-table-column prop="company" label="公司名称" >
							</el-table-column>
							<el-table-column prop="contact" label="联系人" >
							</el-table-column>
							<el-table-column prop="phone" label="联系电话" >
							</el-table-column>
							<el-table-column prop="usern" label="提交人" >
								<template slot-scope="scope">
									<div class="fcj_ll">
										<div><img :src="scope.row.logo" alt="" class="header-logo"></div>
										<div>{{scope.row.usern}}</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="createtime" label="提交时间" >
							</el-table-column>
							<el-table-column prop="purpose" label="使用需求" >
							</el-table-column>
							<el-table-column prop="staten" label="订单状态">
								<template slot-scope="scope">
									<span :class="['reservation_order_state'+scope.row.state]">{{scope.row.staten}}</span>
								</template>
							</el-table-column>
							
							<el-table-column prop="money" label="订单金额">
								
							</el-table-column>
							<el-table-column label="操作" width="100">
								<template slot-scope="scope">

									<el-button size="mini" slot="reference" icon="el-icon-edit"
										@click.stop="edit(scope.$index, scope.row)" circle
										style="margin-right: 10px;">
									</el-button>
									<el-popover placement="top" width="60" v-model="scope.row.visible">
										<p>确定要撤销吗？</p>
										<div style="text-align: right; margin: 0">
											<el-button size="mini" type="text"
												@click.stop="scope.row.visible = false">取消</el-button>
											<el-button type="primary" size="mini"
												@click.stop="handleDelete(scope.$index, scope.row)">确定
											</el-button>
										</div>
										<el-button size="mini" slot="reference" icon="el-icon-circle-close" circle>
										</el-button>
									</el-popover>
						
								</template>
							</el-table-column>
							<template>
								<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
							</template>
						</el-table>
						<div style="padding:15px;text-align: right;">
							<el-pagination background layout="total,prev, pager, next" :current-page.sync="pgno"
								:page-size="pgsize" @current-change="page" :total="pgcount">
							</el-pagination>
						</div>


					</div>
					<div class="panel-foot">
						<div class="pad16"></div>
					</div>
				</div>
			</el-col>

		</el-row>
		<el-dialog :visible.sync="isshowadd"  @close="closefrom" width="700px"  top="10vh">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm" >
				
				<div v-show="acrowid===''">
					<el-form-item label="场地名称" prop="tp">
						<el-select v-model="ruleForm.tp" placeholder="场地名称" style="width:180px;" >
							<el-option v-for="(item,index) in area" :label="item.rname" :value="item.id"
								:key="index">
								{{item.rname}}
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="活动名称" prop="company">
						<el-input  v-model="ruleForm.company"></el-input>
					</el-form-item>
					<el-form-item label="联系人" prop="contact">
						<el-input  v-model="ruleForm.contact"></el-input>
					</el-form-item>
					<el-form-item label="联系电话" prop="phone">
						<el-input  v-model="ruleForm.phone"></el-input>
					</el-form-item>
					<el-form-item label="计划使用时间" prop="note">
						<el-input  v-model="ruleForm.note"></el-input>
					</el-form-item>
					<el-form-item label="场地预订用途" prop="purpose">
						<el-input type="textarea" v-model="ruleForm.purpose" maxlength="255" show-word-limit></el-input>
					</el-form-item>
				</div>
				
				<div v-show="acrowid!==''">
					<el-descriptions title="" :column="1" size="medium" labelClassName="des-label" contentClassName="des-content"> 
					
						<el-descriptions-item label="场地名称">
							{{acrowid!==''?(isshowordercale?caledata[acrowid].rname:tableData[acrowid].rname):rname}}
						</el-descriptions-item>
						<el-descriptions-item label="活动名称">
							{{ruleForm.company}}
						</el-descriptions-item>
						<el-descriptions-item label="联系人">
							{{ruleForm.contact}}
						</el-descriptions-item>
						
						<el-descriptions-item label="联系电话">
							{{ruleForm.phone}}
						</el-descriptions-item>
						
						<el-descriptions-item label="计划使用时间">
							{{ruleForm.note}}
						</el-descriptions-item>
						
						<el-descriptions-item label="场地预订用途">
							{{ruleForm.purpose}}
						</el-descriptions-item>
						<el-descriptions-item label="订单状态">
							{{ruleForm.staten}}
						</el-descriptions-item>
						<el-descriptions-item label="确认时间">
							<div v-if="ruleForm.dt.length==1">{{ ruleForm.dt[0].day+','+ruleForm.dt[0].timerange }}</div>
							<div v-else><div v-for="(item,index) in ruleForm.dt" :key="index">{{ item.day+' '+item.timerange }}</div></div>
							
						</el-descriptions-item>
					</el-descriptions>
					<div style="border-top:1px solid #EDF0F5;margin-bottom: 40px;"></div>
					<el-form-item label="活动名称" prop="company">
						<el-input  v-model="ruleForm.company" style="width:200px;"></el-input>
					</el-form-item>
					<el-form-item label="订单金额" prop="money">
						<el-input  v-model="ruleForm.money" style="width:200px;"></el-input>
					</el-form-item>
					<el-form-item label="付款状态" prop="state">
						<el-radio-group v-model="ruleForm.state">
							<el-radio v-for="(item,index) in staten" :key="item" :label="index" :value="index" v-show="index>0&&index<3">{{item}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="活动时间" prop="dt">
						<div class="fcj_ll">
							<div>
								<el-date-picker
								v-model="data1"
								type="datetimerange"
								value-format="yyyy-MM-dd HH:mm:ss"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期">
								</el-date-picker>
								
							</div>
							<!-- <div>
								<el-button type="success" style="margin-left:10px;" @click="addactime">添加</el-button>
							</div> -->
						</div>
						<!-- <div style="margin-top: 10px;">
							<el-checkbox-group v-model="checkList" >
								<el-checkbox label="上午" :disabled="data1[0]!=data1[1]"></el-checkbox>
								<el-checkbox label="下午" :disabled="data1[0]!=data1[1]"></el-checkbox>
								<el-checkbox label="晚上" :disabled="data1[0]!=data1[1]"></el-checkbox>
								<el-checkbox label="全天" :disabled="data1[0]!=data1[1]"></el-checkbox>
							</el-checkbox-group>
							
						</div> -->
						<!-- <div>
							<div class="fcj_ll" v-for="(item,index) in chdate" :key="index">
								<div>{{ item }}</div>
								<div><el-button size="mini" style="margin-left:10px;" @click="delchtime(index)">删除</el-button></div>
							</div>
						</div> -->
						<!-- <el-button @click="setdatae">选择</el-button>
						<div v-if="sedt.length>0" class="tag-group">
							<span v-if="sedata" style="margin-right: 10px;">{{abc}}</span><el-tag v-for="(item,index) in sedt" :key="item" closable @close="handleClose(index)" >{{item}}</el-tag>
						</div> -->
					</el-form-item>
					<el-form-item label="备注说明" prop="remark">
						<el-input type="textarea" v-model="ruleForm.remark" maxlength="255" show-word-limit></el-input>
					</el-form-item>
					
				</div>
				
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
					<el-button @click="resetForm('ruleForm')">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog :visible.sync="isshowordercale" width="980px" top="5vh">
			<div>
			<el-calendar v-model="calendardate" @change="calechange" value-format="yyyy-MM-dd">
				<template
					slot="dateCell"
					slot-scope="{date, data}">
					<div style="min-height: 60px;">
					<p>
					{{ parseInt(data.day.split('-')[2]) }} {{ data.isSelected ? '✔️' : ''}}
					</p>
					<div v-for="(item,index) in orders[data.day]" :key="'c'+index" class="c_a" @click="edit(item.index,item)">
						{{ item.company }}
					</div>
				</div>
				</template>
			</el-calendar>
			</div>
		</el-dialog>
		
		<el-dialog :visible.sync="isshowcale"  width="700px" append-to-body  top="10vh">
			<div>
			<el-calendar v-model="sedata" >
				<template
					slot="dateCell"
					slot-scope="{date, data}">
					<p @click="gets(data.day)">
					{{ parseInt(data.day.split('-')[2]) }} {{ data.isSelected ? '✔️' : ''}}
					</p>
				</template>
			</el-calendar>
			</div>
			
			<div style="text-align: center;margin-bottom:20px;" v-if="acrowid!==''&&areamap[tableData[acrowid].tp].mintime==2">
				<el-radio-group v-model="timemodel" @change="timemodelc">
					<el-radio name="tp" label="1">
						全天
					</el-radio>
					<el-radio name="tp" label="2">
						上午
					</el-radio>
					<el-radio name="tp" label="3">
						下午
					</el-radio>
				</el-radio-group>
			</div>
			<div style="padding:0 20px 0px 20px;" class="tag-group">
				<el-tag v-for="(item,index) in marks" :key="item.label" :type="st[item.st]" :class="[(item.selected==1?'selected':'')]" @click="cht(index,item)">{{item.label}}</el-tag>
			</div>
			<div style="padding:20px;text-align: center;">
				<el-button type="primary" @click="start(0)">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>

	export default {
		data() {
			return {
				checkList:[],
				chdate:[],
				data1:'',
				orders:{},
				isshowordercale:false,
				timemodel:'',
				isshowcale:false,
				st:['primary','success','info'],
				sedt: [],
				marks:[{label:1,st:0},{label:2,st:0}],
				sedata:'',
				calendardate:'',
				rname:'',	
				isshowadd:false,
				ruleForm:{
					'tp':'',
					'company':'',
					'contact':'',
					'office':'',
					'phone':'',
					'note':'',
					'purpose':'',
					'staten':'',
					'state':'',
					'money':'',
					'remark':'',
					'dt':[]
				},
				rules: {
					tp: [{
						required: true,
						message: '请输入选择场地',
						trigger: 'change'
					}],
					company: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}]
					
				},
				uploaddata: {
					pusern: '',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				tableData: [],
				host:'',
				pgno:1,
				pgcount: 0,
				pgsize: 50,
				staten:[],
				keywords:'',
				chstate:'',
				id:'',
				acrowid:'',
				tpid:'',
				thisday:'',
				area:[],
				areamap:{},
				searchtp:'',
				caledata:''
			}
		},
		mounted: function() {
			this.uploaddata.pusern=localStorage.getItem('pusern');
			this.searchtp=this.$route.query.tpid?this.$route.query.tpid:'';
			this.tpid=this.$route.query.tpid?this.$route.query.tpid:'';
			if(!isNaN(this.$route.query.state)){
				this.chstate=parseInt(this.$route.query.state);
			}
			var d=new Date;
			this.thisday=this.sedata=d.getFullYear()+'-'+('0'+(d.getMonth()+1)).slice(-2)+'-'+('0'+d.getDate()).slice(-2)
			this.getdata();
			this.getarea();
			this.host = this.$host
		},
		methods: {
			calechange(e){
				console.log(e)
			},	
			addactime(){
				this.chdate.push(this.data1);
			},
			delchtime(v){
				this.chdate.splice(v,1);
			},
			showorder(){
				if(this.calendardate==''){
					var a=new Date()
					var abc=a.getFullYear()+'-'+('0'+(a.getMonth()+1)).slice(-2)+'-'+a.getDate();
					this.calendardate=abc;
				}
				this.isshowordercale=true;
				this.$nextTick(() => {
					let prevBtn1 = document.querySelector(
						".el-calendar__button-group .el-button-group>button:nth-child(1)"
					);
					let prevBtn2 = document.querySelector(
						".el-calendar__button-group .el-button-group>button:nth-child(2)"
					);
					let prevBtn3 = document.querySelector(
						".el-calendar__button-group .el-button-group>button:nth-child(3)"
					);
					
					
					prevBtn1.addEventListener("click", e => {
						var a=new Date(this.calendardate);
						var abc=a.getFullYear()+'-'+('0'+(a.getMonth()+1)).slice(-2)+'-'+a.getDate();
						console.log(abc);
						this.getorderbydate(abc);
					});
					
					
					prevBtn2.addEventListener("click", e => {
						var a=new Date(this.calendardate);
						var abc=a.getFullYear()+'-'+('0'+(a.getMonth()+1)).slice(-2)+'-'+a.getDate();
						console.log(abc);
						this.getorderbydate(abc);
					});
					prevBtn3.addEventListener("click", e => {
						var a=new Date(this.calendardate);
						var abc=a.getFullYear()+'-'+('0'+(a.getMonth()+1)).slice(-2)+'-'+a.getDate();
						console.log(abc);
						this.getorderbydate(abc);
					});
					var a=new Date(this.calendardate);
					var abc=a.getFullYear()+'-'+('0'+(a.getMonth()+1)).slice(-2)+'-'+a.getDate();
					this.getorderbydate(abc)
				})
			},
			getorderbydate(v){
				this.$ajax({
					url: 'reservation/',
					data: {
						'do': 'list',
						'date':v,
						'pgsize':200
					},
				}).then(res => {
					
					this.caledata = res.data.res;
					
					var od={};
					var tmp='';
					for( var i=0;i<res.data.c;i++){
						for(var j=0;j<res.data.res[i].dt.length;j++){
							if(res.data.res[i].state!=3){
								res.data.res[i].index=i;
								tmp=res.data.res[i].dt[j].day;
								if(od[tmp]){
									od[tmp].push(res.data.res[i])
								}else{
									od[tmp]=[res.data.res[i]];
								}
							}
						}
						
					}
					this.orders=od;
				})
			},
			editc(id){
				// this.isshowadd=true;
				// this.acrowid=id;
				// this.id=row.id
				// this.sedata=this.tableData[this.acrowid].day
				// for(var i in this.ruleForm){
				// 	if(row[i]){
				// 		if(i=='state'){
				// 			this.ruleForm[i]=parseInt(row[i]);
				// 		}else{
				// 			this.ruleForm[i]=row[i];
				// 		}
						
				// 	}
				// }
				// this.sedt=[];
				// for(i=0;i<row.dt.length;i++){
				// 	this.sedt.push(row.dt[i].df)
				// }
			},
			timemodelc(v){
				this.sedt=[];
				for(var i=0;i<this.marks.length;i++){
					if(v==1||v==2&&i<this.marks.length/2||v==3&&i>=this.marks.length/2){
						this.marks[i].selected=1;
						this.sedt.push(this.marks[i].value);
					}else{
						this.marks[i].selected=0;
					}
				}
				
			},
			closefrom(){
				this.acrowid=''
				this.id=''
			},
			
			
			showadd(v) {
				this.acrowid='';
				this.isshowadd=true;
				this.ruleForm={
					'tp':this.tpid,
					'company':'',
					'contact':'',
					'office':'',
					'phone':'',
					'note':'',
					'purpose':'',
					'staten':'',
					'state':'',
					'money':'',
					'remark':'',
					'dt':[]
				}
			},
			resetForm(){
				this.$refs['ruleForm'].resetFields();
				this.isshowadd=false;
				this.acrowid=''
				this.id=''
			},
			submitForm(formName) {
			
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						var data={'do':'add','id':this.id,'bulu':1,'sedata':this.sedata,'chdate':this.data1,'time':this.checkList};
						this.sedt=this.sedt.sort(function(a,b){return a-b;});
						this.ruleForm.dt=this.sedt
						data.formdata = this.ruleForm;
						this.$ajax({
							url: 'reservation/',
							data: data,
						}).then(res => {
							if (res.error > 0) {
								this.$message({
									message: res.msg,
									type: 'warning'
								});
							} else {
								this.$message({
									message: '保存成功',
									type: 'success'
								});
								this.isshowadd=false
								if(this.isshowordercale){
									this.getorderbydate(this.sedata);
								}
								this.page(this.pgno);
								
							}
						
						})
						
						
						
					} else {
						return false;
					}
				});
			
			},
			handleDelete(index, row) {
				this.$ajax({
					url: 'reservation/',
					data: {
						'do': 'delreservation_order',
						'id': row.id
					},
				}).then(res => {
					if (res.error > 0) {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						this.page(this.pgno);
					}


				})
			},
			page(v) {
				this.pgno = v;
				this.getdata({
					pgno: v
				});
			},
			getdata(v) {
				if(v==1){
					this.down=1;
				}else{
					this.down=0;
				}
				if (typeof(v) != 'object' || v.pgno === undefined) {
					this.pgno = 1;
				}
				this.$ajax({
					responseType:this.down?'blob':'',
					url: 'reservation/',
					data: {
						'do': 'list',
						'pgno':this.pgno,
						'pgsize': this.pgsize,
						'keywords':this.keywords,
						'state':this.chstate,
						'tp':this.searchtp,
						'down':this.down
					},
				}).then(res => {
					if(this.down){
						this.down=0;
						let url=window.URL.createObjectURL(new Blob([res]));
						let link=document.createElement('a')
						link.download='场地预定.xls'
						link.href=url
						link.click()
						return
					}
					this.tableData = res.data.res;
					this.pgcount = parseInt(res.data.count);
					this.staten=res.data.staten
					if(this.ruleForm.tp){
						this.rname=res.data.tplist.rname;
					}
					
				})
			},
			edit(id,row){
				this.isshowadd=true;
				this.acrowid=id;
				this.id=row.id

				if(row.dt.length==1){
					var timerange=row.dt[0].timerange.split('-');
					this.data1=[
						row.dt[0].day+' '+timerange[0],
						row.dt[row.dt.length-1].day+' '+timerange[1]
					];
				}else if(row.dt.length>0){
					this.data1=[
						row.dt[0].day+' '+row.dt[0].timerange.split('-')[0],
						row.dt[row.dt.length-1].day+' '+row.dt[row.dt.length-1].timerange.split('-')[1]
					];
				}else{
					this.data1=[];
				}
				
				
				this.checkList=row.dt.length>0?row.dt[0].timerange.split(','):[];

				// if(this.isshowordercale){
				// 	this.sedata=this.caledata[this.acrowid].day
				// }else{
				// 	this.sedata=this.tableData[this.acrowid].day
				// }
				this.ruleForm={
					'tp':'',
					'company':'',
					'contact':'',
					'office':'',
					'phone':'',
					'note':'',
					'purpose':'',
					'staten':'',
					'state':'',
					'money':'',
					'remark':'',
					'dt':[]
				};

				for(var i in this.ruleForm){
					if(row[i]){
						if(i=='state'){
							this.ruleForm[i]=parseInt(row[i]);
						}else{
							this.ruleForm[i]=row[i];
						}
						
					}
				}
				this.sedt=[];
				for(i=0;i<row.dt.length;i++){
					this.sedt.push(row.dt[i].df)
				}
				// console.log(row.dt);
				// if(row.dt.length>0){
				// 	var j=0;
					
				// 	for(i=0;i<this.marks.length;i++){
						
				// 		if(row.dt[j]&&this.marks[i].value==row.dt[j].df){
				// 			this.marks[i].st=row.dt[j].state;
				// 			this.marks[i].selected=1;
				// 			j++;
				// 		}else{
				// 			this.marks[i].st=0;
				// 		}
				// 	}
				// }
			},
			
			handleClose(id){
				this.sedt.splice(id,1);
			},
			start(){
				console.log(this.sedata)
				var a=new Date(this.sedata);
				this.sedata=a.getFullYear()+'-'+('0'+(a.getMonth()+1)).slice(-2)+'-'+a.getDate()
				this.isshowcale=false;
			},
			setdatae(row){
				//调出日历
				this.isshowcale=true;
				
				var m=[];
				var tmp='';
				for(var i=8;i<20;i++){
					tmp=i<10?'0'+i:i;
					//tmp=i+':00';
					m.push({label:tmp+':00'+'~'+tmp+':30',st:0,value:tmp+':00',selected:0})
					m.push({label:tmp+':30'+'~'+('0'+(parseInt(tmp)+1)).slice(-2)+':00',st:0,value:tmp+':30',selected:0})
				}
				this.marks=m;
				this.acrow=row;
				this.sedata=this.tableData[this.acrowid].day||this.thisday
				this.gets(this.sedata);
			},
			cht(key,v){
				//选取非确认时间段
				if(this.acrowid!==''&&this.areamap[this.tableData[this.acrowid].tp].mintime==2){
					this.$message({
						message:'最少选择半天',
						type:'warning'
					})
				}else{
					var i=this.sedt.indexOf(v.value);
					if(i>=0){
						this.sedt.splice(i,1);
						v.selected=0;
					}else{
						this.sedt.push(v.value);
						v.selected=1;
					}
				}
			},
			getarea(){
				this.$ajax({
					url: 'reservation/',
					data: {
						'do': 'selectreservation'
					},
				}).then(res => {
					if(res.data.c>0){
						this.area = res.data.res;
						this.areamap={};
						for(var i=0;i<this.area.length;i++){
							this.areamap[this.area[i].id]=this.area[i];
						}
					}
				})
			},
			gets(v){
				//某一天已预定的时间段数据
				this.$ajax({
					url: 'reservation/',
					data: {
						'do': 'getselected',
						'date':v,
						'tp':this.tableData[this.acrowid].tp
					},
				}).then(res => {
					this.sedt=[];
					var real = res.data.res;
					//已选时间段回显
				
					var j=0;
					for(var i=0;i<this.marks.length;i++){
						
						if(res.data.c>0&&real[j]&&v+' '+this.marks[i].value==real[j].dt){
							this.marks[i].st=real[j].state;
							j++;
						}else{
							this.marks[i].st=0;
						}
					}
					
				})
			},
		},
	}
</script>
