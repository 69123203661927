<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right" v-if="breadcrumb.length>0">
				<div class="pagetitle">{{breadcrumb[breadcrumb.length-1].name}}</div>
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadcrumb" :key="index" v-show="index!=breadcrumb.length-1">{{item.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="panel">
			<div class="panel-body pad16">
				<div style="line-height: 150%;" class="rlist">
					<el-descriptions title="" :column="1" size="medium">
						<el-descriptions-item label="报修单号" label-class-name="des-label"
							content-class-name="des-content">
							{{acrepair.number}}
						</el-descriptions-item>
						<el-descriptions-item label="报修时间" label-class-name="des-label"
							content-class-name="des-content">
							{{acrepair.time}}
						</el-descriptions-item>
						<el-descriptions-item label="维修区域" label-class-name="des-label"
							content-class-name="des-content">
							{{acrepair.adr}}
						</el-descriptions-item>
						<el-descriptions-item label="报修联系人" label-class-name="des-label"
							content-class-name="des-content">
							{{acrepair.tname}}
						</el-descriptions-item>
						<el-descriptions-item label="联系人电话" label-class-name="des-label"
							content-class-name="des-content">
							{{acrepair.phone}}
						</el-descriptions-item>
						<el-descriptions-item label="报修来源" label-class-name="des-label"
							content-class-name="des-content">
							{{acrepair.source==1?'内部报修':'外部报修'}}
						</el-descriptions-item>
						<el-descriptions-item label="维修响应期望" label-class-name="des-label"
							content-class-name="des-content">
							{{acrepair.back==0?'不急':(acrepair.back==1?'有点急':'非常急')}}
						</el-descriptions-item>
						<el-descriptions-item label="报修分类" label-class-name="des-label"
							content-class-name="des-content">
							{{acrepair.tt}}

						</el-descriptions-item>
						<el-descriptions-item label="报修状态" label-class-name="des-label"
							content-class-name="des-content">
							{{state[acrepair.state]}}
						</el-descriptions-item>
						<el-descriptions-item label="故障描述" label-class-name="des-label"
							content-class-name="des-content">
							
							{{acrepair.note}}
							<div>
								<div style="margin:10px 0;" v-if="acrepair.file&&acrepair.file.length>0">
									<div class="audios" v-for="(iitem,aindex) in acrepair.file" :key="iitem.id" :style="'width:'+(50+iitem.time*5)+'px'"
										@click.stop="playa(aindex)">{{iitem.time}} " </div>
								</div>
							</div>
						</el-descriptions-item>
						
						<el-descriptions-item label="现场图片" label-class-name="des-label"
							content-class-name="des-content">
							
							<el-image v-for="(item) in acrepair.pics" :key="'d'+item.id"
								style="width: 150px; height: 150px;margin:0 5px 5px 0;border-radius: 5px;" :src="item"
								:preview-src-list="acrepair.pics">
							</el-image>
						</el-descriptions-item>
					</el-descriptions>



					<div style="border-bottom:2rpx solid #ddd;margin:20rpx;"></div>
					<div class="block">
						<el-form ref="repairform" :model="repairform" :rules="rules" label-width="140px"
							:disabled="repairform.state==0||repairform.state>2">


							<el-form-item label="收费金额">
								<el-input-number :controls="false" :step="1" :min="0" v-model="repairform.fee"
									style="width:100px"></el-input-number> 元
							</el-form-item>

							<el-form-item label="报修类型" prop="retp">
								<el-checkbox-group v-model="repairform.retp">
									<el-checkbox :label="index" name="type" v-for="(item,index) in retp" :key="index">
										{{item.tpname}}
									</el-checkbox>
								</el-checkbox-group>
							</el-form-item>
							<div v-for="(item,index) in repairform.retp" :key="'c'+index">
								<el-form-item :label="retp[item].tpname">
									<div class="fcj_ll">

										<el-select v-model="retp[item].stid" placeholder="请选择">
											<el-option v-for="(iitem,iindex) in retp[item].rename" :key="'e'+iindex"
												:label="iitem.tname" :value="iitem.stid">
											</el-option>
										</el-select>

									</div>

								</el-form-item>
							</div>

							<!-- 				<el-form-item label="报修状态">
					<el-select v-model="repairform.state" placeholder="请选择">
						<el-option v-for="(item,index) in state" :key="index" :label="item" :value="index">
						</el-option>
					</el-select>
				</el-form-item> -->

							<el-form-item label="维修备注">

								<el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="repairform.remark"
									show-word-limit :autosize="{ minRows: 5}" maxlength="255" style="width:600px;">
								</el-input>

							</el-form-item>

							<el-form-item>
							<div style="display:flex;justify-content:flex-start;margin:40px 0;">
								<div style="padding:20rpx;text-align:center;">
									<el-button type="primary" @click="subset(2)" icon="el-icon-document"
										v-if="repairform.state==1">报修受理</el-button>
									<el-button type="primary" @click="subset()" icon="el-icon-document"
										v-if="repairform.state==2">保存进度</el-button>
									<el-button type="primary" @click="subset(3)" icon="el-icon-document"
										v-if="repairform.state==2">维修完成</el-button>

									<el-popconfirm v-if="repairform.state>0&&repairform.state<=2"
										confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info"
										icon-color="red" title="确定要撤销码？" @confirm="subset(0)" style="margin:0 5px;">
										<el-button slot="reference">撤销</el-button>
									</el-popconfirm>



									<el-button @click="calset()" v-if="repairform.state>0&&repairform.state<=2">返回
									</el-button>

								</div>
							</div>
							</el-form-item>
						</el-form>
					</div>
					<div style="display:flex;justify-content: center;margin:40px 0;adding:20rpx;text-align:center;">
						<el-button @click="calset()" type="primary" v-if="repairform.state==0||repairform.state>2">返回
						</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				breadcrumb:[],
				id: '',
				repairtype: {},
				acrepair: {},
				state: [],
				retp: {},
				repairform: {
					paytype: 0,
					source: '',
					fee: '',
					retp: [],
					remark: '',
					state: ''
				},
				rules: {
					retp: [{
						required: true,
						message: '请选择报修类型',
						trigger: 'change'
					}],
				}
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=vm.$breadcrumb(vm.$root.myrouter,to)
			})
			
		},
		mounted() {
			this.breadcrumb=this.$root.myrouter
			this.host = this.$host
			this.id = this.$route.query.id;
			this.getdata();
		},
		methods: {
			calset() {
				this.$router.go(-1)
			},
			subset(v) {
				if (v == 0) {
					this.$ajax({
						url: 'repair/',
						data: {
							'do': 'cancel',
							'id': this.acrepair.id,
							'remark': this.repairform.remark
						},
					}).then(res => {
						if (res.error == 0) {
							this.$router.go(-1);
						}
						this.$message({
							message: res.msg,
							type: res.error == 0 ? 'success' : 'warning'
						});
					});
				} else {
					this.$refs['repairform'].validate((valid) => {
						if (valid) {
							var md = [];
							for (var i = 0; i < this.repairform.retp.length; i++) {
								if (this.retp[this.repairform.retp[i]].stid) {
									md.push([this.repairform.retp[i], this.retp[this.repairform.retp[i]].stid]);
								} else {
									this.$alert('请选择维修师傅')
									return;
								}
							}

							if (v == 2 || v == 3) {
								this.repairform.state = v
							}
							this.$ajax({
								url: 'repair/',
								data: {
									'do': 'update',
									'id': this.acrepair.id,
									'state': this.repairform.state,
									'source': this.repairform.source,
									'paytype': this.repairform.paytype,
									'fee': this.repairform.fee,
									'md': md,
									'remark': this.repairform.remark
								},
							}).then(res => {
								if (res.error == 0) {
									this.$router.go(-1);
								}
								this.$message({
									message: res.msg,
									type: res.error == 0 ? 'success' : 'warning'
								});
							});
						}
					})
				}
			},
			playa(b) {
				document.getElementById('myaudio').loop = false;
				document.getElementById('myaudio').src = this.$host + this.acrepair.file[b].path.fpath
				document.getElementById('myaudio').play();
			},
			getdata() {

				this.$ajax({
					url: 'repair/',
					data: {
						'do': 'info',
						'id': this.id
					},
				}).then(res => {


					// this.retp = res
					// this.repairform = JSON.parse(JSON.stringify(this.acrepair));
					// this.repairform.retp=[];
					// for (var i in res) {
					// 	if (res[i].repairid > 0) {
					// 		this.repairform.retp.push(res[i].tp)
					// 	}
					// }

					// this.isshow = true;
					// console.log(this.repairform.retp)
					// this.repairform.state = parseInt(this.repairform.state)


					this.state = res.state
					this.repairtype = res.repairtype
					this.retp = res.tp
					this.acrepair = res.re.res[0]

					this.repairform.paytype = this.acrepair.paytype
					this.repairform.source = this.acrepair.source
					this.repairform.fee = this.acrepair.fee
					this.repairform.state = this.acrepair.state
					this.repairform.remark = this.acrepair.remark


					var str = [];
					for (var i in res.tp) {
						if (res.tp[i].repairid > 0) {
							this.repairform.retp.push(res.tp[i].tp)
							str.push(res.tp[i].tpname)
						}
					}
					this.acrepair.tt = str.join('，');

					var a = [this.acrepair.build, this.acrepair.office, this.acrepair.other];
					a = a.join('，').replace(/^(，+)|(，+)$/g, '');
					this.acrepair.adr = a

					this.repairform.state = parseInt(this.repairform.state)
				})
			},

		}
	}
</script>

<style>

</style>
