<template>
	<div>
		<el-button @click="showchoffice"  :size="fsize[0]">选择房间</el-button>
		<el-dialog title="选择关联房间" :visible.sync="isshowchoffice" width="600px" :append-to-body="true">
			<div>
				<div style="border-radius:20rpx;">
					<div class="dialog-body" id="dialog-body"
						style="padding:0 40rpx ;overflow:visible;font-size:24rpx;">
						<el-form label-width="100px">
							<el-form-item label="所在楼栋" prop="note">
								<div class="fcj_ll">
									<el-select v-model="bdindex" placeholder="选择楼栋" @change="bdchange">
										<el-option v-for="(item,index) in building" :key="index" :label="item.bname"
											:value="index">
										</el-option>
									</el-select>
									<div v-if="bdindex!==''" style="margin-left:10px;">
										<el-button type="default"
											style="margin-left:10rpx;" @click="challbuilding">
											{{'全选'+building[bdindex].bname}}
										</el-button>
									</div>
								</div>
							</el-form-item>
							<el-form-item label="所在楼层" prop="note" v-if="bdindex!==''&&!(building[bdindex].ch)">
								<div class="fcj_ll">
									<el-select v-model="findex" placeholder="选择楼层" @change="flChange">
										<el-option v-for="(item,index) in floor" :key="index" :label="item.fname"
											:value="index">
										</el-option>
									</el-select>
									<div v-if="isshowchallfloor" style="margin-left:10px;">
										<el-button type="default"
											style="margin-left:10rpx;" @click="challfloor">
											{{'全选'+floor[findex].fname}}
										</el-button>
									</div>
								</div>
							</el-form-item>
							<el-form-item label="房间" v-if="office.c>0&&!building[bdindex].ch&&!building[bdindex].floor[findex].ch">
								
									<el-button v-for="(item,sindex) in office.res" :key="sindex"  plain  @click="choffice(sindex)" :type="item.state==1?'info':''" style='margin-bottom:10px;margin-left:0px;margin-right:10px;'>{{item.roomnumber}}</el-button>
								
							</el-form-item>
						</el-form>
		
						
		
						<div style="background-color: #f8f8f8;padding:5px;border-radius: 8px;margin-top:20px;">
							<div class="fcjtitle" style="margin:0px;padding:10px;">已选房间</div>
							<div style="padding:10px;background-color: #fff;padding:10px;border-radius: 8px;">
								<div class="wuye" v-if="building.length>0">
									<template v-for="(item,bindex) in building">
										<el-tag type="success" class="btn btn-xs btn-warning" closable @close="delbuilding(bindex)" :key="bindex"
											v-if="item.ch" >
											{{item.bname}}
										</el-tag>
										<template v-for="(iitem,iindex) in item.floor">
											<el-tag type="success" v-if="iitem.ch" :key="bindex+iindex" closable @close="delfloor(bindex,iindex)">
												{{item.bname+' '+iitem.fname+' '}}
											</el-tag>
										</template>
									</template>
		
									<el-tag type="success" v-for="(item,cindex) in offices" :key="item.id" closable @close="deloffice(cindex)">
										{{item.name}}
									</el-tag>
								</div>
							</div>
						</div>
		
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="cc" round>确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
	
		data() {
			return {
				isshowchoffice:false,
				banks: [],
				comtype: [],
				building: [],
				contracttype: [],
				mem: [],
				bdindex: '',
				floor: [],
				findex: '',
				office: [],
				offices: {}
			}
		},
		props: {
			fsize:[],
			mul:{//1:只能选一个
				type:String
			}
		},
		mounted: function() {
			this.ini()
		},
		methods: {
			handleClose(id) {
				this.$set(this.building[id], 'ch', false)
				//this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
			},
			cc: function() {
				var buildch=[];
				var floorch=[];
				var roomch=[];
				var propertyname=[];
				for(var i=0;i<this.building.length;i++){
					if(this.building[i].ch){
						propertyname.push(this.building[i].bname)
						buildch.push(this.building[i].id)
					}else{
						if(this.building[i].floor){
							for(var j=0;j<this.building[i].floor.length;j++){
								if(this.building[i].floor[j].ch){
									propertyname.push(this.building[i].bname+this.building[i].floor[j].fname)
									floorch.push([this.building[i].id,this.building[i].floor[j].floorid]);
								}
								
							}
						}
					}
				}
				for(i in this.offices){
					propertyname.push(this.offices[i].name)
					roomch.push(i);
				}

				
				this.isshowchoffice = false
				this.$emit("input", {
					'building':this.building,
					'offices':this.offices,
					'propertyname':propertyname.sort().join(','),
					'buildch':buildch,
					'floorch':floorch,
					'roomch':roomch
					});
				this.$emit("change");
			},
			deloffice: function(id) {
				var offices = this.offices;
				delete offices[id];
				this.offices = [];
				this.offices = offices;
			},
			delbuilding: function(id) {
				this.$set(this.building[id], 'ch', false)
			},
			delfloor: function(id, bid) {
				this.$set(this.building[id].floor[bid], 'ch', false);
			
			},
			challbuilding: function() {
				if(this.mul=='1'){
					this.clearall();
					this.$set(this.building[this.bdindex], 'ch', true);
				}else{
					if (!this.building[this.bdindex].ch) {
						for (var i in this.offices) {
							if (this.offices[i].buildingid == this.building[this.bdindex].id) {
								delete this.offices[i];
							}
						}
						for(i in this.building[this.bdindex].floor){
							if(this.building[this.bdindex].floor[i].ch){
								this.$set(this.building[this.bdindex].floor[i], 'ch', false);
							}
						}
						this.$set(this.building[this.bdindex], 'ch', true);
					}
				}
				//  else {
				// 	this.$set(this.building[this.bdindex], 'ch', false);
				// }
			
			},
			choffice(id) {
				if(this.mul=='1'){
					this.clearall();
				}
				var offices = this.offices;
				if (offices[this.office.res[id].id]) {
					delete offices[this.office.res[id].id];
				} else {
					offices[this.office.res[id].id] = {
						name: this.building[this.bdindex].bname + this.office.res[id].roomnumber,
						buildingid: this.building[this.bdindex].id,
						floorid: this.floor[this.findex].floorid
					}
				}
				this.offices = [];
				this.offices = offices;
				
			},
			challfloor: function() {
				if(this.mul=='1'){
					this.clearall();
					this.$set(this.building[this.bdindex].floor[this.findex], 'ch', true);
				}else{
					if (!this.building[this.bdindex].floor[this.findex].ch) {
						var offices = this.offices;
						for (var i = 0; i < this.office.c; i++) {
							delete offices[this.office.res[i].id];
						}
						this.offices = [];
						this.offices = offices
					}
					this.$set(this.building[this.bdindex].floor[this.findex], 'ch', true);
				}
				//this.$set(this.building[this.bdindex].floor[this.findex], 'ch', this.building[this.bdindex].floor[this.findex].ch ? false : true);
			
				//this.building[this.bdindex].floor[this.findex].ch = this.building[this.bdindex].floor[this.findex].ch ? false : true
			
			
			},
			bdchange(value) {
				this.bdindex = value
				this.floor = [];
				this.floor = this.building[value].floor
				this.isshowchallfloor = false;
				this.findex = ''
				this.office = []
			},
			flChange: function(value) {
				this.findex = value
			
			
				this.$ajax({
					url: 'building/',
					data: {
						'do': 'getoffice',
						'buildingid': this.building[this.bdindex].id,
						'floorid': this.floor[this.findex].floorid
					}
				}).then(res => {
			
					var tag = true;
					for (var i = 0; i < res.c; i++) {
						if (res.res[i].sstate == 1) {
							tag = false;
							break
						}
					}
					this.office = res
					this.isshowchallfloor = tag
				})
			},
			showchoffice() {
				this.isshowchoffice = true;
			},
			ini() {
				this.$ajax({
			
					url: 'contract/',
					data: {
						do: 'info'
					},
				}).then(res => {
			
					this.banks = res.banks
					this.comtype = res.comtype
					this.mem = res.mem
					this.building = res.building
					this.contracttype = res.contracttype
				})
			},
			clearall(){
				
				for (var i in this.offices) {
					//if (this.offices[i].buildingid == this.building[this.bdindex].id) {
						delete this.offices[i];
					//}
				}
				for( i in this.building){
					if(this.building[i].ch){
						this.$set(this.building[i], 'ch', false);
					}
					for(var j in this.building[i].floor){
						if(this.building[i].floor[j].ch){
							this.$set(this.building[i].floor[j], 'ch', false);
						}
						
					}
				}
				
			}
		}
	}
</script>

<style scoped>
	.wuye span {
		margin-right: 10px;
		margin-bottom: 10px;
		cursor: pointer;
	}
</style>
