<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<div class="pagetitle">{{breadcrumb[breadcrumb.length-1].name}}</div>
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadcrumb" :key="index" v-show="index!=breadcrumb.length-1">{{item.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="24">
					<div class="panel" style="height: calc(100vh - 120px);overflow-y: auto; ">
						<div class="panel-body">
							<div v-if="setshow">
								<div class="fcjtitle">显示参数设置（用户端）</div>
								<div class="formdiv">
									<el-form ref="form" :model="form" label-width="180px">

										<el-form-item label="楼栋图标显示内容">
											<el-radio-group v-model="form.showinfo">
												<el-radio label="楼栋名称"></el-radio>
												<el-radio label="房间数量"></el-radio>
												<el-radio label="无"></el-radio>
											</el-radio-group>
										</el-form-item>
										<el-form-item label="租金价格信息显示">
											<el-radio-group v-model="form.showprice">
												<el-radio label="显示"></el-radio>
												<el-radio label="隐藏"></el-radio>
											</el-radio-group>
										</el-form-item>
										<el-form-item label="物业价格信息显示">
											<el-radio-group v-model="form.showpropertyprice">
												<el-radio label="显示"></el-radio>
												<el-radio label="隐藏"></el-radio>
											</el-radio-group>
										</el-form-item>


										<el-form-item>
											<el-button type="primary" @click="onSubmit">保存</el-button>
										</el-form-item>
									</el-form>
								</div>
							</div>

							<div v-if="addbuildingshow">
								<div class="fcjtitle" style="padding:20px 40px;">新增楼栋标签</div>
								<div class="formdiv">
									<el-form ref="buildform" :model="buildform" :rules="rules" label-width="150px">

										<el-form-item label="楼栋名称" prop="bname">
											<el-input v-model="buildform.bname" style="width:200px;"></el-input>
										</el-form-item>
										<div class="fcj_ll">
											<div><el-form-item label="地面楼层数" prop="num">
											<el-input-number controls-position="right" :min="1" v-model="buildform.num" style="width:100px" :max="500" :disabled="buildform.id!=''?true:false" />
										</el-form-item></div>
											<div><el-form-item label="地下楼层数" prop="num2">
											<el-input-number controls-position="right" :min="0"  v-model="buildform.num2" style="width:100px" :max="30" :disabled="buildform.id!=''?true:false" />
										</el-form-item></div>
										</div>
										
										
										<el-form-item label="建筑用途" prop="purpose">
											<el-radio-group v-model="buildform.purpose">
												<el-radio name="purpose" v-for="(item,index) in buildingpurpose"
													:key="index" :label="item.fid">
													{{item.class_name}}
												</el-radio>
											</el-radio-group>
										</el-form-item>
										<el-form-item label="建筑类型" prop="type">
											<el-radio-group v-model="buildform.type">
												<el-radio name="type" v-for="(item,index) in buildingtype"
													:key="index" :label="item.fid">
													{{item.class_name}}
												</el-radio>
											</el-radio-group>
										</el-form-item>
										<el-form-item label="出租统计" prop="statistics">
											<el-radio-group v-model="buildform.statistics">
												<el-radio name="statistics" label="1">
													统计
												</el-radio>
												<el-radio name="statistics" label="0">
													不统计
												</el-radio>
											</el-radio-group>
										</el-form-item>
										<el-form-item label="楼栋外观图">
											<el-upload :action="host+'upimg/'" list-type="picture-card" ref="upload"
												name="upfile" :data="uploaddata" :on-success="uploadsuccess" :file-list="fileList"
												:auto-upload="false" :on-change="uploadchange">
												<i slot="default" class="el-icon-plus"></i>
												<div slot="file" slot-scope="{file}">
													<img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
													<span class="el-upload-list__item-actions">
														<span class="el-upload-list__item-preview"
															@click="handlePictureCardPreview(file)">
															<i class="el-icon-zoom-in"></i>
														</span>
														<span v-if="!disabled" class="el-upload-list__item-delete"
															@click="handleDownload(file)">
															<i class="el-icon-download"></i>
														</span>
														<span v-if="!disabled" class="el-upload-list__item-delete"
															@click="handleRemove(file)">
															<i class="el-icon-delete"></i>
														</span>
													</span>
												</div>

											</el-upload>
											<el-dialog :visible.sync="dialogVisible">
												<img width="100%" :src="dialogImageUrl" alt="">
											</el-dialog>
										</el-form-item>

										<el-form-item style="margin-top:50px;">
											
											<el-button type="primary" icon="el-icon-document"  @click="savebuild">确定
											</el-button>
											
											<el-button style="margin-left:10px;" @click="cancel">取消
											</el-button>
										</el-form-item>
									</el-form>
								</div>

							</div>
						</div>
					</div>
				
		</el-col>
		</el-row>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				breadcrumb:[],
				uploaddata: {
					pusern: '',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				visible:false,
				setshow: false,
				addbuildingshow: true,
				mainpic:'',
				form: {
					showinfo: '',
					showprice: '',
					showpropertyprice: ''
				},
				host: '',
				
				pics:[],

				buildform: {
					id:'',
					bname:'',
					num:1,
					num2:0,
					purpose:'',
					type:'',
					statistics:''
					
				},
				rules:{
					bname: [{
						required: true,
						message: '请输入楼栋名称',
						trigger: 'blur'
					}],
					num: [{
						required: true,
						message: '请输入地面楼层数',
						trigger: 'blur'
					}],
					num2: [{
						required: true,
						message: '请输入地下楼层数',
						trigger: 'blur'
					}],
					purpose: [{
						required: true,
						message: '请选择建筑用途',
						trigger: 'change'
					}],
					type: [{
						required: true,
						message: '请选择建筑类型',
						trigger: 'change'
					}],
					statistics: [{
						required: true,
						message: '请选择是否出租统计',
						trigger: 'change'
					}],
				},
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				fileList: [],
				floorpic: '',
				buildingtype:{},
				buildingpurpose:{},
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=vm.$breadcrumb(vm.$root.myrouter,to)
			})
			
		},
		mounted: function() {
			this.breadcrumb=this.$root.myrouter
			this.uploaddata.pusern=localStorage.getItem('pusern');
			this.title='添加客户'
			if (this.$route.query.id) {
				this.buildform.id = this.$route.query.id;
				this.title='编辑客户'
			}

			this.host = this.$host
			this.buildini();
			if(this.buildform.id){
				this.getdata();
			}

		},
		methods: {
			save(){
				if(this.buildform.id==''){
					this.$confirm('保存后楼层数不可更改，确定保存吗？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.sub();
					}).catch(() => {
						
					});
				}else{
					this.sub();
				}
			},
			cancel(){
				this.$router.go(-1);
			},
			handleRemove(file) {
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].uid == file.uid) {
						this.fileList.splice(i, 1);
						break;
					}
				}
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleDownload(file) {},
			uploadsuccess(response, file, fileList) {
				this.pics = [];
				this.fileList = fileList;
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].response == undefined) {
						break;
					} else {
						if(typeof(this.fileList[i].response)=='object'){
							this.pics.push(this.fileList[i].response.fpath)
						}else{
							this.pics.push(this.fileList[i].response)
						}
					}
				}
				if (i == this.fileList.length) {
					this.sub();
				}

				//this.buluform.pics.push(response);
			},
			uploadchange(file, fileList) {
				this.fileList = fileList;
			},


			savebuild() {
				this.$refs['buildform'].validate((valid) => {
					if (valid) {
						if (this.fileList.length == 0) {
							this.save();
						} else {
							this.pics = [];
							
							for (var i = 0; i < this.fileList.length; i++) {
								if (this.fileList[i].response == undefined) {
									break;
								} else {
									if(typeof(this.fileList[i].response)=='object'){
										this.pics.push(this.fileList[i].response.fpath)
									}else{
										this.pics.push(this.fileList[i].response)
									}
								}
							}
							if (i == this.fileList.length) {
								this.save();
							} else {
								this.$refs.upload.submit();
							}
						}
					}
				})
			},
			sub() {
				
				
				this.buildform.do='add';
				this.buildform.pics=this.pics;
				this.$ajax({
					url: 'building/',
					data: this.buildform,
				}).then(res => {
					if(this.buildform.id==''){
						sessionStorage.removeItem('buildingid')
					}
					this.$alert(res.msg, '提示', {
						confirmButtonText: '确定',
						callback: action => {
							this.$router.go(-1)
						}
					});
				})
			},




			buildini(){
				this.$ajax({
					url: 'building/',
					data: {
						'do': 'ini'
					},
				}).then(res => {
					this.buildingtype=res.data.buildingtype;
					this.buildingpurpose=res.data.buildingpurpose;
				})
			},
			getdata() {
				this.$ajax({
					url: 'building/',
					data: {
						'do': 'info',
						'id':this.buildform.id
					},
				}).then(res => {
					if(res.error==0){
						if (res.data.pics.length > 0) {
							for (var i = 0; i < res.data.pics.length; i++) {
								this.fileList.push({
									name: '',
									url: this.host + res.data.pics[i],
									response: res.data.pics[i]
								})
							}
						} else {
							this.fileList = [];
						}
						this.buildform={
							id:res.data.id,
							bname:res.data.bname,
							num:res.data.num,
							num2:res.data.num2,
							purpose:res.data.purpose,
							type:res.data.type,
							statistics:res.data.statistics
						}
					}else{
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					}
					
					
				})
			}
		}
	};
</script>


