<template>
	<div :style="{position:'absolute','z-index': '1000',left:position[0]+'px',top:position[1]+'px'}" ref="jcj">
		<slot></slot>
		<slot name="foot"></slot>
	</div>
</template>

<script>
	export default {

		data() {
			return {
				ac: false,
				abc: null,
				temp1: 0,
				temp2: 0,
				x: 0,
				y: 0,
			}
		},
		props: {
			whrange: [],
			position: [],
			scale:{
				type:Number,
				value:1
			}
		},
		mounted: function() {
			const that = this;
			this.addEvent(this.$refs.jcj, 'mousedown', function(e) {
				that.down(e, that.$refs.jcj);
			})
		},
		methods: {

			bcfun(v) {
				this.$emit("input", v);
			},
			addEvent(obj, type, fn, abc) {

				if (obj.attachEvent) {
					obj['e' + type + fn] = fn;
					obj[type + fn] = function() {
						obj['e' + type + fn](window.event).call(this, abc);
					}
					obj.attachEvent('on' + type, obj[type + fn]);
				} else {
					obj.addEventListener(type, function(e) {
						fn(e, abc)
					}, false);
				}
			},
			move: function(es, mv) {

				var e = arguments[0] || window.event;
				if (mv.ac) {

					mv.abc.style.position = "absolute";

					var l1 = (e.clientX + mv.temp1 - mv.x)/this.scale,
						t1 = (e.clientY + mv.temp2 - mv.y)/this.scale,
						wh = mv.whrange;
						
						
						
					if (wh[0] != undefined) {
						if (t1 < wh[0]) {
							t1 = wh[0];
						}
					}
					if (wh[1] != undefined) {
						if (l1 > wh[1]) {
							l1 = wh[1];
						}
					}
					if (wh[2] != undefined) {
						if (t1 > wh[2]) {
							t1 = wh[2];
						}
					}
					if (wh[3] != undefined) {
						if (l1 < wh[3]) {
							l1 = wh[3];
						}
					}

					mv.abc.style.left = l1 + "px";
					mv.abc.style.top = (t1 < 0 ? 0 : t1) + "px";

					// if(mv.bcfun!=undefined){
					// 	mv.bcfun([l1,t1]);
					// }

					return false;
				}

			},
			down: function(es, o, fun) {

				document.onselectstart = new Function("event.returnValue=false");
				document.body.setAttribute('style', '-moz-user-select: none;');
				var e = es || window.event;


				this.ac = true;
				this.abc = o;

				this.temp1 = this.abc.offsetLeft*this.scale,
				this.temp2 = this.abc.offsetTop*this.scale;
				this.x = e.clientX;
				this.y = e.clientY;

				//this.bcfun=fun;
				this.addEvent(document, 'mousemove', this.move, this);
				const that = this;
				document.onmouseup = function() {
					that.st();
				}

				return false;

			},
			st: function() {
				this.ac = false;
				if (this.temp1 != parseInt(this.abc.style.left) || this.temp2 != parseInt(this.abc.style.top)) {
					this.$emit("input", [parseInt(this.abc.style.left), parseInt(this.abc.style.top)]);
					this.$emit("change");
				}
				document.body.removeAttribute('style');
				document.onselectstart = null;
				document.onmouseup = null;
			}

		}
	};
</script>
