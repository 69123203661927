<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/users' }">账号管理</el-breadcrumb-item>
				<el-breadcrumb-item>编辑账号</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	
	<el-row :gutter="15">
		<el-col :span="24">
			<div class="panel">
				<div class="panel-header">
					<div class="charttitle">{{formname}}</div>
				</div>
				<div class="panel-body pad16">
					<div v-if="activeName=='first'">
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
							class="demo-ruleForm">
							<el-form-item label="用户ID" prop="uuid">
								<el-input v-model="ruleForm.uuid"></el-input>
							</el-form-item>
							<el-form-item label="用户姓名" prop="name">
								<el-input v-model="ruleForm.name"></el-input>
							</el-form-item>

							<el-form-item label="部门" prop="tp">
								<el-checkbox-group v-model="ruleForm.tp">
									<el-checkbox name="tp" v-for="(item,index) in department" :key="index"
										:label="index">
										{{item}}
									</el-checkbox>
								</el-checkbox-group>
							</el-form-item>

							<el-form-item label="角色" prop="groupid">
								<el-radio-group v-model="ruleForm.groupid">
									<el-radio name="tp" v-for="(item,index) in groups" :key="index"
										:label="item.groupsid">
										{{item.gname}}
									</el-radio>
								</el-radio-group>
							</el-form-item>

							<el-form-item>
								<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
								<el-button @click="resetForm('ruleForm')">重置</el-button>
							</el-form-item>
						</el-form>
					</div>
					<div v-else>
						<el-form :model="staffForm" :rules="staffrules" ref="staffForm" label-width="100px"
							class="demo-ruleForm">
							<el-form-item label="人员类别" prop="job">
								<el-radio-group v-model="staffForm.job">
									<el-radio name="tp" v-for="(item,index) in jobs" :key="index" :label="index">
										{{item}}
									</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="职能" prop="tp" v-if="staffForm.job==1">
								<el-checkbox-group v-model="staffForm.tp">
									<el-checkbox name="tp" v-for="(item,index) in tpini" :key="index" :label="index">
										{{item}}
									</el-checkbox>
								</el-checkbox-group>
							</el-form-item>
							<el-form-item label="用户ID" v-if="staffForm.job==0">
								<el-input v-model="staffForm.uuid"></el-input>
							</el-form-item>
							<el-form-item label="姓名" prop="tname">
								<el-input v-model="staffForm.tname"></el-input>
							</el-form-item>
							<el-form-item label="手机" prop="phone">
								<el-input v-model="staffForm.phone"></el-input>
							</el-form-item>




							<el-form-item>
								<el-button type="primary" @click="submitForm('staffForm')">保存</el-button>
								<el-button @click="showstaffadd">重置</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</el-col>
	</el-row>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeName: 'first',
				formname: '编辑管理员',
				tableData: [],
				staff: [],
				tpini: {},
				departmentid: '',
				chgroupid: '',
				keywords: '',
				jobs: ['招商人员', '维修人员'],
				department: ['招商', '物业', '财务', '管理层'],
				groups: [],
				width: 100,
				width1: 100,
				pgno: 1,
				pgcount: 0,
				staffForm: {
					uuid: '',
					tname: '',
					phone: '',
					job: [],
					tp: []
				},
				ruleForm: {
					uuid: '',
					name: '',
					groupid: '',
					tp: []
				},
				staffrules: {
					tname: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入手机',
						trigger: 'blur'
					}]
				},
				rules: {
					uuid: [{
						required: true,
						message: '请输入uid',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					groupid: [{
						required: true,
						message: '选择角色',
						trigger: 'blur'
					}]
				},
				groupkv: []
			}
		},
		mounted: function() {


			// window.onresize = function() {
			// 	var w = that.$refs.userlist.offsetWidth;
			// 	that.width = w - 420;
			// 	that.width1 = w - 330;
			// }
			this.activeName=this.$route.query.activeName;
				
			if(this.activeName=='showadd'){
				this.showadd();
			}
			this.host = this.$host
			this.$ajax({
				url: 'user/',
				data: {
					do: 'ini'
				},
			}).then(res => {
				this.tpini = res
				this.getdata();
			})

		},
		methods: {

			tableCellClassName(row, rowIndex) {
				row.index = rowIndex
			},

			tabclick(tab, event) {
				this.pgno = 1;
				if (tab.name == "second") {
					this.getstaff();
					this.formname = '编辑人员'
				} else {
					this.getdata();
					this.formname = '编辑管理员'
				}

			},
			showadd() {
				var f = {
					uuid: '',
					name: '',
					groupid: '',
					tp: []
				}
				this.formname = '新增管理员'
				this.ruleForm = f;
			},
			showstaffadd() {
				var f = {
					uuid: '',
					tname: '',
					phone: '',
					repid: '',
					job: [],
					tp: []
				}
				this.formname = '新增人员'
				this.staffForm = f;
			},
			gostaffinfo(row, column, event) {
				var f = {
					uuid: row.uuid,
					tname: row.tname,
					phone: row.phone,
					repid: row.id,
					job: parseInt(row.job),
					tp: row.tp.split(',')
				}
				this.staffForm = f;
			},
			goinfo(row, column, event) {
				var roles = [];
				if (row.roles.c > 0) {
					for (var i = 0; i < row.roles.c; i++) {
						roles.push(parseInt(row.roles.res[i].typeid))
					}
				}

				var f = {
					uuid: row.uuid,
					name: row.name,
					groupid: row.groupid,
					tp: roles
				}
				this.formname = '编辑管理员'
				this.ruleForm = f;
			},
			submitForm(formName) {
				if (formName == 'staffForm') {
					this.$refs[formName].validate((valid) => {
						if (valid) {
							const that = this;
							this.staffForm.do = 'addm';
							this.$ajax({
								url: 'user/',
								data: this.staffForm,
								fun(res) {
									if (res.error > 0) {console.log('abc')
										that.$message({
											message: res.msg,
											type: 'warning'
										});
									} else {
										that.$message({
											message: '保存成功',
											type: 'success'
										});
										// that.showstaffadd();
										// that.getstaff();
									}
								}
							})
						} else {
							return false;
						}
					});
				} else {
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.$ajax({
								url: 'user/',
								data: {
									'do': 'add',
									'uuid': this.ruleForm.uuid,
									'addname': this.ruleForm.name,
									'modi': 1,
									'tp': this.ruleForm.tp,
									'groupid': this.ruleForm.groupid
								}
							}).then(res => {
								if (res.error > 0) {
									this.$message({
										message: res.msg,
										type: 'warning'
									});
								} else {
									this.resetForm('ruleForm');
									this.$message({
										message: '保存成功',
										type: 'success'
									});
									this.getdata();

								}
							})
						} else {
							console.log('error submit!!');
							return false;
						}
					});
				}

			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			handleDelete(index, row) {
				this.$ajax({
					url: 'user/',
					data: {
						'do': 'del',
						'id': row.id
					}
				}).then(res => {

					this.getdata();

				})
			},
			delstaff(index, row) {
				this.$ajax({
					url: 'user/',
					data: {
						do: 'delm',
						id: row.id
					},
				}).then(res => {

					this.getstaff();

				})
			},
			getdata(v) {
				if (typeof(v) != 'object' || v.pgno === undefined) {
					this.pgno = 1;
				}
				this.$ajax({
					url: 'user/',
					data: {
						'do': 'list',
						'departmentid': this.departmentid,
						'keywords': this.keywords,
						'chgroupid': this.chgroupid,
						'pgno': this.pgno,
						'pgsize': 10
					},
				}).then(res => {
					// for (var i = 0; i < res.c; i++) {
					// 	res.res[i].visible = false
					// }
					this.tableData = res.res;
					this.pgcount = parseInt(res.count);
					var tmp = [];
					for (var i = 0; i < res.groups.c; i++) {
						tmp[res.groups.res[i].groupsid] = res.groups.res[i]
					}
					this.groupkv = tmp;
					this.groups = res.groups.res;


				})
			},
			page(v) {
				this.pgno = v;
				this.getdata({
					pgno: v
				});
			},
			getstaff(pgno) {
				if (pgno) {
					this.pgno = pgno;
				}
				this.$ajax({
					url: 'user/',
					data: {
						'do': 'liststaff',
						'pgno': this.pgno,
						'pgsize': 10
					},
				}).then(res => {

					this.pgcount = parseInt(res.count);
					this.staff = res.res;


				})
			}

		}
	};
</script>

<style>
	.user-logo {
		width: 36px;
		height: 36px;
		border-radius: 5px;
	}

	.el-tabs__nav-scroll {
		overflow: auto;
	}
</style>
