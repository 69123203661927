<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/contract' }">合同管理</el-breadcrumb-item>
				<el-breadcrumb-item>{{id==''?'新建合同':'编辑合同'}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="panel">
			<div class="panel-header">{{id==''?'新建合同':'编辑合同'}}</div>
			<div class="panel-body pad16">
				<el-form :model="info" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
					<el-form-item label="合同编号" prop="number">
						<el-input v-model="info.number" style="width:350px;"></el-input>
					</el-form-item>
					<el-form-item label="合同类型" prop="ctype">
						<el-radio-group v-model="info.ctype">
							<el-radio name="contracttype" v-for="(item,index) in contracttype" :key="index"
								:label="item.id" :value="item.id">{{item.name}}
							</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="租赁类型" prop="rent_type">
						<el-radio-group v-model="info.rent_type">
							<el-radio name="rent_type" v-for="(item,index) in rent_type" :key="index"
								:label="item.fid" :value="item.fid">{{item.class_name}}
							</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="签署合同类型" prop="createtype">
						<el-radio-group v-model="info.createtype" @change="createtypechange">
							<el-radio name="createtype" v-for="(item,index) in createtype" :key="index"
								:label="item.fid" :value="item.fid">{{item.class_name}}
							</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="缩/扩租面积" prop="createtype" v-show="xuqian">
						<el-input  v-model="info.dif_area" style="width: 200px;text-align: right;"><el-button type="primary" slot="append" >㎡</el-button></el-input>
					</el-form-item>


					<!-- <el-form-item label="原合同" prop="createtype" v-if="xuqian">
						<el-input v-model="info.fromContract" style="width:550px;" size="medium" :readonly="true" >
							<el-button type="primary" slot="append" @click="showcontract">请选择</el-button>
						</el-input>
					</el-form-item> -->
					
					<el-form-item label="单位名称" prop="company">
						<el-input v-model="info.company" style="width:350px;" size="medium" :readonly="true" >
							<el-button type="primary" slot="append" @click="showcustomer">请选择</el-button>
						</el-input>
					</el-form-item>


					<el-form-item label="单位联系人" prop="connecter">
						<el-input v-model="info.connecter" style="width:200px;"></el-input>
					</el-form-item>

					<el-form-item label="联系人电话" prop="phone">
						<el-input v-model="info.phone" style="width:200px;"></el-input>
					</el-form-item>
					<el-form-item label="租赁物业">
						<div class="fcj_ll">
							<div v-if="info.id==''||info.state<2"><property v-model="chproperty" :fsize="['small']" @change="ch"  /></div>
							<div style="padding-left:10px;" class="fcj_ll"><div>{{info.propertyname}}</div>
							<div v-if="(info.id=='')&&info.propertyname!=''" style="padding-left:20px;"><el-button size="mini" type="primary" @click="clearpropertyname">清除</el-button></div></div>
						</div>
					</el-form-item>

					<el-form-item label="其他地址" prop="address">
						<el-input v-model="info.address" style="width:350px;" :disabled="info.id!=''&&info.state>1&&copy==''"></el-input>
					</el-form-item>

					<el-form-item label="租赁期限" prop="registdate">
						<div class="fcj_ll">
							<div>
								<el-date-picker :disabled="info.id!=''&&info.state==3&&copy==''" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="info.registdate[0]" style="width: 160px;"
									@change="registdatechange" :picker-options="pickerOptionss">
								</el-date-picker>
							</div>
							<div style="margin:0 8px;">至</div>
							<div>
								<el-date-picker :disabled="info.id!=''&&info.state==3&&copy==''" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd"  v-model="info.registdate[1]" style="width: 160px;"
									@change="registdatechange" :picker-options="pickerOptionse">
								</el-date-picker>
							</div>
						</div>
						<!-- <el-date-picker :disabled="info.id!=''&&info.state!=1" v-model="info.registdate" type="daterange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" @change="registdatechange" style="width:350px">
						</el-date-picker> -->
					</el-form-item>
					<el-form-item label="免租期限" prop="freedate">
						<el-date-picker :disabled="info.id!=''&&info.state==3&&copy==''" v-model="info.freedate" type="daterange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd" :picker-options="pickerOptions" style="width:350px">
						</el-date-picker>
					</el-form-item>

					<el-form-item label="收费项目" prop="paytype">
						<el-checkbox-group :disabled="info.id!=''&&info.state==3&&copy==''" v-model="info.paytype">
							<el-checkbox name="paytype" v-for="(item,index) in paytype" :label="item.fid" :value="item.fid" :key="index" v-show="item.fid<100">{{item.class_name}}</el-checkbox>
						</el-checkbox-group>
					</el-form-item>

					<div style="border-top:1px solid #EDF0F5;margin:40px 0;"></div>


					<div>
						<div class="fcj_ll">
							<div><el-form-item label="租赁总面积（㎡）" prop="area">
									<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right" :controls="false" :step="0.01" :min="0" v-model="info.area"
										@blur="inputbuilduparea"></el-input-number>
								</el-form-item></div>
							<div><el-form-item label="租金单价（元/㎡/天）" prop="price" label-width="160px">
									<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right" :controls="false" :step="0.01" :min="0" v-model="info.price"
										@blur="inputbuilduparea"></el-input-number>
								</el-form-item></div>
							<div>
								<el-form-item label="物业单价（元/㎡/天）" prop="property" label-width="160px">
									<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right" :controls="false" :step="0.01" :min="0" v-model="info.property"
										@blur="inputbuilduparea"></el-input-number>
								</el-form-item>
							</div>
						</div>
						
						<div class="fcj_ll">
							<div>
								<el-form-item label="月租金(元)" prop="money">
									<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right" :controls="false" :step="0.01" :min="0" v-model="info.money"
										@blur="monthmoneyupdate"></el-input-number>
								</el-form-item>
							</div>
							<div class="fcj_ll">
								<div>
									<el-form-item label="付" prop="cycle" label-width="160px">
										<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right"  :step="1" :min="0" v-model="info.cycle" style="width:100px;"></el-input-number>
									</el-form-item>
								</div>
								<div>
									<el-form-item label="押" prop="depositmonth" label-width="30px">
										<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right"  :step="1" :min="0" v-model="info.depositmonth" style="width:100px;"
											@change="inputbuilduparea1"></el-input-number>
									</el-form-item>
								</div>
							</div>
							<div>
								<el-form-item label="租金押金(元)" prop="deposit" label-width="110px">
									<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right" :controls="false" :step="0.1" :min="0" v-model="info.deposit"></el-input-number>
								</el-form-item>
							</div>
						</div>
						
						<div class="fcj_ll">
							<div>
								<el-form-item label="月物业费(元)" prop="propertyfee">
									<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right" :controls="false" :step="0.1" :min="0" v-model="info.propertyfee"
										@blur="monthmoneyupdate"></el-input-number>
								</el-form-item>
							</div>
							<div class="fcj_ll">
								<div>
									<el-form-item label="付" prop="propertycycle" label-width="160px">
										<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right"  :step="1" :min="0" v-model="info.propertycycle" style="width:100px;"></el-input-number>
									</el-form-item>
								</div>
								<div>
									<el-form-item label="押" prop="propertydepositmonth" label-width="30px">
										<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right"  :step="1" :min="0" v-model="info.propertydepositmonth"
											@change="inputbuilduparea2" style="width:100px;"></el-input-number>
									</el-form-item>
								</div>
							</div>
							<div>
								<el-form-item label="物业押金(元)" prop="propertydeposit" label-width="110px">
									<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right" :controls="false" :step="0.1" :min="0" v-model="info.propertydeposit"></el-input-number>
								</el-form-item>
							</div>
						</div>

						<div class="fcj_ll">
							<div>
								<el-form-item label="一次性租金" prop="oncerent">
									<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right" :controls="false" :step="0.1" :min="0" v-model="info.oncerent"></el-input-number>
								</el-form-item>
							</div>
							<div>
								<el-form-item label="其他押金" prop="otherrent">
									<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right" :controls="false" :step="0.1" :min="0" v-model="info.otherrent"></el-input-number>
								</el-form-item>
							</div>
						</div>
						
						<div class="fcj_ll">
							<div>
								<el-form-item label="首期租金账单周期" prop="firstdate">
									<el-date-picker :disabled="info.id!=''&&info.state==3&&copy==''" v-model="info.firstdate" type="daterange" range-separator="至"
										start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" style="width:350px">
									</el-date-picker>
								</el-form-item>
							</div>
							<div>
								<el-form-item label="首期租金" prop="firstmoney">
									<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right" :controls="false" :step="0.1" :min="0" v-model="info.firstmoney"></el-input-number>
								</el-form-item>
							</div>
						</div>
						<div class="fcj_ll">
							<div>
								<el-form-item label="首期物业费账单周期" prop="firstpropertydate">
									<el-date-picker :disabled="info.id!=''&&info.state==3&&copy==''" v-model="info.firstpropertydate" type="daterange" range-separator="至"
										start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" style="width:350px">
									</el-date-picker>
								</el-form-item>
							</div>
							<div>
								<el-form-item label="首期物业费" prop="firstpropertyfee">
									<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right" :controls="false" :step="0.1" :min="0" v-model="info.firstpropertyfee"></el-input-number>
								</el-form-item>
							</div>
						</div>


						
						



						<el-form-item label="合同期内租金递增" prop="dizeng">
							<el-radio-group :disabled="info.id!=''&&info.state==3&&copy==''" v-model="info.dizeng" @change="dizengchange">
								<el-radio name="dizeng" label="1" value="1">有递增</el-radio>
								<el-radio name="dizeng" label="0" value="0">无递增</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="" v-if="info.dizeng==1">
						<div class="block" style="margin:0px;">
							<div class="tbtr" v-for="(item,index) in depositmorev" :key="index">
								<el-row>
									<el-col :span="7">
										<el-form-item label="递增后月租金">
											<el-input-number :disabled="info.id!=''&&info.state==3&&copy==''" controls-position="right" :controls="false" :step="0.1" :min="0" v-model="depositmorev[index]" />
										</el-form-item>
									</el-col>
									<el-col :span="8">
										<el-form-item label="开始生效日期">
											<el-date-picker :disabled="info.id!=''&&info.state==3&&copy==''" v-model="depositmoredate[index]" type="date"
												style="width:140px" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
											</el-date-picker>
										</el-form-item>
									</el-col>
									<el-col :span="3">
										<el-button :disabled="info.id!=''&&info.state==3&&copy==''" @click="deldep(index)" icon="el-icon-delete">删除</el-button>
									</el-col>
								</el-row>

							</div>
							<div style="margin-left:160px;">
								<el-button :disabled="info.id!=''&&info.state==3&&copy==''" @click="adddep" icon="el-icon-plus">添加</el-button>
							</div>
						</div>
						</el-form-item>




					</div>
					<el-form-item label="补充条款" prop="supplement">
						<el-input type="textarea" v-model="info.supplement" style="width:500px;" :autosize="{ minRows: 5}">
						</el-input>
					</el-form-item>


					<el-form-item label="条款触发日期" prop="descdate">
						<el-date-picker type="date" placeholder="选择日期" v-model="abc" style="width: 160px;"
							@change="descdatechange" :picker-options="pickerOptions">
						</el-date-picker>

						<div class="dlist">
							<el-tag  type="success" closable @close="desClose(index)"
								v-for="(item,index) in info.descdate" :key="index">{{item}}</el-tag>
						</div>
					</el-form-item>


					<div class="fcj_ll">
						<div>
							<el-form-item label="空调数量" prop="kongtiao">
								<el-input-number controls-position="right" :min="0" v-model="info.kongtiao"></el-input-number>
							</el-form-item>
						</div>
						<div>
							<el-form-item label="车位数量" prop="car" label-width="140px">
								<el-input-number controls-position="right" :min="0" v-model="info.car"></el-input-number>
							</el-form-item>
						</div>
					</div>

					<div class="fcj_ll">
						<div>
							<el-form-item label="经办业务员" prop="memindex">
								<el-select v-model="info.memindex" placeholder="经办业务员" style="width:180px;">
									<el-option v-for="(item,index) in mem" :label="item.tname" :value="index"
										:key="index">
										{{item.tname}}
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div>
							<el-form-item label="中介费用（元）" prop="agency" label-width="140px">
								<el-input-number controls-position="right" :controls="false" :step="0.1" :min="0" v-model="info.agency"></el-input-number>
							</el-form-item>
						</div>
					</div>
					<div class="fcj_ll">
						<div>
							<el-form-item label="收费人" prop="payerindex">
								<el-select v-model="info.payerindex" placeholder="收费人" style="width:180px;">
									<el-option v-for="(item,index) in mem2" :label="item.tname" :value="index"
										:key="index">
										{{item.tname}}
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</div>


					<el-form-item label="租金收款账号" prop="bankid">
						<el-select v-model="info.bankid" placeholder="请选择收款账号" style="width:500px;">
							<el-option v-for="(item,index) in banks" :label="item.des" :value="index" :key="index">
								{{item.des}}
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="物业收款账号" prop="propertybankid">
						<el-select v-model="info.propertybankid" placeholder="请选择收款账号" style="width:500px;">
							<el-option v-for="(item,index) in banks" :label="item.des" :value="index" :key="'p'+index">
								{{item.des}}
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="合同备注" prop="note">
						<el-input type="textarea" v-model="info.note" style="width:500px;" show-word-limit
							:autosize="{ minRows: 5}" ></el-input>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')" icon="el-icon-document">保存</el-button>
						<el-button type="warning" @click="ddsend('ruleForm')">发起钉钉审批</el-button>
						<el-button type="default" @click="cacel" >取消</el-button>

					</el-form-item>
				</el-form>
			</div>



		</div>

		<el-dialog :visible.sync="isshowcustomer">
			<div class="fcj_ll table-filter">
				<el-input placeholder="客户名称" size="small" clearable v-model="keywords" class="input-with-select" @input="getcustomer()">

					<el-button slot="append" icon="el-icon-search" @click="getcustomer()"></el-button>
				</el-input>
			</div>
			<div>
				<el-table :data="customers" @row-click="getTemplateRow" style="width:100%"
					header-cell-class-name="table-header" stripe>
					<el-table-column width="40">
						<template scope="scope">
							<el-radio :label="scope.row.id" v-model="info.customerid" :value="scope.row.id"
								@change.native="getTemplateRow(scope.row)">&nbsp;</el-radio>
						</template>
					</el-table-column>

					<el-table-column prop="cname" label="客户名称">
					</el-table-column>
					<el-table-column prop="contacts" label="联系人">
					</el-table-column>
					<el-table-column prop="phone" label="联系电话">
					</el-table-column>
				</el-table>
			</div>
			<div style="padding:15px;text-align: right;">
				<el-pagination background layout="prev, pager, next" :current-page.sync="pgno" :page-size="pgsize"
					@current-change="page" :total="pgcount">
				</el-pagination>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="isshowcontract">
			<div class="fcj_ll table-filter">
				<el-input placeholder="客户名称" size="small" clearable v-model="keywords" class="input-with-select" @input="getcontract()">

					<el-button slot="append" icon="el-icon-search" @click="getcontract()"></el-button>
				</el-input>
			</div>
			<div>
				<el-table :data="contracts" @row-click="getfromContractTemplateRow" style="width:100%"
					header-cell-class-name="table-header" stripe>
					<el-table-column width="40">
						<template scope="scope">
							<el-radio :label="scope.row.id" v-model="info.contractid" :value="scope.row.id"
								@change.native="getfromContractTemplateRow(scope.row)">&nbsp;</el-radio>
						</template>
					</el-table-column>

					<el-table-column prop="company" label="客户名称">
					</el-table-column>
					<el-table-column prop="number" label="合同编号">
					</el-table-column>
					<el-table-column prop="office" label="所租物业">
					</el-table-column>
				</el-table>
			</div>
			<div style="padding:15px;text-align: right;">
				<el-pagination background layout="prev, pager, next" :current-page.sync="pgno" :page-size="pgsize"
					@current-change="page" :total="pgcount">
				</el-pagination>
			</div>
		</el-dialog>


		<el-dialog :visible.sync="isshowddform" width="600px"  :close-on-click-modal="false">
			<form-create v-model="fApi" :rule="ddrule" :option="option" :value.sync="ddformdata">
			</form-create>
		</el-dialog>

		<el-dialog :visible.sync="isshowdduser" append-to-body>
			<div class="fcj_lc" style="align-items: flex-start;">
				<div class="utt">
					<div>部门列表</div>
					<div v-for="(item) in dddpt" :key="item.dptid" @click="dduser(item.dept_id)" style="cursor: pointer;">
						{{item.name}}
					</div>
				</div>
				<div class="utt">
					<div>人员列表</div>
					<div v-for="(item,index) in dduserlist" :key="item.unionid" @click="chdduser(index)" style="cursor: pointer;">
						{{item.name}}
					</div>
				</div>
				<div class="utt">
					<div>已选人员</div>
					<div v-if="ddusertype==0">
						<div v-for="(item,index) in sedduser" :key="item.userid" @click="chdduser(item.userid,index)" style="cursor: pointer;">
							{{item.name}}
						</div>
					</div>
					<div v-if="ddusertype==1">
						<div v-for="(item,index) in seddcc" :key="item.userid" @click="chdduser(item.userid,index)" style="cursor: pointer;">
							{{item.name}}
						</div>
					</div>
				</div>
			</div>

		</el-dialog>

	</div>
</template>
<script>

	import property from '../components/property.vue'
	export default {
		components: {
			property
		},
		data(vm) {
			return {
					//实例对象
					fApi: {},
					//表单数据
					ddformdata: {},
					//表单生成规则
					ddrule: [],
					//组件参数配置
					option: {
						//表单提交事件
						onSubmit: function (formData) {
							vm.addddform();
						}
					},
				ddpost:{},
				chproperty: {},
				abc: '',
				pgno: 1,
				pgcount: 0,
				pgsize:10,
				customers: [],
				keywords: '',
				isshowcustomer: false,
				isshowdduser:false,
				id: '',
				oldid: '',
				copy: '',
				setp: [],
				propertytypeid: '',
				propertytype: ['办公室', '商铺', '地下室'],
				contracttype: [],
				rent_type:[],
				createtype:[],
				cycle: ['付1', '付2', '付3', '付4', '付5', '付6', '付7', '付8', '付9', '付10', '付11', '付12', ],
				cycleindex: 2,
				depositmonth: ['押1', '押2', '押3', '押4', '押5', '押6'],
				depositmonthindex: 1,
				dizeng: true,
				depositmorev: [''],
				depositmoredate: [],
				formcheckres:0,
				pass:0,
				info: {
					number: '',
					ctype: '',
					rent_type:'',
					createtype:'',
					company: '',
					customerid: 0,
					connecter: '',
					phone: '',
					address: '',
					registdate: ['',''],
					freedate: [],
					paytype: [],
					area: undefined,
					price: undefined,
					property: undefined,
					money: undefined,
					cycle: undefined,
					depositmonth: undefined,
					deposit: undefined,
					isrentprint: '',
					propertyfee: undefined,
					propertycycle: undefined,
					propertydepositmonth: undefined,
					propertydeposit: undefined,
					oncerent: undefined,
					otherrent: undefined,
					firstmoney: undefined,
					firstpropertyfee: undefined,
					firstdate: [],
					firstpropertydate: [],
					dizeng: '',
					supplement: '',
					descdate: [],
					kongtiao: undefined,
					car: undefined,
					memindex: '',
					agency: undefined,
					bankid: '',
					propertybankid:'',
					note: '',
					depositmore: [],
					propertyname:'',
					choffice:'',
					dif_area: 0,
					id: ''
				},
				pickerOptions: {
					disabledDate: (time) => {
						return time.getTime() > new Date(this.info.registdate[1]+' 00:00:00').getTime() || time.getTime() < (new Date(this.info.registdate[0]+' 00:00:00')).getTime();
					}
				},
				pickerOptionss: {
					disabledDate: (time) => {
						return time.getTime() > new Date(this.info.registdate[1]+' 00:00:00').getTime() ;
					}
				},
				pickerOptionse: {
					disabledDate: (time) => {
						return time.getTime() < new Date(this.info.registdate[0]+' 00:00:00').getTime() ;
					}
				},
				banks: [],
				comtype: [],
				
				rules: {
					number: [{
						required: true,
						message: '请输入合同编号',
						trigger: 'blur'
					}],
					ctype: [{
						required: true,
						message: '请选择合同类型',
						trigger: 'change'
					}],
					rent_type: [{
						required: true,
						message: '请选择租赁类型',
						trigger: 'change'
					}],
					createtype:[{
						required: true,
						message: '请选择签署合同类型',
						trigger: 'change'
					}],
					company: [{
						required: true,
						message: '请选择租赁单位',
						trigger: 'change'
					}],
					// connecter: [{
					// 	required: true,
					// 	message: '请输入联系人',
					// 	trigger: 'blur'
					// }],
					// phone: [{
					// 	required: true,
					// 	message: '请输入联系电话',
					// 	trigger: 'blur'
					// }],

					paytype: [{
						type: 'array',
						required: true,
						message: '至少一项目收费项目',
						trigger: 'change'
					}],

					dizeng: [{
						required: true,
						message: '是否有递增',
						trigger: 'change'
					}],

					registdate: [{
						required: true,
						message: '请选择租赁期限',
						trigger: 'blur'
					}],

					memindex: [{
						required: true,
						message: '请选择经办业务员',
						trigger: 'change'
					}],
					payerindex: [{
						required: true,
						message: '请选择收费人',
						trigger: 'change'
					}],
					bankid: [{
						required: true,
						message: '请选择收款账号',
						trigger: 'change'
					}],

					propertybankid: [{
						required: true,
						message: '请选择收款账号',
						trigger: 'change'
					}]
					
				},
				mem: [],
				mem2: [],
				paytype:[],
				edit:false,
				dddpt:[],
				dduserlist:[],
				sedduser:[],
				loading:false,
				dingding:'',
				uploaddata:{
					pusern:localStorage.getItem('pusern'),
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				host:'',
				isshowddform:false,
				sedduserdiv:[],
				seddccdiv:[],
				seddcc:[],//钉钉抄送人列表
				ddusertype:0,//当前是选审批人还是抄送人1，审批人，1抄送人
				ddformattdata:[],//钉钉表单附件暂存
				ddformattdataview:[],
				schema:[],
				sysset:{},
				havecontract:0,//1合同已保存成功
				acsys:{},
				xuqian:false,
				isshowcontract:false,
				contracts:[]
			}
		},
		mounted: function() {
			if (this.$route.query.id != undefined) {
				this.id = this.$route.query.id;
				this.info.oldid = this.$route.query.id;
				
			}
			if (this.$route.query.renewal != undefined) {
				this.copy = this.$route.query.id;
			}
			if (this.id) {
				if(this.copy==undefined){
					this.edit=true;
				}
				this.getinfo();
			}else{
				this.ini();
			}
			
				
	
			this.host = this.$host
			this.inidduid();
		},
		methods: {
			createtypechange(e){
				console.log(e)
				for(var i=0;i<this.createtype.length;i++){
					if(this.createtype[i].fid==e){
						var name=this.createtype[i].class_name;
					}
				}
				console.log(name);
				var arr=['换房扩租','原地扩租','换房缩租','原地缩租'];
				if(arr.indexOf(name)>=0){
					this.xuqian=true;
				}else{
					this.info.dif_area=0;
					this.xuqian=false;
				}
			},
			upfile(){
				this.$ajax({
					url: 'ddapi/',
					data: {
						do: 'upfile'
					},
				}).then(res => {
					console.log(res);
				});
			},
			ddsend(formName){
				this.checkform(formName);
				if(this.formcheckres==1){
					var smap={};
					this.dingding=1;
					this.isshowddform=true;
					for (var i = 0 ;i<this.schema.length ; i++) {
						if(this.schema[i].props.label=='合同编号'){
							this.schema[i].props.value=this.info.number?this.info.number:''
							continue;
						}
						// if(this.schema[i].props.label=='合同名称'){
						// 	this.schema[i].props.value='新签';
						// 	continue;
						// }
						if(this.schema[i].props.label=='出租方'){
							this.schema[i].props.value=this.sysset.mycompany;
							continue;
						}

						if(this.schema[i].props.label=='租赁类型'){
							for(j=0;j<this.rent_type.length;j++){
								var rent_type_name=''
								if(this.rent_type[j].fid==this.info.rent_type){
									rent_type_name=this.rent_type[j].class_name
									break;
								}
							}
							this.schema[i].props.value=rent_type_name;
							continue;
						}
						if(this.schema[i].props.label=='签署合同类型'){
							for(j=0;j<this.createtype.length;j++){
								var createtype_name=''
								if(this.createtype[j].fid==this.info.createtype){
									createtype_name=this.createtype[j].class_name
									break;
								}
							}
							
							console.log(createtype_name)
							this.schema[i].props.value=createtype_name;
							continue;
						}

						if(this.schema[i].props.label=='承租方'){
							this.schema[i].props.value=this.info.company?this.info.company:'';
							continue;
						}
						if(this.schema[i].props.label=='房屋坐落'){
							this.schema[i].props.value=(this.info.propertyname?this.info.propertyname:'')+(this.info.address?this.info.address:'');
							continue;
						}
						if(this.schema[i].props.label=='租赁建筑面积（平方米）'){
							this.schema[i].props.value=this.info.area?this.info.area:'';
							continue;
						}
						// if(this.schema[i].props.label=='房屋情况'){
						// 	this.schema[i].props.value=this.info.;
						// 	continue;
						// }
						if(this.schema[i].props.label=='租赁期限'){
							this.schema[i].props.value=this.info.registdate?this.info.registdate[0]:'';
							continue;
						}
						
						if(this.schema[i].props.label=='至'){
							this.schema[i].props.value=this.info.registdate?this.info.registdate[1]:'';
							continue;
						}
						
						
						if(this.schema[i].props.label=='免租期'){
							if(this.info.freedate.length>0){
								this.schema[i].props.value=this.info.freedate[0];
							}else{
								this.schema[i].props.value='';
							}
							continue;
						}
						
						if(this.schema[i].props.label=='到'){
							if(this.info.freedate.length>0){
								this.schema[i].props.value=this.info.freedate[1];
							}else{
								this.schema[i].props.value='';
							}
							continue;
						}
						
						
						
						if(this.schema[i].props.label=='日租金'){
							this.schema[i].props.value=this.info.price;
							continue;
						}
						if(this.schema[i].props.label=='月租金'){
							this.schema[i].props.value=this.info.money;
							continue;
						}
						if(this.schema[i].props.label=="租赁押金"){
							this.schema[i].props.value=this.info.deposit;
							continue;
						}
						if(this.schema[i].props.label=='租金递增情况'){
							if(this.info.money){
								var st=[];'没有递增';
								if(this.info.dizeng==1){
									console.log(this.info.depositmore[0].date);
									for(var j=0;j<this.info.depositmore.length;j++){
										st.push(this.info.depositmore[j].date + '，'+this.info.depositmore[j].deposit+'元/月');
									}
								}else{
									st.push('没有递增');
								}
								this.schema[i].props.value=st.join("；\n");
							}else{
								this.schema[i].props.value='没有递增';
							}
							continue;
						}
						if(this.schema[i].props.label=='日物业管理费'){
							this.schema[i].props.value=this.info.property;
							continue;
						}
						if(this.schema[i].props.label=='月物业管理费'){
							this.schema[i].props.value=this.info.propertyfee;
							continue;
						}
						// if(this.schema[i].props.label=='日常管理费'){
						// 	this.schema[i].props.value=this.info.;
						// 	continue;
						// }
						if(this.schema[i].props.label=='物业押金'){
							this.schema[i].props.value=this.info.propertydeposit;
							continue;
						}
						if(this.schema[i].props.label=='支付方式'){
							var tmps=[];
							if(this.info.cycle&&this.info.depositmonth){
								tmps.push('租金：付'+this.info.cycle+'押'+this.info.depositmonth)
							}
							if(this.info.propertycycle&&this.info.propertydepositmonth){
								tmps.push('物业费：付'+this.info.propertycycle+'押'+this.info.propertydepositmonth)
							}
							if(tmps.length>0){
								this.schema[i].props.value=tmps.join('；');
							}else{
								this.schema[i].props.value='';
							}
							continue;
						}
						// if(this.schema[i].props.label=='公司注册情况'){
						// 	this.schema[i].props.value=this.info.;
						// 	continue;
						// }

						if(this.schema[i].props.label=='中介费'){
							this.schema[i].props.value=this.info.agency;
							continue;
						}
						if(this.schema[i].props.label=='其他情况说明'){
							this.schema[i].props.value=this.info.note+"\n\n"+(this.info.supplement?this.info.supplement:'');
							continue;
						}
					}
					for (i=0;i<this.schema.length;i++){
						smap[this.schema[i].props.id]=this.schema[i].props.value?this.schema[i].props.value:''
					}
					for(i=0;i<this.ddrule.length;i++){
						this.ddrule[i].value=smap[this.ddrule[i].field]
					}
				}
			},
			chdduser(v,s){	//添加，删除审批人
				var uname='';
				if(this.ddusertype==0){
					uname='sedduser';
				}else{
					uname='seddcc';
				}
				if(s!=undefined){
					
					this[uname].splice(s,1);
					this[uname+'div'].splice(s,1);
				}else{
					for(var i=0;i<this[uname].length;i++){
						if(this[uname][i].userid==this.dduserlist[v].userid){
							break;
						}
					}
					if(i==this[uname].length){
						this[uname+'div'].push({type:'el-tag',style:'margin-right:5px',children:[this.dduserlist[v].name]})
						this[uname].push(this.dduserlist[v]);
					}else{
						this.$alert('已选');
					}
				}
				localStorage.setItem('sedduser',JSON.stringify(this.sedduser))
				localStorage.setItem('sedduserdiv',JSON.stringify(this.sedduserdiv))
				localStorage.setItem('seddcc',JSON.stringify(this.seddcc))
				localStorage.setItem('seddccdiv',JSON.stringify(this.seddccdiv))
			},
			dduser(dptid){//钉钉用户列表

				const that = this;
				this.$ajax({
					url: 'ddapi/',
					data: {
						do: 'dingdinguser',
						'dpid':dptid,
						'all':1
					},
				}).then(res => {
					if(res.errcode==0){
						that.dduserlist=res.result.list
					}else{
						this.$alert(res.errmsg);
					}
				});
			},
			ddsp(v){//部门列表
				this.dduserlist=[];
				this.ddusertype=v;
				const that = this;
				this.formcheckres=0;
				this.isshowdduser=true;
				this.$ajax({
					url: 'ddapi/',
					data: {
						do: 'dingdinguser'
					},
				}).then(res => {
					if(res.errcode==0){
						that.dddpt=res.result
					}else{
						this.$alert(res.errmsg);
					}
				});
				
			},
			inidduid(){
				//通过手机号关联dd uid
				this.$ajax({
					url: 'ddapi/',
					data: {
						do: 'ddalluser'
					},
				}).then(res => {
					
				});
				//取审批表单
				const that=this;
				this.$ajax({
					url: 'ddapi/',
					data: {
						do: 'getschema'
					},
				}).then(res => {
					var j=0;
					var tmp=res.result.schemaContent.items;
					this.schema=tmp;
					if(localStorage.getItem('sedduser')){
						this.sedduser=JSON.parse(localStorage.getItem('sedduser'));
						this.sedduserdiv=JSON.parse(localStorage.getItem('sedduserdiv'));
					}
					if(localStorage.getItem('seddcc')){
						this.seddcc=JSON.parse(localStorage.getItem('seddcc'));
						this.seddccdiv=JSON.parse(localStorage.getItem('seddccdiv'));
					}
					for (var i = tmp.length - 1; i >= 0; i--) {
						if('TextNote'==tmp[i].componentName){
							this.schema.splice(i,1);
							continue;
						}
						
						if(tmp[i].componentName=="TextField"||"NumberField"==tmp[i].componentName||'MoneyField'==tmp[i].componentName){
							this.ddrule.unshift({
								type: 'input',
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								props:{
									placeholder:tmp[i].props.placeholder,
									readonly:(tmp[i].props.label=="租赁类型"||tmp[i].props.label=="签署合同类型")
								},
								validate:[{
									required:tmp[i].props.required?tmp[i].props.required:false,
									message:'请输入'+tmp[i].props.label
								}]
							})
							
						
						}else if("DDMultiSelectField"==tmp[i].componentName){
							for(j=0;j<tmp[i].props.options.length;j++){
								tmp[i].props.options[j]=JSON.parse(tmp[i].props.options[j]);
								tmp[i].props.options[j].label=tmp[i].props.options[j].value;

							}
							this.ddrule.unshift({
								type: "select",
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								options: tmp[i].props.options,
								validate:[{
									required:tmp[i].props.required?tmp[i].props.required:false,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									multiple: false
								}
							})
						}else if("DDSelectField"==tmp[i].componentName){
							for(j=0;j<tmp[i].props.options.length;j++){
								tmp[i].props.options[j]=JSON.parse(tmp[i].props.options[j]);
								tmp[i].props.options[j].label=tmp[i].props.options[j].value;

							}
							this.ddrule.unshift({
								type: "select",
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								options: tmp[i].props.options,
								validate:[{
									required:tmp[i].props.required?tmp[i].props.required:false,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									multiple: false
								}
							})
						}else if("DDDateField"==tmp[i].componentName){
							
							this.ddrule.unshift({
								type: "DatePicker",
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								validate:[{
									required:tmp[i].props.required?tmp[i].props.required:false,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									format: tmp[i].props.format,
								}
							})
						}else if("TextareaField"==tmp[i].componentName){
							this.ddrule.unshift({
								type: 'input',
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								validate:[{
									required:tmp[i].props.required?tmp[i].props.required:false,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									rows:5,
									type: "textarea",
									placeholder:tmp[i].props.placeholder
								},
							})
						}else if("DDAttachment"==tmp[i].componentName){
							var updata=JSON.parse(JSON.stringify(this.uploaddata));
							updata['noc']=tmp[i].props.id;

							this.ddrule.unshift({
								type: 'el-upload',
								field: tmp[i].props.id,
								title: tmp[i].props.label,
								validate:[{
									required:tmp[i].props.required?tmp[i].props.required:false,
									message:'请输入'+tmp[i].props.label
								}],
								props: {
									action:this.host+'upfile/',
									name:"upfile",
									data: updata,
									onSuccess:function (res, file ,filelist) {
										console.log(file);
										console.log(filelist);
										console.log(that.value);
										that.ddformdata[res.noc]=[]
										for(var i=0;i<filelist.length;i++){
											that.ddformdata[res.noc].push(filelist[i].response.fileid)
										}
										//file.url=res.fileid
										//that.ddformattdata.push(file);
									}
								},
								children:[{
									type: 'el-button',
									children:['上传附件']
								}]
							})

						}
						
					}
					this.ddrule.push({
						type:'div',
						props:{
							class:'el-col el-col-24 fcj_ll',
							style:'padding-bottom:13px;'
						},
						children:[{
							type:'div',
							props:{
								style:'width:125px;'
							}
						},{
							type:'div',
							children:[
								{
									type:'el-button',
									children:['选择审批人'],
									on:{
										click:()=>{
											this.ddsp(0);
										}
									}
								},
								{
									type:'div',
									style:'padding:10px 0',
									children:this.sedduserdiv
								}
							]
						}],
						
					})
					this.ddrule.push({
						type:'div',
						props:{
							class:'el-col el-col-24 fcj_ll',
							style:'padding-bottom:13px;'
						},
						children:[{
							type:'div',
							props:{
								style:'width:125px;'
							}
						},{
							type:'div',
							children:[
								{
									type:'el-button',
									children:['选择抄送人'],
									on:{
										click:()=>{
											this.ddsp(1);
										}
									}
								},
								{
									type:'div',
									style:'padding:10px 0',
									children:this.seddccdiv
								}
							]
						}],
						
					})
				});
				
			},
			upddfile(fid,lb){
				this.$ajax({
					url: 'ddapi/',
					data: {
						'do': 'upfile',
						'fileid': fid,

					}
				}).then(res => {
					if(this.ddformattdata[lb]){
						this.ddformattdata[lb].push({
							"spaceId": res.spaceId, 
							"fileName": res.fileName, 
							"fileSize": res.fileSize, 
							"fileType": res.fileExtension, 
							"fileId": res.fileId
						})
					}else{
						this.ddformattdata[lb]=[{
							"spaceId": res.spaceId, 
							"fileName": res.fileName, 
							"fileSize": res.fileSize, 
							"fileType": res.fileExtension, 
							"fileId": res.fileId
						}]
					}
					console.log(res)
				});
			},
			addddform(){
				if(this.sedduser.length==0){
					this.$message({
						message: '请选择审批人',
						type: 'warning'
					})
					return
				}else{
					var uu=[]
					for(var i=0;i<this.sedduser.length;i++){
						uu.push(this.sedduser[i].userid);
					}
				}
				var cc=[];
				if(this.seddcc.length>0){
					for(i=0;i<this.seddcc.length;i++){
						cc.push(this.seddcc[i].userid);
					}
				}
				this.ddpost.dduser=uu;
				this.ddpost.cc=cc;
				// for(var n in this.ddformattdata){
				// 	this.ddformdata[this.ddformattdata[n].response.noc].push(this.ddformattdata[n].response.fileid);
				// }
				var fd=[];
				for(i=0;i<this.schema.length;i++){
					fd[i]={"name":this.schema[i].props.label,"value":this.ddformdata[this.schema[i].props.id]?this.ddformdata[this.schema[i].props.id]:'',"tp":this.schema[i].componentName}
				}
				this.ddpost.formdata=fd;

				this.submitForm('ruleForm')
			},
			cacel(){
				this.$router.go(-1);
			},
			ch() {
				console.log(this.chproperty)
				this.info.propertyname=this.chproperty.propertyname
				this.info.choffice=this.chproperty
			},
			desClose(v) {
				this.info.descdate.splice(v, 1);
			},
			clearpropertyname(){
				this.info.office=''
				this.info.officeid=''
				this.info.choffice=''
				this.info.propertyname=''
			},
			descdatechange(v) {
				if (v) {
					var n = new Date(v);
					this.info.descdate.push(n.getFullYear() + '-' + (n.getMonth() + 1) + '-' + n.getDate());
					this.info.descdate.sort();
				}
			},
			registdatechange(e) {
				
				this.info.freedate = [];
				this.info.firstdate = [];
				this.info.firstpropertydate = [];
				this.info.descdate = [];
			},
			getTemplateRow(row) { //获取选中数据
				this.info.company = row.cname;
				this.info.connecter = row.contacts
				this.info.customerid = row.id
				this.info.phone = row.phone
				this.isshowcustomer = false;
			},
			getfromContractTemplateRow(row) { //获取选中数据
				this.info.fromContract = row.company+row.regist_time+'到'+row.term;
				this.info.copy = row.id
				this.isshowcontract = false;
			},
			page(v) {
				this.pgno = v;
				this.getcustomer({
					pgno: v
				});
			},
			getcustomer(v) {
				if (typeof(v) != 'object' || v.pgno === undefined) {
					this.pgno = 1;
				}
				this.$ajax({
					url: 'customer/',
					data: {
						'do': 'list',
						'pgno': this.pgno,
						'pgsize':this.pgsize,
						'searchkey': {
							'cname': this.keywords
						}
					}
				}).then(res => {
					if (res.c > 0) {
						this.customers = res.res
					} else {
						this.customers = [];
					}
					this.pgcount=parseInt(res.count)
				});
			},
			getcontract(v){
				if (typeof(v) != 'object' || v.pgno === undefined) {
					this.pgno = 1;
				}
				this.$ajax({
					url: 'contract/',
					data: {
						'do': 'list',
						'pgno': this.pgno,
						'pgsize':this.pgsize,
						'company':this.keywords,
						'searchkey': {
							'cname': this.keywords
						}
					}
				}).then(res => {
					if (res.c > 0) {
						this.contracts = res.res
					} else {
						this.contracts = [];
					}
					this.pgcount=parseInt(res.count)
				});
			},
			showcustomer() {
				this.isshowcustomer = true;
				this.getcustomer();
			},
			showcontract(){
				this.isshowcontract = true;
				this.getcontract();
			},

			//递增设置
			adddep: function() {
				var depositmore = this.depositmorev;
				depositmore[depositmore.length] = ''
				this.depositmorev = [];
				this.depositmorev = depositmore;
			},
			deldep(v) {
				if(this.depositmorev.length==1){
					return;
				}else{
					this.depositmorev.splice(v, 1);
					this.depositmoredate.splice(v, 1);
				}
			},
			dizengchange(v) {},

			//更新月租金
			inputbuilduparea: function() {
				if(!this.edit){
					var money = '';
					if (this.info.price && this.info.area) {
						money = this.info.area.mul(parseFloat(this.info.price).mul(365).div(12)).toFixed(2);
					}
					var f = '';
					if (this.info.property && this.info.area) {
						f = this.info.area.mul(parseFloat(this.info.property).mul(365).div(12)).toFixed(2);
					}
					if (money != '') {
						this.$set(this.info, 'money', money);
					}
					if (f != '') {
						this.$set(this.info, 'propertyfee', f);
					}

					if (this.info.money && this.info.depositmonth) {
						this.$set(this.info, 'deposit', this.info.depositmonth.mul(this.info.money).toFixed(2));
					}
					if (this.info.propertyfee && this.info.propertydepositmonth) {
						this.$set(this.info, 'propertydeposit', this.info.propertydepositmonth.mul(this.info.propertyfee)
							.toFixed(2));
					}
				}
			},
			inputbuilduparea1: function() {
				if (this.info.money && this.info.depositmonth) {
					this.$set(this.info, 'deposit', this.info.depositmonth.mul(this.info.money).toFixed(2));
				}
				
			},
			inputbuilduparea2: function() {
				if (this.info.propertyfee && this.info.propertydepositmonth) {
					this.$set(this.info, 'propertydeposit', this.info.propertydepositmonth.mul(this.info.propertyfee)
						.toFixed(2));
				}
			},
			
			monthmoneyupdate() {
				//if(!this.edit){console.log('abc')
					if (this.info.money && this.info.depositmonth) {
						this.$set(this.info, 'deposit', this.info.depositmonth.mul(this.info.money).toFixed(2));
					}
					if (this.info.propertyfee && this.info.propertydepositmonth) {
						this.$set(this.info, 'propertydeposit', this.info.propertydepositmonth.mul(this.info.propertyfee)
							.toFixed(2));
					}
				//}
			},


			getinfo() {
				this.$ajax({
					url: 'contract/',
					data: {
						do: 'info',
						id: this.id ,
						notall: 1
					},
				}).then(res => {
					var dd = {
						"info": res.res[0],
						banks: res.banks,
						building: res.building,
						comtype: res.comtype,
						mem: res.mem,
						mem2: res.mem2,
						contracttype : res.contracttype,
						rent_type:res.rent_type,
						createtype:res.createtype,

						paytype:res.paytype,
						fromContract:res.fromContract,
						sup:res.sup,
						acsys:res.acsys
					};

					//delete dd.info.id;
					//delete dd.info.office;
					
					if(dd.sup.c>0){
						dd.info.supplement=dd.sup.res[0].des
						dd.info.descdate=[];
						for (var i = 0; i < dd.sup.c; i++) {
							dd.info.descdate.push(dd.sup.res[i].dt)
						}
					}else{
						dd.info.descdate=[];
					}
					
					dd.info.depositmore=JSON.parse(dd.info.dizeng);
					if(dd.info.depositmore[0].deposit!=''){
						dd.depositmorev=[];
						dd.depositmoredate=[];
						dd.info.dizeng='1'
						for(i=0;i<dd.info.depositmore.length;i++){
							dd.depositmorev.push(dd.info.depositmore[i].deposit)
							dd.depositmoredate.push(dd.info.depositmore[i].date)
						}
					}else{
						dd.info.dizeng = '0';
					}
					
					if (res.res[0].personalid > 0) {
						for (i = 0; i < dd.mem.length; i++) {
							if (dd.mem[i].uid == res.res[0].personalid) {
								dd.info.memindex = i;
								break;
							}
						}
					} else {
						dd.info.memindex = '';
					}
					if (res.res[0].payerid > 0) {
						for (i = 0; i < dd.mem2.length; i++) {
							if (dd.mem2[i].uid == res.res[0].payerid) {
								dd.info.payerindex = i;
								break;
							}
						}
					} else {
						dd.info.payerindex = '';
					}
					dd.info.bankid = parseInt(dd.info.bankid);
					dd.info.propertybankid = parseInt(dd.info.propertybankid);
					dd.info.registdate=[res.res[0].regist_time,res.res[0].term];
					
					dd.info.freedate=[res.res[0].freestart,res.res[0].freeterm];

					dd.contracttype = res.contracttype

					dd.info.area = res.res[0].area / 100;
					dd.info.dif_area = res.res[0].dif_area.div(100);
					dd.info.price = res.res[0].price / 100;
					
					dd.info.property = res.res[0].property / 100;
					dd.info.propertyfee = res.res[0].propertyfee / 100;
					dd.info.deposit = res.res[0].deposit / 100;
					
					dd.info.otherrent = res.res[0].otherrent / 100;
					dd.info.oncerent = res.res[0].oncerent / 100;
					dd.info.firstpropertyfee = res.res[0].firstpropertyfee / 100;
					dd.info.firstmoney = res.res[0].firstmoney / 100;
					dd.info.money=res.res[0].money / 100;
					dd.info.propertyname=res.res[0].office;
					dd.info.propertydeposit = res.res[0].propertydeposit / 100;console.log('propertydeposit0000000')
					dd.info.choffice='';
					if(this.copy){
						for (let index = 0; index < dd.createtype.length; index++) {
							if('续签' == dd.createtype[index].class_name){
								dd.info.createtype=dd.createtype[index].fid
								break;
							}
							
						}
						dd.info.copy=this.copy;
					}else if(this.id){
						dd.info.copy=dd.info.fromid
					}
					
					if(!this.copy){
						for (let index = 0; index < dd.createtype.length; index++) {
							if(dd.info.createtype == dd.createtype[index].fid){
								var name=dd.createtype[index].class_name
								break;
							}
							
						}
						var arr=['换房扩租','原地扩租','换房缩租','原地缩租'];
						if(arr.indexOf(name)>=0){
							this.xuqian=true;
							//dd.info.fromContract=dd.fromContract.company+dd.fromContract.regist_time+'到'+dd.fromContract.term;
						}
					}else{
						this.xuqian=true;
						//dd.info.fromContract=dd.info.company+dd.info.regist_time+'到'+dd.info.term;
					}
					for (var na in dd) {
						this[na] = dd[na]
					}

					this.sysset=res.pare;
					const that=this
					setTimeout(function(){that.edit=false;},500);

				})
			},
			ini() {
				this.$ajax({

					url: 'contract/',
					data: {
						do: 'info'
					},
				}).then(res => {
					this.acsys=res.acsys;
					this.banks = res.banks
					this.comtype = res.comtype
					this.mem = res.mem
					this.mem2 = res.mem2
					this.building = res.building
					this.contracttype = res.contracttype
					this.rent_type=res.rent_type
					this.createtype=res.createtype
					
					this.paytype=res.paytype;
					this.sysset=res.pare;

				})
			},
			checkform(formName){
				return this.$refs[formName].validate((valid) => {
					if (valid) {

						if (this.info.memindex !== '') {
							this.info.personal = this.mem[this.info.memindex].tname;
							this.info.personalid = this.mem[this.info.memindex].uid
						}
						if (this.info.payerindex !== '') {
							this.info.payer = this.mem2[this.info.payerindex].tname;
							this.info.payerid = this.mem2[this.info.payerindex].uid
						}
						
						
						if (this.info.propertyname=='') {
							if (this.info.address == '') {
								this.$alert('请选择或输入租赁物业', {
									confirmButtonText: '确定'
								});
								return false;
							}
						}

						this.info.depositmore = []
						for (var i = 0; i < this.depositmorev.length; i++) {
							this.info.depositmore.push({
								deposit: this.depositmorev[i],
								date: this.depositmoredate[i]
							})
						}


						if (this.info.dizeng == 1) {
							for (i = 0; i < this.info.depositmore.length; i++) {
								if (this.info.depositmore[i].deposit == '') {

									this.$alert('请输入正确的递增租金和生效日期', {
										confirmButtonText: '确定'
									});

									return false;
								}
							}
						}
						for(i=this.info.paytype.length-1;i>=0;i--){
							if(this.info.paytype[i]==''){
								this.info.paytype.splice(i,1);
							}
						}
						if(this.info.paytype.length==0){
							this.$alert('请选择收费项目', {
								confirmButtonText: '确定'
							});
						}
						
						if(this.info.paytype.indexOf('87')>=0){//分期租金
							if(!this.info.area){
								this.$message({
									message: '“分期租金” 请输入 “租赁面积”',
									type: 'warning'
								})
								return false;
							}
							// if(!this.info.price){
							// 	this.$message({
							// 		message: '“分期租金” 请输入 “单价”',
							// 		type: 'warning'
							// 	})
							// 	return false;
							// }
							if(!this.info.money){
								this.$message({
									message: '“分期租金” 请输入 “月租金”',
									type: 'warning'
								})
								return false;
							}
							if(!this.info.cycle){
								this.$message({
									message: '“分期租金” 请输入 “付款周期”',
									type: 'warning'
								})
								return false;
							}
							if(!this.info.firstmoney){
								this.$message({
									message: '“分期租金” 请输入 “首期租金”',
									type: 'warning'
								})
								return false;
							}
							if(this.info.firstdate.length==0){
								this.$message({
									message: '“分期租金” 请输入 “首期租金账单周期”',
									type: 'warning'
								})
								return false;
							}
							
						}
						
						if(this.info.paytype.indexOf('88')>=0){//租金押金
							if(!this.info.deposit){
								this.$message({
									message: '请输入 “租金押金”',
									type: 'warning'
								})
								return false;
							}
							
						}
						
						if(this.info.paytype.indexOf('89')>=0){//分期物业费
							if(!this.info.area){
								this.$message({
									message: '“分期物业费” 请输入 “租赁面积”',
									type: 'warning'
								})
								return false;
							}
							if(!this.info.property){
								this.$message({
									message: '“分期物业费” 请输入 “物业费单价”',
									type: 'warning'
								})
								return false;
							}
							if(!this.info.propertyfee){
								this.$message({
									message: '“分期物业费” 请输入 “月物业费”',
									type: 'warning'
								})
								return false;
							}
							if(!this.info.propertycycle){
								this.$message({
									message: '“分期物业费” 请输入 “付款周期”',
									type: 'warning'
								})
								return false;
							}
							if(!this.info.firstpropertyfee){
								this.$message({
									message: '“分期物业费” 请输入 “首期物业费”',
									type: 'warning'
								})
								return false;
							}
							if(this.info.firstpropertydate.length==0){
								this.$message({
									message: '“分期物业费” 请输入 “首期物业费账单周期”',
									type: 'warning'
								})
								return false;
							}
							
						}
						
						if(this.info.paytype.indexOf('90')>=0){//物业押金
							if(!this.info.propertydeposit){
								this.$message({
									message: '请输入 “物业押金”',
									type: 'warning'
								})
								return false;
							}
							
						}
						
						if(this.info.paytype.indexOf('92')>=0){//一次性费用
							if(!this.info.oncerent){
								this.$message({
									message: '请输入 “一次性费用”',
									type: 'warning'
								})
								return false;
							}
							
						}
						
						if(this.info.paytype.indexOf('93')>=0){//其他押金
							if(!this.info.otherrent){
								this.$message({
									message: '请输入 “其他押金”',
									type: 'warning'
								})
								return false;
							}
							
						}
						this.formcheckres=1;
						return true;
					}else{
						return false
					}
				})
			},
			submitForm(formName) {

				this.checkform(formName)
				if(this.formcheckres==1){
					this.formcheckres=0;
					this.info.do = 'add';
					this.loading = this.$loading({
						lock: true,
						text: 'Loading',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					this.info.pass=this.pass;
					if(this.copy){//新合同，续租
						this.info.id='';
					}
					this.$ajax({
						url: 'contract/',
						data: this.info
					}).then(res => {
						if(res.error=='88'){
							this.loading.close();
							this.$confirm(res.msg,'提示').then(() => {
								this.pass=1;
								this.submitForm('ruleForm');
							}).catch(()=>{
								this.pass=0;
							})
							
							return;
						}
						this.havecontract=1;
						if(this.dingding==1){
							this.ddpost.do='add'
							this.ddpost.approval_tpid=1;
							this.ddpost.contractid=res.data.contractid;
							//提交钉钉审批
							this.$ajax({
								url: 'ddapi/',
								data: this.ddpost
							}).then(res => {
								this.dingding='';
								this.loading.close();
								if(res.error==0){
									this.$message({
										message: res.msg,
										type: 'success'
									})
									this.$router.go(-1)
								}else{
									this.$alert("提交钉钉审批失败\r\n"+(res.data.errmsg||res.msg))
								}
								
								this.loading.close();
								
								
								


							});
						}else{
							this.$message({
								message: res.msg,
								type: res.error==0?'success':'warning'
							})
							this.loading.close();
							if(res.error==0){
								this.$router.go(-1)
							}
						}
						
					});

				} else {
					return false;
				}
				
			}
		}
	}
</script>

<style scoped>

	.utt{
		padding:0 20px 20px;
	}
	.utt>div:first-child{
		margin:0px -10px 20px -10px;
		padding:10px 20px;
		background-color: #efefef;
		border-radius: 4px;
	}
	.el-form-item {
		margin-bottom: 14px;
	}

	.el-row {
		margin-bottom: 0px;
	}


	
</style>
