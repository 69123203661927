<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>
					<div class="pagetitle">账号管理</div>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<el-row :gutter="15">
			<el-col :span="24">

				<div class="panel">
					<div class="panel-body">
						<div></div>

						<el-tabs v-model="activeName" type="card" @tab-click="tabclick" style="margin-top:20px;">
							<el-tab-pane label="管理员" name="first">

								<div class="panel-body pad16" style="border-bottom: 1px solid #EBEEF5;padding-top:0px;">
									<div class="fcj_lr table-filter">
										<div class="fcj_ll">
											<!-- <el-select v-model="departmentid" slot="prepend" size="small" clearable placeholder="部门"
											@change="getdata" style="margin-right:10px;">
											<el-option label="所有部门" value=""></el-option>
											<el-option v-for="(item,index) in department" :key="item.value"
												:label="item" :value="index">
											</el-option>
										</el-select> -->
											<el-select v-model="chgroupid" slot="prepend" size="small" clearable
												placeholder="角色" @change="getdata" style="margin-right:10px;">
												<el-option label="全部角色" value=""></el-option>
												<el-option v-for="item in groups" :key="item.groupsid"
													:label="item.gname" :value="item.groupsid">
												</el-option>
											</el-select>
											<el-input placeholder="请输入姓名" size="small" clearable v-model="keywords"
												class="input-with-select" @keyup.enter.native="getdata">
												<el-button slot="append" icon="el-icon-search" @click="getdata()">
												</el-button>
											</el-input>
										</div>
										<div style="margin-left:10px;">
											<el-button type="primary" size="small" @click="showadd" icon="el-icon-plus">
												添加管理员</el-button>
										</div>
									</div>
									<template>
										<el-table :data="tableData" v-loading="loading"
											header-cell-class-name="table-header" stripe>
											<el-table-column prop="logo" label="头像" width="60">
												<template slot-scope="scope">
													<img :src="scope.row.logo?scope.row.logo:host+'images/fb_icon12.png'"
														alt="" class="user-logo">
												</template>
											</el-table-column>
											<el-table-column prop="uuid" label="昵名(姓名)">
												<template slot-scope="scope">
													<div>{{scope.row.name?scope.row.name:scope.row.usern}}</div>
													<div class="notimportant">{{scope.row.uuid}}</div>
												</template>
											</el-table-column>
											<el-table-column prop="groupid" label="角色" width="80">
												<template slot-scope="scope">
													<div class="notimportant">
														{{groupkv[scope.row.groupid]?groupkv[scope.row.groupid].gname:''}}
													</div>
												</template>
											</el-table-column>
											<!-- <el-table-column label="部门">
											<template slot-scope="scope">
												<div>
													<el-tag size="small" style="margin-right: 10px;margin-bottom:5px;"
														type="info" v-for="(item) in scope.row.roles.res" :key="item.typid"
														disable-transitions>
														{{department[item.typeid]}}
													</el-tag>
												</div>
											</template>
										</el-table-column> -->

											<el-table-column label="" width="120">
												<template slot-scope="scope">
													<el-button size="mini" slot="reference" icon="el-icon-edit"
														@click.stop="goinfo(scope.row)" circle
														style="margin-right: 10px;">
													</el-button>
													<el-popover placement="top" width="60" v-model="scope.row.visible">
														<p>确定删除吗？</p>
														<div style="text-align: right; margin: 0">
															<el-button size="mini" type="text"
																@click.stop="scope.row.visible = false">取消</el-button>
															<el-button type="primary" size="mini"
																@click.stop="handleDelete(scope.$index, scope.row)">确定
															</el-button>
														</div>
														<el-button size="mini" slot="reference" icon="el-icon-delete"
															circle>
														</el-button>
													</el-popover>

												</template>
											</el-table-column>
										</el-table>
									</template>

									<div style="padding:15px;text-align: right;">
										<el-pagination background layout="total,prev, pager, next"
											:current-page.sync="pgno1" :page-size="pgsize" @current-change="page"
											:total="pgcount1" key="p1">
										</el-pagination>
									</div>
								</div>



							</el-tab-pane>
							<el-tab-pane label="人员管理" name="second">
								<div class="panel-body pad16" style="border-bottom: 1px solid #EBEEF5;padding-top:0px;">
									<div class="fcj_lr table-filter">
										<div class="fcj_ll">

										</div>
										<div style="margin-left:10px;">
											<el-button type="primary" size="small" @click="showstaffadd"
												icon="el-icon-plus">添加人员</el-button>
										</div>
									</div>
									<template>
										<el-table :data="staff" header-cell-class-name="table-header" stripe>
											<el-table-column width="40" fixed>
											</el-table-column>
											<el-table-column prop="tname" label="姓名" width="120">
												<template slot-scope="scope">
													<div>{{scope.row.tname}}</div>
												</template>
											</el-table-column>

											<el-table-column prop="tps" label="职能">

											</el-table-column>
											<el-table-column prop="phone" label="手机" :width="110">

											</el-table-column>

											<el-table-column label="" width="120">
												<template slot-scope="scope">
													

													<el-button size="mini" slot="reference" icon="el-icon-edit"
														@click.stop="gostaffinfo(scope.row)" circle
														style="margin-right: 10px;">
													</el-button>
													
													<el-button size="mini" slot="reference" icon="el-icon-delete"
														@click.stop="delstaff(scope.$index, scope.row)" circle>
													</el-button>
													
													

												</template>
											</el-table-column>
										</el-table>
									</template>
									<div style="padding:15px;text-align: right;">
										<el-pagination background layout="total,prev, pager, next"
											:current-page.sync="pgno" :page-size="pgsize" @current-change="getstaff"
											:total="pgcount" key="p2">
										</el-pagination>
									</div>
								</div>

							</el-tab-pane>
						</el-tabs>


					</div>
				</div>
			</el-col>
		</el-row>


		<el-dialog :visible.sync="isshow" width="700px">

			<div class="panel-body pad16">
				<div v-if="activeName=='first'">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="用户ID" prop="uuid">
							<el-input v-model="ruleForm.uuid"></el-input>
						</el-form-item>
						<el-form-item label="用户姓名" prop="name">
							<el-input v-model="ruleForm.name"></el-input>
						</el-form-item>

						<el-form-item label="部门" prop="tp">
							<el-checkbox-group v-model="ruleForm.tp">
								<el-checkbox name="tp" v-for="(item,index) in department" :key="index"
									:label="index">
									{{item}}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item label="角色" prop="sysid">
							<el-select v-model="ruleForm.groupid" filterable placeholder="请选择">
								<el-option v-for="item in groups" :key="item.groupsid" :label="item.gname"
									:value="item.groupsid">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="园区" prop="sysid" v-if="maindb==1">
							<el-checkbox-group v-model="ruleForm.sysid">
								<el-checkbox name="sys" v-for="(item,index) in sys" :key="index" :label="item.id">
									{{item.sysname}}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>

						<el-form-item style="margin-top: 20px;">
							<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
							<el-button @click="resetForm('ruleForm')">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
				<div v-else>
					<el-form :model="staffForm" :rules="staffrules" ref="staffForm" label-width="100px"
						class="demo-ruleForm">
						<el-form-item label="人员类别" prop="job">
							<el-radio-group v-model="staffForm.job">
								<el-radio name="tp" v-for="(item,index) in jobs" :key="index" :label="index">
									{{item}}
								</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="职能" prop="tp" v-if="staffForm.job==1">
							<el-checkbox-group v-model="staffForm.tp">
								<el-checkbox name="tp" v-for="(item,index) in tpini" :key="index" :label="index">
									{{item}}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item label="用户ID" v-if="staffForm.job==0">
							<el-input v-model="staffForm.uuid"></el-input>
						</el-form-item>
						<el-form-item label="姓名" prop="tname">
							<el-input v-model="staffForm.tname"></el-input>
						</el-form-item>
						<el-form-item label="手机" prop="phone">
							<el-input v-model="staffForm.phone"></el-input>
						</el-form-item>
						<el-form-item label="是否是业务员" prop="is_salesman">
							<el-radio-group v-model="staffForm.is_salesman">
								<el-radio name="tp"  label="0">
									不是
								</el-radio>
								<el-radio name="tp"  label="1">
									是
								</el-radio>
							</el-radio-group>
						</el-form-item>



						<el-form-item>
							<el-button type="primary" @click="submitForm('staffForm')">保存</el-button>
							<el-button @click="showstaffadd">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>

		</el-dialog>
	</div>

</template>

<script>
	import uilt from '../assets/a.js'
	export default {
		data() {
			return {
				loading: false,
				isshow: false,
				activeName: 'first',
				formname: '编辑管理员',
				tableData: [],
				staff: [],
				tpini: {},
				departmentid: '',
				chgroupid: '',
				keywords: '',
				jobs: ['招商人员', '维修人员'],
				department: ['招商', '物业', '财务', '管理层'],
				groups: [],
				width: 100,
				width1: 100,
				pgno: 1,
				pgcount: 0,
				pgno1: 1,
				pgcount1: 0,
				pgsize: 9,
				staffForm: {
					uuid: '',
					tname: '',
					phone: '',
					job: [],
					tp: [],
					is_salesman:''
				},
				ruleForm: {
					uuid: '',
					name: '',
					groupid: '',
					tp: [],
					sysid: []
				},
				staffrules: {
					tname: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入手机',
						trigger: 'blur'
					}]
				},
				rules: {
					uuid: [{
						required: true,
						message: '请输入uid',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					groupid: [{
						required: true,
						message: '选择角色',
						trigger: 'change'
					}],
					sysid: [{
						required: true,
						message: '选择管理园区',
						trigger: 'change'
					}]
				},
				groupkv: [],
				sys: [],
				acsys: [],
				maindb: 0
			}
		},
		mounted: function() {


			// window.onresize = function() {
			// 	var w = that.$refs.userlist.offsetWidth;
			// 	that.width = w - 420;
			// 	that.width1 = w - 330;
			// }
			this.host = this.$host
			this.$ajax({
				url: 'user/',
				data: {
					do: 'ini'
				},
			}).then(res => {
				this.tpini = res.tp
				this.sys = res.sys
				this.acsys = res.acsys
				this.maindb = res.main
				this.getdata();
			})

		},
		methods: {

			tableCellClassName(row, rowIndex) {
				row.index = rowIndex
			},

			tabclick(tab, event) {
				this.pgno = 1;
				if (tab.name == "second") {
					this.getstaff();
					this.formname = '编辑人员'
				} else {
					this.getdata();
					this.formname = '编辑管理员'
				}

			},
			showadd() {
				var f = {
					uuid: '',
					name: '',
					groupid: '',
					modi: '',
					tp: [],
					sysid: [this.acsys.id]
				}
				this.formname = '新增管理员'
				this.ruleForm = f;
				this.isshow = true;
			},
			showstaffadd() {
				var f = {
					uuid: '',
					tname: '',
					phone: '',
					repid: '',
					job: [],
					tp: [],
					is_salesman:''
				}
				this.formname = '新增人员'
				this.staffForm = f;
				this.isshow = true;
			},
			gostaffinfo(row, column, event) {
				var f = {
					uuid: row.uuid,
					tname: row.tname,
					phone: row.phone,
					repid: row.id,
					job: parseInt(row.job),
					tp: row.tp.split(','),
					is_salesman:row.is_salesman
				}
				this.staffForm = f;
				this.isshow = true;
			},
			goinfo(row, column, event) {
				var roles = [];
				var sys = [];
				if (row.roles.c > 0) {
					for (var i = 0; i < row.roles.c; i++) {
						roles.push(parseInt(row.roles.res[i].typeid))
					}
				}
				for (i = 0; i < row.sysid.length; i++) {
					sys.push(row.sysid[i].sysid)
				}
				var f = {
					uuid: row.uuid,
					name: row.name,
					groupid: row.groupid,
					modi: 1,
					tp: roles,
					sysid: this.maindb == 1 ? sys : [this.acsys.id]
				}
				console.log(f);
				this.formname = '编辑管理员'
				this.ruleForm = f;
				this.isshow = true;
			},
			submitForm(formName) {
				if (formName == 'staffForm') {
					this.$refs[formName].validate((valid) => {
						if (valid) {
							const that = this;
							this.staffForm.do = 'addm';
							this.$ajax({
								url: 'user/',
								data: this.staffForm,
							}).then(res => {
								if (res.error > 0) {
									that.$message({
										message: res.msg,
										type: 'warning'
									});
								} else {
									that.$message({
										message: '保存成功',
										type: 'success'
									});
									that.showstaffadd();
									that.getstaff();
								}

							})
						} else {
							return false;
						}
					});
				} else {
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.$ajax({
								url: 'user/',
								data: {
									'do': 'add',
									'uuid': this.ruleForm.uuid,
									'addname': this.ruleForm.name,
									'modi': this.ruleForm.modi,
									'tp': this.ruleForm.tp,
									'groupid': this.ruleForm.groupid,
									'sysid': this.ruleForm.sysid
								}
							}).then(res => {
								if (res.error > 0) {
									this.$message({
										message: res.msg,
										type: 'warning'
									});
								} else {

									this.$message({
										message: '保存成功',
										type: 'success'
									});
									this.getdata();
									uilt.$emit('demo');
								}
							})
						} else {
							console.log('error submit!!');
							return false;
						}
					});
				}

			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			handleDelete(index, row) {
				this.$ajax({
					url: 'user/',
					data: {
						'do': 'del',
						'id': row.id
					}
				}).then(res => {

					this.getdata();

				})
			},
			delstaff(index, row) {
				this.$confirm('确认删除吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$ajax({
						url: 'user/',
						data: {
							do: 'delm',
							id: row.id
						},
					}).then(res => {

						this.getstaff();

					})
				}).catch(() => {

				});

			},
			getdata(v) {
				if (typeof(v) != 'object' || v.pgno === undefined) {
					this.pgno1 = 1;
				}
				this.loading = true
				this.$ajax({
					url: 'user/',
					data: {
						'do': 'list',
						'departmentid': this.departmentid,
						'keywords': this.keywords,
						'chgroupid': this.chgroupid,
						'pgno': this.pgno1,
						'pgsize': this.pgsize
					},
				}).then(res => {
					// for (var i = 0; i < res.c; i++) {
					// 	res.res[i].visible = false
					// }
					this.loading = false
					this.tableData = res.res;
					console.log(this.tableData);
					this.pgcount1 = parseInt(res.count);
					var tmp = [];
					for (var i = 0; i < res.groups.c; i++) {
						tmp[res.groups.res[i].groupsid] = res.groups.res[i]
					}
					this.groupkv = tmp;
					this.groups = res.groups.res;


				})
			},
			page(v) {
				this.pgno1 = v;
				this.getdata({
					pgno: v
				});
			},
			getstaff(pgno) {
				if (pgno) {
					this.pgno = pgno;
				}
				this.$ajax({
					url: 'user/',
					data: {
						'do': 'liststaff',
						'pgno': this.pgno,
						'pgsize': this.pgsize
					},
				}).then(res => {

					this.pgcount = parseInt(res.count);
					this.staff = res.res;

					for (var i = 0; i < this.staff.c; i++) {
						this.staff[i].visible = false;
					}
					console.log(this.staff)
				})
			}

		}
	};
</script>

<style>
	.user-logo {
		width: 36px;
		height: 36px;
		border-radius: 5px;
	}

	.el-tabs__nav-scroll {
		overflow: auto;
	}
</style>
