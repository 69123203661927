<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">基础设置</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-bottom:15px;">
			
					<div class="panel" >

						<div class="panel-body">
							
							
							<div class="fcj_ll" style="align-items: stretch;">
								<div style="margin:20px;flex:1">
									<div class="fcj_lr table-filter" style="margin:20px 0;">
										<div>
											园区平面图
										</div>
										<div>
											<div class="fcj_ll">
												<div v-if="showqu">
													<el-select v-model="searcharea" size="small" clearable placeholder="区"
													@change="getdata" style="margin-right:10px;width:100px;" >
													<el-option value="">全部</el-option>
													<el-option primary v-for="(item,index) in officearea" :label="item" :value="item" :key="index">
														{{item}}
													</el-option>
														
													</el-select>
												</div>
												<div>
												<el-select v-model="sevalue" size="medium" @change="flt()" filterable placeholder="请选择">
													<el-option label='全部' value=''></el-option>
													<el-option
													v-for="(item,index) in clres"
													:key="index"
													:label="item.bname"
													:value="index">
													</el-option>
													</el-select>
												</div>
												<div>
													<!-- <el-upload action="http://localhost:8081/upfile" :data="uploaddata" list-type="text" ref="uploadparkpic" name="upfile" :on-success="uploadparkpicsuccess" :auto-upload="true" :limit="1"> -->
													<el-upload :action="host+'upimg/'" :data="uploaddata" list-type="text" ref="uploadparkpic" name="upfile" :on-success="uploadparkpicsuccess" :auto-upload="true" :limit="1">
														<el-button type="primary" size="medium" icon="el-icon-picture-outline">上传园区平面图</el-button>
														
													</el-upload>
												</div>
												<div>
														<el-button type='primary' size="medium" icon="el-icon-plus" @click="goedit(1)">新增楼栋</el-button>
													
												</div>
											</div>
										</div>
									</div>
									<div style="" ref="canvas">
										<div :style="'overflow: hidden;width:'+(mainwh[0]*scale)+'px;height:'+(mainwh[1]*scale)+'px;margin:0 auto;'">
										<div :style="'position: relative;display:inline-block;transform: scale('+scale+');transform-origin:left top'">
											<img :src="host+mainpic" draggable="false">
											
											<fcj :whrange="whrange" :position="[item.position[0],item.position[1]]" :scale="scale" v-model="pp" @change="setpoint()" v-for="(item,index) in res" :key="index" v-show="sevalue===''||sevalue==index">
													<div @mousedown="edit(item)"
														class="po"
														:style="{'background-image': 'url('+host+'images/position'+item.icon+'.png)'}">
													<div :class="['circle',acbuilding.id==item.id?'acpoint':'bpoint']">
														
													</div>
													{{item.bnamen}}
												</div>
											</fcj>
											
											
											
										</div>
										</div>
									</div>
								</div>
								<div style="width:300px;background-color: #f7f9ff;border-radius: 0 8px 8px 0;padding:40px;">
									<div class="formdiv">
										<div>
											<div class="h3">
												楼栋信息
											</div>
											<div class="redtitle" style="margin-top:30px;">{{acbuilding.bname}}</div>
										
										</div>
										
										<el-descriptions title="" :column="1" size="medium" labelClassName="des-labeloffceinfo" contentClassName="des-content">
											<el-descriptions-item label="楼栋编号">
												{{acbuilding.id}}
											</el-descriptions-item>
											<el-descriptions-item label="楼栋名称">
												{{acbuilding.bname}}
											</el-descriptions-item>
											<el-descriptions-item label="建筑用途">
												{{acbuilding.purpose}}
											</el-descriptions-item>
											<el-descriptions-item label="建筑类型">
												{{acbuilding.type}}
											</el-descriptions-item>
									
										</el-descriptions>
										<div style="text-align: center;" class="fcj_ll">
												<router-link :to="'/areaedit?bid='+acbuilding.id">
												<div class="linka blue" ><i class="el-icon-share"></i>楼层设置</div>
												</router-link>
												<div class="linka blue"  @click="goedit(acbuilding)" style="margin:0 10px"><i class="el-icon-edit"></i>编辑楼栋
												</div>
												
												<el-popover placement="top" width="160" v-model="visible">
													<p>确定删除吗？</p>
													<div style="text-align: right; margin: 0">
														<el-button size="mini" type="text" @click="visible = false">取消
														</el-button>
														<el-button type="primary" size="mini" @click="delsure(acbuilding)">确定
														</el-button>
													</div>
													<div class="linka red"  slot="reference"><i class="el-icon-delete"></i>删除楼栋
													</div>
												</el-popover>
										</div>
										<div style="margin-top:30px;">
											<div class="fcjtitle">楼栋曾用名</div>
											<div>
												<div class="fcj_table" style="line-height:20px;padding:10px;font-size: 14px;box-sizing: border-box;color:#96A1B3;font-size: 14px;margin-bottom: -8px;;">
													<div style="flex:1">名称</div>
													<div style="flex:1">修改日期</div>
													<div style="flex:1">修改人</div>
												</div>
												<div class="fcj_table" v-for="(item) in acbuilding.log" :key="item.id" style="line-height:20px;padding:10px;font-size: 14px;box-sizing: border-box; margin:8px 0;border-radius: 4px;background-color: #fdfdfe;">
													<div style="flex:1;font-weight: bold;">{{item.note}}</div>
													<div style="flex:1">{{item.dt}}</div>
													<div style="flex:1">{{item.name||item.usern||item.id}}</div>
												</div>
												
											</div>
										</div>
									</div>
								</div>
							</div>
							

							
						</div>
					</div>
				
			
		</div>


	</div>
</template>

<script>
	import fcj from '../components/fcj.vue'
	export default {
		components: {
			fcj
		},
		data() {
			return {
				whrange:[0,70,2000,0],
				mainwh:[],
				canvaswh:[],
				scale:1,
				pp:[],
				
				clres:'',
				sevalue:'',
				visible:false,
				mainpic: '',
				host: '',
				res: [],
				bid:'',
				acbuilding: '',
				buildingtype: {},
				buildingpurpose: {},
				uploaddata:{
					pusern: '',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				showqu:false,
				searcharea:''
			}
		},
		
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=[to];
			})
		},
		mounted: function() {
			
			this.uploaddata.pusern=localStorage.getItem('pusern');
			this.bid=this.$route.query.bid?this.$route.query.bid:''
			if(this.bid){
				sessionStorage.setItem('buildingid',this.bid)
			}
			sessionStorage.removeItem('floorid')
			const that = this;
			this.host = this.$host
			
			this.canvaswh=[this.$refs.canvas.offsetWidth,this.$refs.canvas.offsetHeight];
			if(this.host=='https://admin.kefan.com/zhfty/'||this.host=='http://localhost/zhfty/'){
				this.showqu=true;
			}

			this.ini();
			this.getdata();

		},
		methods: {
			flt(){
				this.edit(this.res[this.sevalue])
			},
			mu(){
				// if(this.canvaswh[0]/this.canvaswh[1]>this.mainwh[0]/this.mainwh[1]){
				// 	//画布很宽,以高度缩放
				// 	this.scale=this.canvaswh[1]/this.mainwh[1];
				// }else{
				// 	this.scale=this.canvaswh[0]/this.mainwh[0];
				// }
				this.scale=750/this.mainwh[0];
			},
			setpoint(){

				var x = (this.pp[0]);
				var y = (this.pp[1]);

				this.$ajax({
					url: 'building/',
					data: {
						'do': 'updatepoint',
						position: [x,y],
						id: this.acbuilding.id
					}
				}).then(res => {
				})
				
			},
			uploadparkpicsuccess(response) {
				this.floorpic = response.fpath
				//this.saveparkpic()
				this.$ajax({
					url: 'map/',
					data: {
						'do': 'update',
						'path': response
					}
				}).then(res => {
					if (res.error == 0) {
						this.mainpic = response.fpath;
						this.mainwh=[response.imgw,response.imgh];
						this.whrange=[0,response.imgw-10,response.imgh-10,0]
						this.mu();
					}
					this.$message({
						message: res.msg,
						type: 'success'
					})
				})
				this.$refs.uploadparkpic.clearFiles();
			},

			delsure(v) {
				this.$ajax({
					url: 'building/',
					data: {
						'do': 'del',
						'id': this.acbuilding.id
					}
				}).then(res => {
					if (res.error == 0) {
						for (var i = this.res.length - 1; i >= 0; i--) {
							if (this.res[i].id == this.acbuilding.id) {
								this.res.splice(i, 1);
							}
						}
						
						if(this.res.length>0){
							this.acbuilding=this.res[this.res.length-1];
							sessionStorage.setItem('buildingid',this.res[this.res.length-1].id)
						}else{
							this.acbuilding='';
							sessionStorage.removeItem('buildingid')
						}
					}
					this.visible=false;
					this.$message({
						message: res.msg,
						type: res.error == 0?'success':'warning'
					})

					
					
				})
			},
			edit(v) {
				this.acbuilding = v
				sessionStorage.setItem('buildingid',v.id)
			},
			goedit(v) {
				
				if(v==1){
					this.$router.push('/editbuilding')
				}else{
					this.$router.push('/editbuilding?id=' + v.id)
				}
				
			},
			showset() {
				this.setshow = true;
				this.addbuildingshow = false;
			},

			ini() {
				this.$ajax({
					url: 'map/',
					data: {
						'do': 'list',
						'type': ['mainmap']
					},
				}).then(res => {
					this.mainpic = res.data.mainmap.content;
					this.mainwh=[res.data.mainmap.imgw,res.data.mainmap.imgh];
					this.whrange=[0,res.data.mainmap.imgw-10,res.data.mainmap.imgh-10,0]
					this.mu();
				})
			},

			getdata() {
				this.$ajax({
					url: 'building/',
					data: {
						'do': 'list',
						'searcharea':this.searcharea
					},
				}).then(res => {
					var v = res;
					var acid=0;
					if(sessionStorage.getItem('buildingid')){
						acid=sessionStorage.getItem('buildingid');
					}

					for (var i = v.c - 1; i >= 0; i--) {
						
						if(v.res[i].position==''){
							v.res[i].position=[360,0]
						}else{
							v.res[i].position = v.res[i].position.split(',')
						}
						if (v.res[i].pics != '') {
							v.res[i].pics = v.res[i].pics.split(',');
						}
						var s=v.res[i].bname.match("([0-9,a-z,A-Z]+)号楼");
						if(s){
							v.res[i].bnamen=s[1];
						}else{
							v.res[i].bnamen=v.res[i].bname;
						}
						if(acid==v.res[i].id){
							this.acbuilding = v.res[i];
							this.bid=this.acbuilding.id;
						}
					}
					if(v.c>0&&acid==0){
						this.acbuilding = v.res[v.c - 1];
					}
					
					if(v.c>0){
						this.clres=JSON.parse(JSON.stringify(v.res));
						this.res = v.res;
					}else{
						this.res =[]
						this.clres=[]
					}
					this.officearea=v.officearea;
				})
			}
		}
	};
</script>

<style scoped>
	.po {
		overflow: hidden;
		width: 25px;
		height: 25px;
		color:#fff;
		background-color: #5680FA;
		box-shadow: 0 0 5px #666;
		border-radius: 50%;
		text-align: center;
		line-height: 26px;
		background-size: cover;
		cursor:pointer;
		
	}
	.circle{
		position: absolute;
		top:-20px;
		right:-10px;

	}
	.h180 {
		height: 54px
	}

	.h132 {
		height: 66px
	}


</style>
