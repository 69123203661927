<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">楼栋统计</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div>
			<el-row :gutter="15">
				<el-col :span="24">
					<div style="margin:0 -10px;">
							<div style="display: flex;">
								<div class="fcj_lb littleblock" style="flex:1">
									<div>
										<div class="num">{{buildingc}}</div>
										<div>楼栋数量</div>
									</div>		
									<div>
										<div class="num">{{roomnum}}</div>
										<div>房间数量</div>
									</div>
								</div>
								
								<div class="fcj_lb littleblock" style="flex:2">
									<div>
										<div class="num">{{tongji.allarea}}</div>
										<div>园区总面积(㎡)</div>
									</div>	
									<div>
										<div class="num">{{tongji.all_can_rent_area}}</div>
										<div>园区可出租面积(㎡)</div>
									</div>
									<div>
										<div class="num">{{tongji.area}}</div>
										<div>已出租面积(㎡)</div>
									</div>
									<div>
										<div class="num">{{tongji.empty_area}}</div>
										<div>空置总面积(㎡)</div>
									</div>
									
								</div>
								
								<div class="fcj_lb littleblock" style="flex:1">
									<!-- <div>
										<div class="num">{{renovation}}</div>
										<div>装修房间数</div>
									</div>	 -->	
									<div>
										<div class="num numred">{{tongji.rate}}</div>
										<div>出租比例</div>
									</div>
									<div>
										<div class="num numred">{{tongji.empty_rate}}</div>
										<div>空置比例</div>
									</div>
								</div>
							
							</div>
							
							<div style="display: flex;">
								<div class="fcj_lb littleblock" style="flex:1.5">
									<div>
										<div class="num">{{tj[0]}}</div>
										<div>办公房间数</div>
									</div>		
									<div>
										<div class="num">{{tj[1]}}</div>
										<div>办公总面积(㎡)</div>
									</div>
									<div>
										<div class="num numgreen">{{tj[2].mul(100)+'%'}}</div>
										<div>办公出租比例</div>
									</div>
								</div>
								<div class="fcj_lb littleblock" style="flex:1.5">
									<div>
										<div class="num">{{tj[3]}}</div>
										<div>商铺房间数</div>
									</div>		
									<div>
										<div class="num">{{tj[4]}}</div>
										<div>商铺总面积(㎡)</div>
									</div>
									<div>
										<div class="num numred">{{tj[5].mul(100)+'%'}}</div>
										<div>商铺出租比例</div>
									</div>
								</div>
								
								<div class="fcj_lb littleblock" style="flex:1;cursor:pointer;" @click="gocontract">
									<!-- <div>
										<div class="num">{{endtime}}</div>
										<div>30天内到期数</div>
									</div> -->
									<div>
										<div class="num">{{repair}}</div>
										<div>报修房间数</div>
									</div>
								</div>
							</div>
							
							
						
					</div>
				</el-col>
			</el-row>
			<div class="panel">
				<div class="panel-body pad16">
					<div>
						<div v-if="buildingqrcode"><el-button :disabled="istb1" :loading="istb1" @click="downqrcode(1)">批量单个下载</el-button><el-button :disabled="istb2" :loading="istb2" @click="downqrcode(2)">合并下载</el-button><el-button @click="downqrcode(1,1)" :disabled="istb11" :loading="istb11">房间二维码下载</el-button></div>
						<el-table :data="res"  @selection-change="handleSelectionChange" header-cell-class-name="table-header" stripe>
							<el-table-column type="selection" width="55" fixed v-if="buildingqrcode">
							</el-table-column>
							<el-table-column width="60">
								<template slot-scope="scope">
									<div class="fcj_ll">
										<img class="icon-small" :src="host+'images/icon/down.png'" alt="" @click.stop="sorts( scope.row,'down')">
										<img class="icon-small" :src="host+'images/icon/up.png'" alt="" @click.stop="sorts( scope.row,'up')">
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="bname" label="楼栋名称">
								<template slot-scope="scope">
									<router-link :to="'/areaset?bid='+scope.row.id" >{{scope.row.bname}}</router-link>
								</template>
							</el-table-column>
							<el-table-column prop="roomnum" label="房间总数" width="80">
								<template slot-scope="scope">
									<router-link :to="'/area1?bid='+scope.row.id" >{{scope.row.roomnum}}</router-link>
								</template>
							</el-table-column>
							<el-table-column prop="sumarea" label="总面积(㎡)/办公/商铺">
								<template slot-scope="scope">
									{{scope.row.sumarea.toFixed(2)+' / '+scope.row.bangong[1].toFixed(2)+' / '+scope.row.shangpu[1]}}
								</template>	
							</el-table-column>

							<el-table-column label="已出租(㎡)/办公/商铺">
								<template slot-scope="scope">
									{{scope.row.rentarea.toFixed(2)+' / '+scope.row.bangong[0]+' / '+scope.row.shangpu[0]}}
								</template>
							</el-table-column>
							<el-table-column label="未出租(㎡)/办公/商铺">
								<template slot-scope="scope">
									{{scope.row.sumarea.accSub(scope.row.rentarea).toFixed(2)+' / '+scope.row.bangong[1].accSub(scope.row.bangong[0]).toFixed(2)+' / '+scope.row.shangpu[1].accSub(scope.row.shangpu[0]).toFixed(2)}}
								</template>
							</el-table-column>
							<el-table-column label="出租比例/办公/商铺">
								<template slot-scope="scope">
									{{(scope.row.sumarea>0?(scope.row.rentarea.div(scope.row.sumarea)).toFixed(4).mul(100)+'%':'0')+' / '+(scope.row.bangong[1]>0?scope.row.bangong[0].div(scope.row.bangong[1]).toFixed(4).mul(100)+'%':'0')+' / '+(scope.row.shangpu[1]>0?scope.row.shangpu[0].div(scope.row.shangpu[1]).toFixed(4).mul(100)+'%':'0')}}
								</template>
							</el-table-column>
							<el-table-column label="建筑用途" width="80">
								<template slot-scope="scope">
									{{buildingpurpose[scope.row.purpose]?buildingpurpose[scope.row.purpose].class_name:''}}
								</template>
							</el-table-column>
							<el-table-column label="建筑类型" width="80">
								<template slot-scope="scope">
									{{buildingtype[scope.row.type]?buildingtype[scope.row.type].class_name:''}}
								</template>
							</el-table-column>
							

							<template>
								<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
							</template>
						</el-table>


					</div>
				</div>
			</div>
		</div>



	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading:false,
				buildingc: 0,
				roomnum: 0,
				areac: 0,
				outareac: 0,
				renovation: 0,
				endtime: 0,
				repair: 0,
				rentpercent: '0%',
				notrentpercent:'0%',
				nav: {},
				buildingpurpose: {},
				buildingtype: {},
				ot:{},

				host: '',
				res: [],
				tj:[0,0,0,0,0,0,0,0],
				multipleSelection:[],
				buildingqrcode:0,
				istb11:false,
				istb1:false,
				istb2:false,
				tongji:{}
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=[to];
			})
		},
		mounted: function() {
			const that = this;
			this.host = this.$host
			this.getdata();
			this.$MoveObject

		},
		methods: {
			sorts(row, v) {
				this.$ajax({
					url: 'building/',
					data: {
						'do': 'sort',
						'sort': v,
						'id': row.id
					},
				}).then(res => {
					this.getdata();
			
				})
			
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			downqrcode(tp,room){
				
				var po = [];
				if (this.multipleSelection.length == 0) {
					this.$alert('请至少选择一条记录进行操作', '提示', {
						confirmButtonText: '确定'
					});
					return;
				}
				for (var i = 0; i < this.multipleSelection.length; i++) {
					po.push(this.multipleSelection[i].id);
				}
				if(tp==1&&room==1){
					this.istb11=true;
				}else if(tp==1){
					this.istb1=true;
				}else if(tp==2){
					this.istb2=true;
				}
				this.$ajax({
					responseType:'blob',
					url: 'building/',
					data: {
						'do': 'downqrcode',
						'ids':po,
						'tp':tp,
						'room':room?room:0
					},
				}).then(res => {
					let url=window.URL.createObjectURL(new Blob([res]));
					let link=document.createElement('a')
					link.download='二维码.zip'
					link.href=url
					link.click()
					this.istb11=false;
					this.istb1=false;
					this.istb2=false;
					return
				})

			},
			gocontract(){
				this.$router.push('/area1?repair=1')
			},
			goinfo(v) {
				this.$router.push('/area1?bid=' + v.id)
			},
			getdata() {
				this.$ajax({
					url: 'building/',
					data: {
						'do': 'tongji'
					},
				}).then(res => {
					
					if (res.error > 0) {
						console.log(res.msg)
					}

					var bid = {};

					for (var n in res.nav) {
						res.nav[n].tongji = {
							num: 0,
							sumarea: 0,
							outarea: 0
						};
					}
					var nav = res.nav;
					this.ot=res.ot;
					this.buildingtype = res.buildingtype;
					this.buildingpurpose = res.buildingpurpose;
					this.endtime = res.endtime;
					this.renovation = res.renovation;
					this.repair = res.repair;

					this.roomnum = 0;//总房间数
					this.buildingc=res.c;//总楼数
					
					for (var i = 0; i < res.c; i++) {
						res.res[i].building=res.res[i].id
						res.res[i].rentarea=0;//每栋已出租面积
						res.res[i].sumarea=0;//每栋总面积
						res.res[i].roomnum=0;//每栋总房间数
						res.res[i].bangong=[0,0];//每栋办公室
						res.res[i].shangpu=[0,0];//每栋商铺
						for(var j=0;j<res.res[i].rooms.c;j++){
							res.res[i].roomnum=res.res[i].roomnum.add(res.res[i].rooms.res[j].roomnum);
							this.roomnum+=parseInt(res.res[i].rooms.res[j].roomnum);//总房间数
							if (res.res[i].rooms.res[j].state == 1) { //每栋楼已出租面积
								res.res[i].rentarea=res.res[i].rentarea.add(res.res[i].rooms.res[j].sumarea);
								
								if(res.res[i].rooms.res[j].type==72){//每栋办公室
									res.res[i].bangong[0]=res.res[i].bangong[0].add(res.res[i].rooms.res[j].sumarea);
									
								}
								if(res.res[i].rooms.res[j].type==73){//每栋办公室
									res.res[i].shangpu[0]=res.res[i].shangpu[0].add(res.res[i].rooms.res[j].sumarea);
								}
							}else{
								this.outareac=this.outareac.add(res.res[i].rooms.res[j].sumarea);//园区总的未出租面积
							}
							if(res.res[i].rooms.res[j].type==72){//每栋办公室
								res.res[i].bangong[1]=res.res[i].bangong[1].add(res.res[i].rooms.res[j].sumarea);
							}
							if(res.res[i].rooms.res[j].type==73){//每栋商铺
								res.res[i].shangpu[1]=res.res[i].shangpu[1].add(res.res[i].rooms.res[j].sumarea);
							}
							res.res[i].sumarea=res.res[i].sumarea.add(res.res[i].rooms.res[j].sumarea);
							this.areac=this.areac.add(res.res[i].rooms.res[j].sumarea);//园区总面积
							
							if (nav[res.res[i].rooms.res[j].type]) {
								nav[res.res[i].rooms.res[j].type].tongji.num = parseInt(nav[res.res[i].rooms.res[j].type].tongji.num) + parseInt(res.res[i].rooms.res[j].roomnum);
								nav[res.res[i].rooms.res[j].type].tongji.sumarea = nav[res.res[i].rooms.res[j].type].tongji.sumarea.add(res.res[
									i].sumarea)
								if (res.res[i].rooms.res[j].state == 1) { //已出租面积
									nav[res.res[i].rooms.res[j].type].tongji.outarea = nav[res.res[i].rooms.res[j].type].tongji.outarea.add(res
										.res[i].rooms.res[j].sumarea);
								}
							}
						}

						// if (!bid[res.res[i].building]) {

						// 	bid[res.res[i].building] = res.res[i];
						// 	this.buildingc++;

						// 	if (res.res[i].state == 1) { //已出租面积
						// 		bid[res.res[i].building].rentarea = res.res[i].sumarea
						// 	} else {
						// 		bid[res.res[i].building].rentarea = 0;
						// 	}
						// 	bid[res.res[i].building].sumarea = parseFloat(bid[res.res[i].building].sumarea)
						// } else {
						// 	bid[res.res[i].building].sumarea = bid[res.res[i].building].sumarea.add(parseFloat(res
						// 		.res[i].sumarea)) //总面积
						// 	bid[res.res[i].building].roomnum = parseInt(bid[res.res[i].building].roomnum) + parseInt(res.res[i].roomnum); //总房间数
						// 	if (res.res[i].state == 1) { //已出租面积
						// 		bid[res.res[i].building].rentarea = bid[res.res[i].building].rentarea.add(
						// 			parseFloat(res.res[i].sumarea))
						// 	}

						// }

						
						// if(res.c>0){
						// 	this.areac = parseFloat(this.areac.add(parseFloat(res.res[i].sumarea))).toFixed(2)
						// 	if (res.res[i].state == 1) { //已出租面积

						// 		this.outareac = this.outareac.add(parseFloat(res.res[i].sumarea))
						// 	}
						// 	this.roomnum += parseInt(res.res[i].roomnum);
						// }
					}
					var tj=[0,0,0,0,0,0,0,0];
					
					for(i=0;i<res.officetj.length;i++){
						if(res.officetj[i].type=='72'){
							tj[0]=tj[0].add(parseInt(res.officetj[i].a))
							tj[1]=tj[1].add(parseFloat(res.officetj[i].sumarea))
							if(res.officetj[i].state==1){
								tj[6]=tj[6].add(parseFloat(res.officetj[i].sumarea));
							}
						}else if(res.officetj[i].type=='73'){
							tj[3]=tj[3].add(parseInt(res.officetj[i].a))
							tj[4]=tj[4].add(parseFloat(res.officetj[i].sumarea)).toFixed(2)
							if(res.officetj[i].state==1){
								tj[7]+=parseFloat(res.officetj[i].sumarea);
							}
						}
					}
					tj[1]=tj[1].toFixed(2);
					tj[2]=tj[1]>0?tj[6].div(tj[1]).toFixed(4):0;
					tj[5]=tj[4]>0?tj[7].div(tj[4]).toFixed(4):0;

					this.tj=tj


					this.rentpercent=this.areac>0?((this.areac.accSub(this.outareac)).div(this.areac).toFixed(4).mul(100)):0
					this.notrentpercent=(100-this.rentpercent).toFixed(2);
					this.rentpercent+='%';
					this.notrentpercent+='%';
					this.nav = nav;
					this.res = res.res;
					this.tongji = res.tongji;
					if(res.buildingqrcode){
						this.buildingqrcode=1;
					}
					this.loading=true;
				})
			}
		}
	};
</script>

<style scoped>
	.tj {
		text-align: center;
		padding: 15px 0;
		font-size: 12px;
		border-radius: 10px;
		margin: 10px;
		background-color: #efefef;
	}

	.num {
		font-size: 20px;
		margin-top: 10px;
	}



	.po {
		position: absolute;
		width: 32px;
		height: 40px;
		text-align: center;
		line-height: 33px;
		background-size: cover;
	}

	.h180 {
		height: 54px
	}

	.h132 {
		height: 66px
	}

</style>
