<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right" v-if="breadcrumb.length>0">
				<div class="pagetitle">{{breadcrumb[breadcrumb.length-1].name}}</div>
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadcrumb" :key="index" v-show="index!=breadcrumb.length-1">{{item.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel">

					<div class="panel-body pad16">
						<div v-if="proview">
							<div class="fcj_lr" style="margin:0 10px;">
								<div style="color: #FA6400;font-size:20px;">客户ID：{{ruleForm.id}}</div>
								<div><div type="primary" @click="modify()" style="color:#4D7CFE;font-size: 14px;cursor: pointer;"><i class="el-icon-edit" style="margin-right:10px;"></i>修改编辑</div></div>
							</div>
							<div class="info res pad16" style="line-height:180%;font-size:12px;"
								v-if="maps.source&&ruleForm.cname!=''">
								<el-descriptions title="" :column="1" size="medium" labelClassName="des-label" contentClassName="des-content">
									<el-descriptions-item label="客户名称">
										{{ruleForm.cname}}
									</el-descriptions-item>
								</el-descriptions>
								<el-descriptions title="" :column="1" size="medium" labelClassName="des-label" contentClassName="des-content">
									<el-descriptions-item label="所属公司">
										{{ruleForm.parent}}
									</el-descriptions-item>
								</el-descriptions>
								<el-descriptions title="" :column="4" size="medium" style="width:900px" labelClassName="des-label" contentClassName="des-content">
									<el-descriptions-item label="联系人">
										{{ruleForm.contacts}}
									</el-descriptions-item>
									<el-descriptions-item label="注册地" span=3>
										{{ruleForm.areaname}}
									</el-descriptions-item>
									<el-descriptions-item label="联系电话">
										{{ruleForm.phone}}
									</el-descriptions-item>
									
									<el-descriptions-item label="详细地址" span=3>
										{{ruleForm.address}}
									</el-descriptions-item>
								</el-descriptions>

								<el-descriptions title="" :column="1" size="medium" labelClassName="des-label" contentClassName="des-content">
									<el-descriptions-item label="注册地情况">
										{{maps.registplacestate[ruleForm.registplacestate]?maps.registplacestate[ruleForm.registplacestate].class_name:''}}
									</el-descriptions-item>
									<el-descriptions-item label="所属行业">
										<el-tag size="small" v-for="(item,index) in ruleForm.trade" style="margin-right: 8px;"
											:key="index">{{maps.trade[item]?maps.trade[item].class_name:''}} </el-tag>
									</el-descriptions-item>
									<el-descriptions-item label="行业属性">
										<el-tag size="small" v-for="(item,index) in ruleForm.tradetype" style="margin-right: 8px;"
											:key="index">{{maps.tradetype[item]?maps.tradetype[item].class_name:''}} </el-tag>
									</el-descriptions-item>
									<el-descriptions-item label="企业性质">
										{{maps.nature[ruleForm.nature]?maps.nature[ruleForm.nature].class_name:''}}
									</el-descriptions-item>
									<el-descriptions-item label="是否上市公司">
										{{maps.stockmarket[ruleForm.stockmarket]?maps.stockmarket[ruleForm.stockmarket].class_name:''}}
									</el-descriptions-item>
									<el-descriptions-item label="企业资质">
										<span v-for="(item,index) in ruleForm.qualifications"
											:key="index">{{maps.qualifications[item].class_name}}；</span>
									</el-descriptions-item>
									<el-descriptions-item label="企业证书">
										<span v-for="(item,index) in ruleForm.certificate"
											:key="index">{{maps.certificate[item].class_name}}；</span>
									</el-descriptions-item>
									<el-descriptions-item label="客户类型">
										{{maps.customertype[ruleForm.customertype]?maps.customertype[ruleForm.customertype].class_name:''}}
									</el-descriptions-item>
									<el-descriptions-item label="客户来源">
										{{ruleForm.source&&maps.source[ruleForm.source]?maps.source[ruleForm.source].class_name:''}}
									</el-descriptions-item>
									<el-descriptions-item label="来源说明">
										{{ruleForm.sourceremark}}
									</el-descriptions-item>
								</el-descriptions>

								<el-descriptions title="" :column="3" size="medium" style="width:800px" labelClassName="des-label" contentClassName="des-content">
									<el-descriptions-item label="员工人数" >
										{{ruleForm.personalnum}}
									</el-descriptions-item>
									<el-descriptions-item label="外地员工人数" >
										{{ruleForm.outpersonalnum}}
									</el-descriptions-item>
									<el-descriptions-item label="外籍员工人数" >
										{{ruleForm.foreignpersonalnum}}
									</el-descriptions-item>
									<el-descriptions-item label="党员人数" >
										{{ruleForm.partypersonalnum}}
									</el-descriptions-item>
									<el-descriptions-item label="是否有党支部" >
										{{ruleForm.isparty?'有':'没有'}}
									</el-descriptions-item>
								</el-descriptions>
								<el-descriptions title="" :column="1" size="medium" labelClassName="des-label" contentClassName="des-content">
									<el-descriptions-item label="企业需求" >
										<span v-for="(item,index) in ruleForm.demand"
											:key="index">{{maps.demand[item].class_name}}；</span>
									</el-descriptions-item>
									<el-descriptions-item label="备注信息" >
										{{ruleForm.mainbusiness}}
									</el-descriptions-item>
									<el-descriptions-item label="工商信息" >
										{{ruleForm.businessinfo}}
									</el-descriptions-item>
									<el-descriptions-item label="录入时间" >
										{{ruleForm.create_time}}
									</el-descriptions-item>
									<el-descriptions-item label="录入人员" >
										{{ruleForm.name||ruleForm.usern}}
									</el-descriptions-item>
								</el-descriptions>

								<div class="block">
									<div class="fcjtitle">对公开票信息</div>
									<el-descriptions title="" :column="1" size="medium" labelClassName="des-label" contentClassName="des-content">
										<el-descriptions-item label="单位名称" >
											{{ruleForm.taxcompany}}
										</el-descriptions-item>
										<el-descriptions-item label="税号" >
											{{ruleForm.taxnumber}}
										</el-descriptions-item>
										<el-descriptions-item label="地址及电话" >
											{{ruleForm.taxaddress}}
										</el-descriptions-item>
										<el-descriptions-item label="开户行及账号" >
											{{ruleForm.taxbankinfo}}
										</el-descriptions-item>
									</el-descriptions>

								</div>
							</div>
							<div style="text-align: center;">
								
							</div>
						</div>
						<div v-else>
							<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px"
								class="demo-ruleForm">
								<el-form-item label="客户名称" prop="cname">
									<el-input v-model="ruleForm.cname" style="width:620px"></el-input>
								</el-form-item>
								<el-form-item label="所属公司" prop="parent">
									<el-input v-model="ruleForm.parent" style="width:620px"></el-input>
								</el-form-item>

								<div class="fcj_ll">
									<div>
										<el-form-item label="联系人" prop="contacts">
											<el-input v-model="ruleForm.contacts" style="width:240px"></el-input>
										</el-form-item>
									</div>
									<div>
										<el-form-item label="联系电话" prop="phone">
											<el-input v-model="ruleForm.phone" style="width:240px"></el-input>
										</el-form-item>
									</div>
								</div>

								<div class="fcj_ll">
									<div>
										<el-form-item label="注册地" prop="areaid">
											<el-cascader size="large" :options="areaoptions" v-model="ruleForm.areaid"
												@change="areachange" style="width:240px">
											</el-cascader>
										</el-form-item>
									</div>
									<div>
										<el-form-item label="详细地址" prop="address">
											<el-input v-model="ruleForm.address" style="width:240px"></el-input>
										</el-form-item>
									</div>
								</div>

								<el-form-item label="注册地情况" prop="registplacestate">
									<el-radio-group v-model="ruleForm.registplacestate">
										<el-radio name="registplacestate" v-for="(item,index) in maps.registplacestate"
											:key="index" :label="item.fid">
											{{item.class_name}}
										</el-radio>
									</el-radio-group>
								</el-form-item>
								<div style="border-top:1px solid #EDF0F5;margin:40px 0;"></div>
								<el-form-item label="所属行业" prop="trade">
									<el-cascader v-model="ruleForm.trade" :options="maps.trade" @change="linkchange" :props="{value:'fid',label:'class_name'}"></el-cascader>
								</el-form-item>

								<el-form-item label="行业属性" prop="tradetype">
									<el-checkbox-group v-model="ruleForm.tradetype">
										<el-checkbox name="tradetype" v-for="(item,index) in maps.tradetype" :key="index"
											:label="item.fid">
											{{item.class_name}}
										</el-checkbox>
									</el-checkbox-group>
								</el-form-item>

								<el-form-item label="企业性质" prop="nature">
									<el-radio-group v-model="ruleForm.nature">
										<el-radio name="nature" v-for="(item,index) in maps.nature" :key="index"
											:label="item.fid">
											{{item.class_name}}
										</el-radio>
									</el-radio-group>
								</el-form-item>

								<el-form-item label="是否上市公司" prop="stockmarket">
									<el-radio-group v-model="ruleForm.stockmarket">
										<el-radio name="stockmarket" v-for="(item,index) in maps.stockmarket"
											:key="index" :label="item.fid">
											{{item.class_name}}
										</el-radio>
									</el-radio-group>
								</el-form-item>

								<el-form-item label="企业资质" prop="qualifications">
									<el-checkbox-group v-model="ruleForm.qualifications">
										<el-checkbox v-for="(item,index) in maps.qualifications" :key="index"
											:label="item.fid">
											{{item.class_name}}
										</el-checkbox>
									</el-checkbox-group>
								</el-form-item>

								<el-form-item label="企业证书" prop="certificate">
									<el-checkbox-group v-model="ruleForm.certificate">
										<el-checkbox name="certificate" v-for="(item,index) in maps.certificate"
											:key="index" :label="item.fid">
											{{item.class_name}}
										</el-checkbox>
									</el-checkbox-group>
								</el-form-item>

								<el-form-item label="客户类型" prop="customertype">
									<el-radio-group v-model="ruleForm.customertype">
										<el-radio name="customertype" v-for="(item,index) in maps.customertype"
											:key="index" :label="item.fid">
											{{item.class_name}}
										</el-radio>
									</el-radio-group>
								</el-form-item>

								<el-form-item label="客户来源" prop="source">
									<el-radio-group v-model="ruleForm.source">
										<el-radio name="source" v-for="(item,index) in maps.source" :key="index"
											:label="item.fid">
											{{item.class_name}}
										</el-radio>
									</el-radio-group>
								</el-form-item>

								<el-form-item label="客户来源说明" prop="sourceremark">
									<el-input type="textarea" v-model="ruleForm.sourceremark" :autosize="{ minRows: 2, maxRows: 4}" style="width:600px">
									</el-input>
								</el-form-item>

								<div class="fcj_ll">
									<div>
										<el-form-item label="员工人数" prop="personalnum">
											<el-input-number controls-position="right" :min="0"
												v-model="ruleForm.personalnum" style="width:106px;"></el-input-number>
										</el-form-item>
									</div>
									<div>
										<el-form-item label="外地员工人数" prop="outpersonalnum">
											<el-input-number controls-position="right" :min="0"
												v-model="ruleForm.outpersonalnum" style="width:106px;">
											</el-input-number>
										</el-form-item>
									</div>
									<div>
										<el-form-item label="外籍员工人数" prop="foreignpersonalnum">
											<el-input-number controls-position="right" :min="0"
												v-model="ruleForm.foreignpersonalnum" style="width:106px;">
											</el-input-number>
										</el-form-item>
									</div>
								</div>

								<div class="fcj_ll">
									<div>
										<el-form-item label="党员人数" prop="partypersonalnum">
											<el-input-number controls-position="right" :min="0"
												v-model="ruleForm.partypersonalnum" style="width:106px;">
											</el-input-number>
										</el-form-item>
									</div>
									<div>
										<el-form-item label="是否有党支部" prop="isparty">
											<el-radio-group v-model="ruleForm.isparty">
												<el-radio name="isparty" label="1">
													有
												</el-radio>
												<el-radio name="isparty" label="0">
													无
												</el-radio>
											</el-radio-group>
										</el-form-item>
									</div>
								</div>

								<el-form-item label="企业需求" prop="demand">
									<el-checkbox-group v-model="ruleForm.demand">
										<el-checkbox name="demand" v-for="(item,index) in maps.demand" :key="index"
											:label="item.fid">
											{{item.class_name}}
										</el-checkbox>
									</el-checkbox-group>
								</el-form-item>

								<el-form-item label="备注信息" prop="mainbusiness">
									<el-input type="textarea" v-model="ruleForm.mainbusiness" :autosize="{ minRows: 2, maxRows: 4}" style="width:600px">
									</el-input>
								</el-form-item>

								<el-form-item label="关联公司" prop="businessinfo">
									<el-input type="textarea" v-model="ruleForm.businessinfo" :autosize="{ minRows: 2, maxRows: 4}" style="width:600px">
									</el-input>
								</el-form-item>

								<div class="block" style="padding:20px 0;">
									<div class="fcjtitle" style="padding-left:20px;">对公开票信息</div>
									<el-form-item label="单位名称" prop="company">
										<el-input v-model="ruleForm.taxcompany" style="width:400px;"></el-input>
									</el-form-item>
									<el-form-item label="税号" prop="taxnumber">
										<el-input v-model="ruleForm.taxnumber" style="width:400px;"></el-input>
									</el-form-item>
									<el-form-item label="地址及电话" prop="taxaddress">
										<el-input v-model="ruleForm.taxaddress" style="width:400px;"></el-input>
									</el-form-item>
									<el-form-item label="开户行及账号" prop="bankinfo">
										<el-input v-model="ruleForm.taxbankinfo" style="width:400px;"></el-input>
									</el-form-item>
								</div>
								<div style="text-align: left;">
									<el-form-item>
										<el-button type="primary" @click="submitForm('ruleForm')" style="width:100px;">确定</el-button>
										<el-button @click="cancel" style="width:100px;">取消</el-button>
									</el-form-item>
								</div>
							</el-form>
						</div>
					</div>
				</div>

			</el-col>
		</el-row>
		<el-row :gutter="15" v-if="proview">
			<el-col :span="24">
				<div class="panel">
					<div class="panel-header">
						<div class="charttitle">合同列表</div>
					</div>
					<div class="panel-body pad16">
						<el-table :data="contract" header-cell-class-name="table-header" stripe>
							<el-table-column prop="number" label="编号" >
								<template slot-scope="scope">
								<router-link :to="'contractinfo?id='+scope.row.id">{{scope.row.number}}</router-link>
								</template>
							</el-table-column>
							<el-table-column prop="office" label="租赁物业" >
							</el-table-column>
							<el-table-column prop="state" label="合同状态" >
								<template slot-scope="scope">
									{{contractstate[scope.row.state]}}
								</template>
							</el-table-column>
							<el-table-column prop="dt" label="租赁周期" >
								<template slot-scope="scope">
									{{scope.row.regist_time+'~'+scope.row.term}}
								</template>
							</el-table-column>
						
							<el-table-column prop="personal" label="经办人">
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-col>
		</el-row>
		
		<el-row :gutter="15" v-if="proview">
			<el-col :span="24">
				<div class="panel">
					<div class="panel-header">
						<div class="charttitle"><div><img :src="path+'images/icon/history.png'" class="licon"></div><div>编辑历史记录</div></div>
					</div>
					<div class="panel-body pad16">
						<div class="stepbody">
						<div v-for="(item,index) in tableData" :key="index" class="stepitem">
							<div class="steporder stepdian">
								<div class="stepc"></div>
								<div class="stepline"></div>
							</div>
							<div style="margin-top:-4px;">
								<div class="fcj_ll" style="padding:0px 10px 40px 0;font-size:14px;">
									<div style="color: #96A1B3;width:160px;">{{item.dt}}</div>
									<div style="margin-right:20px ;">{{item.name||item.usern}}</div>
									<div>{{item.note}}</div>
								</div>
							</div>
						</div>
						</div>
						
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="15" v-if="proview">
			<el-col :span="24">
				<div class="panel">
					<div class="panel-header">
						<div class="charttitle"><div><i class="el-icon-user licon" style="color:#4D7CFE;font-weight: bold;"></i></div><div>绑定联系人</div></div>
					</div>
					<div class="panel-body pad16">
						<div><el-button @click="showadduser('')">添加人员</el-button></div>
						<el-table :data="binduserdata" style="width:100%"  stripe>

							<el-table-column label="姓名" prop="cname" width="100">

					

							</el-table-column>
							<el-table-column label="手机" prop="phone">

							</el-table-column>
							<el-table-column label="操作" >
								<template slot-scope="scope">

									<el-button @click="showadduser(scope)">编辑</el-button>
									<el-button @click="delbinduser(scope)">删除</el-button>
									
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-col>
		</el-row>

		<el-dialog :visible.sync="showadduserdiv"  width="500px" >
			<el-form :model="userform" :rules="userrule" ref="userform" label-width="100px" class="demo-ruleForm">
				<el-form-item label="姓名" prop="name">
					<el-input v-model="userform.name"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="userform.phone"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="adduser()">保存</el-button>
					<el-button @click="showadduserdiv = false">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		regionDataPlus,
		CodeToText
	} from 'element-china-area-data'
	export default {
		data() {
			return {
				binduserdata:[],
				showadduserdiv:false,
				path: this.$host,
				breadcrumb:[],
				title: '',
				CodeToText: CodeToText,
				proview: false,
				maps: {},
				areaoptions: regionDataPlus,
				selectedareaOptions: [],
				tableData: [],
				might: {},
				ruleForm: {
					areaname:'',
					cname: '',
					parent:'',
					contacts: '',
					phone: '',
					areaid: [],
					address: '',
					registplacestate: '',
					trade: [],
					tradetype: [],
					nature: '',
					stockmarket: '',
					qualifications: [],
					certificate: [],
					customertype: '',
					source: '',
					sourceremark: '',
					personalnum: '',
					outpersonalnum: '',
					foreignpersonalnum: '',
					partypersonalnum: '',
					isparty: '',
					demand: [],
					mainbusiness: '',
					businessinfo: '',
					taxcompany: '',
					taxnumber: '',
					taxaddress: '',
					taxbankinfo: '',
					id: ''
				},
				rules: {
					cname: [{
						required: true,
						message: '请输入客户名称',
						trigger: 'blur'
					}]
					,
					contacts: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入联系电话',
						trigger: 'blur'
					}],
					source: [{
						required: true,
						message: '请选择客户来源',
						trigger: 'blur'
					}],
					
					
					trade: [{
						type: 'array',
						required: true,
						message: '请选择所属行业',
						trigger: 'blur'
					}],
					registplacestate: [{
						required: true,
						message: '请选择注册地情况',
						trigger: 'blur'
					}]
					/*
					nature: [{
						required: true,
						message: '请选择企业性质',
						trigger: 'blur'
					}],
					stockmarket: [{
						required: true,
						message: '请选择是否上市',
						trigger: 'blur'
					}],
					customertype: [{
						required: true,
						message: '请选择客户类型',
						trigger: 'blur'
					}]*/

				},
				userform:{
					name:'',
					phone:'',
					id:'',
					asset_id:'',
					contractid:'',
				},
				userrule:{
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					phone:[{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}]
				},
				contract:[],
				contractstate:[],
				link:{}
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				var t='';
				if(vm.$route.query.proview){
					t='客户信息'
				}else{
					if(vm.$route.query.id){
					t='编辑客户'
					}else{
						t='添加客户'
					}
				}
				vm.$root.myrouter=vm.$breadcrumb(vm.$root.myrouter,to,t)
			})
		},
		mounted: function() {
			this.breadcrumb=this.$root.myrouter
			this.title = '添加客户'
			if (this.$route.query.id) {
				this.ruleForm.id = this.$route.query.id;
				this.title = '编辑客户'
			}
			if (this.$route.query.proview) {
				this.proview = true;
				this.title = '客户信息'
			}

			this.ini();
			this.host = this.$host
			this.getuser();
		},
		methods: {
			showadduser(v){
				this.showadduserdiv=true;
				console.log(v.row);
				this.userform={
					name:v?v.row.cname:'',
					phone:v?v.row.phone:'',
					id:v?v.row.id:'',
					customerid:this.ruleForm.id,
				}
				console.log(this.userform);
			},
			delbinduser(v){
				this.$ajax({
					url: 'bind/',
					data: {
						'do':'deladm',
						'id':v.row.id
					}
				}).then(res => {
					if(res.error==0){
						this.getuser();
					}else{
						this.$alert(res.msg);
					}
				});
			},
			adduser(id){
				if(id){
					this.binduserid=id;
				}else{
					this.binduserid='';
				}
				this.$refs['userform'].validate((valid) => {
					if (valid) {
						var data=this.userform;
						data.do='addcustomeruser'
						this.$ajax({
							url: 'bind/',
							data: data
						}).then(res => {
							if(res.error==0){
								this.getuser();
								this.showadduserdiv=false;
							}else{
								this.$alert(res.msg);
							}
						});
					}
				})
			},
			getuser(){
				this.$ajax({
					url: 'bind/',
					data: {
						'do':'list',
						'customerid':this.ruleForm.id
					}
				}).then(res => {
					this.binduserdata=res.res;
				});
			},
			linkchange(){
				var t=this.link[this.ruleForm.trade[this.ruleForm.trade.length-1]]
				if(t){
					this.ruleForm.tradetype=t;
				}else{
					this.ruleForm.tradetype=[]
				}
			},
			cancel() {
				this.$router.go(-1)
			},
			areachange(value) {
				console.log(value)
			},
			submitForm(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.ruleForm.outpersonalnum + this.ruleForm.foreignpersonalnum > this.ruleForm
							.personalnum) {
							this.$message({
								message: '外籍员工+外地员工不能大于员人总人数',
								type: 'warning'
							});
							return;
						}
						if (this.ruleForm.partypersonalnum > this.ruleForm
							.personalnum) {
							this.$message({
								message: '党员人数不能大于员工人数',
								type: 'warning'
							});
							return;
						}
						
						this.ruleForm.do = 'add';
						var data = this.ruleForm;

						this.$ajax({
							url: 'customer/',
							data: data,
						}).then(res => {
							if (res.error > 0) {
								this.$message({
									message: res.msg,
									type: 'warning'
								});
							} else {
								this.$message({
									message: '保存成功',
									type: 'success'
								});
								this.$router.go(-1)
							}

						})
					} else {
						return false;
					}
				});

			},
			modify() {
				this.proview = false;
				this.ini();
			},
			ini() {
				this.$ajax({
					url: 'customer/',
					data: {
						'do': 'ini',
						'type': this.proview ? 'key' : ''
					},
				}).then(res => {
					this.link=res.link
					this.maps = res.nav;

					// for(var i=0;i<this.maps.trade.length;i++){
					// 	this.maps.trade[i].value=this.maps.trade[i].fid;
					// 	this.maps.trade[i].label=this.maps.trade[i].label;
					// }
					if (this.ruleForm.id) {
						this.getdata();
					}
				})
			},
			getdata() {
				this.$ajax({
					url: 'customer/',
					data: {
						'do': 'list',
						'id': this.ruleForm.id
					},
				}).then(res => {
					if (res.c > 0) {
						this.ruleForm = res.res[0];
						if(res.contract.c>0){
						this.contract=res.contract.res
						}
						this.contractstate=res.contractstate
						if (this.proview) {
							this.ruleForm.areaname = codetot(this.ruleForm.areaid);
							if (res.history.c > 0) {
								this.tableData = res.history.res;
							}
						}
					} else {
						this.ruleForm.id = '';
						this.$message({
							message: '数据不存在',
							type: 'success'
						});
					}

				})
			}
		},
	}

	function codetot(v) {
		var area = [];
		for (var i = 0; i < v.length; i++) {
			if (v[i] != '') {
				area.push(CodeToText[v[i]]);
			}
		}
		return area.join('/')
	}

	function chs(res) {
		for (var i = 0; i < res.length; i++) {
			var f = res[i].fid,
				c = res[i].class_name,
				ch = res[i].children;
			res[i] = {
				value: f,
				label: c,
				children: ch
			};
			if (res[i].children.length > 0) {
				res[i].children = chs(res[i].children);
			} else {
				delete res[i].children;
			}
		}
		return res;
	}
</script>

<style>
</style>
