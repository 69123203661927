<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">账单管理</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		
		<div style="margin-bottom:40px;">
			<div  v-if="d">
				<el-row :gutter="20">
					<el-col :span="6">
						<el-card class="box-card box-card1">
							<div @click="rto('bill?type=rent')" class="box ">
								<div class="fcj_lc"><img :src="host+'images/icon/shop.png'" alt="" style="margin-right: 5px;width:24px;">租金物业费账单确认</div>
								<div class="tongji">
									<el-row type="flex" :gutter="0" justify="center">
										<el-col :span="12">
											<div class="tjnumber">{{res.rent.a}}</div>待确认（份）
										</el-col>
										<el-col :span="12">
											<div class="tjnumber">{{res.rent.money/100}}</div>总金额（元）
										</el-col>
									</el-row>
								</div>
							</div>
						</el-card>
					</el-col>
					<el-col :span="6">
						<el-card class="box-card box-card2">
							<div @click="rto('bill?type=ele')" class="box">
								<div class="fcj_lc"><img :src="host+'images/icon/dashboard.png'" alt="" style="margin-right: 5px;width:24px;">水电费账单确认</div>
								<div class="tongji">
									<el-row type="flex" :gutter="20" justify="center">
										<el-col :span="12">
											<div class="tjnumber">{{res.ele.a}}</div>待确认（份）
										</el-col>
										<el-col :span="12">
											<div class="tjnumber">{{res.ele.money/100}}</div>总金额（元）
										</el-col>
									</el-row>
								</div>
							</div>
						</el-card>
					</el-col>
					<el-col :span="6">
						<el-card class="box-card box-card3">
							<div @click="rto('bill?type=invoice')" class="box">
								<div class="fcj_lc"><img :src="host+'images/icon/yuan.png'" alt="" style="margin-right: 5px;width:24px;">收款及发票登记跟踪</div>
								<div class="tongji">
									<el-row type="flex" :gutter="20" justify="center">
										<el-col :span="12">
											<div class="tjnumber">{{res.invoice[0]}}</div>待收款（份）
										</el-col>
										<el-col :span="12">
											<div class="tjnumber">{{res.invoice[1]}}</div>侍开票（份）
										</el-col>
									</el-row>
								</div>
							</div>
						</el-card>
					</el-col>
					<el-col :span="6">
						<el-card class="box-card" style="color:#000;">
							<div @click="rto('bill?type=history')" class="box">
								<div><img :src="path+'images/icon/history-card.png'" style="width:57px;height:57px;margin: 0 auto 10px auto;"></div>
								<div>历史已结算账单</div>
								
							</div>
						</el-card>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="panel" style="margin-bottom:60px;">
			<div class="panel-body">
				
				<div>
					
					<div class="pad16">
						<div class="fcjtitle fcj_lr" style="margin:10px;">协议合同触发条款提醒<div><el-button @click="rto('/monthtj')">月度统计</el-button></div></div>
						<el-table :data="supplement" style="width:100%" header-cell-class-name="table-header" stripe>
							<el-table-column prop="number" label="合同编号" width="200px">
							</el-table-column>
							<el-table-column prop="company" label="客户名称">
							</el-table-column>
							<el-table-column prop="office" label="租赁物业">
							</el-table-column>
							<el-table-column prop="dt" label="触发日期">
							</el-table-column>
							<el-table-column prop="des" label="协议内容">
							</el-table-column>
							<el-table-column width="180" align="center" label="操作">
								<template slot-scope="scope">
									<el-popconfirm title="确认要关闭吗？" @confirm="closef(scope.$index, scope.row)" style="margin-right:10px;">
										<el-button slot="reference" size="small">关闭</el-button>
									</el-popconfirm>

									<el-button size="small">
										<router-link :to="'/bill?id='+scope.row.id">合同账单</router-link>
									</el-button>
								</template>
							</el-table-column>
							<template>
								<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
							</template>
						</el-table>
					</div>
				</div>
			</div>


		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				host:this.$host,
				supplement: [],
				path: this.$host,
				res:{},
				d:false
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=[to];
			})
		},
		mounted: function() {
			this.getdata();
		},
		methods: {
			closef(v, row) {
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'updatesup',
						id:row.sid
					}
				}).then(res => {
					if(res.error==0){
						this.supplement.splice(v,1)
					}
				})
			},
			rto(v) {
				localStorage.setItem('pageno','');
				this.$router.push(v);
			},
			getdata() {
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'webtj'
					}
				}).then(res => {
					this.d=true;
					this.res=res
					if (res.supplement.c > 0) {
						this.supplement = res.supplement.res
					}
				})

			}
		},
	}
</script>
<style scoped>
	.el-row {
		margin-bottom: 0px;
		}
	.box-card {
		text-align: center;
		box-shadow: 0px 20px 60px 0px rgba(0,0,0,0.04);
		border-radius: 8px;
		color:#fff;
		height: 143px;
	}
	.box-card1{
		background-image: linear-gradient(180deg, #FF7E88 0%, #D64458 95%);
	}
	.box-card2{
		background-image: linear-gradient(180deg, #79D7FF 0%, #477DFF 95%);
	}
	.box-card3{
		background-image: linear-gradient(180deg, #F385FF 0%, #8246F8 94%);
	}

	.box {
		cursor: pointer;
	}

	.tjnumber {
		margin: 10px 0;
		font-size: 28px;
		color:#fff;
	}

	.tongji {
		padding-top: 10px;
		font-size: 12px;
		color: #fff;
	}
</style>
