<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">巡检管理</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel">
					
					<div class="panel-body pad16">
						<div class="fcj_lr" style="border-bottom:1px solid #e4e7ed;padding-bottom: 20px;">
							<div class="fcj_ll">
								

								<div class="fc"><el-button :type="active=='goods'?'primary':'info'" plain @click="chac('goods')" size="small" icon="el-icon-refrigerator">项目管理</el-button></div>
								<div class="fc"><el-button :type="active=='lun'?'primary':'info'" plain @click="chac('lun')" size="small" icon="el-icon-picture">项目分类</el-button></div>
								<div class="fc"><el-button :type="active=='maint_log'?'primary':'info'" plain @click="chac('maint_log')" size="small" icon="el-icon-goods">维保记录</el-button></div>
								<div class="fc"><el-button :type="active=='users'?'primary':'info'" plain @click="chac('users')" size="small" icon="el-icon-goods">维保人员</el-button></div>
								<div class="fc"><el-button :type="active=='contract'?'primary':'info'" plain @click="chac('contract')" size="small" icon="el-icon-document">维保合同</el-button></div>
								<div class="fc"><el-button :type="active=='qrcode'?'primary':'info'" plain @click="chac('qrcode')" size="small" icon="el-icon-qrcode">维保二维码</el-button></div>


							</div>
						</div>

						
						<div v-show="active=='goods'">
							<div style="margin-top:20px;text-align: right;"><el-button type="primary" size="small" @click="showaddasset=true"  icon="el-icon-plus">添加项目</el-button></div>
							<div v-if="goods!=''">
								<el-table :data="goods" stripe style="width: 100%;" :cell-style="goodrow">
									
									<el-table-column prop="name" label="项目名称" width="200px">
										<template slot-scope="scope">
											<div @click="editgoods(scope.row)" class="link">{{scope.row.name}}</div>
										</template>
									</el-table-column>
									<el-table-column prop="remark" label="备注" >
									</el-table-column>
									<el-table-column prop="picsf" label="图片" >
										<template slot-scope="scope">
											<div class="fcj_ll"><div style="border-radius:5px;box-shadow: 0 0 10px #ddd;padding:5px;margin:10px;" v-for="(item,index) in scope.row.picsf" :key="item.id"><el-image 
												style="width: 50px; height: 50px;display: block;"
												:src="item" 
												fit="cover"
												:preview-src-list="scope.row.picsf" v-if="index<3&&item!=''">
											</el-image></div><div v-if="scope.row.picsf.length>3"> ...</div></div>
										</template>
									</el-table-column>
									<el-table-column prop="company" label="维保公司" >
									</el-table-column>
									<el-table-column prop="company" label="维保人员" width="200">
										
										<template slot-scope="scope">
											
												<div class="fcj_ll">
													<el-tag style="margin-right: 5px;" v-for="item in scope.row.users.res" :key="item.id"  closable @close="delauser(item.id)">{{item.name}}</el-tag>
												</div>
											
											
										</template>
									</el-table-column>
									<el-table-column prop="user" label="操作" >
										<template slot-scope="scope">
										<el-button size="mini" icon="el-icon-plus" @click="showadduser(scope.row)" type="default">添加人员</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<div style="padding:15px;text-align: right;">
								<el-pagination background layout="total,prev, pager, next" :current-page.sync="goods_pgno" :page-size="goods_pgsize"
									@current-change="getgoods" :total="goods_pgcount">
								</el-pagination>
							</div>
						</div>


						<div v-show="active=='lun'">
							
									
											
							<div class=" textright"  style="margin-top:20px;"><el-button type="primary" size="small"  icon="el-icon-plus" @click="showadd">添加主类</el-button></div>
							
							
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="ord" label="#" width="50">
								</el-table-column>
								<el-table-column prop="ord" label="排序" width="150">
									<template slot-scope="scope">
										<el-button size="mini" slot="reference" icon="el-icon-sort-down"
											@click.stop="sorts(scope.$index, scope.row,'down')" circle
											style="margin-right: 10px;">
										</el-button>
										<el-button size="mini" slot="reference" icon="el-icon-sort-up"
											@click.stop="sorts(scope.$index, scope.row,'up')" circle
											style="margin-right: 10px;">
										</el-button>
							
									</template>
								</el-table-column>
								<el-table-column prop="class_name" label="分类" >
									<template slot-scope="scope">
										<span class="span"
											:style="{width:scope.row.level*20+5+'px'}"></span>{{scope.row.class_name}}
									</template>
								</el-table-column>
								<el-table-column prop="note" label="备注">
								</el-table-column>
								<el-table-column label="" width="240">
									<template slot-scope="scope">

										<el-button size="mini" slot="reference" icon="el-icon-edit"
											@click.stop="edit(scope.$index, scope.row)" circle
											style="margin-right: 10px;">
										</el-button>
										<el-popover placement="top" width="60" v-model="scope.row.visible">
											<p>删除后与此属性相关的数据将找不到此属性！确定删除吗？</p>
											<div style="text-align: right; margin: 0">
												<el-button size="mini" type="text"
													@click.stop="scope.row.visible = false">取消</el-button>
												<el-button type="primary" size="mini"
													@click.stop="handleDelete(scope.$index, scope.row)">确定
												</el-button>
											</div>
											<el-button size="mini" slot="reference" icon="el-icon-delete" circle>
											</el-button>
										</el-popover>
							
									</template>
								</el-table-column>
							</el-table>
						</div>

						<div v-show="active=='maint_log'">
							<div class=" table-filter" style="margin-top:20px;">
								
									<div class="fcj_ll">
										<div>
											<el-date-picker v-model="mainlogsearch.day" size="small" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:270px;" @change="getmainlog"></el-date-picker>
										</div>
										<div>
											<el-input size="small" v-model="mainlogsearch.username" placeholder="巡检人" />
										</div>
										<div>
											<el-input size="small" v-model="mainlogsearch.assetname" placeholder="项目名称" />
										</div>
										<div>
											<el-input size="small" v-model="mainlogsearch.company" placeholder="维保公司" />
										</div>
										<div>
											<el-button size="small" @click="getmainlog" icon="el-icon-search" type="primary">查询</el-button>
										</div>
									</div>
									
							</div>
							<div v-if="mainlog!=''">
								<el-table :data="mainlog" header-cell-class-name="table-header" stripe>

									<el-table-column prop="create_time" label="巡检时间" width="100">
									</el-table-column>
									<el-table-column prop="name" label="维保项目" width="150px">
									</el-table-column>
									
									<el-table-column prop="username" label="维保人" >
									</el-table-column>
									<el-table-column prop="area" label="维保范围" >
									</el-table-column>
									<el-table-column prop="note" label="维保内容" >
									</el-table-column>
									<el-table-column prop="pics" label="图片" >
										<template slot-scope="scope">
											<div class="fcj_ll"><div style="border-radius:5px;box-shadow: 0 0 10px #ddd;padding:5px;margin:10px;" v-for="(item,index) in scope.row.pics" :key="item.id"><el-image 
											style="width: 50px; height: 50px;display: block;"
											:src="item" 
											fit="cover"
											:preview-src-list="scope.row.pics" v-if="index<3&&item!=''">
											</el-image></div><div v-if="scope.row.pics.length>3"> ...</div></div>
										</template>
									</el-table-column>
									<el-table-column prop="company" label="维保公司" >
									</el-table-column>
									<el-table-column prop="company" label="操作" width="180">
										<template slot-scope="scope">
											<el-popconfirm
											title="确定删除吗？"
											@confirm='dellog(scope.row)'
											>
												<el-button slot="reference" size="small" plain icon="el-icon-edit" style="margin-right: 5px;">删除</el-button>
											</el-popconfirm>

											<el-button size="small" @click="modifylog(scope.row)" plain icon="el-icon-edit" type="primary">修改</el-button>
										</template>
									</el-table-column>

									<template>
										<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
									</template>
								</el-table>
								<div style="padding:15px 0;text-align: right;">
									<div class="fcj_lr">
										<div>
											
										</div>
										<div>
											<el-pagination background layout="total,prev, pager, next" :current-page.sync="mainlog_pgno" :page-size="mainlog_pgsize"
												@current-change="getmainlog" :total="mainlog_pgcount">
											</el-pagination>

										</div>
										
									</div>
									
								</div>
							</div>
						</div>

						<div v-show="active=='users'">
							<div class=" table-filter" style="margin-top:20px;">
								<div class="fcj_lr">
									<div class="fcj_ll">
										
										<div>
											<el-input size="small" v-model="usersearch.name" placeholder="姓名" />
										</div>
										<div>
											<el-input size="small" v-model="usersearch.phone" placeholder="手机号" />
										</div>
										<div>
											<el-input size="small" v-model="usersearch.company" placeholder="维保公司" />
										</div>
										<div>
											<el-button size="small" @click="getusers" icon="el-icon-search" type="primary">查询</el-button>
										</div>
									</div>

									<div>
											<el-button size="small"  @click="showadduser()" icon="el-icon-search" type="primary">添加</el-button>
									</div>
								</div>
							</div>
							<div v-if="userslist!=''">
								<el-table :data="userslist" header-cell-class-name="table-header" stripe>
									<el-table-column prop="name" label="姓名" width="150px">
									</el-table-column>

									<el-table-column prop="phone" label="手机" >
									</el-table-column>

									<el-table-column prop="company" label="公司" >
									</el-table-column>
									<el-table-column prop="state" label="状态" >
										<template slot-scope="scope">
											{{scope.row.state==1?'拒绝访问':'正常'}}
										</template>
									</el-table-column>
									
									<el-table-column prop="id" label="操作" width="220">
										<template slot-scope="scope">
											<el-button size="small" icon="el-icon-delete" @click="deluser(scope.row)">删除</el-button>
											<el-button size="small"  @click="refuse(scope.row)">{{scope.row.state==0?'拒绝访问':'正常访问'}}</el-button>

										</template>
									</el-table-column>
									<template>
										<div slot="empty" style="padding:50px 0;"> <img :src="host+'images/empty.png'" class="emptyimg" alt=""><div>暂无数据</div></div>
									</template>
								</el-table>
								<div style="padding:15px 0;text-align: right;">
									<div class="fcj_lr">
										<div>
											
										</div>
										<div>
											<el-pagination background layout="total,prev, pager, next" :current-page.sync="users_pgno" :page-size="users_pgsize"
												@current-change="getusers" :total="users_pgcount">
											</el-pagination>

										</div>
										
									</div>
									
								</div>
							</div>
						</div>

						<div v-show="active=='contract'">
							<div style="margin-top:20px;text-align: right;"><el-button type="primary" size="small" @click="showaddcontractd"  icon="el-icon-plus">添加合同</el-button></div>

							<div v-if="contract!=''">
								<el-table :data="contract" stripe style="width: 100%;" :cell-style="goodrow">
									
									<el-table-column prop="name" label="合同名称" width="200px">
										<template slot-scope="scope">
											<div @click="editcontract(scope.row)" class="link">{{scope.row.name}}</div>
										</template>
									</el-table-column>
									<el-table-column prop="range" label="合同周期" >
										<template slot-scope="scope">
											{{scope.row.start_time+' ~ '+scope.row.end_time}}
										</template>
									</el-table-column>
									<el-table-column prop="content" label="合同内容" >
									</el-table-column>
									<el-table-column prop="create_time" label="录入时间" >
									</el-table-column>
									<el-table-column prop="create_time" label="操作" width="120">
										<template slot-scope="scope">
											<el-popconfirm
											title="确定删除吗？"
											@confirm='delcontract(scope.row)'
											>
												<el-button slot="reference" size="small" plain icon="el-icon-edit" style="margin-right: 5px;">删除</el-button>
											</el-popconfirm>
											
										</template>
									</el-table-column>
								</el-table>
							</div>

							<div style="padding:15px;text-align: right;">
								<el-pagination background layout="total,prev, pager, next" :current-page.sync="contract_pgno" :page-size="contract_pgsize"
									@current-change="getcontract" :total="contract_pgcount">
								</el-pagination>
							</div>
						</div>

						<div v-show="active=='qrcode'">
							<img :src="host+qrcodeurl" v-if="qrcodeurl!=''">
						</div>
					</div>

					

				</div>
					
			</el-col>
		</el-row>

		<el-dialog :visible.sync="showaddasset" width="700px" title="项目管理" :close-on-click-modal="false">
			<el-form :model="assetform" :rules="assetrule" ref="assetform" label-width="100px" class="demo-ruleForm">
				<el-form-item label="维保公司" prop="name">
					<el-autocomplete
					v-model="comkey"
					:fetch-suggestions="searchcom"
					placeholder="请输入内容"
					@select="handleSelect"
					style="width:550px"
					>
						<template slot-scope="{ item }">
							<div class="fcj_ll">
								<div style="margin-right:20px;">{{ item.value }}</div>
							</div>
						
						</template>
					</el-autocomplete>
				</el-form-item>
				<el-form-item label="项目名称" prop="name">
					<el-input v-model="assetform.name" style="width:550px;"></el-input>
				</el-form-item>
				<el-form-item label="项目分类" prop="name">
					<el-cascader v-model="assetform.fid" :options="tableData"  :props="{value:'fid',label:'class_name'}"></el-cascader>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input type="textarea" v-model="assetform.remark" style="width:550px;" rows="5"></el-input>
				</el-form-item>
				<el-form-item label="图片" prop="pics">
					<el-upload :action="host+'upimg/'" list-type="picture-card"
					ref="upload" name="upfile" :data="uploaddata" :on-success="uploadsuccess"
					:file-list="fileList" :auto-upload="false"
					:on-change="uploadchange">
						<i slot="default" class="el-icon-plus"></i>
						<div slot="file" slot-scope="{file}">
							<img class="el-upload-list__item-thumbnail" :src="file.url"
								alt="">
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview"
									@click="handlePictureCardPreview(file)">
									<i class="el-icon-zoom-in"></i>
								</span>
								<span v-if="!disabled"
									class="el-upload-list__item-delete"
									@click="handleDownload(file)">
									<i class="el-icon-download"></i>
								</span>
								<span v-if="!disabled"
									class="el-upload-list__item-delete"
									@click="handleRemove(file)">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>

					</el-upload>
					<el-dialog :visible.sync="dialogVisible" append-to-body>
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</el-form-item>
				

				<el-form-item>
					<el-button type="primary" @click="addasset()">保存</el-button>
					<el-button @click="showaddasset = false">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog :visible.sync="showaddcontract" width="700px" title="合同管理" :close-on-click-modal="false" >
			<el-form :model="contractform" :rules="contractrule" ref="contractform" label-width="100px" class="demo-ruleForm">
				
				<el-form-item label="合同名称" prop="name">
					<el-input v-model="contractform.name" style="width:550px;"></el-input>
				</el-form-item>
				<el-form-item label="合同周期" prop="name">
					<el-date-picker v-model="contractform.daterange" type="daterange"
						style="margin-right:10px;" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" size="small" value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				
				<el-form-item label="合同内容" prop="remark">
					<el-input type="textarea" v-model="contractform.content" style="width:550px;" rows="5"></el-input>
				</el-form-item>
				

				<el-form-item>
					<el-button type="primary" @click="addcontract()">保存</el-button>
					<el-button @click="clearcontractform">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	
		<el-dialog :visible.sync="showapalist" width="700px" title="添加维保人员信息">
			<el-form :model="userform" :rules="userrule" ref="userform" label-width="100px" class="demo-ruleForm">
				<el-form-item label="姓名" prop="name">
					<el-input v-model="userform.name" style="width:550px;"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="userform.phone" style="width:550px;"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="adduser()">保存</el-button>
					<el-button @click="showapalist = false">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>


		<el-dialog :visible.sync="isshowadd">
			<div class="panel">
				<div class="panel-header">
					<div class="charttitle">{{formname}}</div>
				</div>
				<div class="panel-body pad16">


					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="分类名" prop="class_name">
							<el-input v-model="ruleForm.class_name"></el-input>
						</el-form-item>


						<el-form-item label="备注" prop="note">
							<el-input type="textarea" v-model="ruleForm.note"></el-input>
						</el-form-item>


						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
							<el-button @click="resetForm('ruleForm')">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="isshoweditlog" title='修改维保内容'>
			<div class="panel">
				<div class="panel-body ">


					<el-form :model="aclog"  ref="aclogruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="维保范围" prop="class_name">
							<el-input type="textarea" v-model="aclog.area"></el-input>
						</el-form-item>


						<el-form-item label="维保内容" prop="note">
							<el-input type="textarea" v-model="aclog.note" rows="5"></el-input>
						</el-form-item>


						<el-form-item>
							<div style="margin-top:20px;">
							<el-button type="primary" @click="modifylog('aclog')">保存</el-button>
							<el-button @click="resetForm('aclogruleForm')">重置</el-button>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-dialog>


	</div>
</template>

<script>
	export default {
		data() {
			return {

				aclog:{},
				isshoweditlog:false,
				qrcodeurl:'',

				activeName: 'asset',
				isshowadd: false,
				tableData: [],
				formname: '角色管理',
				might: {},
				ruleForm: {
					class_name: '',
					note: '',
					sku: '',
					plid: '0',
					logo: '',
					ord: '',
					fid: ''
				},
				rules: {
					class_name: [{
						required: true,
						message: '请输入分类名称',
						trigger: 'blur'
					}]
				},
				comkey:'',
				restaurants:[],



				showaddcontract:false,
				contractform:{
					id:'',
					name:'',
					content:'',
					daterange:[]
				},
				contractrule:{
					name: [{
						required: true,
						message: '请输入合同名称',
						trigger: 'blur'
					}],
					range:[{
						required: true,
						message: '请输入合同时间范围',
						trigger: 'blur'
					}]
				},
				contract:'',
				contract_pgno:1,
				contract_pgsize:12,
				contract_pgcount:0,



				isshowedit:false,
				goods_pgno:1,
				goods_pgsize:12,
				goods_pgcount:0,
				goods:'',
				assetform:{
					id:'',
					name:'',
					fid:0,
					remark:'',
					contractid:0,
					pics:[],
				},
				assetrule:{

				},
				showaddasset:false,
				active:'goods',


				host:'',
				uploaddata: {
					pusern: localStorage.getItem('pusern'),
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				fileList: [],
				disabled: false,
				dialogVisible:false,
				dialogImageUrl:'',


				mainlog:'',
				mainlogsearch:{
					day:[],
					username:'',
					assetname:'',
					company:''
				},
				mainlog_pgcount:0,
				mainlog_pgno:1,
				mainlog_pgsize:20,
				mainlog_all:0,

				userslist:'',
				usersearch:{
					name:'',
					phone:'',
					company:''
				},	
				users_pgcount:0,
				users_pgno:1,
				users_pgsize:20,

				showapalist:false,
				userform:{
					name:'',
					phone:'',
					id:'',
					asset_id:'',
					contractid:'',
				},
				userrule:{
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					phone:[{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}]
				},
				
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=[to];
			})
		},
		mounted: function() {

			this.host = this.$host
			this.getgoods();
			this.ini();

		},
		methods: {
			dellog(v){
				this.$ajax({
					url: 'maintenance/',
					data: {
						'do': 'delmainlog',
						'id': v.id
					},
				}).then(res => {
					if (res.error > 0) {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						this.getmainlog();
					}


				})
			},
			modifylog(v){
				if(v=='aclog'){
					this.$ajax({
						url: 'maintenance/',
						data: {
							'do': 'modimainlog',
							'area': this.aclog.area,
							'note':this.aclog.note,
							'id':this.aclog.id
						},
					}).then(res => {
						if (res.error > 0) {
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							});
						} else {
							this.isshoweditlog=false;
							this.getmainlog();
						}


					})
				}else{
					this.isshoweditlog=true;
					this.aclog={
						'id':v.id,
						'area':v.area,
						'note':v.note
					}

				}
			},
			edit(index, row) {
				this.isshowadd = true;
				var f = {
					class_name: row.class_name,
					note: row.note,
					sku: row.sku,
					plid: row.plid,
					logo: row.logo,
					ord: row.ord,
					fid: row.fid
				}
				this.ruleForm = f;
			},
			addchild(i, row) {
				this.isshowadd = true;
				var f = {
					class_name: '',
					note: '',
					sku: '',
					plid: row.fid,
					logo: '',
					ord: parseInt(row.ord) + 1,
					fid: ''
				}
				this.ruleForm = f;
			},
			showadd() {
				this.isshowadd = true;
				var f = {
					class_name: '',
					note: '',
					sku: '',
					plid: '0',
					logo: '',
					ord: '',
					fid: ''
				}
				this.ruleForm = f;
			},
			handleDelete(index, row) {
				this.$ajax({
					url: 'navset/',
					data: {
						'op': 'del',
						'fid': row.fid
					},
				}).then(res => {
					if (res.error > 0) {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						this.ini();
					}


				})
			},
			sorts(index, row, v) {
				this.$ajax({
					url: 'navset/',
					data: {
						'op': 'sort',
						'sort': v,
						'fid': row.fid
					},
				}).then(res => {
					this.ini();

				})

			},

			submitForm(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.ruleForm.op = 'add';
						var data = this.ruleForm;
						this.$ajax({
							url: 'navset/',
							data: this.ruleForm,
						}).then(res => {
							if (res.error > 0) {
								this.$message({
									message: res.msg,
									type: 'warning'
								});
							} else {
								this.$message({
									message: '保存成功',
									type: 'success'
								});
								this.showadd();
								this.ini();
							}

						})
					} else {
						return false;
					}
				});

			},
			ini() {
				this.$ajax({
					url: 'navset/',
					data: {
						'navname': this.activeName
					},
				}).then(res => {
					this.tableData = res.res;
					// for (var i = 0; i < res.groups.length; i++) {
					// 	var a = res.groups[i].mights?res.groups[i].mights.split(','):[];
					// 	res.groups[i].mights=a;
					// 	res.groups[i].mname = [];
					// 	for (var j = 0; j < a.length; j++) {

					// 		res.groups[i].mname.push(res.might[a[j]].name);
					// 	}
					// }
					// this.tableData = res.groups;
					// this.might = res.might

				})
			},





			showadduser(v){
				this.showapalist=true
				this.userform={
					name:'',
					phone:'',
					id:'',
					asset_id:v?v.id:0,
					contractid:v?v.contractid:0,
				}
			},
			edituser(v){
				this.userform.id=v.id;
				this.userform.name=v.name;
				this.userform.phone=v.phone;
				this.userform.asset_id=v.asset_id;
				this.userform.contractid=v.contractid;
				this.showapalist=true;
			},
			delauser(v){
				this.$ajax({
					url: 'maintenance/',
					data: {
						'do':'delauser',
						'id':v
					}
				}).then(res => {
					this.getgoods();
				});
			},
			refuse(v){
				this.$ajax({
					url: 'maintenance/',
					data: {
						'do':'refuse',
						'id':v.id
					}
				}).then(res => {
					this.getusers();
				});
			},
			deluser(v){
				this.$ajax({
					url: 'maintenance/',
					data: {
						'do':'deluser',
						'id':v.id
					}
				}).then(res => {
					this.getusers();
				});
			},
			adduser(){
				this.$refs['userform'].validate((valid) => {
					if (valid) {
						var data=this.userform;
						data.do='adduser'
						this.$ajax({
							url: 'maintenance/',
							data: data
						}).then(res => {
							if(res.error==0){
								if(data.asset_id){
									this.getgoods();

								}else{
									this.getusers();
								}
								this.showapalist=false;
								this.$message({
									message: res.msg,
									type: 'success'
								});
							}else{
								this.$alert(res.msg);
							}
						});
					}
				})
			},



			handleSelect(item) {
				this.assetform.contractid=item.id;
			},
			searchcom(queryString,cb){

				if(queryString!=''){
					this.$ajax({
						url: 'maintenance/',
						data: {
							'do': 'company',
							'key':queryString
						}
					}).then(res => {
						if(res.data==''){
							cb([])
						}else{

						cb(res.data);
						}
					});
				}
				
			},
			showaddad(v){
				this.adform.type=v;
				this.isshowedit=true
			},
			qingkong(){
				this.adform.link='';
			},
			goodrow(){
				return {'padding':'5px'};
			},
			chac(v){
				this.active=v;
				if(v=='goods'){
					this.getgoods();
				}else if(v=='users'){
					this.getusers();
				}else if(v=='lun'){
					this.ini()
				}else if(v=='qrcode'){
					this.getqrcode();
				}else if(v=='contract'){
					this.getcontract();
				}else if(v=='maint_log'){
					this.getmainlog();
				}
			},
			getqrcode(){
				this.$ajax({
					url: 'maintenance/',
					data: {
						'do': 'qrcode'
					}
				}).then(res => {
					this.qrcodeurl=res.msg
				});
			},
			getusers(){
				this.$ajax({
					url: 'maintenance/',
					data: {
						'do': 'users',
						'pgno':this.users_pgno,
						'pgsize':this.users_pgsize,
						'search':this.usersearch
					}
				}).then(res => {
					if(res.error>0){
						this.$alert(res.msg);
					}
					this.userslist=res.data.res;
					this.users_pgcount=parseInt(res.data.count);
				});
			},
			getmainlog(){
				this.$ajax({
					url: 'maintenance/',
					data: {
						'do': 'mainlog',
						'pgno':this.mainlog_pgno,
						'pgsize':this.mainlog_pgsize,
						'search':this.mainlogsearch
					}
				}).then(res => {
					if(res.error>0){
						this.$alert(res.msg);
					}
					this.mainlog=res.data.res;
					this.mainlog_pgcount=parseInt(res.data.count);
					this.mainlog_all=res.data.all;
				});
			},

			dellun(v){
				this.$ajax({
					url: 'mod_ads/',
					data: {
						'do': 'del',
						'id': v.id
					},
				}).then(res => {
					this.getads();
				})
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},


			getads(v){
				this.$ajax({
					url: 'mod_ads/',
					data: {
						'do': 'ads',
						'type': [this.adform.type],
						'styleid':1
					},
				}).then(res => {
					this.ads[this.adform.type] = res[this.adform.type];
				})
			},


			handleRemove(file) {
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].uid == file.uid) {
						this.fileList.splice(i, 1);
						break;
					}
				}
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleDownload(file) {
				console.log(file);
				console.log(this.fileList);
			},
			uploadsuccess(response, file, fileList) {
				this.assetform.pics = [];
				this.fileList = fileList;
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].response == undefined) {
						break;
					} else {
						if(typeof(this.fileList[i].response)=='object'){
							this.assetform.pics.push(this.fileList[i].response.fpath)
						}else{
							this.assetform.pics.push(this.fileList[i].response)
						}
					}
				}
				console.log(this.fileList);
				if (i == this.fileList.length) {
					this.sub();
				}

				//this.buluform.pics.push(response);
			},
			uploadchange(file, fileList) {
				this.fileList = fileList;
			},

			sub(){
				this.$ajax({
					url: 'maintenance/',
					data: {
						'do': 'update_asset',
						'form':this.assetform
					}
				}).then(res => {
					this.showaddasset=false;
					this.assetform={
						id:'',
						name:'',
						fid:0,
						remark:'',
						contractid:0,
						pics:[],
					}
					this.restaurants=[];
					this.comkey='';
					this.fileList=[];
					this.goods_pgno=1;
					this.getgoods();
				})
			},
			editgoods(v){
				this.assetform=v
				this.fileList=[];
				if (this.assetform.pics.length > 0) {
					for (var i = 0; i < this.assetform.pics.length; i++) {
						this.fileList.push({
							name: '',
							url:  this.host + this.assetform.pics[i],
							response: this.assetform.pics[i]
						})
					}
				}
				this.comkey=v.company
				this.showaddasset=true
			},
			addasset(){
				this.$refs['assetform'].validate((valid) => {
					if (valid) {
						if (this.fileList.length == 0) {
							this.sub();
						} else {
							this.assetform.pics = [];
							for (var i = 0; i < this.fileList.length; i++) {
								if (this.fileList[i].response == undefined) {
									break;
								} else {
									if(typeof(this.fileList[i].response)=='object'){
										this.assetform.pics.push(this.fileList[i].response.fpath)
									}else{
										this.assetform.pics.push(this.fileList[i].response)
									}
								}
							}
							
							if (i == this.fileList.length) {
								this.sub();
							} else {
								this.$refs.upload.submit();
							}
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			getgoods(){
				this.$ajax({
					url: 'maintenance/',
					data: {
						'do': 'goods',
						'pgsize':this.goods_pgsize,
						'pgno':this.goods_pgno
					}
				}).then(res => {
					this.goods=res.data.res;
					for (var j = this.goods.length - 1; j >= 0; j--) {
						if(!this.goods[j].picsf){
							this.goods[j].picsf=[];
						}
						for (var i = 0; i < this.goods[j].pics.length; i++) {
							if(this.goods[j].pics[i]!=''){
								this.goods[j].picsf[i]=this.host+this.goods[j].pics[i];
							}
						}
					}
					this.goods_pgcount=parseInt(res.data.count);
				})
			},


			clearcontractform(){
				this.showaddcontract=false
				this.contractform={
					id:'',
					name:'',
					content:'',
					daterange:[]
				}
			},
			delcontract(v){
				this.$ajax({
					url: 'maintenance/',
					data: {
						'do':'delcontract',
						'id':v.id
					}
				}).then(res => {
					this.getcontract();
				});
			},
			showaddcontractd(){
				this.clearcontractform();
				this.showaddcontract=true;
			},
			editcontract(v){
				// v.daterange=[v.start_time,v.end_time];
				
				// this.contractform=JSON.parse(JSON.stringify(v));
				// this.contractform.daterange=[v.start_time,v.end_time];
				this.contractform={
					id:v.id,
					name:v.name,
					content:v.content,
					daterange:[v.start_time,v.end_time]
				}
				this.showaddcontract=true
			},

			addcontract(){
				this.$refs['contractform'].validate((valid) => {
					if (valid) {
					this.contractform.do='editcontract'
					this.$ajax({
						url: 'maintenance/',
						data: this.contractform
					}).then(res => {
						this.getcontract();
						this.clearcontractform();
					});
				}else{
					return false;
				}
				})
			},
			getcontract() {
				this.$ajax({
					url: 'maintenance/',
					data: {
						'do': 'contract',
						'pgsize':this.contract_pgsize,
						'pgno':this.contract_pgno
					}
				}).then(res => {
					this.contract=res.data.res;
					
					this.contract_pgcount=parseInt(res.data.count);
				})
			}
		}
	};
</script>

<style scoped>
	.demo-tag .el-tag+.el-tag{
		margin-left:10px;
	}
	.item{
		margin:20px;
		box-shadow: 0 0 10px #ddd;
		padding:10px;
		width:120px;
		height: 120px;
		border-radius: 5px;
	}

	.item_shop{
		margin:10px;
		box-shadow: 0 0 10px #ddd;
		padding:10px;
		width:120px;
		height: 210px;
		border-radius: 5px;
		font-size:13px;
	}

	.item_title{
		margin:5px 0 20px 0;
		height: 36px;
		line-height:18px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.item_bg{
		width:120px;
		background-size:cover;
		background-position: center;
		height:120px;
	}
	.item>div{
		width:100%;
		background-size:cover;
		background-position: center;
		height:100%;
	}
	.fc{
		margin-right: 10px;
	}

</style>
