<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><div class="pagetitle">首页设置</div></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="24">

				<div class="panel">

					<div class="panel-body">


						<div class="fcj_ll" style="align-items:flex-start;">
							<div style="padding:20px;margin:20px;">
								<div style="width:500px;">
									<el-carousel :interval="4000" height="500px" :autoplay="false" :initial-index="initial_index" type="card"
										@change="chindexmodel" trigger='click' ref="zma" style="overflow: hidden;width: 500px;">
										<el-carousel-item style="border-radius: 20px;">
											<img :src="host+'images/modelpic/1.jpg'" alt="" style="height: 100%;width:100%;">
										</el-carousel-item>
										<el-carousel-item style="border-radius: 20px;">
											<img :src="host+'images/modelpic/2.jpg'" alt="" style="height: 100%;width:100%;">
										</el-carousel-item>
										<el-carousel-item style="border-radius: 20px;">
											<img :src="host+'images/modelpic/3.jpg'" alt="" style="height: 100%;width:100%;">
										</el-carousel-item>
										
										<el-carousel-item style="border-radius: 20px;">
											<img :src="host+'images/modelpic/4.jpg'" alt="" style="height: 100%;width:100%;">
										</el-carousel-item>
										<el-carousel-item style="border-radius: 20px;">
											<img :src="host+'images/modelpic/5.jpg'" alt="" style="height: 100%;width:100%;">
										</el-carousel-item>
										<el-carousel-item style="border-radius: 20px;">
											<img :src="host+'images/modelpic/6.jpg'" alt="" style="height: 100%;width:100%;">
										</el-carousel-item>
									</el-carousel>
								</div>
								
								<div class="fcj_lc" style="margin-top:20px;">
									<div>
										<el-radio-group v-model="accolor" @change="colorchange">
											<el-radio :label="1"><div class="colorblock colorblock1"></div></el-radio>
											<el-radio :label="2"><div class="colorblock colorblock2"></div></el-radio>
											<el-radio :label="3"><div class="colorblock colorblock3"></div></el-radio>
										</el-radio-group>
									</div>
								</div>

								<div style="text-align: center;padding:20px" class="fcj_lc">
									<div><img :src="host+previewimg" alt="" style="width:100px;height: 100px;" v-if="previewimg!=''"></div>
									<div style="padding: 0 20px;"><el-button type="warning" @click="preview">预览</el-button></div>
									<div><el-button type="primary" @click="setindexmodel">使用</el-button></div>
									
								</div>
							</div>
							<div style="margin:40px 60px;" v-if="adtypelist.length>0">

								<div v-for="(item,index) in adtypelist" :key="index" class="stepitem">
									<div class="steporder">
										<div class="stepc">{{index+1}}</div>
										<div class="stepline"></div>
									</div>
									<div>
										<div style="padding: 3px 0px;margin-bottom:10px;border-radius: 5px;font-size:14px;color:#121212;min-width:500px"
											class="fcj_ll">
											<div>{{item.name}}</div>
											<div>
												<div style="font-size:12px;color:#4D7CFE;margin-left:20px;cursor: pointer;" @click="getadlist(item)"
													:type="adform.type==item.type?'primary':'default'" >
													{{item.name}}</div>
											</div>
											<div class="fcj_ll" @click="news(item)">
												<img :src="host+'images/icon/indexset_plus.png'" style="width:16px;margin-left:20px;">
												<div style="font-size:12px;color:#4D7CFE;cursor: pointer;margin-left:5px;"
													:type="adform.type==item.type?'primary':'default'" >
													新建</div>
											</div>
										</div>

										<div :class="[item.type=='kuozhan'?'kuozhan':'fcj_ll tt']"
											style="align-items: flex-start;font-size:12px;" v-if="ads[item.type]">
											<div v-for="(iitem,iindex) in ads[item.type].res" :key="iitem.id">
												<div @mouseover="eshow(iitem)" @mouseout="ehide(iitem)" v-if="iitem.content.img!=''" class="ttimg" :style="'width:'+(60/item.imgwh[1]*item.imgwh[0])+'px;background-image:url('+host+iitem.content.img+')'">
													<div v-show="iitem.show">
													<img :src="host+'images/icon/setclose.png'" class="setclose" @click="handleDelete(item.type,iitem,iindex)">
													<div class="btn-mini" @click="edit(item,iitem,iindex)">编辑</div>
													</div>
												</div>
												<div :style="'width:'+(60/item.imgwh[1]*item.imgwh[0])+'px;'">
													<div v-if="iitem.content.modelname!=''">{{iitem.content.modelname}}</div>
													<div v-if="iitem.content.title!=''">{{iitem.content.title}}</div>
													<div v-if="iitem.content.content!=''">{{iitem.content.content}}</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div  class="stepitem">
									<div class="steporder">
										<div class="stepc">-</div>
										<div class="stepline"></div>
									</div>
									<div>
									<div style="padding: 3px 0px;margin-bottom:10px;border-radius: 5px;font-size:14px;color:#121212;min-width:500px"
										class="fcj_ll">
										<div>动态设置</div>
										<div>
											<div style="font-size:12px;color:#4D7CFE;margin-left:20px;cursor: pointer;" @click="showgzh()" >动态设置</div>
										</div>
									</div>
									</div>
								</div>
							</div>

						</div>


					</div>
					<div class="panel-foot">
						<div class="pad16"></div>
					</div>
				</div>
			</el-col>

		</el-row>
		<el-dialog :visible.sync="isshowlist" :title="formname" width="800px">
			<div>
			<div>
				<el-button @click="news('')" type="primary">新建</el-button>
			</div>
			<div v-if="index_all.c>0">
				<div v-for="(item,index) in index_all.res" :key="index" class="fcj_ll" style="margin:20px;">
					<div style="width:90px;">
						<el-button size="mini" slot="reference" icon="el-icon-sort-down"
							@click.stop="sorts(index,item,'down')" circle style="margin-right: 10px;">
						</el-button>
						<el-button size="mini" slot="reference" icon="el-icon-sort-up"
							@click.stop="sorts(index,item,'up')" circle style="margin-right: 10px;">
						</el-button>
					</div>
					<div style="width:50px;cursor:pointer" @click="edit(index,item)">
						<img :src="host+item.content.img" alt="" v-if="item.content.img!=''"
							style="width:40px;height: 40px;">
					</div>
					<div @click="edit(index,item)" style="flex:1;cursor:pointer">
						{{(item.content.modelname?item.content.modelname:'')+' '+item.content.title}}</div>
					<div style="width:100px">

						<el-switch v-model="item.view" active-color="#13ce66" inactive-color="#ff4949" active-value="1"
							inactive-value="0" @change="editover(item)">
						</el-switch>
						{{item.view==1?'显示':'不显示'}}

					</div>
					<div style="width:80px;">
						<el-button @click="handleDelete(index,item)" icon="el-icon-delete" size="mini">删除</el-button>
					</div>
				</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="isshowedit" :title="formname" width="900px" :close-on-click-modal="false">

			<el-form :model="adform" :rules="adformrule" label-width="100px" ref="adform" v-show="adform.type!=''">

				<el-form-item :label="item.name" :prop="item.ename" v-for="(item,index) in fieldobj.field" :key="index">
					<el-input v-model="adform[item.ename]" v-if="item.type=='text'" style="width:400px"></el-input>
					<div v-show="item.type=='img'">
						<div class="fcj_ll">
							<div>
								<el-image v-if="adform[item.ename]" style="height: 80px;margin-right: 10px;"
									:src="host+adform[item.ename]"></el-image>
							</div>
							<div v-if="item.wh" style="padding:0 10px;">
								图片长宽(px)：{{wh?wh:(typeof(item.wh)=='object'?item.wh[indexcss]:item.wh)}}
							</div>
						</div>
						<div class="fcj_ll">
							<div>
								<el-upload :action="host+'upimg/'" :data="uploaddata" list-type="text"
									:ref="'uploadparkpics'" name="upfile" :on-success="uploadparkpicsuccess"
									:auto-upload="true" :limit="1">
									<el-button type="primary" size="medium" icon="el-icon-picture-outline">上传图片</el-button>
								</el-upload>
							</div>
							<div v-if="item.imgurl">
								<el-button size="medium" @click="showmodelpic(item)" style="margin-left:10px;">选择模板图片</el-button>
							</div>
						</div>
					</div>
					<el-select v-model="adform[item.ename]" placeholder="请选择" v-if="item.type=='select'">
						<el-option v-for="items in item.options" :key="items" :label="items" :value="items">
						</el-option>
					</el-select>
					
					<div v-if="item.type=='selectimg'" class="fcj_ll" style="width:400px;overflow-x: auto;">
						<div v-for="items in item.options" :key="'se'+items" :class="['modeli',items==adform[item.ename]?'modelse':'']">
							<img :src="host+'images/modelpic/'+adform.type+items+'.jpg'" @click="chmodelid(items,item)" style="height: 60px;min-width:100px;background-color:#ddd;margin:0 10px 10px 0;" alt="">
							<div class="chdiv"></div>
						</div>
						
					</div>
					<div class="proview" v-if="item.type=='selectimg'" :style="'background-image:url('+host+'images/modelpic/'+adform.type+adform[item.ename]+'.jpg);'"></div>
				</el-form-item>

				

				<el-form-item label="跳转类型" prop="linktype">
					<el-radio-group v-model="adform.linktype">
						<el-radio name="linktype" :label="1" :value="1">功能模块</el-radio>
						<el-radio name="linktype" :label="2" :value="2">小程序页面</el-radio>
						<el-radio name="linktype" :label="3" :value="3">公众号链接</el-radio>
						<el-radio name="linktype" :label="0" :value="0">不跳转</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="链接" prop="link">
					<el-input v-model="adform.link" v-if="adform.linktype==2||adform.linktype==3"></el-input>
					<el-select v-model="adform.link" placeholder="请选择" v-if="adform.linktype==1">
						<el-option v-for="item in sysmodel.res" :key="item.url" :label="item.modelname"
							:value="item.url">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="submitForm('adform')">保存</el-button>
					<el-button @click="resetForm('adform')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog :visible.sync="isshowgzh" title="动态管理" width="1000px">
			<div class="fcj_lr" style="align-items: flex-start;margin-top:-20px;">
				<div style="flex:1;padding:10px;">
					<div class="fcj_lr">
						<div>公众号文章</div>
						<div><el-button type="primary" size="small" @click="getarticle">获取文章</el-button></div>
					</div>
					<div style="height: calc(100vh - 500px);overflow-y: auto;">
						<div v-if="loadinggzh" style="height: 100px;line-height: 100px;text-align: center;color:#666;">正在加载...</div>
						<div class="detail" v-for="(item,index) in gzlist.item" :key="index">
							<div style="float:right"><img :src="host+'images/icon/dongtaiarrow.png'" style="width:20px;height: 20px;" @click="addarticle(item)" ></div>
							<div style="margin:10px 0;">{{item.update_time}}</div>
							<div>{{item.content.news_item[0].title}}</div>
							
							
						</div>
						
					</div>
				</div>
				<div style="flex:1;padding:10px;">
					<div class="fcj_ll">
						<div style="padding-right: 20px;padding-left: 15px;">
							展示样式
						</div>
						<div><el-radio-group v-model="dongtaistyle" @change="dongtaich">
							<el-radio :label="1">大图</el-radio>
							<el-radio :label="2">左图</el-radio>
							<el-radio :label="3">右图</el-radio>
						</el-radio-group></div>
						
					</div>
					<div class="fcj_lr" style="margin:0 15px;">
						<div>园区动态</div>
						<div>
							<el-button size="small" type="primary" @click="showgzh">全部</el-button>
							<el-button size="small" type="primary" @click="showgzh(1)">仅首页</el-button>
						</div>
					</div>
					<div style="height: calc(100vh - 500px);overflow-y: auto;">
						<div class="detail" v-for="(item,index) in gzharticle.res" :key="index">
							<div style="float:right;"><el-button size="mini" @click="dyhindex(item)" :icon="item.isindex==1?'el-icon-success':''">首页显示</el-button><el-button size="mini" @click="del(item.id)">删除</el-button></div>
							<div style="font-size:14px;margin:10px 0;">{{item.ntime}}</div>
							<div style="display:flex;">
								<div style="flex:1;">{{item.title}}</div>
								
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</el-dialog>
		
		<el-dialog  :visible.sync="isshoweditpic" :append-to-body="true" width="680px">
			<iframe  style='width:650px;height:400px' frameborder="no" scrolling="no" border=0 name='resizeiframe' id='resizeiframe' :src="host+'control/resizepic.php?pic='+wh+'&picurl='+host+adform.img+'&m='" width="" height=""></iframe>
		</el-dialog>
		<el-dialog  :visible.sync="isshowmodelpic" :append-to-body="true" width="680px">
			<div style="display: flex;justify-content: flex-start;height: 600px;overflow: auto;flex-wrap: wrap;">
				<div v-for="(item,index) in modelpics" :key="'model'+index" style="margin:5px;">
					<el-image :src="host+item" alt="" fit="scale-down" style="width:115px;height: 115px;" @click="chmodelimg(item)"></el-image>
				</div>
			</div>
		</el-dialog>
		

	</div>
</template>

<script>
	var backimg=function(){};
	export default {
		data() {
			return {
				dongtaistyle:1,
				acmodel:'',
				showarr:[true],
				isshoweditpic:false,
				isshowmodelpic:false,
				isshowgzh:false,
				isshowedit: false,
				isshowlist: false,
				uploaddata: {
					pusern: '',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				addnew:false,
				host: '',

				adform: {
					'id': '',
					'title': '',
					'img': '',
					'content': '',
					'modelname': '',
					'cssid': '',
					'link': '',
					'picwh': '',
					'type': '',
					'linktype': '',
					'orde': '',
					'view': ''
				},
				adformrule: {

				},
				rules: {
					gname: [{
						required: true,
						message: '请输角色名称',
						trigger: 'blur'
					}],
					mights: [{
						type: 'array',
						required: true,
						message: '请至少选择一个权限',
						trigger: 'blur'
					}]
				},
				adtypelist: [],
				fieldobj: {},
				index_all: [],
				sysmodel: {},
				iii: '',
				ads: [],
				gzharticle:{},
				gzlist:{},
				indexcss:0,
				formname:'',
				loadinggzh:false,
				initial_index:0,
				accolor:1,
				previewimg:'',
				acrow:'',
				wh:'',
				actype:'',
				modelpics:[],
			}
		},
		mounted: function() {
			this.uploaddata.pusern = localStorage.getItem('pusern');
			this.ini();
			this.host = this.$host
			window.backimg=this.editok;
		},
		methods: {
			dongtaich(){
				this.$ajax({
					url: 'getarticle/',
					data: {
						'do': 'setstyle',
						'id':this.dongtaistyle
					},
					
				}).then(res => {
				})
			},
			eshow(item){
				this.$set(item,'show',true);
			},
			ehide(item){
				this.$set(item,'show',false);
			},
			showeditpic(){
				this.isshoweditpic=true;
			},
			editok(v){
				this.adform.img =v;
				this.isshoweditpic=false;
			},
			preview(){
				this.$ajax({
					url: 'sysset/',
					data: {
						'op': 'preview',
						'id':this.indexcss+1,
						'accolor':this.accolor
					},
					
				}).then(res => {
					if(res.error==0){
						this.previewimg=res.msg;
					}
				})
			},
			getarticle(){
				this.loadinggzh=true
				this.$ajax({
					url: 'getarticle/',
					data: {
						'do': 'showfeed',
						'source':0
					},
					
				}).then(res => {
					
					for(var i=0;i<res.item_count;i++){
						var n=new Date(parseInt(res.item[i].update_time) * 1000);
						res.item[i].update_time=n.getFullYear()+'-'+(n.getMonth()+1)+'-'+n.getDate()
					}
					this.gzlist=res
					this.loadinggzh=false
				})
				
			},
			del(v){
				
				this.$ajax({
					url: 'getarticle/',
					data: {
						'do': 'delfeed',
						'fid':v
					},
					
				}).then(res => {
					this.showgzh();
					
				})
			},
			addarticle(item){
				this.$ajax({
					url: 'getarticle/',
					data: {
						'do': 'addfeed',
						'title':item.content.news_item[0].title,
						'pic':item.content.news_item[0].thumb_url,
						'url':item.content.news_item[0].url
					},
					
				}).then(res => {
					this.showgzh()
				})
					
			},
			dyhindex(v){
				this.$ajax({
					url: 'getarticle/',
					data: {
						'do': 'setindex',
						'id':v.id,
						'v':v.isindex==1?0:1
					},
					
				}).then(res => {
					if(res.error==0){
						v.isindex=v.isindex==1?0:1
					}
				})
			},
			showgzh(v){
				this.isshowgzh=true
				this.$ajax({
					url: 'getarticle/',
					data: {
						'do': 'listfeed',
						'index':v==1?v:''
					},
					
				}).then(res => {
					this.gzharticle=res
					this.dongtaistyle=res.c>0?parseInt(res.res[0].color):1;
				})
			},
			setindexmodel(){
				this.$ajax({
					url: 'sysset/',
					data: {
						'op': 'chindexmodel',
						'id':this.indexcss+1,
						'accolor':this.accolor
					},
					
				}).then(res => {
					if(res.error==0){
						this.$message({
							message:res.msg,
							type:'success'
						});
					}
				})
			},
			colorchange(){
				this.previewimg=''
				
			},
			chindexmodel(v) {
				this.indexcss=v;
				this.previewimg=''
				this.getads();
			},
			showmodelpic(item){
				this.isshowmodelpic=true;
				this.modelpics=item.imgurl
			},
			chmodelimg(v){
				this.adform.img=v;
				this.isshowmodelpic=false
			},
			uploadparkpicsuccess(response, e) {
				this.adform.img = response.fpath;
				var wh='';
				for(var i in this.fieldobj.field){
					if(this.fieldobj.field[i].type=='selectimg'){
						this.wh=this.fieldobj.field[i].wh[this.adform.cssid-1].join('|');
						break;
					}else if(this.fieldobj.field[i].type=='img'){
						if(typeof(this.fieldobj.field[i].wh)=='object'){
							this.wh=this.fieldobj.field[i].wh[this.indexcss].join('|')
						}else{
							this.wh=this.fieldobj.field[i].wh.replace(',','|');
						}
						break;
					}
				}

				this.showeditpic(response.fpath);
				for (i = 0; i < this.iii.length; i++) {
					if (this.iii[i]) {
						this.iii[i].clearFiles()
					}
				}
			},
			chmodelid(v,item){
				this.adform[item.ename]=v;
				this.wh=item.wh[v-1].join('|');
			},
			handleClick(tab, event) {
				this.ini();
			},
			edit(index, row,t) {
				this.wh=''
				if(isNaN(index)){
					this.fieldobj=index.content;
					this.adform.type = index.type
					this.adform.picwh = index.picwh
					
					this.index_all = this.ads[index.type]
					if(index.name){
					this.formname=index.name;
					}
					const that = this;
					this.$nextTick(() => {
						that.iii = this.$refs.uploadparkpics;
					
					})
				}
				
				this.addnew=false;
				this.acrow=row;
				var f = {
					'id': row.id,
					'title': row.content.title,
					'img': row.content.img,
					'content': row.content.content,
					'link': row.content.link,
					'modelname': row.content.modelname,
					'cssid': row.content.cssid,
					'picwh': row.picwh,
					'type': row.type,
					'linktype': parseInt(row.linktype),
					'orde': row.orde,
					'view': row.view
				};
				this.adform = f;
				this.isshowedit = true;
				
				this.formname=this.ads[row.type].name
						
			},
			editover(v) {
				this.$ajax({
					url: 'mod_ads/',
					data: {
						'do': 'editview',
						'view': v.view,
						'id': v.id
					}
				}).then(res => {

				})
			},
			handleDelete(index, row,t) {
				
				this.$ajax({
					url: 'mod_ads/',
					data: {
						'do': 'del',
						'id': row.id
					},
				}).then(res => {
					if (res.error > 0) {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						if(!isNaN(index)){
							this.index_all.res.splice(index,1)
						}else{
							this.ads[index].res.splice(t,1)
						}
						
						
					}


				})
			},
			sorts(index, row, v) {
				this.$ajax({
					url: 'mod_ads/',
					data: {
						'do': 'sort',
						'sort': v,
						'id': row.id
					},
				}).then(res => {
					
					this.getads();

				})

			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			submitForm(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.adform.do = 'add';
						this.adform.styleid = this.indexcss+1;
						var data = this.adform;
						this.$ajax({
							url: 'mod_ads/',
							data: this.adform,
						}).then(res => {
							if (res.error > 0) {
								this.$message({
									message: res.msg,
									type: 'warning'
								});
							} else {
								this.$message({
									message: '保存成功',
									type: 'success'
								});
								if(this.acrow!=''){
									for(var s in res.data.content){
										this.acrow.content[s]=res.data.content[s];
									}
								}else{
									this.ads[this.adform.type].c++;
									this.ads[this.adform.type].res.push(res.data)
								}
								this.resetForm
								
								this.isshowedit = false
							}

						})
					} else {
						return false;
					}
				});

			},
			ini() {
				this.$ajax({
					url: 'mod_ads/',
					data: {},
				}).then(res => {
					for(var i=0;i<res.ad.c;i++){
						for(var j=0;j<res.ad.res[i].content.count;j++){
							if(res.ad.res[i].content.field[j].type=='img'){
								if(typeof(res.ad.res[i].content.field[j].wh)=='object'){
									res.ad.res[i].imgwh=res.ad.res[i].content.field[j].wh[this.indexcss]
								}else{
									res.ad.res[i].imgwh=res.ad.res[i].content.field[j].wh.split(',');
								}
								break;
							}
						}
						res.ad.res[i]
					}
					this.adtypelist = res.ad.res;
					this.sysmodel = res.sysmodel;
					this.indexcss=res.acsys.styleid-1
					this.accolor=parseInt(res.acsys.color)
					this.$refs.zma.setActiveItem(res.acsys.styleid-1)
					this.getads();
				})

				
			},
			getads(){
				this.$ajax({
					url: 'mod_ads/',
					data: {
						'do': 'ads',
						'type': ['lun', 'gongneng', 'kuozhan'],
						'styleid':this.indexcss+1
					},
				}).then(res => {
					this.ads = res;
					if(this.actype!=''){
					this.index_all = this.ads[this.actype.type]
					}
				})
			},
			news(v) {
				if(v){
					this.fieldobj=v.content;
					this.adform.type = v.type
					this.adform.picwh = v.picwh
					
					this.index_all = this.ads[v.type]
					if(v.name){
					this.formname=v.name;
					}
					const that = this;
					this.$nextTick(() => {
						that.iii = this.$refs.uploadparkpics;
					
					})
				}
				this.wh='';
				this.acrow='';
				this.addnew=true;
				this.isshowedit = true;
				this.adform.id = '',
				this.adform.title = '',
				this.adform.img = '',
				this.adform.content = '',
				this.adform.modelname = '',
				this.adform.cssid = '',
				this.adform.link = '',
				this.adform.linktype = '',
				this.adform.orde = '',
				this.adform.view = ''
				for (var i = 0; i < this.iii.length; i++) {
					if (this.iii[i]) {
						this.iii[i].clearFiles()
					}
				}


			},
			getadlist(v) {
				this.actype=v;
				this.fieldobj=v.content;
				this.adform.id = '';
				this.adform.type = v.type
				this.adform.picwh = v.picwh
				
				this.index_all = this.ads[v.type]
				this.isshowlist = true
				if(v.name){
				this.formname=v.name;
				}
				const that = this;
				this.$nextTick(() => {
					that.iii = this.$refs.uploadparkpics;
				
				})
				
				
				// this.$ajax({
				// 	url: 'mod_ads/',
				// 	data: {
				// 		do: 'list',
				// 		type: v.type,
				// 		'styleid':this.indexcss+1
				// 	},
				// }).then(res => {
				// 	this.adform.id = '';
				// 	this.adform.type = v.type
				// 	this.adform.picwh = v.picwh
				// 	this.fieldobj = res.fieldobj;
				// 	this.index_all = res.index_all
				// 	this.isshowlist = true
				// 	if(v.name){
				// 	this.formname=v.name;
				// 	}
				// 	const that = this;
				// 	this.$nextTick(() => {
				// 		that.iii = this.$refs.uploadparkpics;

				// 	})



				// })
			}
			


		},
	}
</script>

<style>
	.modeli{
		position: relative;
	}
	.chdiv{
		display: none;
		position: absolute;
		right:16px;
		top:5px;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		background-color: red;
	}
	.modelse .chdiv{
		display: block;
	}
	
	
	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 200px;
		margin: 0;
	}

	.el-carousel__item{
		background-color: #d3dce6;
		box-shadow: 0 0 3px #ccc;
	}

	.kuozhan{
		margin:10px 0;
		display: flex;
		flex-direction: column;
	}
	.kuozhan .ttimg {
		margin: 10px 0px;
		height: 60px;
		border-radius: 5px;
		position: relative;
		height:60px;
		background-size:cover;
		background-position: center;
	}

	.tt {
		text-align: center;
		margin: 20px -5px;
	}

	.tt .ttimg {
		height: 60px;
		border-radius: 5px;
		margin: 5px;
		position: relative;
		height:60px;
		background-size:cover;
		background-position: center;
	}
	.detail{
		background-color: #fff;
		border-radius: 5px;
		padding:15px;
		margin:10px;
		font-size:13px;
		border:1px solid #e4e8f0;
		position: relative;
	}
	.el-carousel__item--card.is-active{
		box-shadow: 0 0 20px #ccc;
	}
</style>
