<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right" v-if="breadcrumb.length>0">
				<div class="pagetitle">{{breadcrumb[breadcrumb.length-1].name}}</div>
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadcrumb" :key="index" v-show="index!=breadcrumb.length-1">{{item.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="24">
				<div class="panel">
					<div class="panel-body pad16">

						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm" style="width:500px;">
							<el-form-item label="场地名称" prop="rname">
								<el-input v-model="ruleForm.rname"></el-input>
							</el-form-item>
							<el-form-item label="位置说明" prop="address">
								<el-input  v-model="ruleForm.address"></el-input>
							</el-form-item>
							<el-form-item label="咨询电话" prop="phone">
								<el-input  v-model="ruleForm.phone"></el-input>
							</el-form-item>
							<el-form-item label="显示标题" prop="title">
								<el-input  v-model="ruleForm.title"></el-input>
							</el-form-item>
							<el-form-item label="场地简介" prop="description">
								<el-input type="textarea" v-model="ruleForm.description" maxlength="255" show-word-limit></el-input>
							</el-form-item>
							<el-form-item label="场地面积" prop="area">
								<el-input  v-model="ruleForm.area"></el-input>
							</el-form-item>
							<el-form-item label="最多容纳人数" prop="personal">
								<el-input  v-model="ruleForm.personal"></el-input>
							</el-form-item>
							<el-form-item label="最小租赁时长" prop="mintime">
								<el-radio-group v-model="ruleForm.mintime">
									<el-radio name="tp" label="1">
										时租
									</el-radio>
									<el-radio name="tp" label="2">
										半天
									</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="租金（每小时）" prop="price">
								<el-input  v-model="ruleForm.price"></el-input>
							</el-form-item>
							<el-form-item label="租金（半天）" prop="helfday">
								<el-input  v-model="ruleForm.helfday"></el-input>
							</el-form-item>
							<el-form-item label="租金（全天）" prop="oneday">
								<el-input  v-model="ruleForm.oneday"></el-input>
							</el-form-item>
							<el-form-item label="图片展示样式" prop="pictype">
								<el-radio-group v-model="ruleForm.pictype">
									<el-radio name="tp" label="1" value="1">
										单图
									</el-radio>
									<el-radio name="tp" label="2">
										双图
									</el-radio>
									<el-radio name="tp" label="3">
										多图
									</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="展示图片" prop="pics">
								<el-upload :action="host+'upimg/'" list-type="picture-card"
									ref="upload" name="upfile" :data="uploaddata" :on-success="uploadsuccess"
									:file-list="fileList" :auto-upload="false"
									:on-change="uploadchange">
									<i slot="default" class="el-icon-plus"></i>
									<div slot="file" slot-scope="{file}">
										<img class="el-upload-list__item-thumbnail" :src="file.url"
											alt="">
										<span class="el-upload-list__item-actions">
											<span class="el-upload-list__item-preview"
												@click="handlePictureCardPreview(file)">
												<i class="el-icon-zoom-in"></i>
											</span>
											<span 
												class="el-upload-list__item-delete"
												@click="handleRemove(file)">
												<i class="el-icon-delete"></i>
											</span>
										</span>
									</div>
								
								</el-upload>
								
								
							</el-form-item>
							<el-form-item label="场地展示状态" prop="state">
								<el-radio-group v-model="ruleForm.state">
									<el-radio name="tp" label="1">
										展示
									</el-radio>
									<el-radio name="tp" label="0">
										隐藏
									</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="场地租赁状态" prop="rentstate">
								<el-radio-group v-model="ruleForm.rentstate">
									<el-radio name="tp" label="1">
										可租
									</el-radio>
									<el-radio name="tp" label="0">
										停租
									</el-radio>
								</el-radio-group>
							</el-form-item>
							
							
							

							<el-form-item>
								<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
								<el-button @click="resetForm('ruleForm')">取消</el-button>
							</el-form-item>
						</el-form>
				

					</div>
				</div>
			</el-col>

		</el-row>

		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				breadcrumb:[],
				id:'',
				title:'添加场地',
				acyuan:{},
				uploaddata: {
					pusern: '',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				host:'',
				fileList: [],
				ruleForm: {
					'rname':'',
					'address':'',
					'phone':'',
					'title':'',
					'description':'',
					'area':'',
					'personal':'',
					'mintime':'1',
					'price':'',
					'helfday':'',
					'oneday':'',
					'pictype':'1',
					'pics':[],
					'state':'',
					'rentstate':'',

				},
				rules: {
					rname: [{
						required: true,
						message: '请输入场地名称',
						trigger: 'blur'
					}]
				}
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=vm.$breadcrumb(vm.$root.myrouter,to)
			})
		},
		mounted: function() {
			this.breadcrumb=this.$root.myrouter
			this.host = this.$host
			this.id = this.$route.query.id;
			if(this.id){
				this.title="编辑场地"
				this.ini();
			}
			this.uploaddata.pusern=localStorage.getItem('pusern');
		},
		methods: {
			
			uploadsuccess(response, file, fileList) {
				this.ruleForm.pics = [];
				this.fileList = fileList;
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].response == undefined) {
						break;
					} else {
						if(typeof(this.fileList[i].response)=='object'){
							this.ruleForm.pics.push(this.fileList[i].response.id)
						}else{
							this.ruleForm.pics.push(this.fileList[i].response)
						}
					}
				}
				if (i == this.fileList.length) {
					this.sub();
				}
			
				//this.buluform.pics.push(response);
			},
			uploadchange(file, fileList) {
				this.fileList = fileList;
			},
			handleRemove(file) {
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].uid == file.uid) {
						this.fileList.splice(i, 1);
						break;
					}
				}
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			submitForm(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						if (this.fileList.length == 0) {
							this.sub();
						} else {
							this.ruleForm.pics = [];
							for (var i = 0; i < this.fileList.length; i++) {
								if (this.fileList[i].response == undefined) {
									break;
								} else {
									if(typeof(this.fileList[i].response)=='object'){
										this.ruleForm.pics.push(this.fileList[i].response.id)
									}else{
										this.ruleForm.pics.push(this.fileList[i].response)
									}
								}
							}
							
							if (i == this.fileList.length) {
								this.sub();
							} else {
								this.$refs.upload.submit();
							}
						}
						
						
						
					} else {
						return false;
					}
				});

			},
			sub(){
				var data={'do':'addreservation','id':this.id};
				
				data.formdata = this.ruleForm;
				this.$ajax({
					url: 'reservation/',
					
					data: data,
				}).then(res => {
					if (res.error > 0) {
						this.$message({
							message: res.msg,
							type: 'warning'
						});
					} else {
						this.$message({
							message: '保存成功',
							type: 'success'
						});
						this.$router.go(-1)
					}
				
				})
			},
			ini() {
				this.$ajax({
					url: 'reservation/',
					data:{
						'do':'reservationinfo',
						'id':this.id
					}
				}).then(res => {
					if(res.error==0){
						this.ruleForm=res.data.res[0];
						if (this.ruleForm.pics.length > 0) {
							for (var i = 0; i < this.ruleForm.pics.length; i++) {
								this.fileList.push({
									name: '',
									url: this.host + this.ruleForm.pics[i].fpath,
									response: this.ruleForm.pics[i].id
								})
							}
						}
					}
				})
			},


			
		},
	}
</script>

<style>
	
</style>
