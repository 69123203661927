<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right" v-if="breadcrumb.length>0">
				<div class="pagetitle">{{breadcrumb[breadcrumb.length-1].name}}</div>
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadcrumb" :key="index" v-show="index!=breadcrumb.length-1">{{item.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="panel">
			<div class="panel-body pad16">
				
					<div class="fcj_lr table-filter">
						<div class="fcj_ll">
							<el-select v-model="search.state" slot="prepend" clearable size="small" placeholder="请选择状态"
								@change="getdata" style="margin-right:10px;">
								<el-option v-for="(item,index) in statemap" :key="index" :label="item" :value="index">
								</el-option>
							</el-select>
							<el-select v-model="search.tp" slot="prepend" clearable size="small" placeholder="请选择分类"
								@change="getdata" style="margin-right:10px;">
								<el-option v-for="(item,index) in repairtype" :key="index" :label="item" :value="index">
								</el-option>
							</el-select>
						</div>
						
						<div style="margin-left:10px;">
							<el-button icon="el-icon-edit" size="small" type="primary" @click="isbulushow=true">报修补录
							</el-button>
						</div>
					</div>
				

				<el-table :data="list"  header-cell-class-name="table-header" stripe cell-class-name="fcj">
					<el-table-column width="20">
					</el-table-column>

					<el-table-column prop="number" label="报修单号">
					</el-table-column>
					<el-table-column prop="time" label="报修时间">
					</el-table-column>
					<el-table-column label="分类">
						<template slot-scope="scope">
							<div v-for="(item,index) in scope.row.retp" :key="index" style="display:inline;">
								{{item.tpname}}{{scope.row.retp.length-1!=index?'、':''}}
							</div>
						</template>
					</el-table-column>
					<el-table-column label="维修区域" width="200">
						<template slot-scope="scope">
							<div :title="scope.row.adr">{{scope.row.adr}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="note" label="故障描述" width="200" >
						<template slot-scope="scope">
							<div :title="scope.row.note">{{scope.row.note}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="state" label="状态">
						<template slot-scope="scope">
							<el-tag :type="btnstyle[scope.row.state]?btnstyle[scope.row.state]:'default'">
								{{statemap[scope.row.state]}}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="money" label="操作" width="160">
						<template slot-scope="scope">

							<el-button size="mini" type="warning" @click="goinfo(scope.row)" icon="el-icon-edit"
								:style="{'margin-right':'8px'}">处理</el-button>

							<el-popover placement="top" width="60" v-model="scope.row.visible">
								<p>确定删除吗？</p>
								<div style="text-align: right; margin: 0">
									<el-button size="mini" type="text" @click.stop="scope.row.visible = false">取消
									</el-button>
									<el-button type="primary" size="mini"
										@click.stop="handleDelete(scope.$index, scope.row)">确定
									</el-button>
								</div>
								<el-button size="mini" slot="reference" icon="el-icon-delete" circle>
								</el-button>
							</el-popover>

						</template>
					</el-table-column>
				</el-table>

				<div style="padding:15px;text-align: right;">
					<el-pagination background layout="total,prev, pager, next" :current-page.sync="pgno" :page-size="pgsize"
						@current-change="page" :total="pgcount">
					</el-pagination>
				</div>
			</div>
		</div>

		<el-dialog :visible.sync="isshow" width="600px">

			<div style="line-height: 150%;" class="rlist">
				<div><span>报修单号：</span>{{acrepair.number}}</div>
				<div><span>报修时间：</span>{{acrepair.time}}</div>

				<div><span>电话：</span>{{acrepair.phone}}</div>
				<div>
					<span>维修区域：</span>{{acrepair.address}}
				</div>
				<div>
					<span>图片：</span><el-image v-for="(item,index) in acrepair.pics" :key="index"
						style="width: 100px; height: 100px;margin:0 5px 5px 0"
						:src="item" 
						:preview-src-list="acrepair.pics"></el-image>
				</div>
				<div style="border-bottom:2rpx solid #ddd;margin:20rpx;"></div>

				<div>
					<span>报修来源：</span>
					<el-radio-group v-model="repairform.source">
						<el-radio-button label="0">外部报修</el-radio-button>
						<el-radio-button label="1">内部报修</el-radio-button>
					</el-radio-group>
				</div>
				<div>
					<span>是否收费：</span>
					<el-radio-group v-model="repairform.paytype">
						<el-radio-button label="0">否</el-radio-button>
						<el-radio-button label="1">是</el-radio-button>
					</el-radio-group>
				</div>

				<div v-if="repairform.paytype==1" style="display:flex;align-items:center;margin: 10rpx 0;">
					<span>收费金额：</span>
					<div>
						<el-input-number  :controls="false" :step="1" :min="0" v-model="repairform.fee" style="width:100px"></el-input-number>
					</div>
				</div>


				<div style="margin: 10rpx 0;" v-for="(item,index) in retp" :key="index">
					<span>{{item.tpname}}：</span>
					<div>
						<el-select v-model="tmptp[index]" placeholder="请选择">
							<el-option v-for="(iitem,iindex) in item.rename" :key="iindex" :label="iitem.tname"
								:value="iitem.id">
							</el-option>
						</el-select>
					</div>
				</div>


				<div style="margin: 10rpx 0;">
					<span>报修状态：</span>

					<el-select v-model="repairform.state" placeholder="请选择">
						<el-option v-for="(item,index) in statemap" :key="index" :label="item" :value="index">
						</el-option>
					</el-select>


				</div>

				<div style="margin: 10rpx 0;display:flex;align-items:flex-start;">
					<span>备注：</span>
					<div style="flex:1">
						<el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="repairform.remark">
						</el-input>

					</div>
				</div>


				<div style="display:flex;justify-content: center;">
					<div style="padding:20rpx;text-align:center;">
						<el-button type="primary" @click="subset()">保存</el-button>
					</div>
					<div style="padding:20rpx;text-align:center;">
						<el-button @click="calset()">取消</el-button>
					</div>
				</div>
			</div>

		</el-dialog>


		<el-dialog :visible.sync="isbulushow" width="700px">
			<div class="buluform">
				<el-form ref="buluform" :model="buluform" label-width="140px">
					<el-form-item label="请选择故障类别">
						<el-checkbox-group v-model="buluform.tp">
							<el-checkbox :label="index" name="type" v-for="(item,index) in repairtype" :key="index">
								{{item}}
							</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<div class="address">
						<el-form-item label="公司" >
							<el-input v-model="buluform.office"></el-input>
						</el-form-item>
						<el-form-item label="地址" >
							<div class="fcj_ll">
								<div><property v-model="chproperty" :fsize="['small']" @change="ch"  /></div>
								<div style="padding-left:10px;">{{chproperty.propertyname}}</div>
							</div>
						</el-form-item>
						<el-form-item label="其他" >
							<el-input v-model="buluform.other"></el-input>
						</el-form-item>
						
					</div>
					<el-form-item label="故障描述">
						<el-input type="textarea" v-model="buluform.note"></el-input>
					</el-form-item>
					<el-form-item label="报修来源">
						<el-radio-group v-model="buluform.source">
							<el-radio label="0">外部报修</el-radio>
							<el-radio label="1">内部报修</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="维修响应期望">
						<el-radio-group v-model="buluform.back">
							<el-radio label="0">不急</el-radio>
							<el-radio label="1">有点急</el-radio>
							<el-radio label="2">非常急</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="联系电话">
						<el-input v-model="buluform.phone" style="width:300px"></el-input>
					</el-form-item>
					<el-form-item label="联系人">
						<el-input v-model="buluform.name" style="width:300px"></el-input>
					</el-form-item>

					<el-form-item label="图片">
						<el-upload 
						action="http://localhost/zhfty/upimg/" 
						list-type="picture-card" 
						ref="upload"
						name="upfile" 
						:data="uploaddata"
						:on-success="uploadsuccess" 
						:on-change="uploadchange"
						:file-list="fileList" 
						:auto-upload="false"
						>
							<i slot="default" class="el-icon-plus"></i>
							<div slot="file" slot-scope="{file}">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="handleDownload(file)">
										<i class="el-icon-download"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="handleRemove(file)">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>

						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</el-form-item>


					<el-form-item>
						<el-button type="primary" @click="createrepair()" icon="el-icon-document">立即创建</el-button>
						<el-button @click="isbulushow=false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import property from '../components/property.vue'
	export default {
		components: {
			property
		},
		data() {
			return {
				uploaddata: {
					pusern: '',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				btnstyle:['default','danger','info','success','success'],
				breadcrumb:[],
				chproperty:{},
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				fileList: [],
				buluform: {
					area: 1,
					tp: [],
					building: '',
					office: '',
					other: '',
					note: '',
					source:'',
					back: '',
					phone: '',
					name: '',
					pics: '',
					buildingid:'',
					file: []
				},
				isbulushow: false,
				isshow: false,
				retp: [],
				acrepair: {},
				search: {
					state: '',
					tp:''
				},
				repairform: {
					paytype: '',
					source: '',
					fee: '',
					tmptp: [],
					state: ''
				},
				repairtype: {},
				tmptp: [],
				statemap: [],
				list: [],
				pgno: 1,
				pgcount: 0,
				pgsize:13
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=vm.$breadcrumb(vm.$root.myrouter,to)
			})
		},
		mounted: function() {
			this.uploaddata.pusern=localStorage.getItem('pusern');
			this.getdata();
			this.host = this.$host
			this.breadcrumb=this.$root.myrouter
		},
		methods: {
			ch(){
				var buildid='';
				for(var i in this.chproperty.buildch){
					buildid= this.chproperty.buildch[i]
				}
				for(i in this.chproperty.floorch){
					buildid= this.chproperty.floorch[i][0]
				}
				for(i in this.chproperty.offices){
					buildid= this.chproperty.offices[i].buildingid
				}
				
				this.buluform.buildingid=buildid
				this.buluform.building=this.chproperty.propertyname;
			},
			handleRemove(file) {
				for(var i=0;i<this.fileList.length;i++){
					if(this.fileList[i].uid==file.uid){
						this.fileList.splice(i,1);
						break;
					}
				}
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleDownload(file) {
				console.log(file);
				console.log(this.fileList);
			},
			uploadsuccess(response, file, fileList) {
				this.buluform.pics=[];
				this.fileList=fileList;
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].response == undefined) {
						break;
					}else{
						this.buluform.pics.push(this.fileList[i].response.fpath)
					}
				}
				if (i == this.fileList.length) {
					this.buluform.pics=this.buluform.pics.join(',')
					this.sub();
				}
				
				//this.buluform.pics.push(response);
			},
			uploadchange(file, fileList){
				this.fileList=fileList;
			},
			createrepair() {
				if (this.fileList.length == 0) {
					this.sub();
				} else {
					for (var i = 0; i < this.fileList.length; i++) {
						if (this.fileList[i].response == undefined) {
							break;
						}
					}
					if (i == this.fileList.length) {
						this.sub();
					}else{
						this.$refs.upload.submit();
					}
					
				}
				
				
			},
			sub() {
				this.buluform.do = 'add'
				this.$ajax({
					url: 'repair/',
					data: this.buluform,
				}).then(res => {
					if (res.error > 0) {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						this.isbulushow = false;
						this.getdata();
					}
				})

			},

			handleDelete(index, row) {
				this.$ajax({
					url: 'repair/',
					data: {
						'do': 'del',
						'id': row.id
					},
				}).then(res => {
					this.getdata();

				})
			},
			modify(row) {
				if (row) {
					this.accontract = row
					this.modifyform = JSON.parse(JSON.stringify(row));
					this.modifyform.state = parseInt(this.modifyform.state);

					this.ismodify = true;
				} else {
					this.$refs['modifyform'].validate((valid) => {
						if (valid) {
							this.modifyform.do = 'add'
							this.modifyform.st = this.modifyform.state;
							this.$ajax({
								url: 'contract/',
								data: this.modifyform,
							}).then(res => {

								if (res.error == 0) {
									for (var i in this.modifyform) {
										this.accontract[i] = this.modifyform[i]
									}
									console.log(this.state);
									console.log(this.modifyform['state']);
									this.accontract['statename'] = this.state[this.modifyform['state']]
									this.$alert(res.msg, '提示', {
										confirmButtonText: '确定'
									});

								} else {
									this.accontract.state = 3;
									this.$alert(res.msg, '提示', {
										confirmButtonText: '确定'
									});

								}
							})
						}
					})
				}
			},
			rerent(row) {
				if (row) {

					this.accontract = row;
					this.isrerent = true
				} else {
					this.$refs['backform'].validate((valid) => {
						if (valid) {
							this.backform.id = this.accontract.id
							this.$ajax({
								url: 'contract/',
								data: this.backform,
							}).then(res => {

								if (res.error == 3) {
									this.$alert(res.msg, '提示', {
										confirmButtonText: '确定'
									});

								} else {
									this.accontract.state = 3;
									this.$alert(res.msg, '提示', {
										confirmButtonText: '确定'
									});

								}
							})
						}
					})
				}
			},
			calset() {
				this.isshow = false;
			},
			subset() {

				var md = [];
				for (var i = 0; i < this.tmptp.length; i++) {
					if (this.tmptp) {
						md.push([this.acrepair.retp[i].id, this.tmptp[i]]);
					}
				}
				this.$ajax({
					url: 'repair/',
					data: {
						'do': 'update',
						'id': this.acrepair.id,
						'state': this.repairform.state,
						'source': this.repairform.source,
						'paytype': this.repairform.paytype,
						'fee': this.repairform.paytype == 1 ? this.repairform.fee : 0,
						'md': md,
						'remark': this.repairform.remark
					},
				}).then(res => {
					if (res.error == 0) {
						this.isshow = false;
						this.page(this.pgno)
					}else{
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
						
					}
				});
			},
			goinfo(row) {
				this.$router.push('repairedit?id='+row.id);
				// this.acrepair = row
				// var a=[];
				// if(this.acrepair.build!=''){
				// a.push(this.acrepair.build)
				// }
				// if(this.acrepair.office!=''){
				// a.push(this.acrepair.office)
				// }
				// if(this.acrepair.other!=''){
				// a.push(this.acrepair.other)
				// }
				// this.acrepair.address=a.join('，')
				// this.$ajax({
				// 	url: 'repair/',
				// 	data: {
				// 		'do': 'info',
				// 		'id': row.id
				// 	},
				// }).then(res => {

				// 	this.retp = res
				// 	this.repairform = JSON.parse(JSON.stringify(this.acrepair));
				// 	for (var i = 0; i < res.length; i++) {
				// 		if (res[i].repairuid == 0) {
				// 			this.tmptp[i] = ''
				// 		} else {
				// 			this.tmptp[i] = res[i].repairuid
				// 		}
				// 	}

				// 	this.isshow = true;
				// 	this.repairform.state = parseInt(this.repairform.state)
				// })
			},
			page(v) {
				this.pgno = v;
				this.getdata({
					pgno: v
				});
			},
			getdata(v) {
				if (typeof(v) != 'object' || v.pgno === undefined) {
					this.pgno = 1;
				}
				this.$ajax({
					url: 'repair/',
					data: {
						'do': 'list',
						pgno: this.pgno,
						pgsize:this.pgsize,
						state: this.search.state,
						tp:this.search.tp
					},
				}).then(res => {
					this.list = res.res
					for(var i=0;i<this.list.length;i++){
						var a=[this.list[i].build,this.list[i].office,this.list[i].other];
						a=a.join('，').replace(/^(，+)|(，+)$/g,'');
						this.list[i].adr=a
					}
					this.pgcount = parseInt(res.count);
					this.statemap = res.state
					this.repairtype = res.repairtype
				})
			}

		}
	};
</script>

<style scoped>
	.rlist>div {
		margin: 10px 0;
		display: flex;
	}

	.rlist>div>span {
		display: inline-block;
		width: 100px;
		text-align: right;
	}

	.buluform .des-label {
		width: 100px;
	}
	.image{
		width:100px;
		margin-right:10px;
		margin-bottom:10px;
		display: inline-block;
	}
	.address{
		padding: 10px 20px 10px 0;margin:10px 0px 10px 0;background-color: #f5f5f5;border-radius: 10px;
	}
	.address .el-form-item{
		margin-bottom:5px;
	}
</style>
