//引入vue
import Vue from 'vue';
import VueRouter from 'vue-router';
//第三方库需要use一下才能用
Vue.use(VueRouter)
import index  from './pages/index.vue';
import dashboards  from './pages/dashboards.vue';
import customer  from './pages/customer.vue';
import editcustomer  from './pages/editcustomer.vue';
import users  from './pages/users.vue';
import usersedit  from './pages/usersedit.vue';
import role  from './pages/role.vue';
import officeinfo  from './pages/officeinfo.vue';
import roleedit  from './pages/roleedit.vue';
import editbuilding  from './pages/editbuilding.vue';
import area  from './pages/area.vue';
import area1  from './pages/area1.vue';
import office  from './pages/office.vue';
import areaset  from './pages/areaset.vue';
import areaedit  from './pages/areaedit.vue';
import repair  from './pages/repair.vue';
import repairedit  from './pages/repairedit.vue';

import contract  from './pages/contract.vue';
import billpanel  from './pages/billpanel.vue';
import bill  from './pages/bill.vue';
import billinfo  from './pages/billinfo.vue';
import meter  from './pages/meter.vue';
import contractinfo  from './pages/contractinfo.vue';
import contractform  from './pages/contractform.vue';
import navset  from './pages/navset.vue';
import sysinfo  from './pages/sysinfo.vue';
import sys  from './pages/sys.vue';
import indexset  from './pages/indexset.vue';
import replay  from './pages/replay.vue';
import article  from './pages/article.vue';
import articleedit  from './pages/articleedit.vue';
import reservation  from './pages/reservation.vue';
import reservation_eidt  from './pages/reservation_eidt.vue';
import reservation_order  from './pages/reservation_order.vue';
import apartment  from './pages/apartment.vue';
import maintenance  from './pages/maintenance.vue';
import test  from './pages/test.vue';
import monthtj  from './pages/monthtj.vue';
import asset  from './pages/asset.vue';

//定义routes路由的集合，数组类型
const routes=[
    //单个路由均为对象类型，path代表的是路径，component代表组件
    {path:'/index',component:index,'name':'首页'},
	{path:"/test",component:test,'name':'test',
	meta:{
		keepAlive:true
		}},
    {path:'/dashboards',component:dashboards,'name':'报修管理'},
	{path:"/customer",component:customer,'name':'客户管理',
	meta:{
		keepAlive:true
		}},
	{path:"/editcustomer",component:editcustomer,'name':'编辑客户'},
    {path:"/users",component:users,'name':'用户管理'},
    {path:"/usersedit",component:usersedit,'name':'编辑用户'},
    {path:"/editbuilding",component:editbuilding,'name':'编辑楼栋'},
    {path:"/role",component:role,'name':'角色管理'},
    {path:"/officeinfo",component:officeinfo,'name':'房间详情'},
	{path:"/roleedit",component:roleedit,'name':'编辑角色'},
	{path:"/area",component:resolve=>require(['@/pages/area'],resolve),'name':'楼栋统计'},
	{path:"/area1",component:area1,'name':'空间查询'},
	{path:"/areaedit",component:areaedit,'name':'楼层设置'},
	{path:"/office",component:office,'name':'房间管理'},
	{path:"/areaset",component:areaset,'name':'基础设置'},
	{path:"/repair",component:repair,'name':'报修记录'},
	{path:"/repairedit",component:repairedit,'name':'报修处理'},
	{path:"/contract",component:contract,'name':'合同管理'},
	{path:"/billpanel",component:billpanel,'name':'账单管理'},
	{path:"/bill",component:bill,'name':'账单列表',
	meta:{
		keepAlive:true
		}},
	{path:"/billinfo",component:billinfo,'name':'账单详情'},
	{path:"/meter",component:meter,
		meta:{
			keepAlive:true
		}
	},
	{path:"/contractinfo",component:contractinfo,'name':'合同详情'},
	{path:"/contractform",component:contractform,'name':'编辑合同'},
	{path:"/sysinfo",component:sysinfo,'name':'基础配置'},
	{path:"/sys",component:sys,'name':'园区管理'},
	{path:"/indexset",component:indexset,'name':'首页设置'},
	{path:"/navset",component:navset},
	{path:"/replay",component:replay,'name':'复盘管理'},
	{path:"/article",component:article,'name':'内容管理'},
	{path:"/articleedit",component:articleedit,'name':'内容编辑'},
	{path:"/reservation",component:reservation,'name':'预定管理'},
	{path:"/reservation/reservation_eidt",component:reservation_eidt,'name':'编辑场地'},
	{path:"/reservation_order",component:reservation_order,'name':'场地订单'},
	{path:"/apartment",component:apartment,'name':'公寓管理'},
	{path:"/maintenance",component:maintenance,'name':'维保管理'},
	{path:"/monthtj",component:monthtj,'name':'月度统计'},
	{path:"/asset",component:asset,'name':'档案管理'},
	
	{path:"/",component:index}
]

//实例化VueRouter并将routes添加进去
const router=new VueRouter({
//ES6简写，等于routes：routes
    routes,
	mode:'history'
});

//抛出这个这个实例对象方便外部读取以及访问
export default router