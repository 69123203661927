<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right" v-if="breadcrumb.length>0">
				<div class="pagetitle">{{breadcrumb[breadcrumb.length-1].name}}</div>
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadcrumb" :key="index" v-show="index!=breadcrumb.length-1">{{item.name}}</el-breadcrumb-item>
			</el-breadcrumb>
			<!-- <el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/areaset?bid='+this.filter.bid }">基础设置</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/areaedit?bid='+this.filter.bid }">楼层设置</el-breadcrumb-item>
				<el-breadcrumb-item>房间设置</el-breadcrumb-item>
			</el-breadcrumb> -->
		</div>

		<div class="panel">
			<div class="panel-header">{{filter.rid!=''?'编辑房间':'添加房间'}}</div>
			<div class="panel-body pad16">
				

				<el-form :model="ruleForm" :rules="rules" ref="ruleForm"
					label-width="100px" class="demo-ruleForm" >
					<el-form-item label="门牌名称" prop="roomnumber">
						<el-input v-model="ruleForm.roomnumber" style="width:200px"></el-input>
					</el-form-item>
					<el-form-item label="物业类型" prop="type">
						<el-radio-group v-model="ruleForm.type">
							<el-radio name="purpose" v-for="(item,index) in roomtype"
								:key="index" :label="item.fid">
								{{item.class_name}}
							</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="物业属性" prop="ptype">
						<el-radio-group v-model="ruleForm.ptype">
							<el-radio name="use" v-for="(item,index) in buildingtype"
								:key="index" :label="item.fid">
								{{item.class_name}}
							</el-radio>
						</el-radio-group>
					</el-form-item>
					
					<el-form-item label="建筑面积" prop="builduparea">
						<el-input v-model="ruleForm.builduparea" style="width:200px;" @change="autorentprice"><template
								slot="append">m<sup>2</sup></template></el-input>
					</el-form-item>
					<el-form-item label="套内面积" prop="buildinarea">
						<el-input v-model="ruleForm.buildinarea" style="width:200px;"><template
								slot="append">m<sup>2</sup></template></el-input>
					</el-form-item>
					<el-form-item label="层高" prop="height">
						<el-input v-model="ruleForm.height" style="width:200px;"><template
								slot="append">m</template>
						</el-input>
					</el-form-item>
					<el-form-item label="租金单价" prop="price">
						<el-input v-model="ruleForm.price" style="width:200px;" @change="autorentprice"><template
								slot="append">元/天/m<sup>2</sup></template></el-input>
					</el-form-item>
					<el-form-item label="物业单价" prop="property">
						<el-input v-model="ruleForm.property" style="width:200px;" @change="autorentprice"><template
								slot="append">元/天/m<sup>2</sup></template></el-input>
					</el-form-item>

					<el-form-item label="月租金" prop="monthprice">
						<el-input v-model="ruleForm.monthprice"  style="width:200px;"><template
								slot="append">元/月</template>
						</el-input>
					</el-form-item>
					<el-form-item label="月物业费" prop="monthproperty">
						<el-input v-model="ruleForm.monthproperty" style="width:200px;"><template
								slot="append">元/月</template>
						</el-input>
					</el-form-item>
					<el-form-item label="年租金" prop="yearprice">
						<el-input v-model="ruleForm.yearprice"  style="width:200px;"><template
								slot="append">元/年</template>
						</el-input>
					</el-form-item>
					<el-form-item label="年物业费" prop="yearproperty">
						<el-input v-model="ruleForm.yearproperty" style="width:200px;"><template
								slot="append">元/年</template>
						</el-input>
					</el-form-item>
					<el-form-item label="期数" prop="stage">
						<el-radio-group v-model="ruleForm.stage">
							<el-radio name="stage" v-for="(item,index) in stage"
								:key="index" :label="item.fid">
								{{item.class_name}}
							</el-radio>
						</el-radio-group>
						
					</el-form-item>
					<el-form-item label="区域" prop="area">
						<el-radio-group v-model="ruleForm.area">
							<el-radio name="area" v-for="(item,index) in officearea"
								:key="index" :label="item">
								{{item}}
							</el-radio>
						</el-radio-group>
						
					</el-form-item>
					<el-form-item label="用途" prop="use">
						<el-radio-group v-model="ruleForm.use">
							<el-radio name="use" label="1">
								自用
							</el-radio>
							<el-radio name="use" label="2">
								商用
							</el-radio>
							<el-radio name="use" label="3">
								公用
							</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="房间状态" prop="situation">
						<el-select v-model="ruleForm.situation" placeholder="请选择">
						<el-option
						v-for="item in situation"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="装修状态" prop="renovation_state">
						<el-radio-group v-model="ruleForm.renovation_state">
							<el-radio-button label="0" name="renovation_state">未装修</el-radio-button>
							<el-radio-button label="1" name="renovation_state">装修中</el-radio-button>
							<el-radio-button label="2" name="renovation_state">已装修</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="是否有空调" prop="airc">
						<el-radio-group v-model="ruleForm.airc">
							<el-radio-button label="1" name="airc">有</el-radio-button>
							<el-radio-button label="0" name="airc">没有</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="是否有家具" prop="furniture">
						<el-radio-group v-model="ruleForm.furniture">
							<el-radio-button label="1" name="furniture">有</el-radio-button>
							<el-radio-button label="0" name="furniture">没有</el-radio-button>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="出租统计" prop="statistics">
						<el-radio-group v-model="ruleForm.statistics">
							<el-radio-button label="1" name="statistics">统计</el-radio-button>
							<el-radio-button label="0" name="statistics">不统计</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="展示状态" prop="isshow">
						<el-radio-group v-model="ruleForm.isshow">
							<el-radio-button label="1" name="isshow">对外可见</el-radio-button>
							<el-radio-button label="0" name="isshow">对外隐藏</el-radio-button>
						</el-radio-group>
					</el-form-item>


					<el-form-item label="补充说明" prop="note">
						<el-input type="textarea" v-model="ruleForm.note" maxlength="255" style="width:500px" show-word-limit
							:autosize="{ minRows: 5}">
						</el-input>
					</el-form-item>
					<div style="padding:20px 0;margin-left: 100px;">
						<el-upload :action="host+'upimg/'" list-type="picture-card"
							ref="upload" name="upfile" :data="uploaddata" :on-success="uploadsuccess"
							:file-list="fileList" :auto-upload="false"
							:on-change="uploadchange">
							<i slot="default" class="el-icon-plus"></i>
							<div slot="file" slot-scope="{file}">
								<img class="el-upload-list__item-thumbnail" :src="file.url"
									alt="">
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview"
										@click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled"
										class="el-upload-list__item-delete"
										@click="handleDownload(file)">
										<i class="el-icon-download"></i>
									</span>
									<span v-if="!disabled"
										class="el-upload-list__item-delete"
										@click="handleRemove(file)">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>

						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>

					</div>

					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')">保存
						</el-button>
						<el-button @click="cancel()">取消</el-button>
					</el-form-item>
				</el-form>
										
			</div>
		</div>
		
	</div>
</template>

<script>

	export default {
		data() {
			return {
				officearea:['1区','2区','3区','4区','5区'],
				breadcrumb:[],
				uploaddata: {
					pusern: '',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				filter: {
					bid: '',
					fid: '',
					rid:''
				},
				stage:[],

				officetype: [{
					value: '',
					label: '全部类型'
				}, {
					value: '1',
					label: '办公'
				}, {
					value: '2',
					label: '商铺'
				}, {
					value: '3',
					label: '仓储'
				}, {
					value: '4',
					label: '其他'
				}],

				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				fileList: [],
				host: '',
				edit:false,

				roomtype:[],
				buildingtype:[],
				situation:[],
				ruleForm: {
					roomnumber: '',
					builduparea: '',
					buildinarea: '',
					height: '',
					price: '',
					monthprice: '',
					monthproperty: '',
					yearprice: '',
					yearproperty: '',
					statistics:1,
					isshow: 1,
					stage:'',
					area:'',
					use:'',
					airc:'',
					renovation_state:'',
					furniture:'',
					note: '',
					id:0
				},
				rules: {
					type: [{
						required: true,
						message: '请选择物业类型',
						trigger: 'change'
					}],
					name: [{
						required: true,
						message: '请输入门牌号',
						trigger: 'blur'
					}],
					builduparea: [{

						required: true,
						message: '请输入建筑面积',
						trigger: 'change'
					}, {
						pattern: /^\d+(\.\d+)?$/,
						message: '输入数字',
					}],
					buildinarea: [{
						pattern: /^\d+(\.\d+)?$/,
						message: '输入数字',
					}],
					price: [{
						required: false,
						message: '租金单价',
						trigger: 'change'
					}, {
						pattern: /^\d+(\.\d+)?$/,
						message: '输入数字',
					}],
					property: [{
						required: false,
						message: '物业单价',
						trigger: 'change'
					}, {
						pattern: /^\d+(\.\d+)?$/,
						message: '输入数字',
					}],
					airc:[
						{
							required: true,
							message: '是否有空调',
							trigger: 'blur'
						}
					],
					furniture:[
						{
							required: true,
							message: '是否有家具',
							trigger: 'blur'
						}
					],
					statistics:[
						{
							required: true,
							message: '出租统计',
							trigger: 'blur'
						}
					],
					isshow:[
						{
							required: true,
							message: '是否对外可见',
							trigger: 'blur'
						}
					]
				}
			}
		},
		beforeRouteEnter(to,from,next) {
			next(vm=>{
				vm.$root.myrouter=vm.$breadcrumb(vm.$root.myrouter,to)
			})
		},
		mounted: function() {
			this.breadcrumb=this.$root.myrouter
			this.uploaddata.pusern=localStorage.getItem('pusern');
			this.filter.bid = this.$route.query.bid?this.$route.query.bid:'';
			this.filter.fid = this.$route.query.fid?this.$route.query.fid:'';
			this.filter.rid = this.$route.query.rid?this.$route.query.rid:'';
			this.host = this.$host
			if(this.filter.rid){
				this.edit=true
			}
			this.getoffice();

		},
		methods: {
			
			autorentprice(){
				if(!this.edit){
					if (this.ruleForm.price && this.ruleForm.builduparea) {
						this.ruleForm.monthprice=this.ruleForm.price.mul(this.ruleForm.builduparea).mul(365).div(12).toFixed(2)
						this.ruleForm.yearprice=this.ruleForm.price.mul(this.ruleForm.builduparea).mul(365).toFixed(2)
					}
					if (this.ruleForm.property && this.ruleForm.builduparea) {
						this.ruleForm.monthproperty=this.ruleForm.property.mul(this.ruleForm.builduparea).mul(365).div(12).toFixed(2)
						this.ruleForm.yearproperty=this.ruleForm.property.mul(this.ruleForm.builduparea).mul(365).toFixed(2)
					}
				}
			},
			handleRemove(file) {
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].uid == file.uid) {
						this.fileList.splice(i, 1);
						break;
					}
				}
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleDownload(file) {
			},
			uploadsuccess(response, file, fileList) {
				this.ruleForm.pics = [];
				this.fileList = fileList;
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].response == undefined) {
						break;
					} else {
						if(typeof(this.fileList[i].response)=='object'){
							this.ruleForm.pics.push(this.fileList[i].response.fpath)
						}else{
							this.ruleForm.pics.push(this.fileList[i].response)
						}
					}
				}
				if (i == this.fileList.length) {
					this.sub();
				}

				//this.buluform.pics.push(response);
			},
			uploadchange(file, fileList) {
				this.fileList = fileList;
			},

			



			sub: function() {

				this.$ajax({
					url: 'room/',
					data: {
						'do': 'update',
						roomnumber: this.ruleForm.roomnumber,
						builduparea: this.ruleForm.builduparea,
						type: this.ruleForm.type,
						ptype: this.ruleForm.ptype,
						buildinarea: this.ruleForm.buildinarea,
						price: this.ruleForm.price,
						monthprice: this.ruleForm.monthprice,
						property: this.ruleForm.property,
						yearprice: this.ruleForm.yearprice,
						yearproperty: this.ruleForm.yearproperty,
						monthproperty: this.ruleForm.monthproperty,
						state: this.ruleForm.state!==''?this.ruleForm.state:2,
						note: this.ruleForm.note,
						building: this.filter.bid,
						height: this.ruleForm.height,
						floor: this.filter.fid,
						position: this.ruleForm.position,
						
						statistics:this.ruleForm.statistics,
						isshow:this.ruleForm.isshow,
						pics: this.ruleForm.pics,

						use:this.ruleForm.use,
						stage:this.ruleForm.stage,
						area:this.ruleForm.area,
						situation:this.ruleForm.situation,
						renovation_state:this.ruleForm.renovation_state,
						airc: this.ruleForm.airc,
						furniture: this.ruleForm.furniture,
						id: this.ruleForm.id
					}
				}).then(res => {
					
					this.$message({
						message: res.msg,
						type: res.error==0?'success':'warning'
					})
					this.$router.push('areaedit?bid='+this.filter.bid+'&fid='+this.filter.fid)
					if(this.ruleForm.id==''){
					sessionStorage.removeItem('roomindex')
					}
					//this.$router.go(-1)
				})
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.fileList.length == 0) {
							this.sub();
						} else {
							this.ruleForm.pics = [];
							for (var i = 0; i < this.fileList.length; i++) {
								if (this.fileList[i].response == undefined) {
									break;
								} else {
									if(typeof(this.fileList[i].response)=='object'){
										this.ruleForm.pics.push(this.fileList[i].response.fpath)
									}else{
										this.ruleForm.pics.push(this.fileList[i].response)
									}
								}
							}
							
							if (i == this.fileList.length) {
								this.sub();
							} else {
								this.$refs.upload.submit();
							}
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			cancel(){
				this.edit = false;
				this.$router.go(-1)
			},
			
			getoffice() {
				this.$ajax({
					url: 'floor/',
					data: {
						
						'do': 'floor',
						'bid': this.filter.bid,
						'fid': this.filter.fid,
						'rid': this.filter.rid
					}
				}).then(res => {

					this.roomtype=res.roomtype.res;
					this.buildingtype=res.buildingtype.res;
					this.stage=res.stage;
					this.situation=res.situation;
					this.officearea=res.officearea;
					if(this.filter.rid!=''){
						this.ruleForm=res.rooms[0];

						if (this.ruleForm.pics.length > 0) {
							for (var i = 0; i < this.ruleForm.pics.length; i++) {
								this.fileList.push({
									name: '',
									url: this.host + this.ruleForm.pics[i],
									response: this.ruleForm.pics[i]
								})
							}
						}
					}
					
					const that=this
					setTimeout(function(){that.edit=false;},500);


				})
			}
		}
	};
</script>

<style scoped>
	.se{
		width:30px;
		height: 15px;
		margin-right:10px;
		margin-left:10px;
		border-radius: 4px;
	}
	.roominfobox{
		margin: 10px;
		background-color: #fff;
		border-radius: 5px;
		padding:5px;
	}

	.tj {
		text-align: center;
		padding: 15px 0;
		font-size: 12px;
		border-radius: 10px;
		margin: 10px;
		background-color: #efefef;
	}

	.num {
		font-size: 20px;
		margin-top: 10px;
	}

	.tabl,
	.tablt {
		display: flex;
	}

	.floorname {
		width: 70px;
	}

	.lou {
		padding: 10px;
		font-weight: bold;
		transform: translateY(50%);
		text-align: center;
	}

	.tablt div {
		flex: 1;
		padding: 10px;
		text-align: center;
		border-left: 1px dashed #ddd;
		border-bottom: 1px dashed #ddd;
	}

	.tabl div {
		border-left: 1px dashed #ddd;
		padding: 5px;
		flex: 1;
		text-align: center;
		min-height: 31px;
		font-size: 12px;

		word-break: break-all;
	}

	.dd {
		background-color: #fff;
		border-radius: 7px;
		margin: 15px;
		font-size: 13px;
		box-shadow: 0 0 8px #ddd;
		cursor: pointer;
	}


	.acbuild {
		background-color: #b3b4d5;
		color: #fff;
	}


	.point {
		height: 25px;
		color: #fff;
		font-size: 12px;
		border-radius: 5px;
		box-sizing: border-box;
		padding: 5px;
		cursor: pointer;
	}


	.ch {
		background: #000;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		position: absolute;
		right: -10px;
		top: -10px;
		z-index: 10;
	}

	.markbg {
		position: fixed;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		z-index: 100;
		background: rgba(0, 0, 0, 0.3);
	}

	.pics {
		width: 100px;
	}

	.btns {
		font-size: 12px;
		align-items: center;
	}



	.po {
		position: absolute;
		width: 32px;
		height: 40px;
		text-align: center;
		line-height: 33px;
		background-size: cover;
	}

	.h180 {
		height: 54px
	}

	.h132 {
		height: 66px
	}
</style>
